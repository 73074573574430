/* eslint-disable */
import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";

// Icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import { BorderColor as BorderColorIcon } from "@mui/icons-material";
import { useNavigate } from "react-router";
import NewsDialogs, { newsDialogType } from "../../../dialogs/NewConfirmDialog/NewsDialogs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../../utils/notifications";
import { newsServices } from "../../../../services/news";
import STATIC_ROUTES from "../../../../route/STATIC_ROUTES";
import PermissionWrapper, { PermissionsSchema } from "../../../auth/PermissionsWrapper";

const NewsActions = ({ news }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);

  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    handleClose();
  };

  const handleGoToEditNews = () => {
    navigate(STATIC_ROUTES.news, { state: { type: "edit", id: news?.id } });
  };

  const pinNewsMutation = useMutation({
    mutationFn: newsServices.pinNews,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["news"]);
      handleClose();
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handlePinNews = () => {
    pinNewsMutation.mutate({ id: news?.id, is_pinned: !news?.is_pinned });
  };

  const deleteNewsMutation = useMutation({
    mutationFn: newsServices.deleteNews,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["news"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleDeleteNews = () => {
    deleteNewsMutation.mutate({ id: news?.id });
  };

  return (
    <div style={{ alignSelf: "center", alignSelf: "flex-start" }}>
      <IconButton
        id="news-actions"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="news-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "news-actions",
        }}
        sx={menu.menuStyles}
      >
        <PermissionWrapper allowedPermission={PermissionsSchema.news.u}>
          <MenuItem onClick={handleGoToEditNews}>
            <BorderColorIcon sx={menu.iconStyles} />
            <span style={menu.actions}>Edit</span>
          </MenuItem>
        </PermissionWrapper>
        <PermissionWrapper allowedPermission={PermissionsSchema.news.pin}>
          <MenuItem onClick={handlePinNews}>
            {news?.is_pinned ? (
              <PushPinOutlinedIcon sx={menu.iconStyles} />
            ) : (
              <PushPinIcon sx={menu.iconStyles} />
            )}
            <span style={menu.actions}>{news?.is_pinned ? `Unpin News` : `Pin News`}</span>
          </MenuItem>
        </PermissionWrapper>
        <PermissionWrapper allowedPermission={PermissionsSchema.news.delete_news}>
          <MenuItem onClick={() => setOpenDelete(true)}>
            <DeleteForeverIcon sx={menu.iconStyles} />
            <span style={menu.actions}>Delete</span>
          </MenuItem>
        </PermissionWrapper>
      </Menu>

      {openDelete && (
        <NewsDialogs
          open={openDelete}
          onClose={handleCloseDelete}
          type={newsDialogType.delete}
          action={handleDeleteNews}
        />
      )}
    </div>
  );
};

export default NewsActions;

const menu = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#D1D2D2",
  },
};
