/* eslint-disable */
import { useQuery } from "@tanstack/react-query";
import { filesService } from "../../../services/files";
import FilesTable, { filesTableTypes } from "./FilesTable";
import DeleteMembersModal from "../../modal/DeleteMembersModal";
import { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";

const GeneralFilesTable = ({ selected, setSelected }) => {

    const { data: generalFiles, isLoading, isError, isPending } = useQuery({
        queryKey: ["generalFiles"],
        queryFn: filesService.getAllGeneralFiles,
    });

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <p className="table_title">General Resources</p>
                {selected.length > 0 && (
                    <DeleteMembersModal
                        data={{ selected: selected }}
                        type={confirmTypes.deleteFiles}
                        setSelected={setSelected}
                    />
                )}
            </div>
            {!isLoading && <FilesTable type={filesTableTypes.projectsGeneral} data={generalFiles?.data?.files || []} selected={selected} setSelected={setSelected} />}
        </>
    )
}


export default GeneralFilesTable;