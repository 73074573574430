/* eslint-disable */

import { Button } from "@mui/material";

export const buttonTypes = {
  create: "create",
  cancel: "cancel",
};

const DefaultButton = ({ buttonType, title, onClickEvent, ...props }) => {
  const { disabled } = props;

  let content;

  if (buttonType === buttonTypes.create) {
    content = (
      <Button
        sx={disabled ? buttonStyles.createBtnDisabled : buttonStyles.createButton}
        onClick={() => onClickEvent()}
        {...props}
      >
        {title}
      </Button>
    );
  } else if (buttonType === buttonTypes.cancel) {
    content = (
      <Button sx={buttonStyles.cancelButton} onClick={() => onClickEvent()} {...props}>
        {title}
      </Button>
    );
  }

  return content;
};

export default DefaultButton;

const buttonStyles = {
  createButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  createBtnDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "140px",
    height: "54px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
