/* eslint-disable */
import axios from "axios";
import { saveAs } from "file-saver";
import { errorHandler, options } from "./index";
import { API_URL } from "../config";
import getTheUserState from "../utils/userState";
import { toast_notifications } from "../utils/notifications";

const userState = getTheUserState();
const user = userState?.user;
const token = userState?.token;
const allowedOrigins =
  "http://localhost:3000,http://localhost:3001,http://motcos.com,http://localhost:8090,http://motcos.tvm-engineering.com";

export function fileDownload(filename, setFileDownloadProgress, directory) {
  return async (dispatch) => {
    try {
      const response = await axios(
        {
          url: API_URL + "/downloadFile/" + filename,
          method: "POST",
          responseType: "blob",
          headers: {
            Authorization: token,
            "Access-Control-Allow-Origin": allowedOrigins,
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Content-Type-Options": "nosniff",
            credentials: "same-origin", // include, same-origin, *omit
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            withCredentials: true,
            withAuthentication: true,
          },

          onDownloadProgress(progressEvent) {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setFileDownloadProgress(progress);
          },
          data: {
            directory,
            id: user.id,
            name: user.name,
          },
        }
        // {
        //   params: {
        //     directory: "test",
        //   },
        // }
      );

      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        const fileName = response.headers["content-disposition"].substring(21, 70);
        const blob = new Blob([response.data], { type: "application/*;charset=utf-8" });
        saveAs(blob, fileName);
        return true;
      }
    } catch (error) {
      console.warn("Error:", error);
      dispatch(errorHandler(error));
    }
  };
}

export const getAllFiles = () => {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/files", {}, "get"));

      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };
};

export const getFilesTerms = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + `/filesTerms/${data}`, {}, "get"));

      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };
};

export const getAllFilesByIds = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + `/filesByIds/${data}`, {}, "get"));

      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      dispatch(errorHandler(error));
    }
  };
};

export const filesService = {
  getAllFilesAdmin: () => axios(options(API_URL + `/files`, {}, "get")),
  getAllFiles: (data) =>
    axios(options(API_URL + `/files/${data?.companies}`, {}, "get", { user_id: data.user_id })),
  getAllFilesByIds: (data) => axios(options(API_URL + `/filesByIds/${data}`, {}, "get")),
  getAllGeneralFiles: () => axios(options(API_URL + "/generalFiles", {}, "get")),
  getFilesTerms: (data) => axios(options(API_URL + `/filesTerms/${data}`, {}, "get")),
  getFileById: (data) => axios(options(API_URL + `/files/single/${data}`, {}, "get")),
  updateInCatalog: (body) => axios(options(API_URL + "/files/updateInCatalog", body, "post")),
  deleteFile: (body) => axios(options(API_URL + "/files", body, "delete")),
  getAllFilesInCatalog: (data) => axios(options(API_URL + `/files/catalog/all/${data}`, {}, "get")),
  getAllMyFiles: (data) => axios(options(API_URL + `/files/my-files/${data}`, {}, "get")),
  requestAccess: (file) => axios(options(API_URL + `/files/request-access`, { file }, "post")),
  requestFilesCount: () => axios(options(API_URL + "/files/requested-files/count", {}, "get")),
  requestFilesAll: () => axios(options(API_URL + "/files/requested-files/all", {}, "get")),
  requestFilesByCompanies: (data) =>
    axios(options(API_URL + `/files/requested-files/all/${data}`, {}, "get")),
  handleAccess: (data) =>
    axios(options(API_URL + "/files/requested-files/handleAccess", { data }, "post")),
  requestHistory: () => axios(options(API_URL + "/files/requested-files/history", {}, "get")),
  editFile: (body) => axios(options(API_URL + `/file/edit/${body?.data?.file_id}`, body, "post")),
  checkIfFileExists: (body) => axios(options(API_URL + `/file/isTaken/`, body, "post")),
};
