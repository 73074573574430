/* eslint-disable */
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Flag from "react-country-flag";
import { countries } from "countries-list";

const CountriesDropdown = ({ field, fieldState, sx, }) => {
  const [countryPlaceHolder, setCountryPlaceHolder] = useState("");

  useEffect(() => {
    setCountryPlaceHolder(field?.value);
  }, [field?.value]);

  function handleCountryChange(e, value) {
    field.onChange(value?.name);
    setCountryPlaceHolder(value?.name || field?.value);
  }

  const countryArray = Object.entries(countries).map(([code, name]) => ({
    code,
    name: name?.name,
  }));

  return (
    <Autocomplete
      {...field}
      options={countryArray}
      getOptionLabel={(option) => option.name}
      value={countryArray.find((country) => country.name === countryPlaceHolder) || null}
      onChange={(e, value) => handleCountryChange(e, value)}
      clearIcon={null}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue.toLowerCase();
        return options.filter(
          (option) =>
            option?.name.toLowerCase().includes(inputValue) ||
            option?.code.toLowerCase().includes(inputValue)
        );
      }}
      size="small"
      sx={sx || {
        fontFamily: "IBM Plex Sans",
        minWidth: "300px",
        // maxHeight: "43px",
        [`& fieldset`]: {
          borderRadius: "4px",
          fontFamily: "IBM Plex Sans",
        },
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <Flag countryCode={option.code} svg style={{ paddingRight: "15px", fontSize: "25px" }} />
          {option?.name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          value="test123"
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : null}
          label="Country *"
          variant="outlined"
        />
      )}
    />
  );
};

export default CountriesDropdown;
