import React from "react";
import "./simulationComponent.css";
import MATHLAB_IMG from "../../../assets/img/mathlab.png";
import BLOCKS_IMG from "../../../assets/img/blocks.png";
import ARROW_DOWN_IMG from "../../../assets/img/arrow_down.png";
import SIMULATION_REACT_IMG from "../../../assets/img/Simulation_react.png";
import SIMULATION_BUILDING_IMG from "../../../assets/img/Simulation_building.png";

import MotCosCard from "./MotcosCard";

/* eslint-disable-next-line */
function SimulationComponent({ title, subTitle }) {
  return (
    <section className="section_container_simulation">
      <div className="section_wrapper_simulation">
        <h1 className="section_title">{title}</h1>
        <h3 className="section_subTitle">{subTitle}</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
          }}
        >
          <div className="header_part">
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div style={{ display: "flex" }}>
                  <div>
                    <img
                      src={BLOCKS_IMG}
                      alt="blocks"
                      style={{
                        border: "1px solid transparent",
                        borderRadius: "100px",
                        // paddingRight: "1vh",
                      }}
                    />
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", paddingLeft: "1vh" }}>
                    <p className="text_header">MotCos</p>
                    <p className="text_subheader">SimLab</p>
                  </div>
                </div>
              </div>
              <div className="mathlab_container">
                <img src={MATHLAB_IMG} alt="mathal icon" />
              </div>
            </div>
          </div>
          <p className="text_inner_title">
            MatLab<sup>®</sup> & Simulink<sup>®</sup>
          </p>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              padding: "24px 0px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between", minWidth: "50%" }}>
              <img src={ARROW_DOWN_IMG} alt="arrow" />
              <img src={ARROW_DOWN_IMG} alt="arrow" />
            </div>
          </div>
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
            <MotCosCard
              type="SimLib"
              title="Simulation components"
              subTitle="for integration in existing Matlab® / Simulink®  environment"
              img={SIMULATION_REACT_IMG}
            />
            <MotCosCard
              type="SimApp"
              title="Stand-alone Windows application"
              img={SIMULATION_BUILDING_IMG}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SimulationComponent;
