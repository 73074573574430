/* eslint-disable */
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Menu,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import CreateTermsVersion, {
  createTermsType,
} from "../../dialogs/TermsAndConditions/TermsVersion/CreateTermsVersion";
import { getDateTime2, getDateTime } from "../../../utils/misc";
import DownloadIcon from "@mui/icons-material/Download";
import NewConfirmDialog, { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";
import { deleteTermsVersionById, updateTermsVersionActive } from "../../../services/termsVersion";
import VerificationStatusDropdown from "../../dropdown/VerificationStatusDropdown";
import { fileDownload } from "../../../services/files";
import TermVersionActionsDialog, {
  actionTypes,
} from "../../dialogs/TermsAndConditions/TermsVersion/TermVersionActionsDialog";
import NoTermVersionDialog from "../../dialogs/TermsAndConditions/NoTermVersion/NoTermVersionDialog";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";
import BlockPermissionComponent from "../../auth/BlockPermissionComponent";
import CreateTermsAndConditionsDialog, {
  termsAndConditionsType,
} from "../../dialogs/TermsAndConditions/CreateTermsAndConditions/CreateTermsAndConditions";
import { useQueryClient } from "@tanstack/react-query";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

export const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="top-start"
    {...props}
    classes={{ popper: className }}
    sx={{
      color: "#1E9DF2",
      margin: "0px 0px 0px 8px",
      cursor: "pointer",
      textAlign: "center",
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontFamily: "IBM Plex Sans",
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px" /* 160% */,
    letterSpacing: "0.2px",
  },
}));

const TermsTable = ({ data, toolsData, termsData }) => {

  const [expandedRows, setExpandedRows] = useState({});

  const handleToggleRow = (rowId) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowId]: !prev[rowId],
    }));
  };

  function Row(props) {
    const dispatch = useDispatch();

    const queryClient = useQueryClient();

    const { row, isOpen, handleToggle } = props;
    // const [open, setOpen] = useState(false);

    const termData = {
      id: row?.id,
      version_name: row?.terms_name,
      count: row?.termsVersion?.length,
    };

    // Term
    const [termForEdit, setTermForEdit] = useState({});
    const [isTermDeleteOpen, setIsTermDeleteOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    // Term Version
    const [versionForEdit, setVersionForEdit] = useState({});
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const [isUpdateOpen, setIsUpdateOpen] = useState(false);
    const [isEditVersionOpen, setIsEditVersionOpen] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [isViewOpen, setIsViewOpen] = useState(false);
    const [isStatusConfirmOpen, setIsStatusConfirmOpen] = useState(false);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const isMenuOpen2 = Boolean(anchorEl2);

    const [isNoTermDialogOpen, setIsNoTermDialogOpen] = useState(false);
    const [latestVersion, setLatestVersion] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    // Those are actions for Term
    const displayDeleteTermDialog = () => {
      setIsTermDeleteOpen(true);
      setAnchorEl(null);
    };

    // Those are actions for Term Version
    const handleEdit = () => {
      setAnchorEl(null);
      setAnchorEl2(null);
      setIsUpdateOpen(true);
    };

    const handleEditVersion = () => {
      setAnchorEl(null);
      setAnchorEl2(null);
      setIsEditVersionOpen(true);
    };

    const handleView = () => {
      setIsViewOpen(true);
      setAnchorEl2(null);
    };

    const displayDeleteDialog = () => {
      setIsDeleteOpen(true);
      setAnchorEl2(null);
    };

    const closeDeleteDialog = () => {
      setIsDeleteOpen(false);
    };

    const handleDelete = async () => {
      await dispatch(deleteTermsVersionById({ id: versionForEdit?.id }));
      queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
      setAnchorEl2(null);
    };

    const handleClose = () => {
      // setEditForm(true);
      // setProjectForEdit(id);
      setAnchorEl(null);
    };

    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const handleCreateNewVersion = () => {
      setIsNoTermDialogOpen(false);
      setLatestVersion(null);
      setIsCreateOpen(true);
    };

    const handleCreateVersionWithContent = (data) => {
      // Sort version by date.
      const sortedData = data?.termsVersion.sort(
        (a, b) => new Date(b?.created) - new Date(a?.created)
      );

      // Getting the last created object
      const latestVersion = sortedData[0];

      setLatestVersion(latestVersion?.content);
      setIsNoTermDialogOpen(false);
      setIsCreateOpen(true);
    };

    const handleIsCreateOpen = (row) => {
      const termsLength = row?.termsVersion?.length;

      if (termsLength) {
        setIsNoTermDialogOpen(true);
      } else {
        setIsCreateOpen(true);
      }
    };

    const handleTermForEdit = (event, row) => {
      setAnchorEl(event.currentTarget);
      setTermForEdit(row);
    };

    const handleSetVersionForEdit = (event, row) => {
      setAnchorEl2(event.currentTarget);
      setVersionForEdit(row);
    };

    async function downloadFile(row) {
      const directory = "terms";
      const versionName = row.version_name + ".pdf";
      setAnchorEl2(null);
      await dispatch(fileDownload(versionName, () => { }, directory));
    }

    const closeStatusDialog = () => {
      setIsStatusConfirmOpen(false);
    };

    async function handleChangeVersionStatus() {
      const data = {
        id: versionForEdit?.id,
        active: !versionForEdit?.active,
        terms_and_condition_id: versionForEdit?.terms_and_condition_id,
      };
      await dispatch(updateTermsVersionActive(data));
      queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    }

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleToggle(row.id)}>
              {isOpen ? (
                <RemoveIcon sx={{ color: "#1E9DF2" }} />
              ) : (
                <AddIcon sx={{ color: "#1E9DF2" }} />
              )}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <div style={{ display: "flex", alignItems: "center" }}>
              {row.terms_name}
              {(row?.project_id && row?.project_name) ? (
                <LightTooltip title={row.project_name}>
                  <FolderOutlinedIcon />
                </LightTooltip>
              ) : null}
            </div>
          </TableCell>
          <TableCell>{row.description}</TableCell>
          <TableCell>{row.termsVersion?.length}</TableCell>
          <TableCell>{getDateTime(row.created)}</TableCell>
          <PermissionWrapper allowedPermission={PermissionsSchema.terms.add_term_version}>
            <TableCell>
              <Button
                variant="contained"
                onClick={() => handleIsCreateOpen(row)}
                sx={{
                  backgroundColor: "#1E9DF2",
                  fontSize: "14px",
                  textTransform: "capitalize",
                  borderRadius: "2px",
                }}
              >
                Add Version
              </Button>
            </TableCell>
          </PermissionWrapper>
          <TableCell>
            <IconButton
              id="term-actions"
              aria-controls={isMenuOpen ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={isMenuOpen ? "true" : undefined}
              onClick={(e) => handleTermForEdit(e, row)}
            >
              <MoreHorizIcon
                sx={{
                  color: "#D1D2D2",
                  "&:hover": {
                    color: "#8D8E8F",
                  },
                }}
              />
            </IconButton>
          </TableCell>
          <TableCell>
            <PermissionWrapper allowedPermission={PermissionsSchema.terms.add_term_version}>
              <CreateTermsVersion
                open={isCreateOpen}
                setOpen={setIsCreateOpen}
                type={createTermsType.create}
                data={termData}
                defaultContent={latestVersion ? latestVersion : null}
              />
            </PermissionWrapper>
            {isTermDeleteOpen && (
              <TermVersionActionsDialog
                open={isTermDeleteOpen}
                setOpen={setIsTermDeleteOpen}
                data={termForEdit}
                type={actionTypes.delete_term}
                tools={toolsData}
                termsData={termsData}
              />
            )}
            <NoTermVersionDialog
              open={isNoTermDialogOpen}
              setOpen={setIsNoTermDialogOpen}
              handleNewVersion={handleCreateNewVersion}
              handleNewVersionWithContent={handleCreateVersionWithContent}
              data={row}
            />
          </TableCell>
        </TableRow>
        <Menu
          id="term-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "term-actions",
          }}
          sx={materialUiStyles.menuStyles}
          elevation={2}
        >
          <PermissionWrapper allowedPermission={PermissionsSchema.terms.update_term}>
            <MenuItem onClick={handleEdit}>
              <BorderColorIcon sx={materialUiStyles.iconStyles} />
              <span style={materialUiStyles.actions}>Edit</span>
            </MenuItem>
          </PermissionWrapper>
          <PermissionWrapper allowedPermission={PermissionsSchema.terms.delete_term}>
            <MenuItem onClick={displayDeleteTermDialog}>
              <DeleteOutlineIcon sx={materialUiStyles.iconStyles} />
              <span style={materialUiStyles.actions}> Delete</span>
            </MenuItem>
          </PermissionWrapper>
        </Menu>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                {row?.termsVersion.length > 0 ? (
                  <Table size="small" aria-label="purchases">
                    <TableHead sx={{ background: "#F4F4F4" }}>
                      <TableRow>
                        <TableCell sx={materialUiStyles.headerStyle}>Version Name</TableCell>
                        <TableCell sx={materialUiStyles.headerStyle}>Verification Status</TableCell>
                        <TableCell sx={materialUiStyles.headerStyle}>State</TableCell>
                        <TableCell sx={materialUiStyles.headerStyle}>Creational Date</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row?.termsVersion?.map((versionRow, index) => {
                        return (
                          <TableRow
                            key={versionRow.id}
                            sx={{ width: "100%", margin: "0px 40px 0px 0px" }}
                          >
                            <TableCell component="th" scope="row">
                              {versionRow.version_name}
                            </TableCell>
                            <TableCell>
                              <BlockPermissionComponent
                                allowedPermission={PermissionsSchema.terms.status_term_version}
                              >
                                <VerificationStatusDropdown data={versionRow} />
                              </BlockPermissionComponent>
                            </TableCell>

                            <TableCell>
                              <BlockPermissionComponent
                                allowedPermission={PermissionsSchema.terms.state_term_version}
                              >
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={versionRow.active}
                                      onClick={() => {
                                        setIsStatusConfirmOpen(true);
                                        setVersionForEdit(versionRow);
                                      }}
                                      sx={
                                        versionRow.active
                                          ? materialUiStyles.switchActive
                                          : materialUiStyles.switchInActive
                                      }
                                      disabled={
                                        versionRow.status !== "verified" ||
                                          versionRow.active === true
                                          ? true
                                          : false
                                      }
                                    />
                                  }
                                  label={versionRow.active ? "Active" : "Inactive"}
                                />
                              </BlockPermissionComponent>
                            </TableCell>

                            <TableCell>{getDateTime2(versionRow.created)}</TableCell>
                            <TableCell>
                              <IconButton
                                id="version-actions"
                                aria-controls={isMenuOpen2 ? "basic-menu2" : undefined}
                                aria-haspopup="true"
                                aria-expanded={isMenuOpen2 ? "true" : undefined}
                                onClick={(e) => handleSetVersionForEdit(e, versionRow)}
                              >
                                <MoreHorizIcon
                                  sx={{
                                    color: "#D1D2D2",
                                    "&:hover": {
                                      color: "#8D8E8F",
                                    },
                                  }}
                                />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <Menu
                        id="version-menu"
                        anchorEl={anchorEl2}
                        open={isMenuOpen2}
                        onClose={handleClose2}
                        MenuListProps={{
                          "aria-labelledby": "version-actions",
                        }}
                        sx={materialUiStyles.menuStyles}
                        elevation={2}
                      >
                        <MenuItem onClick={handleView}>
                          <FileOpenIcon sx={materialUiStyles.iconStyles} />
                          <span style={materialUiStyles.actions}>View</span>
                        </MenuItem>
                        <PermissionWrapper
                          allowedPermission={PermissionsSchema.terms.edit_term_version}
                        >
                          {versionForEdit?.status !== "draft" ?
                            <LightTooltip title="You can only edit draft verification status versions" arrow placement="left">
                              <Box sx={{ margin: "0px" }}>
                                <MenuItem onClick={handleEditVersion} disabled={true}>
                                  <BorderColorIcon sx={materialUiStyles.iconStyles} />
                                  <span style={materialUiStyles.actions}>Edit</span>
                                </MenuItem>
                              </Box>
                            </LightTooltip>
                            : <MenuItem onClick={handleEditVersion}>
                              <BorderColorIcon sx={materialUiStyles.iconStyles} />
                              <span style={materialUiStyles.actions}>Edit</span>
                            </MenuItem>}
                        </PermissionWrapper>
                        <PermissionWrapper
                          allowedPermission={PermissionsSchema.terms.delete_term_version}
                        >
                          {versionForEdit.active === false && (
                            <MenuItem onClick={displayDeleteDialog}>
                              <DeleteOutlineIcon sx={materialUiStyles.iconStyles} />
                              <span style={materialUiStyles.actions}> Delete</span>
                            </MenuItem>
                          )}
                        </PermissionWrapper>
                        <PermissionWrapper
                          allowedPermission={PermissionsSchema.terms.download_term_version}
                        >
                          <MenuItem onClick={() => downloadFile(versionForEdit)}>
                            <DownloadIcon sx={materialUiStyles.iconStyles} />
                            <span style={materialUiStyles.actions}> Download</span>
                          </MenuItem>
                        </PermissionWrapper>
                      </Menu>
                      <PermissionWrapper allowedPermission={PermissionsSchema.terms.update_term}>
                        <CreateTermsVersion
                          open={isEditVersionOpen}
                          setOpen={setIsEditVersionOpen}
                          type={createTermsType.update}
                          data={versionForEdit}
                        />
                      </PermissionWrapper>
                      <NewConfirmDialog
                        key={1}
                        open={isDeleteOpen}
                        onClose={closeDeleteDialog}
                        data={termForEdit}
                        action={handleDelete}
                        type={confirmTypes.deleteTermVersion}
                      />
                      <PermissionWrapper
                        allowedPermission={PermissionsSchema.terms.status_term_version}
                      >
                        <NewConfirmDialog
                          key={2}
                          open={isStatusConfirmOpen}
                          onClose={closeStatusDialog}
                          action={handleChangeVersionStatus}
                          type={confirmTypes.changeTermVersionStatus}
                        />
                      </PermissionWrapper>
                      <CreateTermsVersion
                        open={isViewOpen}
                        setOpen={setIsViewOpen}
                        type={createTermsType.view}
                        data={versionForEdit}
                      />
                    </TableBody>
                  </Table>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        minWidth: "100%",
                        textAlign: "center",
                        color: "red",
                        fontFamily: "IBM Plex Sans",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "normal",
                        letterSpacing: "0.14px",
                      }}
                    >
                      No available versions
                    </p>
                  </div>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <PermissionWrapper allowedPermission={PermissionsSchema.terms.update_term}>
          {isUpdateOpen && (
            <CreateTermsAndConditionsDialog
              open={isUpdateOpen}
              onClose={() => setIsUpdateOpen(false)}
              type={termsAndConditionsType.editTerm}
              term={row || {}}
            />
          )}
        </PermissionWrapper>
      </Fragment >
    );
  }

  return (
    <TableContainer>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell sx={materialUiStyles.headerStyle}>Terms Name</TableCell>
            <TableCell sx={materialUiStyles.headerStyle}>Description</TableCell>
            <TableCell sx={materialUiStyles.headerStyle}>Versions</TableCell>
            <TableCell sx={materialUiStyles.headerStyle}>Date</TableCell>
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row) => (
            <Row key={row.terms_name} row={row} isOpen={!!expandedRows[row.id]}
              handleToggle={handleToggleRow} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TermsTable;

const materialUiStyles = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "var(--main-grey-white, #FFF)",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  iconStyles: {
    color: "#D1D2D2",
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  headerStyle: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    // padding: "8px 0px",
  },
  switchActive: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#1E9DF2",
    },
    "& .Mui-checked+.MuiSwitch-track": {
      backgroundColor: "#F4F4F4",
      border: "1px solid gray",
    },
  },
  switchInActive: {
    "& .MuiSwitch-thumb": {
      backgroundColor: "#D1D2D2",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "whitesmoke",
      border: "1px solid gray",
    },
  },
};
