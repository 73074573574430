/* eslint-disable */
import { Autocomplete, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import { Checkbox, TextField } from "@mui/material";
import { useState } from "react";

export const filterTypes = {
  active: "active",
  archived: "archived",
};

const ProjectsFilter = ({ handleFilterChange, handleSearchChange, handleChosenTeam, handlePickedCompany, companies, teams, pickedCompany }) => {

  const [checkObject, setCheckObject] = useState({ isFavorite: false, archived: true, active: true });

  const [forceTeamRerender, setForceTeamRerender] = useState(0);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    let objectUpdated = { ...checkObject };

    if (name === filterTypes.active) {
      const activeValue = checked;
      objectUpdated = { ...checkObject, active: activeValue }
      setCheckObject(objectUpdated);
    }
    if (name === filterTypes.archived) {
      const archivedValue = checked;
      objectUpdated = { ...checkObject, archived: archivedValue }
      setCheckObject(objectUpdated);
    }

    handleFilterChange(objectUpdated);
  };

  const onChangeCompany = (company) => {
    handlePickedCompany(company);
    setForceTeamRerender(prev => prev + 1);
  }

  return (
    <div style={projectFiltersStyles.filterContainer}>
      <div>
        <TextField
          label="Search by name or ID..."
          variant="standard"
          fullWidth
          onChange={handleSearchChange}
        />
        <div style={projectFiltersStyles.section}>
          <p style={projectFiltersStyles.label}>Filter by Type</p>
          <FormControl variant="standard">
            <FormGroup>
              <FormControlLabel
                control={<Checkbox name="active" defaultChecked onClick={handleCheckboxChange} />}
                label="Active"
              />
              <FormControlLabel
                control={<Checkbox name="archived" defaultChecked onClick={handleCheckboxChange} />}
                label="Archived"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div>
          <p className="filter_title">Filter by Company & Team</p>
          <Autocomplete
            id="searchable-dropdown"
            options={companies || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => onChangeCompany(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                size="small"
                sx={{
                  fontFamily: "IBM Plex Sans",
                  minWidth: "220px",
                  paddingBottom: "24px",
                  [`& fieldset`]: {
                    borderRadius: "4px",
                    fontFamily: "IBM Plex Sans",
                  },
                }}
              />
            )}
          />
          <Autocomplete
            key={forceTeamRerender}
            id="searchable-dropdown"
            options={teams || []}
            getOptionLabel={(option) => option.name}
            onChange={(event, value) => handleChosenTeam(value)}
            disabled={!pickedCompany || teams?.length <= 0}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Team"
                size="small"
                sx={{
                  fontFamily: "IBM Plex Sans",
                  minWidth: "220px",
                  paddingBottom: "24px",
                  [`& fieldset`]: {
                    borderRadius: "4px",
                    fontFamily: "IBM Plex Sans",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectsFilter;

const projectFiltersStyles = {
  filterContainer: {
    borderRight: "1px solid #E8E8E9",
    // minHeight: "819px",
    // height: "100%",
    overflow: "auto",
    minWidth: "276px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "32px 40px 16px 40px",
  },
  label: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
  },
  section: {
    marginTop: "28px",
  },
  formControllStyle: {
    marginTop: "16px",
    // borderRadius: "4px",
  },
};
