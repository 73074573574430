import React from "react";

function TestPage() {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <p style={{ fontSize: "clamp(0.875rem, 0.637rem + 1.191vw, 4.688rem)" }}>
        this is the test page
      </p>
      <div style={{ border: "1px solid black", height: "1000px", overflow: "auto", flex: 1 }}>
        <div style={{ height: "800px" }}>Cool test</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          border: "1px solid #E8E8E9",
          height: "80px",
          background: "white",
          flexShrink: 0,
        }}
      >
        <p style={{ textAlign: "flex-end" }}>Footer</p>
      </div>
    </div>
  );
}

export default TestPage;
