/* eslint-disable */
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import getTheUserState from "../../utils/userState";
// import { useAppSelector } from "../../app/hooks";
// import { selectUser } from "../../app/features/usersSlice";

// export const userRoles = {
//   superAdmin: "super-admin",
//   admin: "admin",
// };

export function RouteGuard(Element, allowedPermissions) {
  // const user = useAppSelector(selectUser);

  const user = getTheUserState();
  const userPermissions = user?.user?.role.permissions;

  const hasPermission = (userPermissions, allowedPermissions) => {
    return allowedPermissions?.some((permission) => {
      return userPermissions?.some(
        (userPermission) =>
          userPermission.permission_name === permission && userPermission.status === true
      );
    });
  };
  // Check if the user have any of the allowed permissions
  const userHasPermission = hasPermission(userPermissions, allowedPermissions);

  let content = <Outlet />;

  if (userHasPermission) {
    content = <Element />;
  } else {
    content = <Navigate to="/dashboard" />;
  }

  return content;
}
