/* eslint-disable */

const UserStatusComponent = (status) => {
  const colorSchema = {
    pending: {
      color: "#DFB844",
      textAlign: "center",
      padding: "5px 15px",
      width: "fit-content",
      textTransform: "capitalize",
      background: "#FCF8EC",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
    active: {
      color: "#2D9933",
      textAlign: "center",
      padding: "5px 15px",
      width: "fit-content",
      borderRadius: "2px",
      background: "#EAF5EB",
      textTransform: "capitalize",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
    registered: {
      color: "#5D4A8A",
      textAlign: "center",
      padding: "5px 15px",
      width: "fit-content",
      background: "#EFEDF3",
      textTransform: "capitalize",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
    disabled: {
      color: "#D00101",
      textAlign: "center",
      padding: "5px 15px",
      width: "fit-content",
      background: "#FAE6E6",
      textTransform: "capitalize",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
  };

  return <p style={colorSchema[status]}>{status}</p>;
};

export default UserStatusComponent;
