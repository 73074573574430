/* eslint-disable */
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import GridViewIcon from "@mui/icons-material/GridView";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import "./dashboardSidebar.css";
import { Button, Divider, ListItemIcon } from "@mui/material";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import { SidebarData, SidebarPrivate } from "./sidebarData";
import NavigationWrapper from "../../auth/NavigationWrapper";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";
import { logoutUser } from "../../../services/auth";
import { useDispatch } from "react-redux";
import getTheUserState from "../../../utils/userState";
import { useMutation } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import { usersService } from "../../../services/users";

function DashboardSidebar() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const simAccess = getTheUserState()?.user?.sim_app_access;
  const userInfo = getTheUserState();

  const logout = () => {
    dispatch(logoutUser());
  };

  const [collapsSidebar, setCollapsSidebar] = useState(false);

  const openSimMutation = useMutation({
    mutationFn: usersService.openSimApp,
    onSuccess: (data) => {
      // toast_notifications.success(data?.data?.message);
      window.open(data?.data?.url, `_blank`)
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleOpenSim = () => {
    if (simAccess) {
      openSimMutation.mutate({ userId: userInfo?.user?.id });
    }
  }

  return (
    <div
      className="sidebar_container"
      style={{
        minWidth: collapsSidebar ? "90px" : "230px",
        maxWidth: collapsSidebar ? "90px" : "230px",
        marginLeft: collapsSidebar ? "-11px" : "0px",
        transition: "all 0.3s ease",
      }}
    >
      <div className="sidebar_navigation">
        <Button
          onClick={() => setCollapsSidebar(!collapsSidebar)}
          sx={{ width: "100%", justifyContent: "space-around", color: "#D1D2D2" }}
        >
          <span
            style={{
              display: collapsSidebar ? "none" : "block",
              marginLeft: collapsSidebar ? "" : "34px",
            }}
          >
            Menu
          </span>
          <ChevronLeftIcon
            sx={{
              transform: collapsSidebar ? "rotate(180deg)" : "rotate(0deg)",
              transition: "all 0.3s ease",
              marginLeft: collapsSidebar ? "" : "auto",
            }}
          />
        </Button>
        <Link
          to={SidebarData[0].url}
          className={
            location.pathname === SidebarData[0].url
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color:
                  location.pathname === SidebarData[0].url
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                minWidth: "30px",
                marginLeft:
                  location.pathname === SidebarData[0].url
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
              }}
            >
              {SidebarData[0].icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[0].name}
            </span>
          </div>
        </Link>
        <Link
          to={SidebarData[1].url}
          className={
            location.pathname.includes(SidebarData[1].url)
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color: location.pathname.includes(SidebarData[1].url)
                  ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                  : "#D1D2D2",
                minWidth: "30px",
                marginLeft: location.pathname.includes(SidebarData[1].url)
                  ? `${collapsSidebar ? "0px" : "-4px"}`
                  : "0px",
              }}
            >
              {SidebarData[1].icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[1].name}
            </span>
          </div>
        </Link>
        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.companies.create_company,
            PermissionsSchema.companies.delete_company,
            PermissionsSchema.companies.add_members,
            PermissionsSchema.companies.add_team,
            PermissionsSchema.companies.delete_team,
            PermissionsSchema.companies.edit_company,
            PermissionsSchema.companies.edit_team_name,
            PermissionsSchema.companies.remove_users,
            PermissionsSchema.companies.view_all_companies,
          ]}
        >
          <Link
            to={SidebarData[2].url}
            className={
              location.pathname.includes(SidebarData[2].url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname.includes(SidebarData[2].url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname.includes(SidebarData[2].url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[2].icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[2].name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.users.activate,
            PermissionsSchema.users.deactivate,
            PermissionsSchema.users.delete,
            PermissionsSchema.users.create,
            PermissionsSchema.users.update,
          ]}
        >
          <Link
            to={SidebarData[3].url}
            className={
              location.pathname.includes(SidebarData[3].url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname.includes(SidebarData[3].url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname.includes(SidebarData[3].url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[3].icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[3].name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.terms.create_term,
            PermissionsSchema.terms.delete_term,
            PermissionsSchema.terms.update_term,
            PermissionsSchema.terms.add_term_version,
            PermissionsSchema.terms.edit_term_version,
            PermissionsSchema.terms.delete_term_version,
            PermissionsSchema.terms.download_term_version,
            PermissionsSchema.terms.state_term_version,
            PermissionsSchema.terms.status_term_version,
            PermissionsSchema.terms.show_all_terms,
          ]}
        >
          <Link
            to={SidebarData[4].url}
            className={
              location.pathname.includes(SidebarData[4].url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname.includes(SidebarData[4].url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname.includes(SidebarData[4].url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[4].icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[4].name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <NavigationWrapper allowedPermissions={[PermissionsSchema.files.fm_access]}>
          <Link
            to={SidebarData[5]?.url}
            className={
              location.pathname?.includes(SidebarData[5]?.url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname?.includes(SidebarData[5]?.url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname?.includes(SidebarData[5]?.url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[5]?.icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[5]?.name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.roles.create,
            PermissionsSchema.roles.update,
            PermissionsSchema.roles.delete,
            PermissionsSchema.roles.copy,
          ]}
        >
          <Link
            to={SidebarData[6]?.url}
            className={
              location.pathname?.includes(SidebarData[6]?.url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname?.includes(SidebarData[6]?.url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname?.includes(SidebarData[6]?.url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[6]?.icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[6]?.name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <Link
          to={SidebarData[7]?.url}
          className={
            location.pathname?.includes(SidebarData[7]?.url)
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color: location.pathname?.includes(SidebarData[7]?.url)
                  ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                  : "#D1D2D2",
                minWidth: "30px",
                marginLeft: location.pathname?.includes(SidebarData[7]?.url)
                  ? `${collapsSidebar ? "0px" : "-4px"}`
                  : "0px",
              }}
            >
              {SidebarData[7]?.icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[7]?.name}
            </span>
          </div>
        </Link>
        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.files.file_requests,
            PermissionsSchema.files.all_file_requests,
          ]}
        >
          <Link
            to={SidebarData[8]?.url}
            className={
              location.pathname?.includes(SidebarData[8]?.url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname?.includes(SidebarData[8]?.url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname?.includes(SidebarData[8]?.url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[8]?.icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[8]?.name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <Link
          to={SidebarData[9]?.url}
          className={
            location.pathname?.includes(SidebarData[9]?.url)
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color: location.pathname?.includes(SidebarData[9]?.url)
                  ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                  : "#D1D2D2",
                minWidth: "30px",
                marginLeft: location.pathname?.includes(SidebarData[9]?.url)
                  ? `${collapsSidebar ? "0px" : "-4px"}`
                  : "0px",
              }}
            >
              {SidebarData[9]?.icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[9]?.name}
            </span>
          </div>
        </Link>
        <NavigationWrapper allowedPermissions={[PermissionsSchema.files.view_download_history]}>
          <Link
            to={SidebarData[10]?.url}
            className={
              location.pathname?.includes(SidebarData[10]?.url)
                ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
                : "link"
            }
          >
            <div className="link_inner_container">
              <ListItemIcon
                style={{
                  color: location.pathname?.includes(SidebarData[10]?.url)
                    ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                    : "#D1D2D2",
                  minWidth: "30px",
                  marginLeft: location.pathname?.includes(SidebarData[10]?.url)
                    ? `${collapsSidebar ? "0px" : "-4px"}`
                    : "0px",
                }}
              >
                {SidebarData[10]?.icon}
              </ListItemIcon>
              <span style={{ display: collapsSidebar ? "none" : "block" }}>
                {SidebarData[10]?.name}
              </span>
            </div>
          </Link>
        </NavigationWrapper>
        <Link
          to={SidebarData[11]?.url}
          className={
            location.pathname?.includes(SidebarData[11]?.url)
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color: location.pathname?.includes(SidebarData[11]?.url)
                  ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                  : "#D1D2D2",
                minWidth: "30px",
                marginLeft: location.pathname?.includes(SidebarData[11]?.url)
                  ? `${collapsSidebar ? "0px" : "-4px"}`
                  : "0px",
              }}
            >
              {SidebarData[11]?.icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[11]?.name}
            </span>
          </div>
        </Link>
        <Link
          to={SidebarData[12]?.url}
          className={
            location.pathname?.includes(SidebarData[12]?.url)
              ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
              : "link"
          }
          onClick={logout}
        >
          <div className="link_inner_container">
            <ListItemIcon
              style={{
                color: location.pathname?.includes(SidebarData[12]?.url)
                  ? `${collapsSidebar ? "white" : "#1E9DF2"}`
                  : "#D1D2D2",
                minWidth: "30px",
                marginLeft: location.pathname?.includes(SidebarData[12]?.url)
                  ? `${collapsSidebar ? "0px" : "-4px"}`
                  : "0px",
              }}
            >
              {SidebarData[12]?.icon}
            </ListItemIcon>
            <span style={{ display: collapsSidebar ? "none" : "block" }}>
              {SidebarData[12]?.name}
            </span>
          </div>
        </Link>
        {simAccess && (
          <>
            <Divider />
            {/* <Link
              // to="https://motcos-webui.weltraum-staging.de/?username=testuser123456&password=testuser123456"
              className={"link"}
              target="_blank"
            > */}
            <div className={"link"} style={{ cursor: "pointer" }} onClick={handleOpenSim}>
              <div className="link_inner_container">
                <ListItemIcon
                  style={{
                    color: "#D1D2D2",
                    minWidth: "30px",
                    marginLeft: "0px",
                  }}
                >
                  <AutoGraphIcon />
                </ListItemIcon>
                <span style={{ display: collapsSidebar ? "none" : "block" }}>SimApp</span>
              </div>
              {/* </Link> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DashboardSidebar;

// return (
//   <div
//     className="sidebar_container"
//     style={{
//       minWidth: collapsSidebar ? "90px" : "230px",
//       maxWidth: collapsSidebar ? "90px" : "230px",
//       marginLeft: collapsSidebar ? "-11px" : "0px",
//       transition: "all 0.3s ease",
//     }}
//   >
//     <div className="sidebar_navigation">
//       <Button
//         onClick={() => setCollapsSidebar(!collapsSidebar)}
//         // sx={{
//         //   width: "140px",
//         //   padding: "8px",
//         //   alignSelf: "center",
//         //   display: "flex",
//         //   marginBottom: "21px",
//         //   borderRadius: "2px",
//         //   background: "var(--main-aqua-10, #EEFAFF)",
//         // }}
//         // sx={{ width: collapsSidebar ? "20px" : "100%", transition: "all 0.3s ease" }}
//         sx={{ width: "100%", justifyContent: "space-around", color: "#D1D2D2", }}
//       >
//         <span style={{ display: collapsSidebar ? "none" : "block", marginLeft: collapsSidebar ? "" : "34px", }}>Menu</span>
//         <ChevronLeftIcon
//           sx={{
//             transform: collapsSidebar ? "rotate(180deg)" : "rotate(0deg)",
//             transition: "all 0.3s ease",
//             marginLeft: collapsSidebar ? "" : "auto",
//           }}
//         />
//       </Button>
//       {SidebarData.map((link, index) => {
//         return (
//           <Link
//             key={link.id}
//             to={`${link.url}`}
//             onClick={() => handleLinkClick(index)}
//             className={
//               selectedLink === index
//                 ? `${collapsSidebar ? "link2 selected_link2" : "link selected_link"}`
//                 : "link"
//             }
//           >
//             <div className="link_inner_container">
//               <ListItemIcon
//                 style={{
//                   color:
//                     selectedLink === index
//                       ? `${collapsSidebar ? "white" : "#1E9DF2"}`
//                       : "#D1D2D2",
//                   minWidth: "30px",
//                   marginLeft:
//                     selectedLink === index ? `${collapsSidebar ? "0px" : "-4px"}` : "0px",
//                 }}
//               >
//                 {link.icon}
//               </ListItemIcon>
//               <span style={{ display: collapsSidebar ? "none" : "block" }}>{link.name}</span>
//             </div>
//           </Link>
//         );
//       })}
//     </div>
//     <Button
//       sx={{
//         width: "140px",
//         padding: "8px",
//         alignSelf: "center",
//         display: collapsSidebar ? "none" : "flex",
//         marginBottom: "21px",
//         borderRadius: "2px",
//         background: "var(--main-aqua-10, #EEFAFF)",
//       }}
//     >
//       <SupportAgentIcon /> Chat
//     </Button>
//   </div>
// );
