import i18n from "../services/i18n";

export const market = [
  { label: "Automotive", value: "Automotive" },
  { label: "Medical", value: "Medical" },
  { label: "Industrial", value: "Industrial" },
  { label: "Other", value: "Other" },
];

export const application = [
  {
    label: "Continuous rotating drives",
    value: "Continuous rotating drives",
  },
  { label: i18n.t("core:actuatingDrives"), value: "actuatingDrives" },
  { label: i18n.t("core:other"), value: "other" },
];

export const powerClass = [
  { label: "0 to 10 Watt", value: "0 to 10 Watt" },
  { label: "10 to 20 Watt", value: "10 to 20 Watt" },
  { label: "Higher than 20 Watt", value: "Higher than 20 Watt" },
];

export const type = [
  {
    label: "Continuous rotating drives",
    value: "Continuous rotating drives",
  },
  { label: "Actuating drives", value: "Actuating drives" },
  { label: "Other", value: "Other" },
];

export const platform = [
  { label: "E533.06", value: "E533.06" },
  { label: "E523.63", value: "E523.63" },
  { label: "Undefined / Other", value: "Undefined / Other" },
];

export const motorType = [
  { label: "BLDC/PSM 3-Phase-Motor", value: "BLDC/PSM 3-Phase-Motor" },
  { label: "Stepper 2-Phase-Motor", value: "Stepper 2-Phase-Motor" },
  { label: "Undefined / Other", value: "Undefined / Other" },
];
