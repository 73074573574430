/* eslint-disable */

import { Button, TextField } from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Preview as PreviewIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router";
import TextEditor from "../../../components/textEditor/textEditor";
import { useEffect, useRef, useState } from "react";
import { newsServices } from "../../../services/news";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import { getUserId } from "../../../reducers/auth";
import { useSelector } from "react-redux";
import NewsDialogs, {
  newsDialogType,
} from "../../../components/dialogs/NewConfirmDialog/NewsDialogs";
import "./singleNews.css";
import parse from "html-react-parser";

const AddNews = () => {
  const navigate = useNavigate();
  const quillRef = useRef();
  const queryClient = useQueryClient();

  const { userId } = useSelector((globalState) => ({
    userId: getUserId(globalState),
  }));

  const location = useLocation();
  const pageType = location?.state?.type;
  const newsId = location?.state?.id;

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  //dialog states
  const [openDraftDialog, setOpenDraftDialog] = useState(false);
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [cancelDialog, setCancelDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  const [isPreview, setIsPreview] = useState(false);

  const handleChanges = (e) => {
    const value = e.target.value;

    setTitle(value);
  };

  const {
    data: news,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["newsById", newsId],
    queryFn: () => newsServices.getNewsById(newsId),
    enabled: pageType === "edit",
  });

  useEffect(() => {
    setTitle(news?.data?.title);
    setText(news?.data?.content);
  }, [news?.data]);

  const newsMutation = useMutation({
    mutationFn: newsServices.newsMutate,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["news"]);
      navigate(`/dashboard`);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.response?.data?.message || data?.data?.message);
    },
  });

  const handlePublish = (isDraft) => {
    const newsObjectCreate = {
      title: title,
      content: text,
      creator_id: userId,
      is_draft: isDraft,
      is_favourite: false,
      type: pageType,
    };
    const newsObjectEdit = {
      id: newsId,
      title: title,
      content: text,
      creator_id: userId,
      is_draft: isDraft,
      is_favourite: false,
      type: pageType,
    };
    newsMutation.mutate(pageType === "add" ? newsObjectCreate : newsObjectEdit);
  };

  const disabledButtonEvaluation =
    (text === "" || text === news?.data?.content) && (title === "" || title === news?.data?.title);

  const disabledButtonEvaluationPublish =
    (text === "" || text === news?.data?.content) || (title === "" || title === news?.data?.title);


  return !isPreview ? (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <div style={{ overflow: "auto", flex: 1, padding: "24px 40px", marginBottom: "24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button sx={muiStyles.backBtn} onClick={() => navigate(`/dashboard`)}>
            <ArrowBackIcon sx={muiStyles.editIcon} />
            <span>Back to Dashboard</span>
          </Button>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
            <Button
              sx={muiStyles.editBtn}
              onClick={() => setIsPreview(true)}
              disabled={disabledButtonEvaluation}
            >
              <span>Preview</span>
              <PreviewIcon sx={muiStyles.editIcon} />
            </Button>
            <Button sx={muiStyles.editBtn} disabled>
              <span>Delete</span>
              <DeleteForeverIcon sx={muiStyles.editIcon} />
            </Button>
          </div>
        </div>
        <p style={muiStyles.title}>{pageType === "add" ? `Add News` : `Edit News`}</p>
        <div>
          <TextField
            label="Title"
            placeholder="Enter title..."
            name="title"
            variant="outlined"
            size="small"
            onChange={handleChanges}
            value={title}
            InputProps={{
              autoComplete: "new-password",
            }}
            sx={muiStyles.textField}
          />
          <TextEditor
            data={text}
            style={{ minHeight: "280px", height: "280px", maxWidth: "1014px" }}
            onChange={setText}
            quillRef={quillRef}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          border: "1px solid #E8E8E9",
          height: "80px",
          background: "white",
          flexShrink: 0,
          padding: "0px 40px",
        }}
      >
        <div>
          <Button
            sx={muiStyles.textButtons}
            onClick={() => setOpenDraftDialog(true)}
            disabled={(pageType === "edit" && !news?.data?.is_draft) || disabledButtonEvaluation}
          >
            Save As Draft
          </Button>
        </div>
        <div style={{ display: "flex", gap: "6px" }}>
          <Button sx={muiStyles.textButtons} onClick={() => setCancelDialog(true)}>
            Cancel
          </Button>
          {pageType === "add" && (
            <Button
              disabled={disabledButtonEvaluationPublish}
              variant="contained"
              sx={muiStyles.containedButtons}
              onClick={() => setOpenPublishDialog(true)}
            >
              Publish
            </Button>
          )}
          {pageType === "edit" && (
            <Button
              disabled={disabledButtonEvaluation}
              variant="contained"
              sx={muiStyles.containedButtons}
              onClick={() => setOpenEditDialog(true)}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <NewsDialogs
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        type={newsDialogType.edit}
        action={() => handlePublish(false)}
      />
      <NewsDialogs
        open={openDraftDialog}
        onClose={() => setOpenDraftDialog(false)}
        type={newsDialogType.draft}
        action={() => handlePublish(true)}
      />
      <NewsDialogs
        open={openPublishDialog}
        onClose={() => setOpenPublishDialog(false)}
        type={newsDialogType.publish}
        action={() => handlePublish(false)}
      />
      <NewsDialogs
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        type={newsDialogType.stopEdit}
        action={() => navigate(`/dashboard`)}
      />
    </div>
  ) : (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <div style={{ overflow: "auto", flex: 1, padding: "24px 40px", marginBottom: "24px" }}>
        <Button sx={muiStyles.backBtn} onClick={() => setIsPreview(false)}>
          <ArrowBackIcon sx={muiStyles.editIcon} />
          <span>Back to Edit</span>
        </Button>
        <p style={muiStyles.titlePreview}>Preview</p>
        <p style={muiStyles.title}>{title}</p>
        <article className="content_article">{parse(text || "")}</article>
      </div>
    </div>
  );
};

export default AddNews;

const muiStyles = {
  container: {},
  backBtn: {
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    minWidth: "95px",
    justifyContent: "space-between",
    letterSpacing: "0.11px",
    gap: "6px",
  },
  editBtn: {
    display: "flex",
    gap: "4px",
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    letterSpacing: "0.11px",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 8px",
  },
  editIcon: {
    fontSize: "15px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "clamp(0.875rem, 0.637rem + 1.191vw, 4.688rem)",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px" /* 140% */,
    letterSpacing: "-0.64px",
    margin: "12px 0px",
  },
  titlePreview: {
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px" /* 140% */,
    letterSpacing: "-0.46px",
    margin: "12px 0px",
    padding: "0px 0px 18px 0px",
    width: "900px",
    borderBottom: "1px solid #E8E8E9",
  },
  textField: {
    width: "1014px",
    fontFamily: "IBM Plex Sans",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  textButtons: {
    minWidth: "140px",
    height: "54px",
    padding: "16px 32px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    color: "#1E9DF2",
    borderRadius: "2px",
  },
  containedButtons: {
    minWidth: "140px",
    height: "54px",
    padding: "16px 0px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    background: "#1E9DF2",
    borderRadius: "2px",
  },
};
