/* eslint-disable */

import { useNavigate } from "react-router";
import getTheUserState from "../../../utils/userState";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";
import { colors } from "@mui/material";

function FilesInUser({ name, id }) {

    const userState = getTheUserState();

    const checkFMAccessPermission = userState?.user?.role?.permissions?.some(
        (permission) =>
            permission.permission_name === PermissionsSchema.files.fm_access &&
            permission.status === true
    );

    const navigate = useNavigate();

    const handleNavigateToFile = () => {
        navigate(`/dashboard/file-management/${id}`);
    };

    return (
        <div style={cardStyles.cardWrapper}>
            <div style={cardStyles.cardContent}>
                <div style={cardStyles.content}>
                    {checkFMAccessPermission ? <a onClick={handleNavigateToFile}>
                        <p style={cardStyles.title}>
                            {name?.length > 29 ? name?.slice(0, 29).concat("...") : name}
                        </p>
                    </a>
                        : <p style={cardStyles.title2}>
                            {name?.length > 29 ? name?.slice(0, 29).concat("...") : name}
                        </p>}
                    {/* <div style={cardStyles.info}>
                        <span style={cardStyles.separator}>•</span>
                        <p style={cardStyles.infoLabels}>{id ? `ID - ${id}` : "unkown"}</p>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default FilesInUser;

const cardStyles = {
    cardWrapper: {
        width: "274px",
        zIndex: "999",
        backgroundColor: "white",
    },
    cardContent: {
        display: "flex",
        width: "100%",
        padding: "8px 24px",
        // borderBottom: "1px solid #D1D2D2",
    },
    title: {
        margin: 0,
        color: "#1E9DF2",
        cursor: "pointer",
        fontFamily: "IBM Plex Sans",
        fontSize: "14px,",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.14px",
        paddingBottom: "2px",
    },
    title2: {
        margin: 0,
        color: "#1B1E1F",
        fontFamily: "IBM Plex Sans",
        fontSize: "14px,",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        letterSpacing: "0.14px",
        paddingBottom: "2px",
    },
    info: {
        margin: 0,
        display: "flex",
        alignItems: "center",
    },
    infoLabels: {
        fontFamily: "IBM Plex Sans",
        fontSize: "11px,",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16.5px",
        letterSpacing: "0.11px",
        margin: 0,
    },
    separator: {
        color: "#8D8E8F",
        fontFamily: "IBM Plex Sans",
        fontSize: "11px,",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "16.5px",
        letterSpacing: "0.11px",
        padding: "0px 4px",
    },
    content: {
        width: "204px",
    },
    navigation: {},
};