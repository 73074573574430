/* eslint-disable */
import { useEffect, useState } from "react";
import {
    Paper,
    IconButton,
    LinearProgress,
    CircularProgress,
    Box,
    Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { getFilesForDownload } from "../../../reducers/files";
import axios from "axios";
import { API_URL } from "../../../config";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { toast_notifications } from "../../../utils/notifications";
import { ExtensionsColorSchemaResolver } from "../../cards/FilesCard/extensionsColorSchemaResolver";
import getTheUserState from "../../../utils/userState";

const FileDownloadIndicator = () => {
    const [expand, setExpand] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState({});
    const [cancelTokens, setCancelTokens] = useState([]);
    const [canceledDownloads, setCanceledDownloads] = useState([]);
    const [downloadCounter, setDownloadCounter] = useState(0);
    const [isIndicatorOpened, setIsIndicatorOpened] = useState(true);

    const [downloadedFileNames, setDownloadedFileNames] = useState([]);

    // Selected files from the store
    const { filesForDownload: files } = useSelector((globalState) => ({
        filesForDownload: getFilesForDownload(globalState),
    }));


    const handleCloseIndicator = () => {
        setIsIndicatorOpened(false);
    }

    const user = getTheUserState();
    const filesInfo = [];
    const downloadFiles = async (fileId, index, acceptedTerm, singleFile) => {
        const userInfo = {
            userId: user?.user?.id,
            username: user?.user?.user,
            name: `${user?.user?.firstName} ${user?.user?.lastName}`,
            email: user?.user?.email,
            role: user?.user?.role?.role_name,
        }
        const cancelTokenSource = axios.CancelToken.source();
        setCancelTokens((prevTokens) => ({ ...prevTokens, [index]: cancelTokenSource }));
        try {
            const response = await axios.post(API_URL + "/files/download",
                { fileId, acceptedTerm, userInfo: userInfo }, {
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    const percentCompleted = Math.round((loaded * 100) / total);
                    setDownloadProgress((prevProgress) => ({
                        ...prevProgress,
                        [index]: percentCompleted,
                    }));
                },
                cancelToken: cancelTokenSource.token,
            });

            setDownloadCounter((prevCount) => prevCount + 1);
            const nameFromHeaders = response?.headers["content-disposition"];
            const headersArray = nameFromHeaders?.split("=");
            const fileName = headersArray[1].replace(/"/g, '');
            const url = window.URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            // setDownloadedFileNames(prev => [...prev, nameOfFile]);
            filesInfo.push(singleFile);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            if (files?.files?.length - 1 === index && acceptedTerm?.length !== 0) {
                axios.post(API_URL + '/files/confirm', { filesInfo: filesInfo, userInfo: userInfo, allTerms: files?.terms })
                    .then(function (confirmResponse) {
                        console.log(confirmResponse);
                        toast_notifications.success(confirmResponse?.data?.message);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                setCanceledDownloads((prevCanceledDownloads) => [...prevCanceledDownloads, index]);
            } else {
                console.error('Error downloading files:', error);
                toast_notifications.error("Error downloading the file!");
            }
        }
    };

    // In order to track every file upload % we need to upload them separated.
    const handleDownloadFiles = () => {
        setIsIndicatorOpened(true);
        setDownloadCounter(0);
        files?.files?.forEach((file, index) =>
            downloadFiles(file.id, index,
                files?.terms?.filter(term => term?.id === file?.termId) || [],
                file,
            ));
        setCanceledDownloads([]);
        setDownloadedFileNames([]);

        const timer = setTimeout(() => {
            if (isIndicatorOpened) {
                handleCloseIndicator();
            }
        }, 30000);

        return () => clearTimeout(timer);
    };

    // Cancel the corresponding upload using the cancel token
    const handleCancel = (index) => {
        if (cancelTokens[index]) {
            cancelTokens[index].cancel("Download canceled by user");
            setCancelTokens((prevTokens) => {
                const updatedTokens = { ...prevTokens };
                delete updatedTokens[index];
                return updatedTokens;
            });
            // setCanceledDownloads([]);
        }
    };

    useEffect(() => {
        if (files?.files && files?.files?.length) {
            handleDownloadFiles();
        }
    }, [files?.files]);

    const filedDownloadIndicatorStyles = {
        paperContainer: {
            position: "fixed",
            right: 25,
            bottom: 50,
            minWidth: "292px",
            minHeight: expand ? "260px" : "77px",
            maxHeight: "405px",
            overflowY: "auto",
            transition: "all 0.5s",
            zIndex: 10000,
        },
        expandButton: {
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            transform: expand ? "rotateX(180deg)" : "rotateX(0deg)",
            transition: "ease-in 0.5s",
        },
        title: {
            color: "#1B1E1F",
            fontFamily: "IBM Plex Sans",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "22.4px",
            letterSpacing: "-0.32px",
            textTransform: "capitalize",
            padding: "0px",
            margin: "0px",
        },
        title2: {
            color: "#1B1E1F",
            fontFamily: "IBM Plex Sans",
            fontSize: "11px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16.5px",
            letterSpacing: "0.11px",
            margin: "7px 0px",
        },
    };

    const progressValue = files?.files?.length === 0 ? 0 : (downloadCounter / files?.files?.length) * 100;

    if (files?.files && files?.files?.length && isIndicatorOpened) {
        return (
            <Paper elevation={3} sx={filedDownloadIndicatorStyles.paperContainer}>
                {expand ? (
                    <div style={{ display: "flex", flexDirection: "column", margin: "10px 20px", }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p style={filedDownloadIndicatorStyles.title}>Downloading</p>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <button
                                    onClick={() => setExpand(!expand)}
                                    style={filedDownloadIndicatorStyles.expandButton}
                                >
                                    <KeyboardArrowUpIcon />
                                </button>
                                <button onClick={handleCloseIndicator} style={filedDownloadIndicatorStyles.expandButton}>
                                    <CloseIcon color="disabled" />
                                </button>
                            </div>
                        </div>
                        <p style={filedDownloadIndicatorStyles.title2}>
                            {downloadCounter} out of {files?.files?.length}
                        </p>
                        {files?.files?.map((file, index) => {
                            return (
                                <DownloadingComponent
                                    index={index}
                                    key={file.id}
                                    status={`${downloadProgress[index] || 0}%`}
                                    value={downloadProgress[index]}
                                    file={file}
                                    handleCancel={handleCancel}
                                    canceledDownloads={canceledDownloads}
                                />
                            );
                        })}
                    </div>
                ) : (
                    <div style={{ display: "flex", flexDirection: "column", margin: "10px 20px" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p style={filedDownloadIndicatorStyles.title}>Downloading</p>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <button
                                    onClick={() => setExpand(!expand)}
                                    style={filedDownloadIndicatorStyles.expandButton}
                                >
                                    <KeyboardArrowUpIcon />
                                </button>
                                <button onClick={handleCloseIndicator} style={filedDownloadIndicatorStyles.expandButton}>
                                    <CloseIcon color="disabled" />
                                </button>
                            </div>
                        </div>
                        <p style={filedDownloadIndicatorStyles.title2}>
                            {downloadCounter} out of {files?.files?.length}
                        </p>
                        <LinearProgress
                            variant="determinate"
                            value={progressValue}
                            sx={{ background: "#F4F4F4" }}
                        />
                    </div>
                )}
            </Paper>
        );
    }

    return null;
};

export default FileDownloadIndicator;

const DownloadingComponent = ({ index, file, value, status, handleCancel, canceledDownloads }) => {
    // Since there was an issue we have give a check on the array with the canceled uploads
    function isUploadCanceled(index) {
        if (Array.isArray(canceledDownloads) && canceledDownloads.includes(index)) {
            return true;
        }

        return false;
    }

    const splitFileName = file?.name.split(".");
    const fileName = splitFileName[0];
    const fileExtension = splitFileName[1];

    return (
        <div
            style={{
                display: "flex",
                borderBottom: "2px solid #F4F4F4",

                justifyContent: "space-between",
                padding: "8px 0px",
            }}
        >
            <div style={{ display: "flex", alignItems: "center" }}>
                {/* <CircularProgress variant="determinate" value={value} color="success" size={20} /> */}
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                        variant="determinate"
                        color={isUploadCanceled(index) ? "error" : "success"}
                        value={value}
                        sx={{ width: "30px!important", height: "30px!important" }}
                    />
                    <Box
                        sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="caption" component="div" color="text.secondary">
                            {status !== "100%" ? (
                                <IconButton onClick={() => handleCancel(index)}>
                                    <CloseIcon color="error" fontSize="small" />
                                </IconButton>
                            ) : (
                                <IconButton>
                                    <CheckIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Typography>
                    </Box>
                </Box>
                <span style={{ paddingLeft: "10px" }}>{fileName}</span>
            </div>
            <div
                style={{
                    margin: "0px",
                    padding: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    minWidth: "110px",
                    alignItems: "center",
                }}
            >
                {ExtensionsColorSchemaResolver(fileExtension)}
                <p style={{ color: status === "Ready" ? "#81CE7B" : "black" }}>{status}</p>
            </div>
        </div>
    );
}; 