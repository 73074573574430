/* eslint-disable */
import { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import { companyServices } from "../../../services/company";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import getTheUserState from "../../../utils/userState";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";
import { useQuery } from "@tanstack/react-query";

const FilesSelectCompanyComponent = ({
  isOpen,
  setIsOpen,
  handleSelectOption,
  index,
  fileData,
  setSelectedCompanyValue,
  selectedCompanyValue,
  addSettings,
  setAddSettings,
}) => {
  const [companiesData, setCompaniesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const userCompanies = getTheUserState()?.user?.companies;

  const loggedUser = getTheUserState();

  const checkCompaniesPermission = loggedUser?.user?.role?.permissions?.some(
    (permission => {
      return permission.permission_name === PermissionsSchema?.companies?.view_all_companies && permission.status === true;
    })
  );

  const {
    data: companiesDataQuery,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["companiesByUserId", userCompanies],
    queryFn:
      checkCompaniesPermission
        ? () => companyServices.getAllCompaniesAndProjectsQuery()
        : () => companyServices.getCompaniesAndProjectsQuery(userCompanies),
    enabled: isOpen,
  });

  function handleClose() {
    setIsOpen(false);
    setSearchText("");
  }

  function handleAssignSelectedSettings() {
    handleSelectOption(index, "companies", addSettings);
    setIsOpen(false);
    setSelectedCompanyValue(1);
  }

  // Handle projects that are checked.
  function handleAdjustSettings(event, comp, index) {
    const isChecked = event.target.checked;

    const updateSettings = [...addSettings];

    const companyID = comp?.id;
    const doCompanyExists = addSettings.some((obj) => obj?.company_id === companyID);

    if (isChecked) {
      if (doCompanyExists) {
        const settingsToUpdate = updateSettings.map((obj) => {
          if (obj.company_id === companyID) {
            const updatedProjects = [...obj.projects, comp?.projects[index]?.id];

            return { ...obj, projects: updatedProjects };
          }
          return obj;
        });

        setAddSettings(settingsToUpdate);
      } else {
        const selectedProject = comp?.projects[index];

        setAddSettings((prev) => [
          ...prev,
          {
            company_id: companyID,
            projects: [selectedProject?.id],
          },
        ]);
      }
    } else {
      if (doCompanyExists) {
        const settingsToUpdate = updateSettings.map((obj) => {
          if (obj.company_id === companyID) {
            const updatedProjects = obj.projects.filter(
              (project) => project !== comp?.projects[index]?.id
            );

            if (updatedProjects.length === 0) {
              return null; // Returning null will filter out the object from the array
            }

            return { ...obj, projects: updatedProjects };
          }
          return obj;
        });
        const filteredArray = settingsToUpdate.filter((obj) => obj !== null);

        setAddSettings(filteredArray);
      }
    }
  }

  useEffect(() => {
    if (isOpen && !isLoading) {
      setCompaniesData(companiesDataQuery?.data?.data);
      setAddSettings(fileData?.companies !== "" ? fileData?.companies : []);
    }
  }, [isOpen, isLoading]);

  // From here we track if all of the project from one companies are added to the settings.
  function handleCheckAll(e, comp) {
    const isChecked = e.target.checked;

    const companyID = comp?.id;
    const doCompanyExists = addSettings.some((obj) => obj?.company_id === companyID);

    if (isChecked) {
      const updateSettings = [...addSettings];

      if (doCompanyExists) {
        const settingsToUpdate = updateSettings.map((obj) => {
          if (obj.company_id === companyID) {
            const projects = [];

            comp?.projects?.forEach((prj) => {
              projects.push(prj?.id);
            });
            return { ...obj, projects: projects };
          }
          return obj;
        });

        setAddSettings(settingsToUpdate);
      } else {
        const selectedProject = comp?.projects;

        const projects = [];

        selectedProject?.forEach((prj) => {
          projects.push(prj?.id);
        });

        setAddSettings((prev) => [
          ...prev,
          {
            company_id: companyID,
            projects: projects,
          },
        ]);
      }
    } else {
      if (doCompanyExists) {
        const removeObject = addSettings.filter((s) => s.company_id !== companyID);
        setAddSettings(removeObject);
      }
    }
  }

  function onHandleSearch(e) {
    const searchValue = e.target.value;
    setSearchText(searchValue.toLowerCase());
  }

  // Filter companies based on the search option
  const filteredData = companiesData.filter((item) => {
    const matchSearch = item?.name.toLowerCase()?.includes(searchText);
    const hasProjects = Array.isArray(item?.projects) && item?.projects.length > 0;

    return matchSearch && hasProjects;
  });

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={selectCompanyStyles.drawerRoot}
      // onClose={handleClose}
      >
        <div
          style={{
            padding: "48px 32px 0px 32px",
            minWidth: "324px",
            // maxHeight: "550px",
            // height: "80.5%",
            height: "100%",
            minHeight: "550px",
            overflowY: "auto",
          }}
        >
          <p style={selectCompanyStyles.title}>Choose Companies</p>
          <p style={selectCompanyStyles.smallTitle}>Only companies with projects are displayed here</p>
          <TextField
            id="standard-basic"
            placeholder="Type to search..."
            variant="standard"
            onChange={onHandleSearch}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          {filteredData?.length ? (
            filteredData?.map((comp, companyIndex) => {
              const projectsInCompany = addSettings.filter((s) => s.company_id === comp?.id);
              const projectsLength = projectsInCompany[0]?.projects?.length;
              return (
                <Accordion
                  // expanded={isOpen}
                  key={comp?.id}
                  sx={{
                    boxShadow: "none",
                    // borderBottom: "1px solid #E8E8E9",
                    borderRadius: "0px",
                    cursor: comp?.projects?.length ? "pointer" : "not-allowed",
                    "& .MuiButtonBase-root": {
                      padding: "0px 0px",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={`panel1-header-${companyIndex}`}
                    sx={{
                      "& .Mui-expanded": {
                        margin: "10px 0px",
                      },
                    }}
                  >
                    <div>
                      <Checkbox
                        onClick={(e) => {
                          handleCheckAll(e, comp);
                          e.stopPropagation();
                        }}
                        defaultChecked={comp?.projects?.length === projectsLength ? true : false}
                      />
                      <Badge badgeContent={projectsLength} color="primary">
                        <span style={selectCompanyStyles.accordionLabels}> {comp?.name}</span>
                      </Badge>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      padding: "5px 0px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        borderBottom: "1px solid #F4F4F4",
                      }}
                    ></div>
                    <FormGroup>
                      {comp?.projects?.map((proj, index) => {
                        const filterData = addSettings?.filter(
                          (setting) => setting?.company_id === comp?.id
                        );
                        const isChecked = filterData[0]?.projects.includes(proj?.id);

                        return (
                          <div
                            style={{
                              width: "100%",
                              borderBottom: "1px solid #F4F4F4",
                              padding: "8px 24px",
                              // marginLeft: "24px",
                            }}
                            key={index}
                          >
                            <FormControlLabel
                              key={index}
                              control={
                                <Checkbox
                                  onClick={(e) => handleAdjustSettings(e, comp, index)}
                                  checked={isChecked ? true : false}
                                />
                              }
                              label={proj?.name}
                            />
                          </div>
                        );
                      })}
                    </FormGroup>
                  </AccordionDetails>
                </Accordion>
              );
            })
          ) : (
            <p
              style={{
                fontFamily: "IBM Plex Sans",
                fontWeight: "600",
                fontSize: "23px",
                lineHeight: "32.2px",
                letterSpace: "0.20px",
                color: "#008de4",
                textAlign: "center",
                minWidth: "360px",
              }}
            >
              No matches!
            </p>
          )}
        </div>
        <div style={{
          display: "flex", alignItems: "center", justifyContent: "flex-end",
          padding: "20.5px 32px",
          borderTop: "1px solid  #E8E8E9",
        }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleClose} sx={selectCompanyStyles.cancelButton}>
              Cancel
            </Button>
            <Button
              variant="text"
              onClick={handleAssignSelectedSettings}
              sx={true ? selectCompanyStyles.addButton : selectCompanyStyles.addButtonDisabled}
            // disabled={true}
            >
              Add
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default FilesSelectCompanyComponent;

const selectCompanyStyles = {
  iconStyle: {
    color: "#1E9DF2",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px", overflow: "hidden" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  formLabel: { "& .MuiTypography-root": { fontSize: "11px" } },
  buttons: {
    textTransform: "Capitalize",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
    fontSize: "23px",
    lineHeight: "32.2px",
    letterSpace: "0.20px",
    margin: "0.5em 0px",
  },
  smallTitle: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.8px",
    letterSpace: "0.20px",
    color: "#D22B2B",
  },
  accordionLabels: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.8px",
    letterSpace: "0.20px",
    paddingLeft: "15px",
  },
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
