/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import {
  getGeneralTerm,
  getGeneralTerms,
  termsAndConditionsService,
} from "../../../../services/TermsAndConditions";
import { companyServices } from "../../../../services/company";
import getTheUserState from "../../../../utils/userState";
import { useQuery } from "@tanstack/react-query";
import { useFormContext } from "react-hook-form";
import { PermissionsSchema } from "../../../../components/auth/PermissionsWrapper";
// import { getUploadType } from "../../../reducers/files";
// import { uploadTypes } from "../files_container/FilesContainer";

const termTypes = {
  generalTerms: "General Terms",
  privateTerms: "Private Terms",
};

const EditSelectedTerms = ({ file, terms }) => {
  const userCompanies = getTheUserState()?.user?.companies;

  const { setValue, watch } = useFormContext();
  const radioValue = watch("terms_and_conditions");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [value2, setValue2] = useState("1");
  const [generalTerms, setGeneralTerms] = useState([]);
  const [generalTermsSearchText, setGeneralTermsSearchText] = useState("");
  const [privateTermsSearchText, setPrivateTermsSearchText] = useState("");
  const [termName, setTermName] = useState("");
  const [selectedTerm, setSelectedTerm] = useState({});

  const {
    data: allCompanies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["allCompanies"],
    queryFn: () => companyServices.getAllCompaniesByUser(userCompanies),
    enabled: isDrawerOpen,
  });

  useEffect(() => {
    const permissions = getTheUserState()?.user?.role?.permissions;
    const foundPermission = permissions.find(
      (permission) =>
        permission.permission_name === PermissionsSchema.companies.view_all_companies &&
        permission.status === true
    );
    if (foundPermission) {
      const response = termsAndConditionsService.getAllTermsFiltered();
      response.then((result) => {
        setGeneralTerms(result?.data?.data);
      });
    } else {
      const response = getGeneralTerms(userCompanies);
      response
        .then((result) => {
          setGeneralTerms(result?.data);
          return true;
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, []);

  // const { uploadType } = useSelector((globalState) => ({
  //   uploadType: getUploadType(globalState),
  // }));

  const handleSearchChange = (event, type) => {
    const searchValue = event.target.value;

    if (type === "general") {
      setGeneralTermsSearchText(searchValue.toLowerCase());
    } else {
      setPrivateTermsSearchText(searchValue.toLowerCase());
    }
  };

  const handleChangeTabs = (event, newValue) => {
    if (newValue === "1") {
      setPrivateTermsSearchText("");
    } else {
      setGeneralTermsSearchText("");
    }
    setValue2(newValue);
  };

  function handleDrawerState() {
    setIsDrawerOpen(!isDrawerOpen);
    setSelectedTerm({});
  }

  function handleSelectTerm(e) {
    const value = e.target.value;
    const name = e.target.name;

    // console.log("selected term", { value, name });
    setSelectedTerm({ value, name });
  }

  function handleAcceptState() {
    const prevValues = { ...radioValue };
    const newValues = {
      ...prevValues,
      terms_and_condition: {
        id: parseInt(selectedTerm.value, 10),
        terms_name: selectedTerm.name,
      },
    };
    setValue("terms_and_conditions", newValues);
    setIsDrawerOpen(false);
  }

  // function handleCheckAll(e, term) {
  //   const isChecked = e.target.checked;
  //   const company = term?.company;
  //   const terms = term?.terms;

  //   const companyID = company?.id;
  //   const doCompanyExists = collectData?.data?.some((obj) => obj?.company_id === companyID);

  //   if (isChecked) {
  //     const updateSettings = { ...collectData };
  //     if (doCompanyExists) {
  //       console.log("updateSettings", updateSettings);
  //       const settingsToUpdate = updateSettings?.data?.map((obj) => {
  //         if (obj?.company_id === companyID) {
  //           const termsArray = [];

  //           terms?.forEach((term) => {
  //             termsArray.push(term?.id.toString());
  //           });
  //           return { ...obj, terms: termsArray };
  //         }
  //         return obj;
  //       });

  //       setCollectData({
  //         type: "privateTerms",
  //         data: settingsToUpdate,
  //       });
  //     } else {
  //       // If is checked and if the company DO NOT EXIST!

  //       const termsArray = [];
  //       terms?.forEach((term) => {
  //         termsArray?.push(term?.id.toString());
  //       });

  //       setCollectData((prev) => ({
  //         type: "privateTerms",
  //         data: [
  //           ...prev.data,
  //           {
  //             company_id: companyID,
  //             terms: termsArray,
  //           },
  //         ],
  //       }));
  //     }
  //   } else {
  //     // Remove the object from the array when it's unchecked
  //     if (doCompanyExists) {
  //       const filteredObjects = collectData?.data?.filter((s) => s.company_id !== companyID);

  //       setCollectData({
  //         type: "privateTerms",
  //         data: filteredObjects,
  //       });
  //     }
  //   }
  // }

  function handleSelectTermInCompany(e, companyID) {
    const isChecked = e.target.checked;
    const type = e.target.name;
    const value = e.target.value;

    if (isChecked === true) {
      setCollectData((prevState) => {
        const newData = [...prevState.data];
        const index = newData?.findIndex((item) => item.company_id === companyID);
        if (index !== -1) {
          // Company already exists, update its terms
          if (!newData[index].terms?.includes(value)) {
            newData[index].terms?.push(value);
          }
        } else {
          // Company doesn't exist, add it to the data array
          newData.push({
            company_id: companyID,
            terms: [value],
          });
        }
        return { type: type, data: newData };
      });
    } else if (isChecked === false) {
      setCollectData((prevState) => {
        const newData = [...prevState.data];
        const index = newData?.findIndex((item) => item?.company_id === companyID);
        if (index !== -1) {
          // Remove value from terms of the company
          newData[index].terms = newData[index].terms?.filter((term) => term !== value);
          // Remove company if terms becomes empty
          if (newData[index].terms?.length === 0) {
            newData?.splice(index, 1);
          }
        }
        return { ...prevState, data: newData };
      });
    }
  }

  // function handleAcceptState() {
  //   handleSelectedTerms(fileIndex, "terms", collectData);
  //   setIsDrawerOpen(false);
  // }

  const filteredGeneralData = generalTerms?.generalTerms?.filter((item) => {
    const matchSearch = item.terms_name.toLowerCase().includes(generalTermsSearchText);
    return matchSearch;
  });

  const filteredPrivateData = generalTerms?.privateTerms?.filter((item) => {
    const matchSearch = item.company?.name.trim().toLowerCase().includes(privateTermsSearchText);
    return matchSearch;
  });
  // console.log("private terms", filteredPrivateData);
  const NoResultComponent = () => {
    return <p>No results found.</p>;
  };

  function handleSetTermsName() {
    let termName;
    filteredGeneralData?.map((term, index) => {
      const isChecked = term.id === file?.FilesToTerms[0]?.term_id;
      if (isChecked) {
        // setTermName(file?.FilesToTerms[0]?.terms_and_condition?.terms_name);
        termName = file?.FilesToTerms[0]?.terms_and_condition?.terms_name;
      }
    });

    filteredPrivateData?.map((term, termMainIndex) => { });

    return termName;
  }

  return (
    <div>
      <div onClick={handleDrawerState} style={{ cursor: "pointer" }}>
        <Button
          variant="outlined"
          color="info"
          sx={{ textTransform: "capitalize", fontSize: "11px" }}
        >
          {terms?.terms_and_condition?.terms_name}
        </Button>
        {/* {collectData.type === "" ? " Choose an option..." : termTypes[collectData.type]} */}
      </div>
      <Drawer anchor="right" open={isDrawerOpen} sx={styles.drawerRoot}>
        <div
          style={{
            padding: "48px 32px",
            minWidth: "360px",
            maxHeight: "550px",
            minHeight: "550px",
            overflowY: "auto",
          }}
        >
          <div>
            <TabContext value={value2} sx={styles.tabPanel}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChangeTabs} aria-label="term tabs">
                  <Tab label="General Terms" value="1" sx={styles.tab} />
                  <Tab label="Private Terms" value="2" sx={styles.tab} />
                </TabList>
              </Box>
              <TabPanel value="1" sx={styles.tabPanel}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    variant="outlined"
                    size="small"
                    placeholder="Search in General Terms"
                    sx={styles.textField}
                    onChange={(e) => handleSearchChange(e, "general")}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={radioValue?.terms_and_condition?.id}
                      name="radio-buttons-group"
                    >
                      {filteredGeneralData?.length ? (
                        filteredGeneralData?.map((term, index) => {
                          return (
                            <div key={index} style={styles.checkboxContainer}>
                              <FormControlLabel
                                name={term?.terms_name}
                                value={term?.id}
                                control={<Radio size="small" />}
                                label={term?.terms_name}
                                onClick={(e) => handleSelectTerm(e)}
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoResultComponent />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={styles.tabPanel}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search Terms by Company"
                    sx={styles.textField}
                    onChange={(e) => handleSearchChange(e, "private")}
                  />

                  {filteredPrivateData?.map((term, termMainIndex) => {
                    const defaultLength = term?.terms?.length;
                    // const termsInCompany = collectData?.data?.filter(
                    //   (s) => s?.company_id === term?.company?.id
                    // );
                    // const termsLength = termsInCompany[0]?.terms?.length;
                    return (
                      <Accordion sx={styles.accordionContainer} key={termMainIndex}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{ color: "#1E9DF2" }} />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          {term?.company?.name.toUpperCase()}
                        </AccordionSummary>

                        {term?.terms?.map((singleTerm, termIndex) => {
                          const isChecked =
                            singleTerm?.id === radioValue?.terms_and_condition?.id ? true : false;
                          // console.log("single term", singleTerm);
                          // console.log("radioValue", radioValue);
                          // console.log("isChecked", isChecked);
                          // console.log("selected term", selectedTerm);
                          // console.log({ singleTerm, file: file?.FilesToTerms });
                          // const filteredData = file?.FilesToTerms?.filter((settings) => {
                          //   // console.log("settings", settings?.term_id === singleTerm?.id);
                          //   return settings?.term_id === singleTerm?.id;
                          // });

                          // // console.log("filteredData", { filteredData, singleTerm });
                          // const filterTheData = filteredData.filter(
                          //   (t) => t.term_id === singleTerm?.id
                          // );
                          // console.log("singleTerm", singleTerm);
                          // console.log("radio value", radioValue);

                          return (
                            <AccordionDetails key={termIndex}>
                              <div style={styles.checkboxContainer}>
                                <FormControlLabel
                                  name={singleTerm?.terms_name}
                                  value={singleTerm?.id}
                                  defaultChecked={radioValue?.terms_and_condition?.id}
                                  sx={styles.checkboxTitles}
                                  control={
                                    <Checkbox
                                      size="small"
                                      defaultChecked={isChecked}
                                    // disabled={!isChecked}
                                    // disabled={
                                    //   filterTheData?.length &&
                                    //   filterTheData[0]?.term_id === singleTerm?.id
                                    //     ? false
                                    //     : true
                                    // }
                                    />
                                  }
                                  label={singleTerm.terms_name}
                                  onClick={(e) => handleSelectTerm(e)}
                                />
                              </div>
                            </AccordionDetails>
                          );
                        })}
                      </Accordion>
                    );
                  })}
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
        <div style={{ padding: "0px 32px", borderTop: "1px solid  #E8E8E9", paddingTop: "12px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="text" onClick={handleDrawerState} sx={styles.cancelButton}>
              Cancel
            </Button>
            {/* <Button
              variant="text"
              onClick={handleAcceptState}
              sx={collectData.data?.length > 0 ? styles.addButton : styles.addButtonDisabled}
              disabled={collectData.data?.length > 0 ? false : true}
            >
              Save
            </Button> */}
            <Button
              variant="text"
              onClick={handleAcceptState}
              sx={styles.addButton}
            // disabled={collectData.data?.length > 0 ? false : true}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default EditSelectedTerms;

const styles = {
  selectMenu: {
    minWidth: "185px",
    padding: "0px",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "11px",
    borderTop: "1px solid #E8E8E9",
  },
  labels: {
    // padding: "8px 12px",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    borderBottom: "1px solid #E8E8E9",
  },
  checkboxContainer: {
    borderBottom: "1px solid #E8E8E9",
    padding: "0px 12px",
  },
  checkboxTitles: {
    "& .MuiTypography-root": {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "11px",
      fontWeight: "500",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
    },
  },
  accordionContainer: {
    borderRadius: "0px",
    boxShadow: "none",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px", zIndex: "999" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  tabPanel: {
    padding: "0px",
    minWidth: "360px",
  },
  textField: {
    padding: "24px 0px",
    fontFamily: "IBM Plex Sans",
    // minWidth: "300px",
    maxHeight: "43px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  tab: {
    fontWeight: "500",
    textTransform: "capitalize",
  },
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
