/* eslint-disable */
import { useEffect, useState } from "react";
import {
    Button,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";
import { filesService } from "../../../services/files";
import getTheUserState from "../../../utils/userState";
import FilesTable, {
    filesTableTypes,
} from "../../../components/tables/CustomFilesTable/FilesTable";
import {
    ExtensionsColorSchemaResolver,
    extensionsColorSchemaArray,
} from "../../../components/cards/FilesCard/extensionsColorSchemaResolver";
import { maturityLevel } from "../../../components/files/files_dropdown/FilesSettingsDropdown";
import {
    FileStatusColorSchemaResolver,
    fileStatusColorSchemaArray,
} from "../../../components/widgets/FileStatus/FileStatusColorSchemaResolver";
import types from "../../../actions/types";
import { useDispatch } from "react-redux";
import DownloadFilesDialog from "../../../components/dialogs/NewConfirmDialog/DownloadFileDialog";
import NoFilesInCatalogComponent from "../FileCatalog/NoFilesInCatalogComponent";
import SkeletonTable from "../../../components/loaders/SkeletonTable";

const selectedType = {
    maturityLevelType: "maturityLevel",
    fileExtensionType: "fileExtension",
    status: "file status",
};

const useStyles = makeStyles((theme) => ({
    paper: {
        border: "1px solid #E8E8E9 !important",
        borderRadius: "0px !important",
        margin: "0px",
        padding: "0px",
        boxShadow: "none !important",
        // width: "176px",
        maxWidth: "306px",
    },
    list: {
        padding: "0px !important",
        // width: "176px",
        maxWidth: "306px",
    },
}));

const FileCatalog = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const loggedUser = getTheUserState();

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [maturityLevelSelected, setMaturityLevelSelected] = useState([]);
    const [extensionSelected, setExtensionSelected] = useState([]);
    const [statusSelected, setStatusSelected] = useState([]);
    const [openDownload, setOpenDownload] = useState(false);
    const [hideFilters, setHideFilters] = useState(false);
    // const [filteredData, setFilteredData] = useState();

    // Get All Files
    const {
        data: files,
        isLoading,
        isError,
    } = useQuery({
        queryKey: ["my-files", loggedUser?.user?.id],
        queryFn: () => filesService.getAllMyFiles(loggedUser?.user?.id),
    });

    const [filteredData, setFilteredData] = useState(files?.data?.files);

    useEffect(() => {
        if (!isLoading) {
            setFilteredData(files?.data?.files);
        }
    }, [files?.data, isLoading]);

    console.log("filteredData", filteredData)

    console.log("files?.data", files?.data)

    useEffect(() => {
        const filteredFilesWithTerms = files?.data?.files?.filter((item) =>
            selectedFiles.includes(item.id)
        );
        if (selectedFiles) {
            dispatch({
                type: types.SET_FILES_TERMS,
                payload: filteredFilesWithTerms,
            });
        }
    }, [selectedFiles]);

    useEffect(() => {
        let filtered = files?.data?.files;

        if (extensionSelected.length > 0) {
            filtered = filtered.filter((item) => extensionSelected.includes(item.file_format));
        }
        if (maturityLevelSelected.length > 0) {
            filtered = filtered.filter((item) => maturityLevelSelected.includes(item.maturity_level));
        }
        if (statusSelected.length > 0) {
            filtered = filtered.filter((item) => statusSelected.includes(item.status));
        }
        setFilteredData(filtered);
    }, [maturityLevelSelected, extensionSelected, statusSelected, files?.data?.files]);

    function handleClearSelectedFiles() {
        setSelectedFiles([]);
    }

    const handleOpenDownload = () => {
        setOpenDownload(true);
    };

    const handleCloseDownload = () => {
        setOpenDownload(false);
        setSelectedFiles([]);
    };

    const clearAllFilters = () => {
        setMaturityLevelSelected([]);
        setExtensionSelected([]);
        setStatusSelected([]);
    };

    const someSelected =
        extensionSelected.length > 0 || maturityLevelSelected.length > 0 || statusSelected?.length > 0;

    const handleChange = (event, type) => {
        const {
            target: { value },
        } = event;
        if (type === selectedType.maturityLevelType) {
            setMaturityLevelSelected(
                // On autofill we get a stringified value.
                typeof value === "string" ? value.split(",") : value
            );
        } else if (type === selectedType.fileExtensionType) {
            setExtensionSelected(typeof value === "string" ? value.split(",") : value);
        } else if (type === selectedType.status) {
            setStatusSelected(typeof value === "string" ? value.split(",") : value);
        }
    };

    const handleUnpick = (item, type) => {
        if (type === selectedType.maturityLevelType) {
            const filterPick = maturityLevelSelected.filter((level) => level !== item);
            setMaturityLevelSelected(filterPick);
        } else if (type === selectedType.fileExtensionType) {
            const filterPick = extensionSelected.filter((extension) => extension !== item);
            setExtensionSelected(filterPick);
        } else if (type === selectedType.status) {
            const filterPick = statusSelected.filter((status) => status !== item);
            setStatusSelected(filterPick);
        }
    };

    // if (isLoading) {
    //     return <h1>Loading...</h1>;
    // }

    if (isError) {
        return <h1>{JSON.stringify(getAllFiles.error)}</h1>;
    }

    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
            {files?.data?.files?.length < 1 ? (
                <NoFilesInCatalogComponent />
            ) : (
                <div
                    style={{
                        overflow: "auto",
                        flex: 1,
                        paddingLeft: "40px",
                        paddingTop: "32px",
                        paddingRight: "220px",
                    }}
                >
                    <p style={styles.title}>My Files</p>
                    <div
                        style={{ display: "flex", justifyContent: "space-between", margin: "0px 0px 16px 0px" }}
                    >
                        <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>Filters</Typography>
                        <div>
                            <Button
                                disabled={!someSelected}
                                color="primary"
                                onClick={clearAllFilters}
                                sx={styles.buttons}
                            >
                                Clear all
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => setHideFilters(!hideFilters)}
                                sx={styles.buttons}
                            >
                                {hideFilters ? "Show filters" : "Hide filters"}
                            </Button>
                        </div>
                    </div>
                    {!hideFilters && (
                        <div>
                            <FormControl sx={styles.formControl} size="small">
                                <InputLabel id="demo-select-small-label" placeholder="Choose an option">
                                    Maturity Level
                                </InputLabel>
                                <Select
                                    multiple
                                    value={maturityLevelSelected}
                                    onChange={(e) => handleChange(e, selectedType.maturityLevelType)}
                                    input={<OutlinedInput label="Maturity Level" />}
                                    MenuProps={{
                                        classes: {
                                            paper: classes.paper,
                                            list: classes.list,
                                        },
                                    }}
                                    placeholder="Choose an option"
                                    sx={{
                                        textAlign: "center",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderRadius: "4px",
                                        ".MuiSelect-icon": { color: "#1E9DF2" },
                                    }}
                                >
                                    <MenuItem sx={styles.menuItem} value={-1} disabled>
                                        <span style={{ fontSize: "16px" }}>Choose an option...</span>
                                    </MenuItem>
                                    {maturityLevel.map((level) => {
                                        const maturityLevel = level?.title.split(" - ");
                                        return (
                                            <MenuItem
                                                sx={styles.menuItem}
                                                key={level.value}
                                                value={level.title}
                                                placeholder="Choose an option"
                                            >
                                                <span
                                                    style={{
                                                        fontSize: "14px",
                                                        minWidth: "max-content",
                                                        backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                                                        color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                                                        padding: "0px 6px",
                                                        margin: "0px 4px 0px 0px",
                                                    }}
                                                >
                                                    {maturityLevel[0]}
                                                </span>
                                                <span
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        overflow: "hidden",
                                                        textOverflow: "ellipsis",
                                                    }}
                                                >
                                                    {maturityLevel[1]}
                                                </span>
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>

                            <FormControl sx={styles.formControl} size="small">
                                <InputLabel id="demo-select-small-label">File Format</InputLabel>
                                <Select
                                    sx={{
                                        borderRadius: "4px",
                                        margin: "0px",
                                        ".MuiSelect-icon": { color: "#1E9DF2" },
                                    }}
                                    multiple
                                    value={extensionSelected}
                                    onChange={(e) => handleChange(e, selectedType.fileExtensionType)}
                                    input={<OutlinedInput label="File Format" />}
                                    MenuProps={{
                                        classes: {
                                            paper: classes.paper,
                                            list: classes.list,
                                        },
                                    }}
                                >
                                    <MenuItem sx={styles.menuItem} value={-1} disabled>
                                        <span style={{ fontSize: "16px" }}>Choose an option...</span>
                                    </MenuItem>
                                    {extensionsColorSchemaArray.map((extension) => (
                                        <MenuItem sx={styles.menuItem} key={extension} value={extension}>
                                            <span
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {ExtensionsColorSchemaResolver(extension)}
                                            </span>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    )}
                    <div
                        style={{
                            margin: "20px 0px 0px 0px",
                            display:
                                maturityLevelSelected?.length || statusSelected?.length || extensionSelected?.length
                                    ? "block"
                                    : "none",
                        }}
                    >
                        {maturityLevelSelected &&
                            maturityLevelSelected?.map((levelSelected) => (
                                <Chip
                                    onDelete={() => handleUnpick(levelSelected, selectedType.maturityLevelType)}
                                    key={levelSelected}
                                    label={levelSelected}
                                    sx={styles.chipStyle}
                                />
                            ))}

                        {extensionSelected &&
                            extensionSelected?.map((extensionItemSelected) => (
                                <Chip
                                    onDelete={() =>
                                        handleUnpick(extensionItemSelected, selectedType.fileExtensionType)
                                    }
                                    key={extensionItemSelected}
                                    label={ExtensionsColorSchemaResolver(extensionItemSelected)}
                                    sx={styles.chipStyle}
                                />
                            ))}
                    </div>
                    <div style={{ display: "flex", gap: "20px", marginBottom: "20px", paddingTop: "20px" }}>
                        <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px" }}>
                            <span style={styles.demoTag}>D</span> 0 - 4: Demo/beta
                        </Typography>
                        <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px" }}>
                            <span style={styles.productiveTag}>P</span> 5 - 7: Productive
                        </Typography>
                    </div>
                    {isLoading ? <SkeletonTable /> : <FilesTable
                        type={filesTableTypes.file_catalog}
                        data={filteredData}
                        selected={selectedFiles}
                        setSelected={setSelectedFiles}
                        secondaryType={filesTableTypes.my_files}
                    />}
                </div>
            )}
            {files?.data?.files?.length >= 1 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        borderTop: "1px solid #E8E8E9",
                        height: "80px",
                        flexShrink: 0,
                    }}
                >
                    {selectedFiles?.length !== 0 && (
                        <div
                            style={{
                                display: "flex",
                                padding: "12px 12px 12px 40px",
                                width: "100%",
                                alignItems: "center",
                                justifyContent: "flex-end",
                            }}
                        >
                            <p
                                style={{
                                    textAlign: "flex-end",
                                    color: "#1B1E1F",
                                    fontFamily: "IBM Plex Sans",
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "-0.16px",
                                    marginRight: "8px",
                                }}
                            >
                                Are you sure you want to download{" "}
                                <span style={{ fontWeight: "700" }}>{selectedFiles?.length}</span> files?
                            </p>
                            <div>
                                <Button sx={styles.cancelButton} onClick={handleClearSelectedFiles}>
                                    Cancel
                                </Button>
                                <Button sx={styles.createButton} onClick={handleOpenDownload}>
                                    Download
                                </Button>
                            </div>
                        </div>
                    )}
                    {openDownload && (
                        <DownloadFilesDialog
                            open={openDownload}
                            onClose={handleCloseDownload}
                            files={selectedFiles || []}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default FileCatalog;

const styles = {
    title: {
        fontFamily: "IBM Plex Sans",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "44.8px",
        letterSpacing: "-0.64px",
        color: "#1B1E1F",
        margin: "0px 0px 24px 0px",
    },
    createButton: {
        borderRadius: "2px",
        background: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        color: "white",
        "&:hover": {
            background: "#1E9DF2",
        },
    },
    cancelButton: {
        borderRadius: "2px",
        background: "#FFF",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "140px",
        height: "54px",
        color: "#1E9DF2",
        marginRight: "8px",
    },
    menuItem: {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "16px",
        letterSpacing: "0.14px",
        fontWeight: "400",
        borderTop: "1px solid #E8E8E9",
    },
    autocompleteList: {
        paddingTop: "10px",
        paddingBottom: "10px",
        fontSize: "16px",
        letterSpacing: "0.14px",
        fontWeight: "400",
        borderTop: "1px solid #E8E8E9",
    },
    chipStyle: { marginRight: "4px", marginBottom: "4px", borderRadius: "4px" },
    demoTag: {
        fontSize: "14px",
        minWidth: "max-content",
        backgroundColor: "#EEF3FF",
        color: "#7B6FA3",
        padding: "0px 6px",
        margin: "0px 4px 0px 0px",
        padding: "0px 4px 0px 4px",
        gap: "4px",
        borderRadius: "1px",
        width: "20px",
        height: "20px",
    },
    productiveTag: {
        backgroundColor: "#EEFAFF",
        color: "#43A5CB",
        fontSize: "14px",
        minWidth: "max-content",
        padding: "0px 6px",
        margin: "0px 4px 0px 0px",
        padding: "0px 4px 0px 4px",
        gap: "4px",
        borderRadius: "1px",
        width: "20px",
        height: "20px",
    },
    buttons: {
        color: "#1E9DF2",
        textTransform: "none",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "18.2px",
    },
    formControl: {
        margin: "0px 16px 8px 0px",
        // width: "176px",
        width: "226px",
    },
};
