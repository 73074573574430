/* eslint-disable */
import { useState, useEffect } from "react";
import getTheUserState from "../../../utils/userState";
import { MenuItem, TablePagination, TextField } from "@mui/material";
import ProjectsCard, { cardTypes } from "../../../components/cards/ProjectsCard/ProjectsCard";
import UpdateProject, { actionType } from "../UpdateProject/UpdateProject";
import ProjectsFilter from "../../../components/dashboard/layout/ProjectsLayout/ProjectsFilter/ProjectsFilter";
import DefaultButton, { buttonTypes } from "../../../components/company/defaultButton";
import { useQuery } from "@tanstack/react-query";
import { projectService } from "../../../services/project";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import "./project.css";

const dropdownFilterOptions = [
  {
    value: "lastEdit",
    label: "Last Edit",
  },
  {
    value: "firstEdit",
    label: "First Edit",
  },
  {
    value: "byName",
    label: "By Name",
  },
  {
    value: "byFavourite",
    label: "By Favourite",
  },
];

const Projects = () => {
  const user = getTheUserState();

  const userId = user?.user?.id;

  const [collectProjectsByUserId, setCollectProjectsByUserId] = useState([]);

  const [projectForEdit, setProjectForEdit] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [filters, setFilters] = useState({ active: false, archived: false });
  const [searchText, setSearchText] = useState("");
  // Handle picking company
  const [pickedCompany, setPickedCompany] = useState(null);
  const [pickedTeam, setPickedTeam] = useState(null);
  const [options, setOptions] = useState([]);

  const checkPermission = user?.user?.role?.permissions?.some((permission) => {
    return (
      permission.permission_name === PermissionsSchema?.companies?.view_all_companies &&
      permission.status === true
    );
  });

  function stringifyIds() {
    const companiesArray = [...user?.user?.companies];
    const stringifiedIds = companiesArray.join(",");
    return stringifiedIds;
  }

  const {
    data: projectsData,
    isLoading: isLoadingProjects,
    isPending,
  } = useQuery({
    queryKey: ["projectsByCompanyIdAndTeamId", !checkPermission && stringifyIds(), userId],
    queryFn: checkPermission
      ? () => projectService.getProjectWithAllCompanies({ userId: userId, })
      : () =>
        projectService.getProjectCompanyIdAndTeamId({
          companyIds: stringifyIds(),
          userId: userId,
        }),
  });

  useEffect(() => {
    if (projectsData?.data?.projects) {
      setCollectProjectsByUserId(projectsData?.data?.projects);
    }
  }, [projectsData?.data?.projects]);

  useEffect(() => {
    if (isCreateActive === false) {
      setPickedCompany(null);
    }
  }, [isCreateActive]);

  // Concat "All" option to the dropdown with teams
  function handlePickedCompany(company) {
    const allOption = { name: "All" };
    const teamOptions = [allOption].concat(company?.Teams || []);
    setPickedCompany(company);
    setOptions(teamOptions);
    setPickedTeam(null);
  }

  // Handle picked team / All
  function handleChosenTeam(value) {
    if (value?.name === "All") {
      setPickedTeam(pickedCompany?.Teams);
    } else {
      setPickedTeam([value]);
    }
  }

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);

  //   setPage(0);
  // };

  const handleDisplayCreateProject = () => {
    setIsCreateActive(true);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue.toLowerCase());
  };

  const sortItemsFromDropdown = (sortOption) => {
    const sortedItems = [...collectProjectsByUserId];
    sortedItems.sort((a, b) => {
      if (sortOption === "lastEdit") {
        return new Date(b.updated) - new Date(a.updated); // Sort in descending order by lastUpdated
      } else if (sortOption === "firstEdit") {
        return new Date(a.updated) - new Date(b.updated);
      } else if (sortOption === "byName") {
        return a.name.localeCompare(b.name);
      } else if (sortOption === "byFavourite") {
        return b.isFavorite - a.isFavorite; // Sort by the isFavourite and after that by name by default
      }
    });

    setCollectProjectsByUserId(sortedItems);
  };

  const filteredData = collectProjectsByUserId.filter((item) => {
    const idString = String(item.id);
    const matchSearch =
      idString.includes(searchText) || item.name.toLowerCase().includes(searchText);
    const matchFilters =
      (!filters.active && !filters.archived) ||
      (filters.active && filters.archived && !item.archived && item.archived) ||
      (filters.active && !item.archived) ||
      (filters.archived && item.archived);

    let matchCompany = true;
    if (pickedCompany) {
      matchCompany = item?.company_id === pickedCompany?.id;
    }

    let matchTeam = true;
    if (pickedTeam?.length <= 1) {
      matchTeam = pickedTeam[0] !== null ? item?.team_id === pickedTeam[0]?.id : true;
    }

    return matchSearch && matchFilters && matchCompany && matchTeam;
  });

  const handleChangePage = (event, newPage) => {
    const maxPage = Math.ceil(filteredData.length / rowsPerPage) - 1;
    if (newPage >= 0 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  console.log("filteredData", filteredData)


  return (
    <div style={{ display: "flex", height: "100%" }}>
      {!isCreateActive && (
        <ProjectsFilter
          handleFilterChange={handleFilterChange}
          handleSearchChange={handleSearchChange}
          handlePickedCompany={handlePickedCompany}
          handleChosenTeam={handleChosenTeam}
          companies={projectsData?.data?.companies}
          teams={pickedCompany?.Teams}
          pickedCompany={pickedCompany}
        />
      )}
      {isCreateActive ? (
        <UpdateProject action={actionType.create} setIsOpen={setIsCreateActive} userId={userId} data={projectsData?.data} pickedCompany={pickedCompany} />
      ) : (
        <div style={projectsStyles.projectsContainer}>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <p style={projectsStyles.projectsTitle}>Projects</p>
            <TextField name="sort" select label="Sort By" sx={projectsStyles.dropdown}>
              {dropdownFilterOptions?.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={() => sortItemsFromDropdown(option.value)}
                  sx={projectsStyles.menuItem}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "auto",
              overflowX: "auto",
              overflowY: "auto",
              marginLeft: "40px",
              marginRight: "30px",
              justifyContent: "flex-start",
            }}
          >
            {collectProjectsByUserId ? (
              <div>
                {filteredData?.length ? (
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((project) => {
                      return (
                        <ProjectsCard
                          key={project.id}
                          userId={userId}
                          id={project.id}
                          name={project.name}
                          market={project.marketSelect}
                          companyName={project.companyName}
                          created={project.created}
                          updated={project.updated}
                          setEditForm={setIsCreateActive}
                          setProjectForEdit={setProjectForEdit}
                          isFavorite={project.isFavorite}
                          isArchived={project.archived}
                          type={cardTypes.regular}
                          projectFiles={project?.FileConnections?.map(fileConnection => fileConnection?.File?.id)}
                        />
                      );
                    })
                ) : (
                  <p style={{ textAlign: "center" }}>There are no project with that ID or Name</p>
                )}
              </div>
            ) : (
              <div className="no_projects_container">
                <div style={projectsStyles.noProjectsContainer}>
                  <p style={projectsStyles.noProjectsTitle}>There are no projects</p>
                  <p style={projectsStyles.noProjectsSubTitle}>
                    Just one step to create the first project
                  </p>
                  <PermissionWrapper allowedPermission={PermissionsSchema.projects.create_project}>
                    <DefaultButton
                      buttonType={buttonTypes.create}
                      title="Create Project"
                      onClickEvent={handleDisplayCreateProject}
                    />
                  </PermissionWrapper>
                </div>
              </div>
            )}
          </div>

          <div style={projectsStyles.projectsFooterSection}>
            {collectProjectsByUserId && collectProjectsByUserId?.length > 0 ? (
              <div>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={collectProjectsByUserId.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    ".MuiIconButton-root": {
                      color: "#1E9DF2",
                      "&.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                      },
                    },
                    ".MuiTablePagination-displayedRows": {
                      fontSize: "14px",
                      color: "#1B1E1F",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "500",
                    },
                    ".MuiTablePagination-select": {
                      fontSize: "14px",
                      color: "#1B1E1F",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "500",
                    },
                    ".MuiTablePagination-selectIcon": {
                      color: "#1E9DF2",
                    },
                    ".MuiTablePagination-selectLabel": {
                      fontSize: "14px",
                      color: "#1B1E1F",
                      fontFamily: "IBM Plex Sans",
                      fontWeight: "500",
                    },
                  }}
                />
              </div>
            ) : null}

            <div>
              <PermissionWrapper allowedPermission={PermissionsSchema.projects.create_project}>
                <DefaultButton
                  buttonType={buttonTypes.create}
                  title="Create Project"
                  onClickEvent={handleDisplayCreateProject}
                />
              </PermissionWrapper>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Projects;

const projectsStyles = {
  projectsContainer: {
    display: "flex",
    flexDirection: "column",
    fontFamily: "IBM Plex Sans",
    height: "100%",
    justifyContent: "space-between",
    width: "100%",
  },
  projectsTitle: {
    color: "var(--main-grey-grey-90, #1B1E1F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    marginLeft: "41px",
  },
  createButton: {
    borderRadius: "2px",
    background: "var(--main-primary-normal, #1E9DF2)",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "var(--main-primary-normal, #1E9DF2)",
    },
  },
  noProjectsContainer: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  noProjectsTitle: {
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    textTransform: "capitalize",
    marginBottom: "0px",
  },
  noProjectsSubTitle: {
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11",
    textAlign: "center",
    color: "var(--main-grey-grey-50, #8D8E8F)",
    maxWidth: "130px",
    marginBottom: "24px",
  },
  projectsFooterSection: {
    borderTop: "1px solid #E8E8E9",
    minHeight: "80px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 24px",
  },
  dropdown: {
    minWidth: "210px",
    paddingRight: "30px",
    marginTop: "32px",

    "& .MuiInputBase-root": {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
      maxWidth: "210px",
      height: "40px",
      fontWeight: "400",
    },
    "& .MuiFormLabel-root": {
      top: "-9px",
    },
  },
  menuItem: {
    fontWeight: "400",
  },
};
