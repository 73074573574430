/* eslint-disable */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import DisplayFilesTermVersions from "../../files/files_select_terms/DisplayFilesTermVersions";
import { useDispatch } from "react-redux";
import types from "../../../actions/types";
import { getAllFilesByIds } from "../../../services/files";
import getTheUserState from "../../../utils/userState";

const DownloadFilesDialog = ({ files, open, onClose }) => {
  const [stepper, setStepper] = useState(0);
  const [filesForDownload, setFilesForDownload] = useState([]);

  const dispatch = useDispatch();

  const userState = getTheUserState();

  const checkPermission = userState?.user?.role?.permissions?.some(
    (permission) => permission.permission_name === "FM Access" && permission.status === true
  );

  useEffect(() => {
    if (open && checkPermission) {
      const filesForIndicator = dispatch(getAllFilesByIds(files));
      filesForIndicator.then((result) => {
        setFilesForDownload(result?.files);
      });
    }
  }, []);

  const handleClose = () => {
    onClose();
    setStepper(0);
  };

  const handleStepperActions = () => {
    setStepper((prev) => prev + 1);
  };

  const handleDownload = () => {
    if (checkPermission) {
      dispatch({
        type: types.SET_FILES_FOR_DOWNLOAD,
        payload: { files: filesForDownload },
      });
      onClose();
    }
  };

  return (
    open && (
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
            "& .MuiPaper-root": { minWidth: "800px", },
            borderRadius: "16px",
            padding: "30px 40px -20px 40px",
          }}
        >
          <div
            style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px", zIndex: "99" }}
          >
            <IconButton onClick={handleClose}>
              <CloseIcon color="disabled" />
            </IconButton>
          </div>
          {checkPermission ? (
            <>
              <DialogTitle sx={muiDialogStyles.dialogTitle}>
                {`Are you sure you want to download ${files?.length > 1 ? `these ${files.length} files` : `this ${files.length} file`
                  }?`}
              </DialogTitle>
              <div style={muiDialogStyles.actionsContainer}>
                <Button onClick={handleDownload} autoFocus sx={muiDialogStyles.btn}>
                  Yes, Download
                </Button>
                <Button onClick={handleClose} sx={muiDialogStyles.btnCancel}>
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              {stepper === 0 ? (
                <div>
                  <DialogTitle sx={muiDialogStyles.dialogTitle}>Terms & Conditions</DialogTitle>
                  <DialogContent sx={muiDialogStyles.content}>
                    Before downloading certain files, you should read and accept the terms and
                    conditions for each file. These terms and conditions may vary from file to file,
                    so it is important to read them carefully. In general, the terms and conditions
                    will explain the rights and obligations of both the file owner and the
                    downloader. By downloading the file, you agree to be bound by these terms and
                    conditions.
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleStepperActions} autoFocus sx={muiDialogStyles.btnStart}>
                      Start
                    </Button>
                  </DialogActions>
                </div>
              ) : null}
              {stepper > 0 ? (
                <DisplayFilesTermVersions files={files} onClose={handleClose} />
              ) : null}
            </>
          )}
        </Dialog>
      </div>
    )
  );
};

export default DownloadFilesDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "60%",
    alignSelf: "center",
    padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    // maxWidth: "60%",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    marginBottom: "30px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btnDownload: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnStart: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    // marginRight: "40px",
    margin: "3px 40px 23px 0px",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  //   btnCancel: {
  //     color: "#1E9DF2",
  //     fontFamily: "IBM Plex Sans",
  //     fontSize: "16px",
  //     fontStyle: "normal",
  //     fontWeight: "500",
  //     lineHeight: "22.4px",
  //     letterSpacing: "-0.32px",
  //     textTransform: "capitalize",
  //     width: "160px",
  //     height: "54px",
  //     marginTop: "4px",
  //   },
  content: {
    // display: "flex",
    // flexDirection: "column",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    margin: "20px 40px 20px 40px",
    minWidth: "70%",
    padding: "0px",
  },
};
