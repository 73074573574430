/* eslint-disable */
import { Tooltip, IconButton } from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  spin: {
    animation: "$spin 1s linear infinite",
  },
  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));

export function CheckFileName({ file_id, exepcted_name, disabled }) {
  const classes = useStyles();
  console.log("disabled", disabled);
  const [isLoading, setisLoading] = useState(false);
  const [isValid, setIsValid] = useState("");

  function runCheck() {
    // TODO: Send the check for file name to the server.
  }
  console.log("exepcted_name", exepcted_name);
  const handleClick = () => {
    console.log("is loading");
    setIsValid("");
    setisLoading(true);
    // Simulate a loading process
    setTimeout(() => {
      setisLoading(false);
      setIsValid(true);
    }, 3000);
  };

  let content;

  useEffect(() => {
    setIsValid("");
  }, [exepcted_name]);

  if (isValid === true) {
    content = (
      <Tooltip title="This File Name is not taken." arrow placement="right">
        <IconButton onClick={() => handleClick()} disabled={disabled || isLoading}>
          <PublishedWithChangesIcon sx={{ color: disabled ? "gray" : "#24cf2d" }} />
        </IconButton>
      </Tooltip>
    );
  } else if (isValid === false) {
    content = (
      <Tooltip title="This File Name is already taken!" arrow placement="right">
        <IconButton onClick={handleClick}>
          <SyncProblemIcon color="error" />
        </IconButton>
      </Tooltip>
    );
  } else {
    content = (
      <Tooltip title="Check if this File Name is taken." arrow placement="right">
        <IconButton onClick={handleClick} disabled={disabled || isLoading}>
          <AutorenewIcon
            className={isLoading ? classes.spin : ""}
            sx={{ color: disabled ? "gray" : "#1E9DF2" }}
          />
        </IconButton>
      </Tooltip>
    );
  }

  return content;
}
