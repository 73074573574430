/* eslint-disable */
import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { filesService } from "../../../services/files";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";

const DeleteFilesDialog = ({ files, open, onClose }) => {

  const handleClose = () => {
    onClose();
  };

  const queryClient = useQueryClient();

  const deleteFilesMutation = useMutation({
    mutationFn: filesService.deleteFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["generalFiles", "getProjectById"]);
      toast_notifications.success(data?.data?.message);
      onClose();
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
      onClose();
    },
  });

  const handleDelete = () => {
    deleteFilesMutation.mutate({ files: files });
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
        }}
      >
        <div style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          {`Are you sure you want to delete ${files?.length > 1 ? `these ${files.length} files` : `this ${files.length} file`}?`}
        </DialogTitle>
        <div style={muiDialogStyles.actionsContainer}>
          <Button onClick={handleDelete} autoFocus sx={muiDialogStyles.btn}>
            Yes, Delete
          </Button>
          <Button onClick={handleClose} sx={muiDialogStyles.btnCancel}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default DeleteFilesDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "60%",
    alignSelf: "center",
    padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
};
