import SEPG_IMG from "../../../assets/img/sepg.png";
import SMCS_IMG from "../../../assets/img/smcs.png";
import SMCS_TABLET_IMG from "../../../assets/img/smcs_tablet.png";
import TOOLS_IMG from "../../../assets/img/tools.png";
import SIMULATION_IMG from "../../../assets/img/Simulation.png";
import BEHAVIOURAL_IMG from "../../../assets/img/Behavioural.png";

export const SectionsContext = [
  {
    id: 0,
    title: "Faster results. More quality. Less cost.",
    subTitle:
      "MotCoS tools and services support you in accelerating your hardware and software development according to ASPICE.",
  },
  {
    id: 1,
    title: "MotCoS: ASPICE-Compliant Development Support",
    subTitle: "MotCos supports the entire development process according to ASPICE",
    img: SEPG_IMG,
  },
  {
    id: 2,
    title: "Smart Motor Control Strategy",
    img: SMCS_IMG,
    img2: SMCS_TABLET_IMG,
  },
  {
    id: 3,
    title: "Comprehensive tools",
    subTitle: "for advanced app development",
    img: TOOLS_IMG,
  },
  {
    id: 4,
    title: "Simulation",
    subTitle: "MotCoS SimLab",
    img: SIMULATION_IMG,
  },
  {
    id: 5,
    title: "Behavioral modelling",
    subTitle: "of functions at different abstraction levels & levels of detail",
    img: BEHAVIOURAL_IMG,
  },
];
