import React from "react";
// import { useNavigate } from "react-router";
import "./savingContainer.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import TIME_SAVING_IMG from "../../../assets/img/Vector_Time.png";


function SavingContainer() {
  const navigate = useNavigate();
  function handleClick() {
    navigate("/signup");
  }

  return (
    <section className="saving_container">
      <div className="saving_wrapper">
        <div className="savings_outter">
          <p className="savings_title">Saving Up to 80% of development time</p>
          <img src={TIME_SAVING_IMG} alt="Time saving" />
        </div>
        <p className="saving_content">
          Fully integrated motor control algorithm (FOC, ..) Speed up design-in time at customers
          Model based design flow; ASPICE compliant
        </p>
        <div style={{ width: "100%" }}>
          <Button
            onClick={handleClick}
            sx={{
              borderRadius: "2px",
              background: "#0F6EAE",
              color: "rgba(255, 255, 255, 1)",
              fontFamily: "IBM Plex Sans",
              paddingLeft: "32px",
              paddingRight: "32px",
              "&:hover": {
                background: "#0e80cc",
              },
            }}
          >
            Register Now
          </Button>
        </div>
      </div>
      <div className="saving_wrapper_sx">
        <div className="saving_wrapper_content_sx">
          <h1 className="saving_sx_title">Saving Up to 80% of development time</h1>
          <p className="saving_sx_content">
            Fully integrated motor control algorithm (FOC, ..) Speed up design-in time at customers
            Model based design flow; ASPICE compliant
          </p>
          <div style={{ width: "100%" }}>
            <Button
              onClick={handleClick}
              sx={{
                borderRadius: "2px",
                background: "#0F6EAE",
                color: "rgba(255, 255, 255, 1)",
                fontFamily: "IBM Plex Sans",
                padding: "16px 32px",
                marginBottom: "36px",
                width: "100%",
                "&:hover": {
                  background: "#0e80cc",
                },
              }}
            >
              Register Now
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SavingContainer;