/* eslint-disable */
import "./projectsById.css";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getProjectByUserAndProjectId,
  projectService,
  updateProjectFavoriteStatus,
} from "../../../services/project";
import { Box, Button, LinearProgress } from "@mui/material";
import UpdateProject, { actionType } from "../UpdateProject/UpdateProject";
import getTheUserState from "../../../utils/userState";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { getDateTime2 } from "../../../utils/misc";
import FilesTable, {
  filesTableTypes,
} from "../../../components/tables/CustomFilesTable/FilesTable";
import GeneralFilesTable from "../../../components/tables/CustomFilesTable/GeneralFilesTable";
import { confirmTypes } from "../../../components/dialogs/NewConfirmDialog/NewConfirmDialog";
import DeleteMembersModal from "../../../components/modal/DeleteMembersModal";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import FilesContainer, {
  uploadTypes,
} from "../../../components/files/files_container/FilesContainer";
import types from "../../../actions/types";
import NavigationWrapper from "../../../components/auth/NavigationWrapper";
import EnhancedTable, { tableSpecification, tableType } from "../../../components/tables/CustomMuiTable/EnchancedMuiTable";
import RenderTeamTableComponent from "../../../components/company/AvailableCompany/RenderTeamTableComponent";
import { teamServices } from "../../../services/team";

const ProjectById = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const idFromParams = window.location.pathname.slice(20);
  const userId = getTheUserState()?.user.id;
  const projectId = location?.state?.project_id;
  const isEditOpen = location?.state?.isOpen;
  const userIDFromParams = location?.state?.userId;

  const [isUpdateActive, setIsUpdateActive] = useState(false);
  const [selectGeneralFiles, setSelectGeneralFiles] = useState([]);
  const [selectPrivateFiles, setSelectPrivateFiles] = useState([]);
  const [privateFiles, setPrivateFiles] = useState([]);
  const [isUploadActive, setIsUploadActive] = useState(false);

  const selectedProjectId = projectId ? projectId : idFromParams;

  const {
    data: projectData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["projectById", selectedProjectId],
    queryFn: () =>
      projectService.getProjectById({
        project_id: selectedProjectId,
        user_id: userId,
      }),
  });

  const addToFavouriteMutation = useMutation({
    mutationFn: projectService.updateProjectFavoriteStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projectById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const deleteTeamMutation = useMutation({
    mutationFn: teamServices.deleteTeam,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projectById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  useEffect(() => {
    if (isEditOpen) {
      setIsUpdateActive(isEditOpen);
    }
  }, [isEditOpen]);

  useEffect(() => {
    if (projectData) {
      const privates = projectData?.data?.FileConnections?.filter((connection) => {
        if (connection?.File) {
          const file = connection?.File;
          return file;
        }
      });
      const privateFilesArray = privates?.map((file) => file.File);
      setPrivateFiles(privateFilesArray);
    }
  }, [projectData?.data]);

  function handleDeleteTeam(team) {
    deleteTeamMutation.mutate({ teamId: team.id });
  }

  const handleIsFavoriteStatus = async () => {
    const data = { projectId: projectData?.data?.id, status: projectData?.data?.isFavorite, userId: userId };
    // await dispatch(updateProjectFavoriteStatus(data));
    addToFavouriteMutation.mutate(data);
  };

  function handleUploadActivation() {
    setIsUploadActive(!isUploadActive);
    dispatch({
      type: types.SET_UPLOAD_TYPE,
      payload: uploadTypes.project,
    });
  }

  // function AddToFavoriteButton() {
  //   return (
  //     <div style={{ display: "flex" }}>
  //       <Button
  //         variant="text"
  //         size="small"
  //         sx={{
  //           marginBottom: "-18px",
  //           textTransform: "capitalize",
  //           maxHeight: "55px",
  //           "&:hover": {
  //             background: "transparent",
  //           },
  //         }}
  //         onClick={handleIsFavoriteStatus}
  //       >
  //         {projectData?.data?.isFavorite ? (
  //           <StarIcon color="info" />
  //         ) : (
  //           <StarBorderIcon color="info" />
  //         )}
  //         {projectData?.data?.isFavorite ? (
  //           <p style={muiStyles.favoritesTitle}>Remove from favorites</p>
  //         ) : (
  //           <p style={muiStyles.favoritesTitle}>Add to favorites</p>
  //         )}
  //       </Button>
  //     </div>
  //   );
  // }

  const handleGoBack = () => {
    navigate(-1);
  };

  let content = (
    <>
      {isUpdateActive && !isLoading ? (
        <div className="project_container">
          <UpdateProject
            setIsOpen={setIsUpdateActive}
            data={projectData?.data || []}
            action={actionType.update}
            userId={userIDFromParams ? userIDFromParams : userId}
          />
        </div>
      ) : (
        <div className="project_container">
          <div className="project_container_navigation">
            <Button sx={muiStyles.backBtn} onClickCapture={handleGoBack}>
              <ArrowBackIcon />
              Back to Search
            </Button>
            <PermissionWrapper allowedPermission={PermissionsSchema.projects.update_project}>
              <Button sx={muiStyles.editBtn} onClick={() => setIsUpdateActive(true)}>
                <span style={{ alignSelf: "center" }}>Edit</span>
                <BorderColorIcon sx={muiStyles.editIcon} />
              </Button>
            </PermissionWrapper>
          </div>

          {isLoading ? (
            <div className="project_loading_content">
              <Box sx={{ width: "30%", alignSelf: "center" }}>
                <LinearProgress variant="indeterminate" color="info" sx={{ height: "15px" }} />
              </Box>
            </div>
          ) : (
            <div className="project_main_content">
              <p className="project_main_name">{projectData?.data?.name}</p>
              <div style={{ display: "flex", alignItems: "end" }}>
                <p style={muiStyles.separatorText}>{projectData?.data?.companyName}</p>
                <span style={muiStyles.dotSeparator}>•</span>
                <p style={muiStyles.separatorText}>{projectData?.data?.marketSelect}</p>
                <span style={muiStyles.dotSeparator}>•</span>
                <p style={muiStyles.separatorText}>ID:{projectData?.data?.id}</p>
                <span style={muiStyles.dotSeparator}>•</span>
                <div style={{ display: "flex" }}>
                  <Button
                    variant="text"
                    size="small"
                    sx={{
                      marginBottom: "-18px",
                      textTransform: "capitalize",
                      maxHeight: "55px",
                      "&:hover": {
                        background: "transparent",
                      },
                    }}
                    onClick={handleIsFavoriteStatus}
                  >
                    {projectData?.data?.isFavorite ? (
                      <StarIcon color="info" />
                    ) : (
                      <StarBorderIcon color="info" />
                    )}
                    {projectData?.data?.isFavorite ? (
                      <p style={muiStyles.favoritesTitle}>Remove from favorites</p>
                    ) : (
                      <p style={muiStyles.favoritesTitle}>Add to favorites</p>
                    )}
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <p style={muiStyles.timeStamps}>
                  <span style={muiStyles.timeStampHeader}>Created:</span>
                  {getDateTime2(projectData?.data?.created)}{" "}
                </p>
                <p style={muiStyles.timeStamps2}>
                  <span style={muiStyles.timeStampHeader}>Last edit: </span>
                  {getDateTime2(projectData?.data?.updated)}
                </p>
              </div>
              <div className="project_main_info_container">
                <div className="project_main_info_wrapper">
                  <p>Market / industry</p>
                  <span>{projectData?.data?.marketSelect}</span>
                </div>
                <div className="project_main_info_wrapper">
                  <p>Type</p>
                  <span>{projectData?.data?.type}</span>
                </div>
                <div className="project_main_info_wrapper">
                  <p>Application</p>
                  <span>{projectData?.data?.typeOfApp}</span>
                </div>
                <div className="project_main_info_wrapper">
                  <p>Platform</p>
                  <span>{projectData?.data?.platform}</span>
                </div>
                <div className="project_main_info_wrapper">
                  <p>Power class</p>
                  <span>{projectData?.data?.powerClass}</span>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  overflowY: "hidden",
                  // maxHeight: "420px",
                }}
              >
                <GeneralFilesTable
                  selected={selectGeneralFiles}
                  setSelected={setSelectGeneralFiles}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p className="table_title">Project Resources</p>
                  {selectPrivateFiles.length > 0 && (
                    <DeleteMembersModal
                      data={{ selected: selectPrivateFiles }}
                      type={confirmTypes.deleteAndDownloadFiles}
                      setSelected={setSelectPrivateFiles}
                    />
                  )}
                </div>
                <FilesTable
                  type={filesTableTypes.projectsPrivate}
                  data={privateFiles || []}
                  selected={selectPrivateFiles}
                  setSelected={setSelectPrivateFiles}
                />
                <p className="table_title">{`Team Users for team - ${projectData?.data?.team?.name}`}</p>
                {/* <EnhancedTable
                  // selected={selectedTeamUsers}
                  // setSelected={setSelectedTeamUsers}
                  rows={projectData?.data?.team?.users}
                  type={tableType.teamUsers}
                  specification={tableSpecification.noCheckbox}
                /> */}
                <RenderTeamTableComponent
                  key={projectData?.data?.team?.id}
                  team={projectData?.data?.team}
                  pickedCompany={projectData?.data?.company}
                  handleDeleteTeam={() => handleDeleteTeam(team)}
                />
              </div>
            </div>
          )}

          <NavigationWrapper allowedPermissions={[PermissionsSchema.files.upload_files, PermissionsSchema.files.fm_access]}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                borderTop: "1px solid #E8E8E9",
                height: "80px",
                background: "white",
                flexShrink: 0,
                alignItems: "center",
                paddingLeft: "40px",
              }}
            >
              <div>
                <Button
                  variant="text"
                  color="info"
                  sx={{
                    textTransform: "capitalize",
                    minWidth: "128px",
                    justifyContent: "space-between",
                  }}
                  onClick={handleUploadActivation}
                >
                  Upload File <CloudUploadIcon />
                </Button>
              </div>
            </div>
          </NavigationWrapper>
        </div>
      )}
    </>
  );

  return (
    <>
      {isUploadActive ? (
        <FilesContainer
          uploadFrom={uploadTypes.project}
          handleUploadActivation={handleUploadActivation}
          type={uploadTypes.project}
          setIsUploadActive={setIsUploadActive}
          projectName={projectData?.data?.name ? projectData?.data?.name : "Unknown"}
          project={projectData.data}
        />
      ) : (
        content
      )}
    </>
  );
};

export default ProjectById;

const muiStyles = {
  backBtn: {
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    width: "120px",
    justifyContent: "space-between",
    letterSpacing: "0.11px",
  },
  editBtn: {
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    width: "36px",
    letterSpacing: "0.11px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editIcon: {
    fontSize: "15px",
  },
  dotSeparator: {
    padding: "0px 8px",
    alignSelf: "end",
  },
  separatorText: {
    color: "var(--main-grey-grey-90, #1B1E1F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    marginBottom: "0px",
  },
  description: {
    color: " var(--main-primary-normal, #1E9DF2)",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    marginTop: "0px",
    marginBottom: "0px",
  },
  timeStamps: {
    color: "var(--main-grey-grey-50, #8D8E8F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
  },
  timeStamps2: {
    color: "var(--main-grey-grey-50, #8D8E8F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    marginLeft: "10px",
  },
  timeStampHeader: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    marginRight: "5px",
  },
  favoritesTitle: {
    marginBottom: "10px",
  },
};
