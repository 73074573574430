/* eslint-disable */
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import { maturityLevel } from "../../../../components/files/files_dropdown/FilesSettingsDropdown";
import { DisableML, PermissionsSchema } from "../../../../components/auth/PermissionsWrapper";
import { Controller } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
  },
  list: {
    padding: "0px !important",
  },
}));

function MaturityLevelDropdown({ name, control, defaultValue }) {
  const classes = useStyles();

  function handleDefaultValue(defaultValue) {
    const findIndex = maturityLevel.findIndex((item) => item.title === defaultValue);

    return findIndex;
  }

  return (
    <div>
      <FormControl variant="standard" fullWidth>
        <Controller
          name={name}
          control={control}
          defaultValue={
            maturityLevel[handleDefaultValue(defaultValue)]?.value
              ? maturityLevel[handleDefaultValue(defaultValue)]?.value
              : 0
          }
          render={({ field }) => (
            <Select
              disableUnderline
              onChange={(e) => field.onChange(maturityLevel[e.target.value]?.value)}
              sx={styles.selectMenu}
              size="small"
              {...field}
              value={
                handleDefaultValue(field.value) !==
                maturityLevel[handleDefaultValue(defaultValue)]?.value
                  ? field.value
                  : maturityLevel[handleDefaultValue(defaultValue)]?.value
              }
              MenuProps={{
                classes: {
                  paper: classes.paper,
                  list: classes.list,
                },
              }}
            >
              <MenuItem sx={styles.menuItem} value={-1} disabled>
                <span style={{ fontSize: "11px", padding: "8px 0px" }}>Choose an option...</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[0].value}
                key={maturityLevel[0].value}
                disabled={DisableML(PermissionsSchema.files.ml0)}
              >
                <span style={styles.MLtitle}>{maturityLevel[0].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[1].value}
                key={maturityLevel[1].value}
              >
                <span style={styles.MLtitle}>{maturityLevel[1].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[2].value}
                key={maturityLevel[2].value}
                // onClick={handleChange}
                disabled={DisableML(PermissionsSchema.files.ml2)}
              >
                <span style={styles.MLtitle}>{maturityLevel[2].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[3].value}
                key={maturityLevel[3].value}
                disabled={DisableML(PermissionsSchema.files.ml3)}
              >
                <span style={styles.MLtitle}>{maturityLevel[3].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[4].value}
                key={maturityLevel[4].value}
                disabled={DisableML(PermissionsSchema.files.ml4)}
              >
                <span style={styles.MLtitle}>{maturityLevel[4].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[5].value}
                key={maturityLevel[5].value}
                disabled={DisableML(PermissionsSchema.files.ml5)}
              >
                <span style={styles.MLtitle}>{maturityLevel[5].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[6].value}
                key={maturityLevel[6].value}
                disabled={DisableML(PermissionsSchema.files.ml6)}
              >
                <span style={styles.MLtitle}>{maturityLevel[6].title}</span>
              </MenuItem>

              <MenuItem
                sx={styles.menuItem}
                value={maturityLevel[7].value}
                key={maturityLevel[7].value}
                disabled={DisableML(PermissionsSchema.files.ml7)}
              >
                <span style={styles.MLtitle}>{maturityLevel[7].title}</span>
              </MenuItem>
            </Select>
          )}
        />
      </FormControl>
    </div>
  );
}

export default MaturityLevelDropdown;

const styles = {
  selectMenu: {
    minWidth: "185px",
    padding: "0px",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "14px",
    borderTop: "1px solid #E8E8E9",
  },
  MLtitle: {
    fontSize: "14px",
    minWidth: "max-content",
    backgroundColor: "#EEFAFF",
    color: "#43A5CB",
    padding: "2px 6px",
    margin: "10px 0px",
  },
};
