/* eslint-disable */
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./productCard.css";
import ProductCardComponent, { types } from "./ProductCardComponent";
import { ProductsCardContent } from "./ProductsCardContext";

const ProductCards = () => {
  const [titleContainer, setTitleContainer] = useState(false);
  const [software_card, setSoftwareCard] = useState(false);
  const [functions_card, setFunctionsCard] = useState(false);

  const title = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });
  const software = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const functions = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    if (title.inView) {
      setTitleContainer(true);
    } else {
      setTitleContainer(false);
    }
    if (software.inView) {
      setSoftwareCard(true);
    } else {
      setSoftwareCard(false);
    }
    if (functions.inView) {
      setFunctionsCard(true);
    } else {
      setFunctionsCard(false);
    }
  }, [software.inView, functions.inView]);

  return (
    <section className="product_cards_container">
      <div
        ref={title.ref}
        style={titleContainer ? transitionStyles.onScreen : transitionStyles.offScreen}
      >
        <p className="product_cards_title">The Product</p>
        <p className="product_cards_sub_title">All that you need</p>
      </div>

      <div
        ref={software.ref}
        style={software_card ? transitionStyles.onScreen : transitionStyles.offScreen}
      >
        <ProductCardComponent
          key={ProductsCardContent[0].id}
          title={ProductsCardContent[0].title}
          subTitle={ProductsCardContent[0].subTitle}
          listItems={ProductsCardContent[0].listItems}
          type={types.software}
        />
      </div>
      <div
        ref={functions.ref}
        style={functions_card ? transitionStyles.onScreen : transitionStyles.offScreen}
      >
        <ProductCardComponent
          key={ProductsCardContent[1].id}
          title={ProductsCardContent[1].title}
          subTitle={ProductsCardContent[1].subTitle}
          listItems={ProductsCardContent[1].listItems}
          type={types.functions}
        />
      </div>
    </section>
  );
};

export default ProductCards;

const transitionStyles = {
  onScreen: {
    position: "relative",
    opacity: "1",
    transition: "all 2.5s",
  },
  offScreen: {
    position: "relative",
    opacity: "0",
  },
};
