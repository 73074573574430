import axios from "axios";
// import { actions } from "../reducers/app";
import { errorHandler, options } from ".";
import { API_URL } from "../config";

// TODO: Fix dispatch notification

export async function getCompanies() {
  try {
    const response = await axios(options(API_URL + "/company", {}, "get"));
    const responseOK = response && response.status === 200 && response.statusText === "OK";

    if (responseOK) {
      // console.log("all fine!", response.data);
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function getCompaniesAndTeams() {
  try {
    const response = await axios(options(API_URL + "/company/companiesAndTeams", {}, "get"));
    const responseOK = response && response.status === 200 && response.statusText === "OK";

    if (responseOK) {
      // console.log("all fine!", response.data);
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

export function onlyCompanies() {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/company/onlyCompanies/all", {}, "get"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      console.warn("Error:", error);
      await dispatch(errorHandler(error));
    }
  };
}

export function getCompaniesByUser(companies) {
  return async (dispatch) => {
    try {
      const response = await axios(
        options(API_URL + `/company/onlyCompanies/${companies}`, {}, "get")
      );
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      console.warn("Error:", error);
      await dispatch(errorHandler(error));
    }
  };
}

export function getCompaniesAndProjects(userCompanies) {
  return async (dispatch) => {
    try {
      const response = await axios(
        options(API_URL + `/company/companies-includes-projects/${userCompanies}`, {}, "get")
      );
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        return response.data;
      }
    } catch (error) {
      console.warn("Error:", error);
      await dispatch(errorHandler(error));
    }
  };
}

export const companyServices = {
  createCompany: (data) => axios(options(API_URL + "/company", data, "post")),
  getCompanies: () => axios(options(API_URL + "/company", {}, "get")),
  getCompanyById: (data) => axios(options(API_URL + `/company/${data}`, {}, "get")),
  deleteCompanyUsers: (body) =>
    axios(options(API_URL + "/company/deleteCompanyUsers", body, "delete")),
  deleteCompany: (body) => axios(options(API_URL + "/company/deleteCompany", body, "delete")),
  getAllCompanies: () => axios(options(API_URL + "/company/all-companies", {}, "get")),
  getCompaniesAndTeamsByUserId: (data) =>
    axios(options(API_URL + `/companiesByUser/${data}`, {}, "get")),
  updateCompany: (data) => axios(options(API_URL + "/company", data, "put")),
  getPlainCompanies: () => axios(options(API_URL + "/companies", {}, "get")),
  getPlainCompaniesById: (data) => axios(options(API_URL + `/companies/${data}`, {}, "get")),
  getAllCompaniesByUser: (companies) =>
    axios(options(API_URL + `/company/all-companies/${companies}`, {}, "get")),
  getAllCompaniesWithUsers: () =>
    axios(options(API_URL + `/all-companies-users`, {}, "get")),
  getCompaniesAndProjectsQuery: (userCompanies) =>
    axios(options(API_URL + `/company/companies-includes-projects/${userCompanies}`, {}, "get")),
  getAllCompaniesAndProjectsQuery: () =>
    axios(options(API_URL + `/all-companies-includes-projects`, {}, "get")),
  getCompaniesAndTeams: () => axios(options(API_URL + "/companies-and-teams", {}, "get")),
};
