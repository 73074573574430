/* eslint-disable */

import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { Button, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import { getDateTime } from "../../../utils/misc";
import DeleteFilesDialog from "../../dialogs/ConfirmDeletingFiles/DeleteFilesDialog";
import TermsAndConditionsDialog from "../../dialogs/TermsAndConditions/TermsAndConditionsDialog";
import UserStatusComponent from "../../widgets/UserStatusComponent";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import NewConfirmDialog, { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";
import { adminServices } from "../../../services/admin";
import UserProjectsModal from "../../modal/UserProjectsModal";
import { useNavigate } from "react-router-dom";
import UserActionsColumn from "../../UserActions/UserActionsColumn";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import { LightTooltip } from "../TermsTable/TermsTable";
import CompanyActionsColumn from "../../company/CompanyActions/CompanyActionsColumn";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";
import getTheUserState from "../../../utils/userState";

export const tableType = {
  general: "general",
  project: "project",
  users: "users",
  companies: "companies",
  usersInTeams: "usersInTeams",
  userRoles: "userRoles",
  companyUsers: "companyUsers",
  teamUsers: "teamUsers",
  downloadHistory: "downloadHistory",
};

export const tableSpecification = {
  noCheckbox: "no checkbox",
};

function descendingComparator(a, b, orderBy) {
  if (typeof a[orderBy] === "string" && typeof b[orderBy] === "string") {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
    return 0;
  }
  if (Array.isArray(a[orderBy]) && Array.isArray(b[orderBy])) {
    if (b[orderBy].length < a[orderBy].length) {
      return -1;
    }
    if (b[orderBy].length > a[orderBy].length) {
      return 1;
    }
    return 0;
  }
  if (typeof a[orderBy] === "number" && typeof b[orderBy] === "number") {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  //This if is an edge case only for the filesTableTypes.settings table to sort by filename
  if (
    typeof a?.file?.name === "string" &&
    typeof b?.file?.name === "string" &&
    orderBy === "filename"
  ) {
    if (b.file.name < a.file.name) {
      return -1;
    }
    if (b.file.name > a.file.name) {
      return 1;
    }
    return 0;
  }
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_type",
    numeric: false,
    disablePadding: false,
    label: "File Type",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: true,
    label: "Company(s)",
  },
  {
    id: "team",
    numeric: false,
    disablePadding: false,
    label: "Team(s)",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "last_edit",
    numeric: false,
    disablePadding: false,
    label: "Last edit",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const headCells2 = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_type",
    numeric: false,
    disablePadding: false,
    label: "File Type",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "last_edit",
    numeric: false,
    disablePadding: false,
    label: "Last edit",
  },
];

const usersHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  {
    id: "lastName",
    numeric: false,
    disablePadding: true,
    label: "Last Name",
  },
  //Under discussion - companies
  {
    id: "companies",
    numeric: false,
    disablePadding: false,
    label: "Companies",
  },
  {
    id: "projects",
    numeric: false,
    disablePadding: true,
    label: "Projects",
  },
  {
    id: "files",
    numeric: false,
    disablePadding: true,
    label: "Files",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

const companiesHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "phone",
    numeric: false,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "companyUrl",
    numeric: false,
    disablePadding: true,
    label: "Company URL",
  },
  {
    id: "country",
    numeric: false,
    disablePadding: true,
    label: "Country",
  },
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "Users",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

const companyUsersHeadCells = [
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Full Name",
  },
  {
    id: "Teams",
    numeric: false,
    disablePadding: true,
    label: "Teams",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

const teamUsersHeadCells = [
  {
    id: "user",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Full Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
];

const usersInTeamsHeadCell = [
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "User ID",
  // },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: true,
    label: "Email",
  },
  {
    id: "fullName",
    numeric: false,
    disablePadding: false,
    label: "Full Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
];

const userRolesHeadCell = [
  // {
  //   id: "id",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "User ID",
  // },
  {
    id: "userName",
    numeric: false,
    disablePadding: false,
    label: "Username",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
];

const downloadHistoryHeadCell = [
  {
    id: "username",
    numeric: false,
    disablePadding: true,
    label: "Username",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: true,
    label: "Role",
  },
  {
    id: "file",
    numeric: false,
    disablePadding: false,
    label: "File",
  },
  {
    id: "accepted_terms",
    numeric: false,
    disablePadding: false,
    label: "Accepted Terms",
  },
  {
    id: "downloaded_on",
    numeric: false,
    disablePadding: false,
    label: "Downloaded On",
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, type, specification } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const userState = getTheUserState();

  const checkRemoveUserFromTeamPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.companies.remove_users &&
      permission.status === true
  );

  const removeUserFromTeamCheck = !checkRemoveUserFromTeamPermission && tableType.teamUsers === type;
  const tooltipTitle2 = `You have no permission to remove users from the team!`;

  return (
    <TableHead sx={{ background: "#F4F4F4" }}>
      <TableRow>
        {type === tableType.usersInTeams
          || type === tableType.userRoles
          || type === tableType.companies
          || type === tableType.downloadHistory
          || specification === tableSpecification.noCheckbox ? null : (

          removeUserFromTeamCheck ?
            <LightTooltip title={tooltipTitle2} arrow placement="left">
              < TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  onChange={onSelectAllClick}
                  checked={rowCount > 0 && numSelected === rowCount}
                  disabled={removeUserFromTeamCheck}
                  inputProps={{
                    "aria-labelledby": "select all",
                  }}
                />
              </TableCell>
            </LightTooltip>
            : <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
        )
        }

        {
          tableType.project === type && (
            <>
              {headCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.general === type && (
            <>
              {headCells2?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.users === type && (
            <>
              {usersHeadCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell sx={customTableStyles.cellStyles} align="center">
                Actions
              </TableCell>
            </>
          )
        }
        {
          tableType.companies === type && (
            <>
              {companiesHeadCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.companyUsers === type && (
            <>
              {companyUsersHeadCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.teamUsers === type && (
            <>
              {teamUsersHeadCells?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.usersInTeams === type && (
            <>
              {usersInTeamsHeadCell?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.userRoles === type && (
            <>
              {userRolesHeadCell?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
        {
          tableType.downloadHistory === type && (
            <>
              {downloadHistoryHeadCell?.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={customTableStyles.cellStyles}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </>
          )
        }
      </TableRow >
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export function EnhancedTableToolbar(props) {
  const { numSelected, selected, setSelected, role, tools } = props;

  const queryClient = useQueryClient();

  const [elements, setElements] = React.useState([]);
  const [filteredTools, setFilteredTools] = React.useState([]);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);

  const userDeleteMutation = useMutation({
    mutationFn: adminServices.deleteUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  async function handleDeleteUsers() {
    // dispatch(deleteUser([selected]));
    userDeleteMutation.mutate([selected]);
    closeDeleteDialog();
    setSelected([]);
  }

  function filterTermsAndConditions(array) {
    for (let index = 0; index < array?.length; index++) {
      const element = array[index];

      if (elements?.includes(element?.terms_and_condition_id)) {
        continue;
      } else {
        setElements((prev) => [...prev, element?.terms_and_condition_id]);
      }
    }

    // console.log("elements", elements);
  }

  React.useEffect(() => {
    const filteredArray = tools?.filter((obj) => selected?.includes(obj.id));
    setFilteredTools(filteredArray);
  }, [selected]);

  let content;

  if (role === "user") {
    content = (
      <Toolbar>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            Are you sure you want to download {numSelected} files?
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <div style={{ display: "flex", minWidth: "28%", justifyContent: "space-between" }}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => setSelected([])}
              sx={customTableStyles.cancelBtn}
            >
              Cancel
            </Button>
            <DeleteFilesDialog filesCount={numSelected} />
            <TermsAndConditionsDialog filteredTools={filteredTools} />
          </div>
        ) : null}
      </Toolbar>
    );
  } else if (role === "users") {
    content = (
      <Toolbar sx={{ width: "100%" }}>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            Are you sure you want to delete {numSelected} {numSelected > 1 ? "users?" : "user?"}
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <div style={{ display: "flex", minWidth: "28%", justifyContent: "space-between" }}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => setSelected([])}
              sx={customTableStyles.cancelBtn}
            >
              Cancel
            </Button>
            {/* <TermsAndConditionsDialog filteredTools={filteredTools} /> */}
            <Button
              variant="contained"
              color="inherit"
              onClick={() => setIsDeleteDialogOpen(true)}
              sx={{
                borderRadius: "2px",
                background: "#1E9DF2",
                fontFamily: "IBM Plex Sans",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "22.4px",
                letterSpacing: "-0.32px",
                textTransform: "capitalize",
                marginRight: "-10px",
                width: "200px",
                height: "54px",
                color: "white",
                "&:hover": {
                  background: "#1E9DF2",
                },
              }}
            >
              Delete
            </Button>
            <NewConfirmDialog
              open={isDeleteDialogOpen}
              onClose={closeDeleteDialog}
              data={selected}
              action={handleDeleteUsers}
              type={confirmTypes.deleteUsers}
            />
          </div>
        ) : null}
      </Toolbar>
    );
  } else {
    content = (
      <Toolbar sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        {numSelected > 0 ? (
          <Typography sx={{ flex: "1 1 100%" }} color="inherit" variant="subtitle1" component="div">
            Are you sure you want to delete {numSelected} files?
          </Typography>
        ) : null}
        {numSelected > 0 ? (
          <div style={{ display: "flex", minWidth: "28%", justifyContent: "space-between" }}>
            <Button
              variant="text"
              color="inherit"
              onClick={() => setSelected([])}
              sx={customTableStyles.cancelBtn}
            >
              Cancel
            </Button>
            <DeleteFilesDialog filesCount={numSelected} />
          </div>
        ) : null}
      </Toolbar>
    );
  }

  return content;
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({ selected, setSelected, setSecondType, rows, type, specification }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(tableType.userRoles ? rows?.length : 25);
  const [paginationEvaluation, setPaginationEvaluation] = React.useState();

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const navigate = useNavigate();

  const userState = getTheUserState();

  const checkRemoveUserFromTeamPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.companies.remove_users &&
      permission.status === true
  );

  const checkUsersAllPermissions = userState?.user?.role?.permissions?.some(
    (permission) =>
      (permission.permission_name === PermissionsSchema.users.activate ||
        permission.permission_name === PermissionsSchema.users.deactivate ||
        permission.permission_name === PermissionsSchema.users.create ||
        permission.permission_name === PermissionsSchema.users.delete ||
        permission.permission_name === PermissionsSchema.users.update)
      && permission.status === true
  );

  // tableType.companyUsers === type || tableType.teamUsers === type
  const {
    data: rolesData,
    isLoading: isLoadingRoles,
    isError: isErrorRoles,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: adminServices.getRoles,
    enabled: tableType.companyUsers === type || tableType.teamUsers === type,
  });

  const [selectedRowData, setSelectedRowData] = React.useState(null);
  function handleUserProjects(rowData) {
    setSelectedRowData(rowData);
    setIsModalOpen(true);
  }

  //This function checks if the table need pagination
  const checkType = () => {
    if (type === tableType.userRoles) {
      setPaginationEvaluation(false);
    }
    if (type === tableType.users) {
      setPaginationEvaluation(true);
      setRowsPerPage(25);
    }
    if (type === tableType.companyUsers) {
      setPaginationEvaluation(true);
      setRowsPerPage(25);
    }
    if (type === tableType.teamUsers) {
      setPaginationEvaluation(true);
      setRowsPerPage(25);
    }
    if (type === tableType.companies) {
      setPaginationEvaluation(true);
      setRowsPerPage(25);
    }
    if (type === tableType.downloadHistory) {
      setPaginationEvaluation(true);
      setRowsPerPage(25);
    }
  };

  React.useEffect(() => {
    checkType();
  }, []);

  // TODO: Fix the issue, where more then 1 modal is open

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllInCompanyUsers = (event) => {
    if (event?.target?.checked && selected?.length > 0) {
      setSelected([]);
    } else if (event?.target?.checked && (type === tableType.companyUsers || type === tableType.teamUsers)) {
      const newSelected = rows?.filter((n) => !n?.CompanyUser?.isAdmin).map((n) => n?.id);
      setSelected(newSelected);
      if (setSecondType) {
        setSecondType([]);
      }
    }
    else if (event?.target?.checked && tableType.users === type) {
      const newSelected = rows.filter((row) => !row?.Companies?.some(company => {
        return company?.CompanyUser?.userId === row?.id && company?.CompanyUser?.isAdmin;
      })).map((n) => n?.id);
      setSelected(newSelected);
      if (setSecondType) {
        setSecondType([]);
      }
    } else {
      setSelected([]);
    }
    if (setSecondType) {
      setSecondType([]);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.id);
      setSelected(newSelected);
      if (setSecondType) {
        setSecondType([]);
      }
      return;
    }
    setSelected([]);
    if (setSecondType) {
      setSecondType([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (setSecondType) {
      setSecondType([]);
    }
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    const maxPage = Math.ceil(rows.length / rowsPerPage) - 1;
    if (newPage >= 0 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  function handleUserRedirection(row) {
    const user = {
      id: row.id,
    };

    navigate(`/dashboard/users/${row.id}`, { state: { user } });
  }

  function handleCompanyRedirection(row) {
    const company = {
      companyId: row.id,
    };

    navigate(`/dashboard/companies/${row.id}`, { state: { company } });
  }

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, rows?.length, rows]
  );

  let tableContent;

  if (tableType.project === type) {
    tableContent = (
      <Table
        stickyHeader
        sx={customTableStyles.tableStyles}
        aria-labelledby="tableTitle"
        size="small"
      >
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.length ? (
            visibleRows?.map((row, index) => {
              const isItemSelected = isSelected(row?.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row?.id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row?.id)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ fontSize: "11px", color: "#1E9DF2", border: "1px solid  #E8E8E9" }}
                  >
                    {row?.tool_name}
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    <p style={customTableStyles.fileExtension}>
                      {row.upload_path.substring(row?.upload_path.length - 3)}
                    </p>
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    {row?.status.toString()}
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    Unknown
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    Unknown
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    {row?.description}
                  </TableCell>
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    {getDateTime(row?.updated)}
                  </TableCell>
                  <TableCell align="center" sx={customTableStyles.cellStyles2}>
                    <IconButton
                      size="small"
                      id="project-actions"
                    // aria-controls={open ? "basic-menu" : undefined}
                    // aria-haspopup="true"
                    // aria-expanded={open ? "true" : undefined}
                    // onClick={handleClick}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell align="center" sx={{ color: "orange" }}>
                There are no ressources!
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          )}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  } else if (tableType.general === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                onClick={(event) => handleClick(event, row?.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row?.name}
                selected={isItemSelected}
                sx={{ cursor: "pointer" }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "11px", color: "#1E9DF2", border: "1px solid  #E8E8E9" }}
                >
                  {row?.ressource}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <p style={customTableStyles.fileExtension}>
                    {row?.upload_path?.substring(row?.upload_path.length - 3)}
                  </p>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.status?.toString()}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.description}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {getDateTime(row?.updated)}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  } else if (tableType.users === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllInCompanyUsers}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const isTheUserAdmin = row?.Companies?.some(company => {
              return company?.CompanyUser?.userId === row?.id && company?.CompanyUser?.isAdmin;
            });
            const adminCompanyNames = row?.Companies
              .filter(company => company?.CompanyUser?.userId === row?.id && company?.CompanyUser?.isAdmin)
              .map(adminCompany => adminCompany?.name);
            const tooltipTitle = `${row?.user} is the company admin to ${adminCompanyNames?.join(", ")}.
              In order to delete this user, you need to change company admin first.`;
            const tooltipTitle2 = "View user details.";
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row?.id}
              >
                {isTheUserAdmin ? <LightTooltip title={tooltipTitle} arrow placement="left">
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row?.id)}
                      color="primary"
                      checked={isItemSelected}
                      disabled={isTheUserAdmin}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                </LightTooltip>
                  :
                  <TableCell padding="checkbox">
                    <Checkbox
                      onClick={(event) => handleClick(event, row?.id)}
                      color="primary"
                      checked={isItemSelected}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>}
                <LightTooltip title={tooltipTitle2} arrow placement="left">
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}>
                      {row?.user}
                    </p>
                  </TableCell>
                </LightTooltip>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.email}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.firstName}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.lastName}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <div style={{ display: "flex", color: "#1E9DF2", alignItems: "center" }}>
                    <span style={{ padding: "0px 5px" }}>{row?.companies_count || 0}</span>
                    <span sx={customTableStyles.projectsTitle}>
                      {row?.companies_count !== 1 ? "companies" : "company"}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <div style={{ display: "flex", color: "#1E9DF2", alignItems: "center" }}>
                    <FolderOutlinedIcon />
                    <span style={{ padding: "0px 5px" }}>{row?.projects_count || 0}</span>
                    <span sx={customTableStyles.projectsTitle}>
                      {row?.projects_count !== 1 ? "projects" : "project"}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <div style={{ display: "flex", color: "#1E9DF2", alignItems: "center" }}>
                    <InsertDriveFileIcon />
                    <span style={{ padding: "0px 5px" }}>{row?.files_count || 0}</span>
                    <span sx={customTableStyles.projectsTitle}>
                      {row?.files_count !== 1 ? "files" : "file"}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {UserStatusComponent(row.status)}
                </TableCell>
                <TableCell>
                  <UserActionsColumn user={row} />
                </TableCell>
              </TableRow>
            );
          })}
          {/* {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
      </Table>
    );
  }
  else if (tableType.companies === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            const tooltipTitle = "View company details.";
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row?.id}
              >
                <LightTooltip title={tooltipTitle} arrow placement="left">
                  <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    <p style={customTableStyles.name} onClick={() => handleCompanyRedirection(row)}>
                      {row?.name}
                    </p>
                  </TableCell>
                </LightTooltip>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.email}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.phone}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row.url}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row.country}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <div style={{ display: "flex", color: "#1E9DF2", alignItems: "center" }}>
                    <PeopleOutlineOutlinedIcon />
                    <span style={{ padding: "0px 5px" }}>{row?.users_count || 0}</span>
                    <span sx={customTableStyles.projectsTitle}>
                      {row?.users_count !== 1 ? "users" : "user"}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center" sx={customTableStyles.cellStyles2}>
                  <CompanyActionsColumn company={row} />
                </TableCell>
              </TableRow>
            );
          })}
          {/* {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
      </Table>
    );
  } else if (tableType.companyUsers === type || tableType.teamUsers === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllInCompanyUsers}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
          specification={specification}
        />
        {!isLoadingRoles && <TableBody>
          {visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;
            const isTheUserAdmin = row?.CompanyUser?.isAdmin;
            const removeUserFromTeamCheck = !checkRemoveUserFromTeamPermission && tableType.teamUsers === type;
            const tooltipTitle = `${row?.user} is the company admin.
             In order to delete this user, you need to change company admin first.`;
            const tooltipTitle2 = `You have no permission to remove users from the team!`;
            const userRole = rolesData?.data?.filter(item => Number(item?.role_id) === Number(row?.role_id));
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row?.id}
              >
                {specification !== tableSpecification.noCheckbox && removeUserFromTeamCheck ?
                  <LightTooltip title={tooltipTitle2} arrow placement="left">
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row?.id)}
                        color="primary"
                        checked={isItemSelected}
                        disabled={removeUserFromTeamCheck}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  </LightTooltip> :
                  isTheUserAdmin ? <LightTooltip title={tooltipTitle} arrow placement="left">
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row?.id)}
                        color="primary"
                        checked={isItemSelected}
                        disabled={isTheUserAdmin}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  </LightTooltip>
                    : (specification !== tableSpecification.noCheckbox && <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) => handleClick(event, row?.id)}
                        color="primary"
                        checked={isItemSelected}
                        disabled={isTheUserAdmin}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>)
                }
                {/* <TableCell align="left" sx={{ fontSize: "11px", border: "1px solid  #E8E8E9" }}>
                  {row?.id}
                </TableCell> */}
                {checkUsersAllPermissions ? <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}>
                    {row?.user}
                  </p>
                </TableCell>
                  : <TableCell align="left" sx={customTableStyles.cellStyles2}>
                    {/* <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}> */}
                    {row?.user}
                    {/* </p> */}
                  </TableCell>}
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.email}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.name}
                </TableCell>
                {tableType.companyUsers === type && <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <div style={{ display: "flex", color: "#1E9DF2", alignItems: "center" }}>
                    <FolderOutlinedIcon />
                    <span style={{ padding: "0px 5px" }}>{row?.Teams?.length || 0}</span>
                    <span
                      sx={customTableStyles.projectsTitle}
                    >
                      {row?.Teams?.length !== 1
                        ? ` teams`
                        : ` team`}
                    </span>
                  </div>
                </TableCell>}

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {userRole[0]?.role_name || ""}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {UserStatusComponent(row.status)}
                </TableCell>
                <UserProjectsModal
                  open={isModalOpen}
                  setOpen={setIsModalOpen}
                  projects={selectedRowData}
                />
              </TableRow>
            );
          })
          }
          {/* {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody >}
      </Table >
    );
  } else if (tableType.usersInTeams === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                // onClick={(event) => handleClick(event, row?.id)}
                // role="checkbox"
                // aria-checked={isItemSelected}
                tabIndex={-1}
                key={row?.id}
              // selected={isItemSelected}
              // sx={{ cursor: "pointer" }}
              >
                {/* <TableCell padding="checkbox">
                  <Checkbox
                    onClick={(event) => handleClick(event, row?.id)}
                    color="primary"
                    checked={isItemSelected}
                    inputProps={{
                      "aria-labelledby": labelId,
                    }}
                  />
                </TableCell> */}
                {/* <TableCell align="left" sx={{ fontSize: "11px", border: "1px solid  #E8E8E9" }}>
                  {row?.id}
                </TableCell> */}
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}>
                    {row?.user}
                  </p>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.email}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.name}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row.role}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  } else if (tableType.userRoles === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            const isItemSelected = isSelected(row?.id);
            const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                // onClick={(event) => handleClick(event, row?.id)}
                // role="checkbox"
                // aria-checked={isItemSelected}
                tabIndex={-1}
                key={row?.role_id}
              // selected={isItemSelected}
              // sx={{ cursor: "pointer" }}
              >
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}>
                    {row?.name}
                  </p>
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.role_id}
                </TableCell>
              </TableRow>
            );
          })}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  } else if (tableType.downloadHistory === type) {
    tableContent = (
      <Table sx={customTableStyles.tableStyles} aria-labelledby="tableTitle" size="small">
        <EnhancedTableHead
          numSelected={selected?.length || 0}
          order={order}
          orderBy={orderBy}
          onSelectAllClick={handleSelectAllClick}
          onRequestSort={handleRequestSort}
          rowCount={rows?.length || 0}
          type={type}
        />
        <TableBody>
          {visibleRows?.map((row, index) => {
            // const isItemSelected = isSelected(row?.id);
            // const labelId = `enhanced-table-checkbox-${index}`;

            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row?.id}
              >
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {/* <p style={customTableStyles.name} onClick={() => handleUserRedirection(row)}> */}
                  {row?.username}
                  {/* </p> */}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.email}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.name}
                </TableCell>

                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.role}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.file}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {row?.accepted_terms}
                </TableCell>
                <TableCell align="left" sx={customTableStyles.cellStyles2}>
                  {getDateTime(row?.createdAt)}
                </TableCell>
              </TableRow>
            );
          })}
          {/* {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}
            >
              <TableCell colSpan={6} />
            </TableRow>
          )} */}
        </TableBody>
      </Table>
    );
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          boxShadow: "none",
          background: "transparent",
          // "& .MuiModal-root": { minWidth: "800px" },
        }}
      >
        <TableContainer sx={{ boxShadow: "none" }}>{tableContent}</TableContainer>
        {rows?.length >= 5 && paginationEvaluation ? (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiIconButton-root": {
                color: "#1E9DF2",
                "&.Mui-disabled": {
                  color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                },
              },
              ".MuiTablePagination-displayedRows": {
                fontSize: "14px",
                color: "#1B1E1F",
                fontFamily: "IBM Plex Sans",
                fontWeight: "500",
              },
              ".MuiTablePagination-select": {
                fontSize: "14px",
                color: "#1B1E1F",
                fontFamily: "IBM Plex Sans",
                fontWeight: "500",
              },
              ".MuiTablePagination-selectIcon": {
                color: "#1E9DF2",
              },
              ".MuiTablePagination-selectLabel": {
                fontSize: "14px",
                color: "#1B1E1F",
                fontFamily: "IBM Plex Sans",
                fontWeight: "500",
              },
              ".MuiTablePagination-toolbar": {
                position: (type === tableType.users || type === tableType.companies || type === tableType.downloadHistory) && "absolute",
                bottom: (type === tableType.users || type === tableType.companies || type === tableType.downloadHistory) && "15px",
                paddingLeft: (type === tableType.users || type === tableType.companies || type === tableType.downloadHistory) && "0px",
              }
            }}
          />
        ) : null}
      </Paper>
    </Box>
  );
}

const customTableStyles = {
  tableStyles: {
    borderTop: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderBottom: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderLeft: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderRight: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    boxShadow: "none",
    // border: "2px solid red",
  },
  cellStyles: {
    borderTop: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderBottom: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderLeft: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    color: "#1B1E1F",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    // height: "35px",
    // minWidth: "70px",
    padding: "8px 12px",
    // width: "max-content",
    "& .MuiTableSortLabel-root": {
      width: "max-content",
    },
  },
  cellStyles2: {
    border: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "8px 12px",
    color: "#1B1E1F",
  },
  deleteBtn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  cancelBtn: {
    // borderRadius: "2px",
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "140px",
    height: "54px",
    marginRigh: "15px",
    // color: "white",
    // "&:hover": {
    //   background: "#1E9DF2",
    // },
  },
  fileExtension: {
    background: "#F5F2FF",
    color: "#7B6FA3",
    textAlign: "center",
    textTransform: "uppercase",
    padding: "5px 15px",
    width: "fit-content",
  },
  name: {
    color: "#1E9DF2",
    // padding: "5px 15px",
    cursor: "pointer",
    width: "fit-content",
    "& hover:": {
      borderBottom: "1px solid #1E9DF2",
    },
  },
  projectsTitle: {
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
  },
};
