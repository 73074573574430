/* eslint-disable */
import { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField,
} from "@mui/material";
import EnhancedTable, { tableType } from "../tables/CustomMuiTable/EnchancedMuiTable";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { teamServices } from "../../services/team";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../utils/notifications";

const AddMembersToTeamModal = ({ team, company }) => {
  const [open, setOpen] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);

  const queryClient = useQueryClient();

  const assignUsersToTeamMutation = useMutation({
    mutationFn: teamServices.assignUsersToTeam,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["plainCompanies", "companyById"]);
      setOpen(false);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  useEffect(() => {
    // If the user is already in the team will not be displayed duo to this filtration
    const filterUsersResult = company?.users?.filter(
      (obj1) => !team?.users?.some((obj2) => obj2.id === obj1.id)
    ) || [];

    setFilterUsers(filterUsersResult);
  }, [team, company]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    setSelectedMembers(newValue);
  };

  function handleAssignUsersToTeam() {
    // Collect only the id's of the users.
    const members = selectedMembers.map((obj) => obj.id);
    const data = { teamId: team?.id, members };
    assignUsersToTeamMutation.mutate(data);
  }

  return (
    <div>
      <Button
        sx={{
          padding: "8px",
          alignSelf: "center",
          display: "flex",
          minWidth: "179px",
          textTransform: "capitalize",
          fontSize: "16px",
        }}
        variant="text"
        onClick={() => setOpen(true)}
      >
        <AddIcon /> Add new members
      </Button>
      <Dialog open={open} onClose={handleClose} sx={membersToTeamStyles.dialog}>
        <div
          style={{
            alignSelf: "flex-end",
            // marginBottom: "-30px",
            padding: "10px 10px 0px 10px",
          }}
        >
          <IconButton onClick={handleClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <div style={{ margin: "0px 64px 40px 64px" }}>
          <p style={membersToTeamStyles.title}>Add New Team Members</p>
          <p style={membersToTeamStyles.team}>{team?.name}</p>
          <DialogContent sx={membersToTeamStyles.content}>
            <Autocomplete
              multiple
              id="search-select"
              options={filterUsers}
              getOptionLabel={(option) => `${option.user}`}
              value={selectedMembers}
              onChange={handleChange}
              filterOptions={(options, state) => {
                const inputValue = state.inputValue.toLowerCase();
                return options.filter(
                  (option) =>
                    option.user.toLowerCase().includes(inputValue) ||
                    option.email.toLowerCase().includes(inputValue)
                );
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option, { selected }) => (
                <li {...props} style={{ fontSize: "12px" }} key={option.id}>
                  <Checkbox checked={selected} color="primary" disableRipple size="small" />
                  {`${option.user}  (${option.email})`}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="New team member(s)"
                  size="small"
                  placeholder="Enter username or email"
                  sx={membersToTeamStyles.textField}
                />
              )}
            />
            <EnhancedTable rows={team?.users} type={tableType.usersInTeams} />
          </DialogContent>
        </div>
        <DialogActions sx={membersToTeamStyles.actions}>
          <Button sx={membersToTeamStyles.cancelBtn} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleAssignUsersToTeam}
            style={
              selectedMembers?.length
                ? membersToTeamStyles.saveBtnActive
                : membersToTeamStyles.saveBtnDisabled
            }
            disabled={selectedMembers?.length ? false : true}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddMembersToTeamModal;

const membersToTeamStyles = {
  dialog: {
    "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
    "& .MuiPaper-root": { borderRadius: "16px", width: "100%", minWidth: "fit-content" },
  },
  content: {
    // border: "1px solid red",
    margin: "0px",
    padding: "0px",
  },
  title: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    margin: "0px",
  },
  team: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    paddingTop: "8px",
    paddingBottom: "24px",
  },
  actions: {
    borderTop: "1px solid #F4F4F4",
    padding: "16px 64px",
    justifyContent: "space-between",
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    paddingBottom: "24px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  cancelBtn: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#FFF",
    color: "#1E9DF2",
    textTransform: "capitalize",
  },
  saveBtnActive: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#1E9DF2",
    color: "#FFF",
    textTransform: "capitalize",
  },
  saveBtnDisabled: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#D1D2D2",
    color: "#FFF",
    textTransform: "capitalize",
  },
};
