/* eslint-disable */
import { Grid, IconButton, Toolbar, Typography } from "@mui/material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useState } from "react";
import NewConfirmDialog, { confirmTypes } from "../dialogs/NewConfirmDialog/NewConfirmDialog";
import DeleteFilesDialog from "../dialogs/ConfirmDeletingFiles/DeleteFilesDialog";
import DownloadFilesDialog from "../dialogs/NewConfirmDialog/DownloadFileDialog";
import BlockPermissionComponent from "../auth/BlockPermissionComponent";
import { PermissionsSchema } from "../auth/PermissionsWrapper";
import getTheUserState from "../../utils/userState";

const DeleteMembersModal = (props) => {
  //The data depends on what type we have in the NewConfirmDialog component and the count of selected users
  const { handleDelete, type, data, setSelected = () => { } } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [openSecondDialog, setOpenSecondDialog] = useState(false);

  const userState = getTheUserState();

  const checkFMAccessPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.fm_access &&
      permission.status === true
  );

  const closeAfterDelete = () => {
    setOpenDialog(false);
    setSelected([]);
  };

  const closeAfterDownload = () => {
    setOpenSecondDialog(false);
    setSelected([]);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item>
          <Typography
            sx={{
              padding: "0px 8px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: "500",
              lineHeight: "1.75",
              fontFamily: "Roboto ,sans-serif",
            }}
          >
            {type === confirmTypes.deleteAndDownloadFiles || type === confirmTypes.deleteFiles
              ? `Selected ${data.selected.length} ${data.selected.length > 1 ? `files` : `file`}`
              : `Selected ${data.selected.length} ${data.selected.length > 1 ? `users` : `user`}`}
          </Typography>
        </Grid>
        <Grid item>
          {/* {(type !== confirmTypes.deleteAndDownloadFiles || type !== confirmTypes.deleteFiles)&& (
            <IconButton onClick={() => setOpenDialog(true)} color="primary">
              <DeleteForeverOutlinedIcon fontSize="medium" />
            </IconButton>
          )} */}

          {(type === confirmTypes.deleteAndDownloadFiles || type === confirmTypes.deleteFiles) && (
            <IconButton onClick={() => setOpenSecondDialog(true)} color="primary">
              <FileDownloadIcon fontSize="medium" />
            </IconButton>
          )}
          {(type === confirmTypes.deleteAndDownloadFiles && !checkFMAccessPermission || type === confirmTypes.deleteFiles && !checkFMAccessPermission) ?
            null
            : <IconButton onClick={() => setOpenDialog(true)} color="primary">
              <DeleteForeverOutlinedIcon fontSize="medium" />
            </IconButton>
          }
          {/* {(type !== confirmTypes.deleteTeamUser) && ( */}
          {/* <IconButton onClick={() => setOpenDialog(true)} color="primary">
              <DeleteForeverOutlinedIcon fontSize="medium" />
            </IconButton> */}
          {/* )} */}
          {/* {type === confirmTypes.deleteTeamUser && (
            <BlockPermissionComponent allowedPermission={PermissionsSchema.companies.remove_users}>
              <IconButton onClick={() => setOpenDialog(true)} color="primary">
                <DeleteForeverOutlinedIcon fontSize="medium" />
              </IconButton>
            </BlockPermissionComponent>
          )} */}
        </Grid>
      </Grid>
      {type !== confirmTypes.deleteAndDownloadFiles && type !== confirmTypes.deleteFiles ? (
        <NewConfirmDialog
          open={openDialog}
          onClose={closeAfterDelete}
          action={handleDelete}
          data={data}
          type={type}
        />
      ) : (
        <>
          <DeleteFilesDialog
            open={openDialog}
            onClose={closeAfterDelete}
            files={data?.selected || []}
          />
          {openSecondDialog && (
            <DownloadFilesDialog
              open={openSecondDialog}
              onClose={closeAfterDownload}
              files={data?.selected || []}
            />
          )}
        </>
      )}
    </Toolbar>
  );
};

export default DeleteMembersModal;
