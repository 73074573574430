/* eslint-disable */
import "./fileManagement.css";
import { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  LinearProgress,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FilesContainer, {
  uploadTypes,
} from "../../../components/files/files_container/FilesContainer";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FilesTable, {
  filesTableTypes,
} from "../../../components/tables/CustomFilesTable/FilesTable";
import { filesService } from "../../../services/files";
import { useQuery } from "@tanstack/react-query";
import { maturityLevel } from "../../../components/files/files_dropdown/FilesSettingsDropdown";
import {
  ExtensionsColorSchemaResolver,
  extensionsColorSchemaArray,
} from "../../../components/cards/FilesCard/extensionsColorSchemaResolver";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import types from "../../../actions/types";
import { useDispatch } from "react-redux";
import getTheUserState from "../../../utils/userState";

export const selectedType = {
  maturityLevelType: "maturityLevel",
  companiesType: "companiesType",
  projectsType: "projectsType",
  fileExtensionType: "fileExtension",
  fileCatalog: "fileCatalog",
};

export const fileCatalog = {
  0: "Not in Catalog",
  1: "In Catalog",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
    // width: "176px",
    maxWidth: "306px",
  },
  list: {
    padding: "0px !important",
    // width: "176px",
    maxWidth: "306px",
  },
  disabled: {
    // Your styles for the disabled state
    opacity: 0.5,
    pointerEvents: "none",
  },
}));

const FileManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userCompanies = getTheUserState()?.user?.companies;

  const userObject = {
    companies: userCompanies,
    user_id: getTheUserState()?.user?.id,
  };

  const userState = getTheUserState();

  const checkViewAllFilesPermission = userState?.user?.role?.permissions?.some((permission) => {
    return (
      permission.permission_name === PermissionsSchema?.files?.view_all_files &&
      permission.status === true
    );
  });
  // Read
  const getAllFilesQuery = useQuery({
    queryKey: ["files"],
    queryFn: checkViewAllFilesPermission
      ? () => filesService.getAllFilesAdmin()
      : () => filesService.getAllFiles(userObject),
  });

  const [isUploadActive, setIsUploadActive] = useState(false);
  const [maturityLevelSelected, setMaturityLevelSelected] = useState([]);
  const [extensionSelected, setExtensionSelected] = useState([]);
  const [isFileCatalog, setIsFileCatalog] = useState([]);
  const [fileConnectionCompanies, setFileConnectionCompanies] = useState([]);
  const [fileConnectionProjects, setFileConnectionProjects] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [filteredData, setFilteredData] = useState(getAllFilesQuery?.data?.data?.files);
  const [hideFilters, setHideFilters] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [isUploadActive]);

  useEffect(() => {
    //Companies
    const companiesCheck = [];
    getAllFilesQuery?.data?.data?.files.forEach((file) => {
      file?.FileConnections?.forEach((connection) => {
        companiesCheck.push(connection.Company);
      });
    });

    const uniqueCompanySet = new Set();
    const uniqueCompanyArray = [];
    companiesCheck?.forEach((item) => {
      let object = JSON.stringify(item);
      if (!uniqueCompanySet.has(object)) {
        uniqueCompanySet.add(object);
        uniqueCompanyArray.push(item);
      }
    });
    setFileConnectionCompanies(uniqueCompanyArray);

    //Projects
    const projectsCheck = [];
    getAllFilesQuery?.data?.data?.files?.forEach((file) => {
      file?.FileConnections?.forEach((connection) => {
        projectsCheck.push(connection.project);
      });
    });

    const uniqueProjectSet = new Set();
    const uniqueProjectArray = [];
    projectsCheck?.forEach((item) => {
      let object = JSON.stringify(item);
      if (!uniqueProjectSet.has(object)) {
        uniqueProjectSet.add(object);
        uniqueProjectArray.push(item);
      }
    });
    setFileConnectionProjects(uniqueProjectArray);
  }, [getAllFilesQuery?.data?.data?.files?.FileConnections, getAllFilesQuery.isLoading]);

  useEffect(() => {
    let filtered = getAllFilesQuery?.data?.data?.files;

    if (extensionSelected.length > 0) {
      filtered = filtered.filter((item) => extensionSelected.includes(item.file_format));
    }
    if (maturityLevelSelected.length > 0) {
      filtered = filtered.filter((item) => maturityLevelSelected.includes(item.maturity_level));
    }
    if (selectedCompanies.length > 0) {
      filtered = filtered.filter((item) => {
        const hasMatchingCompany = item?.FileConnections?.some((connection) => {
          return selectedCompanies?.some((selected) => connection.Company.id === selected.id);
        });
        return hasMatchingCompany;
      });
    }
    if (selectedProjects.length > 0) {
      filtered = filtered.filter((item) => {
        const hasMatchingProject = item?.FileConnections?.some((connection) =>
          selectedProjects?.some((selected) => connection.project_id === selected.id)
        );
        return hasMatchingProject;
      });
    }
    if (isFileCatalog.length > 0) {
      filtered = filtered.filter((item) => isFileCatalog.includes(String(item?.file_catalog)));
    }
    if (searchText !== "") {
      filtered = filtered.filter(file => file.file_name.toLowerCase().includes(searchText)
        || file.description.toLowerCase().includes(searchText)
      )
    }
    setFilteredData(filtered);
  }, [
    maturityLevelSelected,
    extensionSelected,
    selectedCompanies,
    selectedProjects,
    isFileCatalog,
    getAllFilesQuery?.data?.data?.files,
    searchText,
  ]);

  function handleUploadActivation() {
    setIsUploadActive(!isUploadActive);
    dispatch({
      type: types.SET_UPLOAD_TYPE,
      payload: uploadTypes.fManager,
    });
  }

  const handleChange = (event, type) => {
    const {
      target: { value },
    } = event;
    if (type === selectedType.maturityLevelType) {
      setMaturityLevelSelected(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
    } else if (type === selectedType.fileExtensionType) {
      setExtensionSelected(typeof value === "string" ? value.split(",") : value);
    } else if (type === selectedType.fileCatalog) {
      setIsFileCatalog(typeof value === "string" ? value.split(",") : value);
    } else if (type === selectedType.companiesType) {
      if (value.length === 0) {
        setSelectedProjects([]);
      }
      setSelectedCompanies(typeof value === "string" ? value.split(",") : value);
    } else if (type === selectedType.projectsType) {
      setSelectedProjects(typeof value === "string" ? value.split(",") : value);
    }
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (event?.target?.value?.length === 0) {
      setSelectedProjects([]);
    }
    setSelectedCompanies(event?.target?.value);
  };

  const handleUnpick = (item, type) => {
    if (type === selectedType.maturityLevelType) {
      const filterPick = maturityLevelSelected.filter((level) => level !== item);
      setMaturityLevelSelected(filterPick);
    } else if (type === selectedType.companiesType) {
      const filterPick = selectedCompanies.filter((company) => company.id !== item.id);
      if (filterPick.length === 0) {
        setSelectedProjects([]);
      }
      setSelectedCompanies(filterPick);
    } else if (type === selectedType.projectsType) {
      const filterPick = selectedProjects.filter((project) => project.id !== item.id);
      setSelectedProjects(filterPick);
    } else if (type === selectedType.fileExtensionType) {
      const filterPick = extensionSelected.filter((extension) => extension !== item);
      setExtensionSelected(filterPick);
    } else if (type === selectedType.fileCatalog) {
      const filterPick = isFileCatalog.filter((catalog) => catalog !== item);
      setIsFileCatalog(filterPick);
    }
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue.toLowerCase());
  };

  const clearAllFilters = () => {
    setMaturityLevelSelected([]);
    setSelectedCompanies([]);
    setSelectedProjects([]);
    setExtensionSelected([]);
    setIsFileCatalog([]);
  };

  const someSelected =
    selectedCompanies.length > 0 ||
    selectedProjects.length > 0 ||
    extensionSelected.length > 0 ||
    isFileCatalog.length > 0 ||
    maturityLevelSelected.length > 0;

  let content;

  if (getAllFilesQuery.status !== "pending") {
    content = (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
        <div
          style={{
            overflow: "auto",
            flex: 1,
            // paddingLeft: "40px",
            // paddingTop: "32px",
            padding: "32px 41px 0px 40px",
          }}
        >
          <p style={styles.title}>File Management</p>
          <div
            style={{ display: "flex", justifyContent: "space-between", margin: "0px 0px 16px 0px" }}
          >
            <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>Filters</Typography>
            <div>
              <Button
                disabled={!someSelected}
                color="primary"
                onClick={clearAllFilters}
                sx={styles.buttons}
              >
                Clear all
              </Button>
              <Button
                color="primary"
                onClick={() => setHideFilters(!hideFilters)}
                sx={styles.buttons}
              >
                {hideFilters ? "Show filters" : "Hide filters"}
              </Button>
            </div>
          </div>
          {!hideFilters && (
            <div>
              <FormControl sx={styles.formControll} size="small">
                <InputLabel id="demo-select-small-label" placeholder="Choose an option">
                  Maturity Level
                </InputLabel>
                <Select
                  multiple
                  value={maturityLevelSelected}
                  onChange={(e) => handleChange(e, selectedType.maturityLevelType)}
                  input={<OutlinedInput label="Maturity Level" />}
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                      list: classes.list,
                    },
                  }}
                  placeholder="Choose an option"
                  sx={{
                    textAlign: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    ".MuiSelect-icon": { color: "#1E9DF2" },
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={-1} disabled>
                    <span style={{ fontSize: "16px" }}>Choose an option...</span>
                  </MenuItem>
                  {maturityLevel.map((level) => {
                    const maturityLevel = level?.title.split(" - ");
                    return (
                      <MenuItem
                        sx={styles.menuItem}
                        key={level.value}
                        value={level.title}
                        placeholder="Choose an option"
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            minWidth: "max-content",
                            backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                            color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                            padding: "0px 6px",
                            margin: "0px 4px 0px 0px",
                          }}
                        >
                          {maturityLevel[0]}
                        </span>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {maturityLevel[1]}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={styles.formControll} size="small">
                <InputLabel id="demo-select-small-label">Company</InputLabel>
                <Select
                  multiple
                  // className={classes.select}
                  sx={{
                    borderRadius: "4px",
                    // margin: "0px",
                    ".MuiSelect-icon": { color: "#1E9DF2" },
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedCompanies}
                  onChange={(e) => handleChange(e, selectedType.companiesType)}
                  input={<OutlinedInput label="Company" />}
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                      list: classes.list,
                    },
                  }}
                  renderValue={(selected) => {
                    // console.log("selected", selected)
                    return selected.map((option) => `${option.name}`).join(", ");
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={-1} disabled>
                    <span style={{ fontSize: "16px" }}>Choose an option...</span>
                  </MenuItem>
                  {fileConnectionCompanies?.map((option) => {
                    const isSelected = selectedCompanies.some((object) => object.id === option.id);
                    return (
                      <MenuItem key={option?.id} value={option} sx={styles.menuItem}>
                        <Checkbox checked={isSelected} />
                        {/* <ListItemText primary={`${option?.name}`} /> */}
                        <span
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={styles.formControll} size="small">
                <InputLabel
                  id="demo-select-small-label"
                // sx={{
                //   "& .MuiInputLabel-root": {
                //     opacity: !(selectedCompanies.length > 0) ? "0.2" : "1"
                //   }
                // }}
                // className={`${!(selectedCompanies.length > 0) ? classes.label : ''}`}
                >
                  Project
                </InputLabel>
                <Select
                  multiple
                  disabled={!(selectedCompanies.length > 0)}
                  className={`${!(selectedCompanies.length > 0) ? classes.disabled : ""}`}
                  sx={{
                    borderRadius: "4px",
                    margin: "0px",
                    ".MuiSelect-icon": { color: "#1E9DF2" },
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={selectedProjects}
                  onChange={(e) => handleChange(e, selectedType.projectsType)}
                  input={<OutlinedInput label="Project" />}
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                      list: classes.list,
                    },
                  }}
                  renderValue={(selected) => {
                    return selected.map((option) => `${option.name}`).join(", ");
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={-1} disabled>
                    <span style={{ fontSize: "16px" }}>Choose an option...</span>
                  </MenuItem>
                  {fileConnectionProjects?.map((option) => {
                    const isSelected = selectedProjects.some((object) => object.id === option.id);
                    return (
                      <MenuItem key={option?.id} value={option} sx={styles.menuItem}>
                        <Checkbox checked={isSelected} />
                        <span
                          style={{
                            fontSize: "16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option?.name}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl sx={styles.formControll} size="small">
                <InputLabel id="demo-select-small-label">File Format</InputLabel>
                <Select
                  sx={{
                    borderRadius: "4px",
                    margin: "0px",
                    ".MuiSelect-icon": { color: "#1E9DF2" },
                  }}
                  multiple
                  value={extensionSelected}
                  onChange={(e) => handleChange(e, selectedType.fileExtensionType)}
                  input={<OutlinedInput label="File Format" />}
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                      list: classes.list,
                    },
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={-1} disabled>
                    <span style={{ fontSize: "16px" }}>Choose an option...</span>
                  </MenuItem>
                  {extensionsColorSchemaArray.map((extension) => (
                    <MenuItem sx={styles.menuItem} key={extension} value={extension}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {ExtensionsColorSchemaResolver(extension)}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                sx={{
                  margin: "0px 16px 8px 0px",
                  // width: "176px",
                  width: "226px",
                  ".MuiSelect-icon": { color: "#1E9DF2" },
                }}
                size="small"
              >
                <InputLabel id="demo-select-small-label">File Catalog</InputLabel>
                <Select
                  sx={{
                    borderRadius: "4px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={isFileCatalog}
                  onChange={(e) => handleChange(e, selectedType.fileCatalog)}
                  input={<OutlinedInput label="File Catalog" />}
                  MenuProps={{
                    classes: {
                      paper: classes.paper,
                      list: classes.list,
                    },
                  }}
                >
                  <MenuItem sx={styles.menuItem} value={-1} disabled>
                    <span style={{ fontSize: "16px" }}>Choose an option...</span>
                  </MenuItem>
                  {Object.entries(fileCatalog).map(([key, value]) => (
                    <MenuItem sx={styles.menuItem} key={key} value={key}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {value}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div
            style={{
              margin: "20px 0px 0px 0px",
              display:
                maturityLevelSelected?.length ||
                  selectedCompanies?.length ||
                  selectedProjects?.length ||
                  extensionSelected?.length ||
                  isFileCatalog?.length
                  ? "block"
                  : "none",
            }}
          >
            {maturityLevelSelected &&
              maturityLevelSelected?.map((levelSelected) => (
                <Chip
                  onDelete={() => handleUnpick(levelSelected, selectedType.maturityLevelType)}
                  key={levelSelected}
                  label={levelSelected}
                  sx={styles.chipStyle}
                />
              ))}
            {selectedCompanies &&
              selectedCompanies?.map((companySelected) => (
                <Chip
                  onDelete={() => handleUnpick(companySelected, selectedType.companiesType)}
                  key={companySelected.id}
                  label={companySelected.name}
                  sx={styles.chipStyle}
                />
              ))}
            {selectedProjects &&
              selectedProjects?.map((selectedProject) => (
                <Chip
                  onDelete={() => handleUnpick(selectedProject, selectedType.projectsType)}
                  key={selectedProject.id}
                  label={selectedProject.name}
                  sx={styles.chipStyle}
                />
              ))}
            {extensionSelected &&
              extensionSelected?.map((extensionItemSelected) => (
                <Chip
                  onDelete={() =>
                    handleUnpick(extensionItemSelected, selectedType.fileExtensionType)
                  }
                  key={extensionItemSelected}
                  label={extensionItemSelected}
                  sx={styles.chipStyle}
                />
              ))}
            {isFileCatalog &&
              isFileCatalog?.map((fileCatalogItem) => (
                <Chip
                  onDelete={() => handleUnpick(fileCatalogItem, selectedType.fileCatalog)}
                  key={fileCatalogItem}
                  label={fileCatalogItem === "0" ? "Not In Catalog" : "In Catalog"}
                  sx={styles.chipStyle}
                />
              ))}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}>
            <div style={{ display: "flex", gap: "20px",}}>
              <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px" }}>
                <span style={styles.demoTag}>D</span> 0 - 4: Demo/beta
              </Typography>
              <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px" }}>
                <span style={styles.productiveTag}>P</span> 5 - 7: Productive
              </Typography>
            </div>
            <TextField
              label="Search"
              variant="standard"
              // fullWidth
              onChange={handleSearchChange}
              sx={{ marginBottom: "24px" }}
            />
          </div>
          <PermissionWrapper allowedPermission={PermissionsSchema.files.fm_access}>
            <FilesTable type={filesTableTypes.files} data={filteredData} />
          </PermissionWrapper>
        </div>
        <PermissionWrapper allowedPermission={PermissionsSchema.files.fm_access}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid #E8E8E9",
              height: "80px",
              background: "white",
              flexShrink: 0,
              alignItems: "center",
              paddingRight: "56px",
            }}
          >
            <div>
              <Button
                variant="text"
                color="info"
                sx={{
                  textTransform: "capitalize",
                  minWidth: "128px",
                  justifyContent: "space-between",
                }}
                onClick={handleUploadActivation}
              >
                Upload File <CloudUploadIcon />
              </Button>
            </div>
          </div>
        </PermissionWrapper>
      </div>
    );
  } else {
    content = (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <div style={{ padding: "0px 250px" }}>
          <LinearProgress sx={{ height: "10px" }} />
        </div>
      </div>
    );
  }

  return (
    <>
      {isUploadActive ? (
        <FilesContainer
          uploadFrom={uploadTypes.fManager}
          handleUploadActivation={handleUploadActivation}
          type={uploadTypes.fManager}
          setIsUploadActive={setIsUploadActive}
        />
      ) : (
        content
      )}
    </>
  );
};

export default FileManagement;

const styles = {
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    color: "#1B1E1F",
    margin: "0px 0px 24px 0px",
  },
  menuItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    letterSpacing: "0.14px",
    fontWeight: "400",
    borderTop: "1px solid #E8E8E9",
  },
  autocompleteList: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    letterSpacing: "0.14px",
    fontWeight: "400",
    borderTop: "1px solid #E8E8E9",
  },
  chipStyle: { marginRight: "4px", marginBottom: "4px", borderRadius: "4px" },
  demoTag: {
    fontSize: "14px",
    minWidth: "max-content",
    backgroundColor: "#EEF3FF",
    color: "#7B6FA3",
    padding: "0px 6px",
    margin: "0px 4px 0px 0px",
    padding: "0px 4px 0px 4px",
    gap: "4px",
    borderRadius: "1px",
    width: "20px",
    height: "20px",
  },
  productiveTag: {
    backgroundColor: "#EEFAFF",
    color: "#43A5CB",
    fontSize: "14px",
    minWidth: "max-content",
    padding: "0px 6px",
    margin: "0px 4px 0px 0px",
    padding: "0px 4px 0px 4px",
    gap: "4px",
    borderRadius: "1px",
    width: "20px",
    height: "20px",
  },
  buttons: {
    color: "#1E9DF2",
    textTransform: "none",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
  },
  formControll: {
    margin: "0px 16px 8px 0px",
    // width: "176px",
    width: "226px",
  },
};
