/* eslint-disable */
import { Outlet } from "react-router-dom";
import Header from "../../../containers/template/Header/Header";
import Footer from "../../../containers/template/Footer";

const HomeLayout = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default HomeLayout;
