/* eslint-disable */
import React, { useState, useEffect } from "react";

import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import i18n from "../../../services/i18n";
import EMAIL_CAR_IMG from "../../../assets/img/motcos_email_car.png";
import {
  checkEmailOnPasswordReset,
  checkPasswordExpiryService,
  resetPassword,
} from "../../../services/auth";
import { Visibility, VisibilityOff, Clear } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import { emailRegex, passwordRegex } from "../../../utils/regexList";

const content_steps = {
  reset: "reset",
  email: "email",
  create: "create",
};

/* eslint-disable-next-line */
function ResetPasswordDialog({ open, setOpen, token, create }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("500"));

  const resetPasswordDialogStyles = {
    dialogRoot: {
      "& .MuiDialog-container": {
        backdropFilter: "blur(15px)",
      },
      "& .MuiPaper-root": {
        borderRadius: fullScreen ? "0px" : "24px",
      },
      "& .MuiDialog-paper:before": {
        height: "0px",
      },
    },
    dialogContent: {
      padding: fullScreen ? "27px 50px 117px 50px" : "27px 117px 117px 117px",
    },
    dialogContent_email: {
      padding: "27px 117px 53px 117px",
      marginTop: "-85px",
    },
    dialogTitle: {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "150%",
      letterSpacing: "-0.72px",
      marginTop: "0px",
      marginLeft: "0px",
      marginBottom: "0px",
    },
    dialogErrorTitle: {
      color: "red",
      fontFamily: "IBM Plex Sans",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "150%",
      letterSpacing: "-0.72px",
      marginTop: "0px",
      marginLeft: "0px",
      marginBottom: "0px",
    },
    resetButton: {
      width: "100%",
      borderRadius: "2px",
      fontSize: "14px",
      fontFamily: "IBM Plex Sans",
      marginTop: "25px",
      backgroundColor: "#1E9DF2",
      padding: "16px 32px",
      boxShadow: "none",
    },
    buttonContainer: {
      paddingTop: "40px",
      paddingRight: "40px",
      display: "flex",
      justifyContent: "flex-end",
    },
    emailTitle: {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "150%",
      letterSpacing: "-0.72px",
      textAlign: "center",
      marginTop: "0px",
      marginLeft: "0px",
      marginBottom: "0px",
    },
  };

  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    getValues,
    reset,
    setError,
  } = useForm();

  const [dialogContent, setDialogContent] = useState(
    create ? content_steps.create : content_steps.reset
  );

  const [checkPasswordExpiry, setCheckPasswordExpiry] = useState();
  const [badToken, setBadToken] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [openPasswordValidation, setOpenPasswordValidation] = useState(false);

  const checkPasswordErrors = () => {
    if (errors?.pass || errors?.confirmPassword) {
      setOpenPasswordValidation(!openPasswordValidation);
    }
  }

  useEffect(() => {
    if (errors?.pass || errors?.confirmPassword) {
      checkPasswordErrors();
    }
  }, [errors]);

  async function checkPasswordService() {
    const findUser = await dispatch(checkPasswordExpiryService(token));
    if (findUser === true) {
      setCheckPasswordExpiry(true);
    } else if (findUser === false) {
      setCheckPasswordExpiry(false);
    } else if (findUser.message) {
      setBadToken({ message: findUser.message, bad: true });
    }
  }

  useEffect(() => {
    if (token) {
      checkPasswordService();
    }
  }, [token]);

  const handleClose = () => {
    setOpen(false);
    reset({ email: "", pass: "", confirmPassword: "" })
    setTimeout(() => {
      setDialogContent(content_steps.reset);
    }, 500);
  };
  async function checkEmailOnPasswordResetHandler(data) {
    const isEmailExists = await dispatch(checkEmailOnPasswordReset({ email: data.email }));

    if (isEmailExists) {
      setDialogContent(content_steps.email);
    } else if (!isEmailExists) {
      setError("email", { type: 'custom', message: "This email does not exist!" })
    }
  }

  async function resetPasswordHandler(data) {
    const dataUpdated = {
      state: {
        pass: data.pass,
      },
      code: token,
    };
    await dispatch(resetPassword(dataUpdated));
    handleClose();
  }

  const submitFormEmail = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      handleSubmit(checkEmailOnPasswordResetHandler)();
    }
  };

  const submitFormResetPassword = () => {
    if (Object.keys(errors).length > 0) {
      checkPasswordErrors();
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      handleSubmit(resetPasswordHandler)();
    }
  };

  const validatePasswordMatch = (value, values) => {
    if (value === values.pass) {
      return true;
    }
    return "Passwords do not match";
  };

  let content;

  if (content_steps.reset === dialogContent) {
    content = (
      <DialogContent sx={resetPasswordDialogStyles.dialogContent}>
        <p style={resetPasswordDialogStyles.dialogTitle}>Reset Password</p>
        <DialogContentText id="reset-dialog-description">
          Put your email and we&apos;ll send you the magic link.
        </DialogContentText>
        <form>
          <FormControl fullWidth={true}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "This field is required!",
                pattern: {
                  value: emailRegex,
                  message: "Invalid Email format.",
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    required
                    autoFocus={true}
                    label={i18n.t("core:email")}
                    placeholder="example@mail.com"
                    inputMode="email"
                    type="email"
                    margin="dense"
                    variant="outlined"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={{
                      marginTop: "25px",
                      "& .MuiInputBase-root": {
                        borderRadius: "4px",
                        border: " 0px solid var(--blc-60, #D1D2D2)",
                      },
                      "& .MuiOutlinedInput-input": {
                        minHeight: "1.35rem",
                      },
                    }}
                    size="medium"
                  />
                )
              }
              }
            >

            </Controller>
            <Button
              id="signInConfirm"
              variant="contained"
              color="primary"
              sx={resetPasswordDialogStyles.resetButton}
              onClick={submitFormEmail}
              disabled={!isDirty}
            >
              {i18n.t("core:resetPassword")}
            </Button>
          </FormControl>
        </form>
      </DialogContent>
    );
  } else if (content_steps.email === dialogContent) {
    content = (
      <DialogContent sx={resetPasswordDialogStyles.dialogContent_email}>
        <img src={EMAIL_CAR_IMG} alt="email car" style={{ width: "100%", height: "auto" }} />
        <p style={resetPasswordDialogStyles.emailTitle}> Email was successfully send!</p>
        <FormControl fullWidth={true}>
          <Button
            id="signInConfirm"
            variant="contained"
            color="primary"
            sx={resetPasswordDialogStyles.resetButton}
            onClick={handleClose}
          >
            {i18n.t("core:checkEmail")}
          </Button>
        </FormControl>
      </DialogContent>
    );
  } else if (content_steps.create === dialogContent) {
    if (badToken?.bad) {
      content = (<DialogContent sx={resetPasswordDialogStyles.dialogContent}>
        <p style={resetPasswordDialogStyles.dialogErrorTitle}>The Token is NOT Valid!</p>
      </DialogContent>)
    } else {
      content = checkPasswordExpiry ? (
        <DialogContent sx={resetPasswordDialogStyles.dialogContent}>
          <p style={resetPasswordDialogStyles.dialogTitle}>{i18n.t("core:passwordExpired")}</p>
        </DialogContent>
      ) : (
        <form>
          <DialogContent sx={resetPasswordDialogStyles.dialogContent}>
            <p style={resetPasswordDialogStyles.dialogTitle}>Create Password</p>
            <FormControl fullWidth={true}>
              <Controller
                name="pass"
                control={control}
                defaultValue=""
                rules={{
                  required: "This field is required!",
                  pattern: {
                    value: passwordRegex,
                    message: "Invalid Password check the information.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    autoFocus={true}
                    label={i18n.t("core:password")}
                    // inputMode="password"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    type={showPassword ? "text" : "password"}
                    margin="dense"
                    variant="outlined"
                    sx={{
                      marginTop: "25px",
                      "& .MuiInputBase-root": {
                        borderRadius: "4px",
                        border: " 0px solid var(--blc-60, #D1D2D2)",
                      },
                      "& .MuiOutlinedInput-input": {
                        minHeight: "1.35rem",
                      },
                    }}
                    size="medium"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth={true}>
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                rules={{
                  required: "This field is required!",
                  pattern: {
                    value: passwordRegex,
                    message: "Invalid Password check the information.",
                  },
                  validate: validatePasswordMatch,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    id="confirm_password"
                    sx={{
                      marginTop: "25px",
                      "& .MuiInputBase-root": {
                        borderRadius: "4px",
                        border: " 0px solid var(--blc-60, #D1D2D2)",
                      },
                      "& .MuiOutlinedInput-input": {
                        minHeight: "1.35rem",
                      },
                    }}
                    label={i18n.t("core:confirmPassword")}
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    type={showConfirmPassword ? "text" : "password"}
                    margin="dense"
                    variant="outlined"
                    size="medium"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle confirm password visibility"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            edge="end"
                          >
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {openPasswordValidation && (
                <div
                  style={{
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
                    maxWidth: "670px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "0px",
                    zIndex: 1,
                    position: "absolute",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      width: "24px",
                      height: "24px",
                      alignSelf: "flex-end",
                      margin: "10px 20px -5px 0px",
                      color: "gray",
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                      color: "#D1D2D2",
                    }}
                    onClick={() => setOpenPasswordValidation(false)}
                  >
                    <Clear />
                  </button>
                  <p style={{ fontSize: "14px", margin: "0px 81px 25px 25px" }}>
                    To create a valid password, it is necessary to use a combination of at least 8
                    characters, including both uppercase and lowercase letters, a number, and one of the
                    special characters such as @, $, !, %, *, ?, or &. Using any other special
                    characters besides these is not allowed.
                  </p>
                </div>
              )}

              <Button
                id="signInConfirm"
                variant="contained"
                color="primary"
                sx={resetPasswordDialogStyles.resetButton}
                onClick={submitFormResetPassword}
                disabled={!isDirty}
              >
                Save Changes
              </Button>
            </FormControl>
          </DialogContent>
        </form>
      );
    }
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        aria-labelledby="reset-dialog-title"
        aria-describedby="reset-dialog-description"
        sx={resetPasswordDialogStyles.dialogRoot}
      >
        <div style={resetPasswordDialogStyles.buttonContainer}>
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: "#1B1E1F" }} fontSize="large" />
          </IconButton>
        </div>
        {content}
      </Dialog>
    </div>
  );
}

export default ResetPasswordDialog;
