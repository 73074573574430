/* eslint-disable */
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const confirmTypes = {
  deleteUser: "delete user",
  deleteUsers: "delete multiple users",
  disableUser: "disable user",
  enableUser: "enable user",
  deleteCompany: "delete company",
  deleteCompanyUsers: "delete company user",
  deleteTeam: "delete team",
  deleteTeamUser: "delete team user",
  deleteRole: "delete role",
  copyRole: "copy role",
  deleteTermVersion: "delete term version",
  changeTermVersionStatus: "change verions status",
  deleteFiles: "delete files",
  deleteAndDownloadFiles: "delete and download files",
  deleteProject: "delete project",
};

/**
 * NewConfirmDialog is a custom React component.
 *
 * @typedef {Object} MyComponentProps
 * @property {boolean} open - State of the dialog open/close.
 * @property {any} onClose - Function that set the dialog to close state.
 * @property {Function} action - Action that will be triggered when YES button is pressed.
 * @property {object} data - Data used to display additional information, usually its an object.
 * @property {string} type - Type represend the type of the Dialog. Check the confirmTypes object for references.
 *
 * @component
 * @param {NewConfirmDialog} props - The component properties.
 * @returns {JSX.Element} - React component.
 */

const NewConfirmDialog = ({ open, onClose, action, data, type }) => {
  const handleActionExecution = () => {
    action();
    onClose();
  };

  let content;
  let defaultAcceptButtonTitle = "Yes, Delete"; // This will be the default label of the button if its not changed

  if (type === confirmTypes.deleteUser) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this user?{" "}
        </DialogTitle>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>{data.name}</DialogTitle>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteUsers) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete {data?.length} users?
        </DialogTitle>
      </DialogContent>
    );
  } else if (type === confirmTypes.disableUser || type === confirmTypes.enableUser) {
    type === confirmTypes.disableUser
      ? (defaultAcceptButtonTitle = "Disable")
      : (defaultAcceptButtonTitle = "Activate");
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to {type === confirmTypes.disableUser ? "Disable" : "Activate"} this
          user?{" "}
        </DialogTitle>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>{data.name}</DialogTitle>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteCompany) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this company?
        </DialogTitle>
        <p style={muiDialogStyles.newTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteCompanyUsers) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to remove {data?.selected.length}{" "}
          {data?.selected.length > 1 ? "users" : "user"} from the company {data?.companyData.name}?
        </DialogTitle>
        <p style={muiDialogStyles.newTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteTeam) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this team?
        </DialogTitle>
        <p style={muiDialogStyles.newTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteTeamUser) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to remove {data?.selected.length}{" "}
          {data?.selected.length > 1 ? "users" : "user"} from team {data?.teamData.name}?
        </DialogTitle>
        <p style={muiDialogStyles.newTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteRole) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this role?
        </DialogTitle>
        <p style={muiDialogStyles.companyTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.copyRole) {
    defaultAcceptButtonTitle = "Yes, Copy role";
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to create copy of this role?
        </DialogTitle>
        <p style={muiDialogStyles.companyTitle}>{data?.name}</p>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteTermVersion) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this Term version?{" "}
        </DialogTitle>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>{data.version_name}</DialogTitle>
      </DialogContent>
    );
  } else if (type === confirmTypes.changeTermVersionStatus) {
    defaultAcceptButtonTitle = "Yes, Change status";
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to change this Term version status?
        </DialogTitle>
      </DialogContent>
    );
  } else if (type === confirmTypes.deleteProject) {
    content = (
      <DialogContent sx={muiDialogStyles.contentStyles}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>
          Are you sure you want to delete this project?{" "}
        </DialogTitle>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>{data.name}</DialogTitle>
      </DialogContent>
    );
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
          zIndex: "9999",
        }}
      >
        <div style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px" }}>
          <IconButton onClick={onClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        {content}
        {type === confirmTypes.deleteTeam && (
          <div style={muiDialogStyles.additionalInfoStyle}>
            Users currently assigned to this team will no longer be part of any teams and will need
            to be reassigned to an existing team or create a new one.
          </div>
        )}
        {type === confirmTypes.deleteCompany && (
          <div style={muiDialogStyles.additionalInfoStyle}>
            <p style={{ marginBottom: "20px" }}>
              Deleting a company will{" "}
              <span style={{ fontWeight: "bold" }}>
                also delete all users associated with that company.
              </span>
              This includes their accounts, data, and any work they have done.
            </p>
            <p>
              Once a company is deleted, it cannot be recovered. Please confirm that you want to
              delete this company and all associated users.
            </p>
          </div>
        )}
        <div
          style={
            type === confirmTypes.deleteTeam ||
              type === confirmTypes.deleteTeamUser ||
              type === confirmTypes.deleteCompany ||
              type === confirmTypes.deleteCompanyUsers ||
              type === confirmTypes.disableUser ||
              type === confirmTypes.enableUser
              ? muiDialogStyles.teamActionsContainer
              : muiDialogStyles.actionsContainer
          }
        >
          <Button onClick={handleActionExecution} autoFocus sx={muiDialogStyles.btn}>
            {defaultAcceptButtonTitle}
          </Button>
          <Button onClick={onClose} sx={muiDialogStyles.btnCancel}>
            Cancel
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default NewConfirmDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "80%",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  companyTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    // margin: "48px 0px 0px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 40px",
  },
  additionalInfoStyle: {
    color: "#1B1E1F",
    // width: "90%",
    // textAlign: "center",
    // width: "600px",
    // marginTop: "16px",
    padding: "10px 40px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "150%" /* 24px */,
    letterSpacing: "-0.16px",
  },
  newTitle: {
    width: "100%",
    // padding: "0px 232px",
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    margin: "48px 0px 0px 0px",
    backgroundColor: "#EEFAFF",
  },
  teamActionsContainer: {
    display: "flex",
    // flexDirection: "column",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
    alignItems: "centar",
    padding: "32px 40px 40px 40px",
  },
};
