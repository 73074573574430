/* eslint-disable */
import { useState, useEffect, useMemo } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  IconButton,
  TextField,
  Tooltip,
  TableSortLabel,
  TablePagination,
  Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import CloseIcon from "@mui/icons-material/Close";
import FilesSettingsDropdown, {
  fileDropdownType,
} from "../../files/files_dropdown/FilesSettingsDropdown";
import FilesSelectTime from "../../files/files_select_time/FilesSelectTime";
import { ExtensionsColorSchemaResolver } from "../../cards/FilesCard/extensionsColorSchemaResolver";
import FilesActions from "../../files/files_actions/FilesActions";
import { getDateTime2, getDateTime3 } from "../../../utils/misc";
import { getComparator, stableSort, tableType } from "../CustomMuiTable/EnchancedMuiTable";
import { getGeneralTerms, termsAndConditionsService } from "../../../services/TermsAndConditions";
import SelectTermsComponent from "../../files/files_select_terms/SelectTermsComponent";
import { companyServices } from "../../../services/company";
import { useQuery } from "@tanstack/react-query";
import types from "../../../actions/types";
import { useDispatch } from "react-redux";
import { uploadTypes } from "../../files/files_container/FilesContainer";
import GppBadIcon from "@mui/icons-material/GppBad";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { FileStatusColorSchemaResolver } from "../../widgets/FileStatus/FileStatusColorSchemaResolver";
import FilesInCatalogActions from "../../../pages/Dashboard/FileCatalog/FilesInCatalogActions";
import FilesRequestActions from "../../files/files_actions/FilesRequestActions";
import getTheUserState from "../../../utils/userState";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";

export const filesTableTypes = {
  files: "files",
  settings: "settings",
  projectsGeneral: "projectsGeneral",
  projectsPrivate: "projectsPrivate",
  file_catalog: "file catalog",
  file_requests: "file requests",
  requests_history: "reqeusts history",
  my_files: "my files",
};

const headCellsFiles = [
  // { id: "file_id", numeric: false, disablePadding: true },
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "file_catalog",
    numeric: false,
    disablePadding: false,
    label: "File Catalog",
  },
  {
    id: "uploader",
    numeric: false,
    disablePadding: false,
    label: "Uploader",
  },
  {
    id: "company",
    numeric: false,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "project",
    numeric: false,
    disablePadding: false,
    label: "Project",
  },
  {
    id: "terms",
    numeric: false,
    disablePadding: false,
    label: "Terms",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Last edit",
  },
  // {
  //   id: "description",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Description",
  // },
];

const headCellsFileCatalog = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "downloaded",
    numeric: false,
    disablePadding: false,
    label: "Downloaded",
  },

  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

const headCellsMyFiles = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "project",
    numeric: false,
    disablePadding: true,
    label: "Project",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
];

const headCellsFilesRequests = [
  // { id: "file_id", numeric: false, disablePadding: true },
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "user_full_name",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "user_email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },

  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Requested at",
  },
];

const headCellsFilesRequestsHistory = [
  // { id: "file_id", numeric: false, disablePadding: true },
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "File Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "user_full_name",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "user_email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "resolved_by",
    numeric: false,
    disablePadding: false,
    label: "Resolved by",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: false,
    label: "Requested at",
  },
];

const headCellsSettings = [
  {
    id: "filename",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
];

const headCellsProjects = [
  {
    id: "file_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "file_format",
    numeric: false,
    disablePadding: false,
    label: "File Format",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: false,
    label: "Last edit",
  },
];

export default function FilesTable({
  data,
  type,
  handleUpdateFileSettings,
  handleUpdateFileStatus,
  handleRemove,
  selected,
  setSelected,
  setSecondType,
  uploadFrom,
  companyID,
  checkTermsSettings,
  forceRerender,
  secondaryType,
}) {
  const userCompanies = getTheUserState()?.user?.companies;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [paginationEvaluation, setPaginationEvaluation] = useState(false);
  const [generalTerms, setGeneralTerms] = useState([]);

  const dispatch = useDispatch();

  const userState = getTheUserState();

  const enableCondition =
    type === filesTableTypes.projectsGeneral ||
    type === filesTableTypes.projectsPrivate ||
    type === filesTableTypes.file_catalog ||
    type === filesTableTypes.file_requests ||
    type === filesTableTypes.requests_history;

  const checkCompaniesPermission = userState?.user?.role?.permissions?.some(
    (permission => {
      return permission.permission_name === PermissionsSchema?.companies?.view_all_companies && permission.status === true;
    })
  );

  const {
    data: allCompanies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["allCompanies"],
    queryFn: checkCompaniesPermission
      ? () => companyServices.getAllCompaniesWithUsers()
      : () => companyServices.getAllCompaniesByUser(userCompanies),
    enabled: !enableCondition,
  });

  const regex = /^[a-zA-Z0-9]*$/;

  const numSelected = selected?.length || 0;
  const rowCount = data?.length || 0;

  const checkViewAllFilesPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.view_all_files &&
      permission.status === true
  );

  const checkFullCatalogAccess = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.file_catalog_full_access &&
      permission.status === true
  );

  useEffect(() => {
    if (filesTableTypes.settings === type) {
      const permissions = getTheUserState()?.user?.role?.permissions;
      const foundPermission = permissions.find(
        (permission) =>
          permission.permission_name === PermissionsSchema.terms.show_all_terms &&
          permission.status === true
      );
      if (foundPermission) {
        const response = termsAndConditionsService.getAllTermsFiltered();
        response.then((result) => {
          setGeneralTerms(result?.data?.data);
        });
      } else {
        const response = getGeneralTerms(userCompanies);
        response
          .then((result) => {
            setGeneralTerms(result?.data);
            return true;
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [type]);

  useEffect(() => {
    const filteredFilesWithTerms = data?.filter((item) => selected?.includes(item.id));
    if (
      (selected && type === filesTableTypes.projectsPrivate) ||
      type === filesTableTypes.projectsGeneral
    ) {
      dispatch({
        type: types.SET_FILES_TERMS,
        payload: filteredFilesWithTerms,
      });
    }
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  function handleDescriptionChange(e, index) {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "serial_id") {
      if (regex.test(value)) {
        // setSerialId(value);
        handleUpdateFileSettings(index, name, value);
      }
    } else {
      handleUpdateFileSettings(index, name, value);
    }
  }

  function handleTermsChange(index, property, value) {
    handleUpdateFileSettings(index, property, value);
  }

  function handleUpdateTimeToUse(index, name, value) {
    handleUpdateFileSettings(index, name, value);
  }

  function handleUpdateSettings(index, name, value) {
    handleUpdateFileSettings(index, name, value);
  }

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handleChangePage = (event, newPage) => {
    const maxPage = Math.ceil(data.length / rowsPerPage) - 1;
    if (newPage >= 0 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data?.map((n) => n.id);
      setSelected(newSelected);
      if (setSecondType) {
        setSecondType([]);
      }

      return;
    }
    setSelected([]);
    if (setSecondType) {
      setSecondType([]);
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    if (setSecondType) {
      setSecondType([]);
    }
  };

  const handleSelectAllFiles = (event) => {
    if (event.target.checked && selected?.length > 0) {
      setSelected([]);
    } else if (event.target.checked) {
      const newSelected = data
        ?.filter((file) => filesTableTypes.my_files === secondaryType ? file : file.status === "Access provided")
        ?.map((n) => n.id);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleSelectSingleFile = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  useEffect(() => {
    if (data?.length > 5) {
      setPaginationEvaluation(true);
    } else {
      setPaginationEvaluation(false);
    }
  }, [data?.length]);

  const visibleRows = useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy))?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, data?.length, data]
  );

  const isSelected = (name) => selected?.indexOf(name) !== -1;

  function handleFileConnectionStatus(row) {
    if (row.is_private) {
      return "Private";
    } else if (row.is_general) {
      return "General";
    }

    return "Connected";
  }

  let tableContent;

  if (filesTableTypes.files === type) {
    tableContent = (
      <Box>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCellsFiles?.map((headCell) => {
                    return (!checkViewAllFilesPermission && headCell?.id === "uploader" ? null : <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStyles}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                    )
                  })}
                  <TableCell sx={customTableStyles.cellStyles} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const maturityLevel = row?.maturity_level?.split(" ");

                  const companies = row?.FileConnections?.map((item) => item.Company?.name);
                  // Get only the Unique company names
                  const uniqueCompanies = new Set(companies);
                  // Seperate Company names by comma
                  const companiesString = Array.from(uniqueCompanies).join(", ");

                  const projects = row?.FileConnections?.map((item) => item.project?.name);

                  const termsNames = row.FilesToTerms.map(fileToTerm => fileToTerm.terms_and_condition.terms_name);

                  return (
                    <TableRow key={index} sx={customTableStyles.cellStyles3}>
                      <TableCell sx={customTableStyles.cellStyles3} component="th" scope="row">
                        {/* {maturityLevel[0]} {index} */}
                        <span
                          style={{
                            fontSize: "11px",
                            minWidth: "max-content",
                            backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                            color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                            padding: "2px 6px",
                            margin: "10px 0px",
                          }}
                        >
                          {maturityLevel[0]}
                        </span>
                        <span style={{ paddingLeft: "8px", color: "#1E9DF2" }}>
                          {row?.file_name}
                        </span>
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {ExtensionsColorSchemaResolver(row?.file_format)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {handleFileConnectionStatus(row)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {row?.file_catalog === 0 ? "Not In Catalog" : "In Catalog"}
                      </TableCell>
                      {checkViewAllFilesPermission &&
                        <TableCell sx={customTableStyles.cellStyles3}>
                          {row?.uploader || ""}
                        </TableCell>}
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {companiesString ? companiesString : ""}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {projects?.join(", ") || ""}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {termsNames?.join(", ") || ""}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {getDateTime3(row?.updatedAt)}
                      </TableCell>
                      {/* <TableCell sx={customTableStyles.cellStyles3}>{row?.description}</TableCell> */}
                      <TableCell sx={customTableStyles.cellStyles3} align="center">
                        {/* ask about this function */}
                        <FilesActions file={row} setSelected={() => { }} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-toolbar": {
                  position: "absolute",
                  bottom: "15px",
                  paddingLeft: "0px",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  } else if (filesTableTypes.settings === type) {
    tableContent = (
      <Box key={forceRerender}>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCellsSettings?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStyles}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  {/* <TableCell sx={customTableStyles.cellStyles}>Name</TableCell> */}
                  <TableCell sx={customTableStyles.cellStyles}>Maturity Level</TableCell>
                  <TableCell sx={customTableStyles.cellStyles}>Serial ID</TableCell>
                  {uploadFrom === uploadTypes.fManager && (
                    <TableCell sx={customTableStyles.cellStyles}>Available for</TableCell>
                  )}
                  <TableCell sx={customTableStyles.cellStyles}>Terms *</TableCell>
                  <TableCell sx={customTableStyles.cellStyles}>Time to use</TableCell>
                  <TableCell sx={customTableStyles.cellStyles}>Description</TableCell>
                  <TableCell sx={customTableStyles.cellStyles} />
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => (
                  <TableRow key={index} sx={customTableStyles.cellStyles2}>
                    <TableCell
                      // sx={{ backgroundColor: validateSettings(index) ? "greenyellow" : "red" }}
                      sx={customTableStyles.cellStyles2}
                      component="th"
                      scope="row"
                    >
                      {row?.file?.name}
                    </TableCell>
                    <TableCell sx={customTableStyles.cellStyles2}>
                      <FilesSettingsDropdown
                        index={index}
                        type={fileDropdownType.maturityLevel}
                        handleSelectOption={handleUpdateFileSettings}
                        defaultValue={row?.settings?.maturity_level}
                      />
                    </TableCell>
                    <TableCell sx={customTableStyles.cellStyles2}>
                      <TextField
                        name="serial_id"
                        variant="standard"
                        size="small"
                        placeholder="Enter Serial ID"
                        defaultValue={row.settings?.serial_id ? row.settings?.serial_id : ""}
                        onChange={(e) => handleDescriptionChange(e, index)}
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          "& .MuiInputBase-root": {
                            fontSize: "11px",
                            color: "black",
                            fontWeight: "400",
                          },
                        }}
                      />
                    </TableCell>
                    {uploadFrom === uploadTypes.fManager && (
                      <TableCell sx={customTableStyles.cellStyles2}>
                        <FilesSettingsDropdown
                          type={fileDropdownType.company}
                          handleSelectOption={handleUpdateSettings}
                          handleUpdateFileStatus={handleUpdateFileStatus}
                          index={index}
                          data={allCompanies}
                          availableForData={row?.settings}
                        />
                      </TableCell>
                    )}
                    <TableCell sx={customTableStyles.termsCellStyle}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {checkTermsSettings(index) ? (
                          <VerifiedUserIcon
                            sx={{ color: "rgb(96, 255, 0)", paddingRight: "5px" }}
                            fontSize="small"
                          />
                        ) : (
                          <GppBadIcon
                            sx={{ color: "rgb(255, 0, 0)", paddingRight: "5px" }}
                            fontSize="small"
                          />
                        )}
                        <SelectTermsComponent
                          terms={generalTerms}
                          handleSelectedTerms={handleTermsChange}
                          fileIndex={index}
                          companyID={companyID}
                          defaultValue={row?.settings?.terms}
                        />
                      </div>
                    </TableCell>
                    <TableCell sx={customTableStyles.cellStyles2}>
                      <FilesSelectTime
                        index={index}
                        handleUpdateTimeToUse={handleUpdateTimeToUse}
                        defaultValue={row?.settings?.time_to_use}
                      />
                    </TableCell>
                    <TableCell sx={customTableStyles.cellStyles2}>
                      <TextField
                        name="description"
                        variant="standard"
                        size="small"
                        placeholder="Add Description"
                        defaultValue={row.settings?.description ? row.settings?.description : ""}
                        onChange={(e) => handleDescriptionChange(e, index)}
                        InputProps={{ disableUnderline: true }}
                        sx={{
                          "& .MuiInputBase-root": {
                            fontSize: "11px",
                            color: "#1E9DF2",
                            fontWeight: "400",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell sx={customTableStyles.cellStyles2} align="center">
                      <Tooltip title="Remove this file" arrow placement="top">
                        <IconButton onClick={() => handleRemove(row, index)}>
                          <CloseIcon color="warning" />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  } else if (filesTableTypes.projectsGeneral === type || filesTableTypes.projectsPrivate === type) {
    tableContent = (
      <Box>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" sx={customTableStyles.cellStylesProject}>
                    <Checkbox
                      color="primary"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {headCellsProjects?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStylesProject}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={customTableStyles.cellStylesProject} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const maturityLevel = row?.maturity_level?.split(" ");
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isItemSelected = isSelected(row?.id);
                  return (
                    <TableRow key={index} sx={customTableStyles.cellStyles3}>
                      <TableCell padding="checkbox" sx={customTableStyles.cellStyles3}>
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, row.id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3} component="th" scope="row">
                        {/* {maturityLevel[0]} {index} */}
                        <span
                          style={{
                            fontSize: "11px",
                            minWidth: "max-content",
                            backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                            color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                            padding: "2px 6px",
                            margin: "10px 0px",
                          }}
                        >
                          {maturityLevel[0]}
                        </span>
                        <span style={{ paddingLeft: "8px", color: "#1E9DF2" }}>
                          {row?.file_name}
                        </span>
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {ExtensionsColorSchemaResolver(row?.file_format)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>{row?.description}</TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {getDateTime3(row?.updatedAt)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3} align="center">
                        {filesTableTypes.projectsGeneral === type && (
                          <FilesActions
                            file={row}
                            type={filesTableTypes.projectsGeneral}
                            setSelected={setSelected}
                          />
                        )}
                        {filesTableTypes.projectsPrivate === type && (
                          <FilesActions
                            file={row}
                            type={filesTableTypes.projectsPrivate}
                            setSelected={setSelected}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  } else if (filesTableTypes.file_catalog === type) {
    tableContent = (
      <Box>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    // key={headCell.id}
                    align="right"
                    padding="checkbox"
                    // sortDirection={orderBy === headCell.id ? order : false}
                    sx={customTableStyles.cellStylesCatalog}
                  >
                    <Checkbox
                      color="primary"
                      // size="small"
                      indeterminate={numSelected > 0 && numSelected < rowCount}
                      checked={rowCount > 0 && numSelected === rowCount}
                      onChange={handleSelectAllFiles}
                      inputProps={{
                        "aria-label": "select all desserts",
                      }}
                    />
                  </TableCell>
                  {secondaryType !== filesTableTypes.my_files ? headCellsFileCatalog?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStyles}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))
                    : headCellsMyFiles.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? "right" : "left"}
                        padding={headCell.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={customTableStyles.cellStyles}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={createSortHandler(headCell.id)}
                        >
                          {headCell.label}
                          {orderBy === headCell.id ? (
                            <Box component="span" sx={visuallyHidden}>
                              {order === "desc" ? "sorted descending" : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  <TableCell sx={customTableStyles.cellStyles} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => {
                  const maturityLevel = row?.maturity_level?.split(" ");
                  const isItemSelected = isSelected(row?.id);
                  const disableCheckBox = row?.status === "Access provided" ? false : true;

                  let projectNames = "";
                  if (row.FileConnections && row.FileConnections.length > 0) {
                    projectNames = row.FileConnections
                      .filter(fc => fc.project && fc.project.name)
                      .map(fc => fc.project.name)
                      .join(", ");
                  } else {
                    projectNames = "User-based access";
                  }
                  return (
                    <TableRow key={index} sx={customTableStyles.cellStyles3}>
                      <TableCell padding="checkbox" align="center">
                        <Checkbox
                          onClick={(event) => handleSelectSingleFile(event, row?.id)}
                          color="primary"
                          checked={isItemSelected}
                          disabled={disableCheckBox && secondaryType !== filesTableTypes.my_files}
                        />
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3} component="th" scope="row">
                        {/* {maturityLevel[0]} {index} */}
                        <span
                          style={{
                            fontSize: "11px",
                            minWidth: "max-content",
                            backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                            color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                            padding: "2px 6px",
                            margin: "10px 0px",
                          }}
                        >
                          {maturityLevel[0]}
                        </span>
                        <span style={{ paddingLeft: "8px", color: "#1E9DF2" }}>
                          {row?.file_name}
                        </span>
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {ExtensionsColorSchemaResolver(row?.file_format)}
                      </TableCell>
                      {secondaryType !== filesTableTypes.my_files && <TableCell sx={customTableStyles.cellStyles3}>
                        {checkFullCatalogAccess ? FileStatusColorSchemaResolver("Access provided") : FileStatusColorSchemaResolver(row?.status)}
                      </TableCell>}
                      {secondaryType !== filesTableTypes.my_files && <TableCell sx={customTableStyles.cellStyles3}>N / A</TableCell>}
                      {secondaryType === filesTableTypes.my_files && <TableCell sx={customTableStyles.cellStyles3}>{projectNames}</TableCell>}
                      <TableCell sx={customTableStyles.cellStyles3}>{row?.description}</TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        <FilesInCatalogActions
                          fileStatus={row?.status}
                          fileId={row?.id}
                          fileName={row?.file_name}
                          fileFormat={row?.file_format}
                          type={filesTableTypes.file_catalog}
                          setSelected={setSelected}
                          file={row}
                          secondaryType={secondaryType}
                        />
                      </TableCell>
                      {/* <TableCell sx={customTableStyles.cellStyles3}>
                        {getDateTime3(row?.updatedAt)}
                      </TableCell> */}
                      {/* <TableCell sx={customTableStyles.cellStyles3}>{row?.description}</TableCell> */}
                      {/* <TableCell sx={customTableStyles.cellStyles3} align="center">
                        <FilesActions file={row} setSelected={() => {}} />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-toolbar": {
                  position: "absolute",
                  bottom: "15px",
                  paddingLeft: "0px",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  } else if (filesTableTypes.file_requests === type) {
    tableContent = (
      <Box>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCellsFilesRequests?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStyles}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell sx={customTableStyles.cellStyles} align="center">
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => {
                  return (
                    <TableRow key={index} sx={customTableStyles.cellStyles3}>
                      <TableCell sx={customTableStyles.cellStyles3} component="th" scope="row">
                        {/* {maturityLevel[0]} {index} */}
                        <span style={{ paddingLeft: "8px", color: "#1E9DF2" }}>
                          {row?.file_name}
                        </span>
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {ExtensionsColorSchemaResolver(row?.file_format)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {row?.user_full_name}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>{row?.user_email}</TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {getDateTime2(row?.createdAt)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3} align="center">
                        <FilesRequestActions data={row} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  } else if (filesTableTypes.requests_history === type) {
    tableContent = (
      <Box>
        <Paper sx={customTableStyles.paperComponent}>
          <TableContainer>
            <Table sx={customTableStyles.tableStyles} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {headCellsFilesRequestsHistory?.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      sortDirection={orderBy === headCell.id ? order : false}
                      sx={customTableStyles.cellStyles}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={createSortHandler(headCell.id)}
                      >
                        {headCell.label}
                        {orderBy === headCell.id ? (
                          <Box component="span" sx={visuallyHidden}>
                            {order === "desc" ? "sorted descending" : "sorted ascending"}
                          </Box>
                        ) : null}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {visibleRows?.map((row, index) => {
                  return (
                    <TableRow key={index} sx={customTableStyles.cellStyles3}>
                      <TableCell sx={customTableStyles.cellStyles3} component="th" scope="row">
                        {/* {maturityLevel[0]} {index} */}
                        <span style={{ paddingLeft: "8px", color: "#1E9DF2" }}>
                          {row?.file_name}
                        </span>
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {ExtensionsColorSchemaResolver(row?.file_format)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {row?.user_full_name}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>{row?.user_email}</TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>{row?.resolved_by || ""}</TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {FileStatusColorSchemaResolver(row?.status)}
                      </TableCell>
                      <TableCell sx={customTableStyles.cellStyles3}>
                        {getDateTime2(row?.createdAt)}
                      </TableCell>
                      {/* <TableCell sx={customTableStyles.cellStyles3}>
                        <FilesRequestActions data={row} />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          {paginationEvaluation && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                ".MuiIconButton-root": {
                  color: "#1E9DF2",
                  "&.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                  },
                },
                ".MuiTablePagination-displayedRows": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-select": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
                ".MuiTablePagination-selectIcon": {
                  color: "#1E9DF2",
                },
                ".MuiTablePagination-selectLabel": {
                  fontSize: "14px",
                  color: "#1B1E1F",
                  fontFamily: "IBM Plex Sans",
                  fontWeight: "500",
                },
              }}
            />
          )}
        </Paper>
      </Box>
    );
  }

  return tableContent;
}

const customTableStyles = {
  tableStyles: {
    borderTop: "1px solid #E8E8E9",
    borderBottom: "1px solid #E8E8E9",
    borderLeft: "1px solid #E8E8E9",
    borderRight: "1px solid #E8E8E9",
    boxShadow: "none",
    // border: "2px solid red",
  },
  cellStyles: {
    borderTop: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderBottom: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderLeft: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    color: "#1B1E1F",
    fontSize: "11px",
    fontWeight: "500",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    // height: "35px",
    // minWidth: "70px",
    padding: "8px 12px",
    // width: "max-content",
    "& .MuiTableSortLabel-root": {
      width: "max-content",
    },
    backgroundColor: "#F4F4F4",
  },
  cellStyles2: {
    border: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "0px 12px",
    color: "#1B1E1F",
  },
  termsCellStyle: {
    border: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "0px 12px",
    color: "#1B1E1F",
    minWidth: "130px",
  },
  cellStylesInValid: {
    borderTop: "1px solid #E8E8E9",
    borderBottom: "1px solid #E8E8E9",
    borderLeft: "2px solid rgb(255, 0, 0)",
    borderRight: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "0px 12px",
    color: "#1B1E1F",
  },
  cellStylesValid: {
    borderTop: "1px solid #E8E8E9",
    borderBottom: "1px solid #E8E8E9",
    borderLeft: "2px solid rgb(96, 255, 0)",
    borderRight: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "0px 12px",
    color: "#1B1E1F",
  },
  cellStyles3: {
    border: "1px solid #E8E8E9",
    fontSize: "11px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "10px 12px",
    color: "#1B1E1F",
  },
  paperComponent: {
    width: "100%",
    mb: 2,
    boxShadow: "none",
    background: "transparent",
  },
  cellStylesProject: {
    borderTop: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderBottom: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderLeft: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    color: "#1B1E1F",
    fontSize: "11px",
    fontWeight: "500",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    padding: "0px 12px",
    "& .MuiTableSortLabel-root": {
      width: "max-content",
    },
    backgroundColor: "#F4F4F4",
  },
  cellStylesCatalog: {
    borderTop: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderBottom: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    borderLeft: "1px solid var(--main-grey-grey-10, #E8E8E9)",
    color: "#1B1E1F",
    fontSize: "11px",
    fontWeight: "500",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    // height: "35px",
    // minWidth: "70px",
    padding: "0px 12px",
    // width: "max-content",
    "& .MuiTableSortLabel-root": {
      width: "max-content",
    },
    backgroundColor: "#F4F4F4",
  },
};
