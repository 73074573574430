/* eslint-disable */
import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

// Icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Check } from "@mui/icons-material";
import { adminServices } from "../../services/admin";
import NewConfirmDialog, { confirmTypes } from "../dialogs/NewConfirmDialog/NewConfirmDialog";
import AssignCompanyToUserDialog from "../dialogs/AssignCompanyToUser/AssignCompanyToUserDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../utils/notifications";
import PermissionWrapper, { PermissionsSchema } from "../auth/PermissionsWrapper";
import { LightTooltip } from "../tables/TermsTable/TermsTable";
// import ConfirmDialog from "../dialogs/ConfirmDialog";

const UserActionsColumn = ({ user }) => {
  // console.log("This is the user data", user);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenActivate, setIsOpenActivate] = useState(false);
  const [isOpenDeactivate, setIsOpenDeactivate] = useState(false);
  const [isActivateOpen, setIsActivateOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const queryClient = useQueryClient();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpenActivate(false);
    setIsOpenDeactivate(false);
    setIsDeleteDialogOpen(false);
    setIsActivateOpen(false);
  };

  const activateUserMutation = useMutation({
    mutationFn: adminServices.activateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const deactivateUserMutation = useMutation({
    mutationFn: adminServices.deactivateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const userActivationMutation = useMutation({
    mutationFn: adminServices.fetchUserActivation,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const userDeleteMutation = useMutation({
    mutationFn: adminServices.deleteUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleChangeUserStatus = async () => {
    const data = {
      id: user.id,
    };

    if (user?.status === "disabled") {
      activateUserMutation.mutate(data);
    } else {
      deactivateUserMutation.mutate(data);
    }
  };

  const handleActivation = async (pickedCompanyIds, pickedTeamIds, pickedRole) => {
    if (user?.status === "registered") {
      userActivationMutation.mutate({
        id: user.id,
        activated: !user?.activated,
        companyIds: pickedCompanyIds,
        teamIds: pickedTeamIds,
        roleId: pickedRole,
      });
    }
  };

  const handleDeleteUser = () => {
    userDeleteMutation.mutate([user.id]);
  }

  const isTheUserAdmin = user?.Companies?.some(company => {
    return company?.CompanyUser?.userId === user.id && company.CompanyUser.isAdmin;
  });

  const adminCompanyNames = user?.Companies
    .filter(company => company.CompanyUser.userId === user.id && company.CompanyUser.isAdmin)
    .map(adminCompany => adminCompany.name);
    
  const tooltipTitle = `${user?.user} is the company admin to ${adminCompanyNames?.join(", ")}.
    In order to delete this user, you need to change company admin first.`;

  return (
    <div style={{ alignSelf: "center" }}>
      <IconButton
        id="project-actions"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="project-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "project-actions",
        }}
        sx={menu.menuStyles}
      >
        {user?.status === "registered" && (
          <PermissionWrapper allowedPermission={PermissionsSchema.users.activate}>
            <MenuItem onClick={() => setIsActivateOpen(true)}>
              <Check sx={menu.iconStyles} />
              <span style={menu.actions}>Activate User</span>
            </MenuItem>
          </PermissionWrapper>
        )}
        {user?.status === "disabled" && (
          <PermissionWrapper allowedPermission={PermissionsSchema.users.activate}>
            <MenuItem onClick={() => setIsOpenActivate(true)}>
              <Check sx={menu.iconStyles} />
              <span style={menu.actions}>Activate User</span>
            </MenuItem>
          </PermissionWrapper>
        )}
        {user?.status === "active" && (
          <PermissionWrapper allowedPermission={PermissionsSchema.users.deactivate}>
            <MenuItem onClick={() => setIsOpenDeactivate(true)}>
              <Check sx={menu.iconStyles} />
              <span style={menu.actions}>Deactivate User</span>
            </MenuItem>
          </PermissionWrapper>
        )}
        <PermissionWrapper allowedPermission={PermissionsSchema.users.delete}>
          {isTheUserAdmin ? <LightTooltip title={tooltipTitle} arrow placement="left">
            <Box sx={{ margin: "0px" }}>
              <MenuItem disabled={isTheUserAdmin} onClick={() => setIsDeleteDialogOpen(true)}>
                <DeleteForeverIcon sx={menu.iconStyles} />
                <span style={menu.actions}>Delete</span>
              </MenuItem>
            </Box>
          </LightTooltip>
            : <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
              <DeleteForeverIcon sx={menu.iconStyles} />
              <span style={menu.actions}>Delete</span>
            </MenuItem>}
        </PermissionWrapper>
      </Menu>
      <NewConfirmDialog
        open={isOpenDeactivate}
        onClose={handleClose}
        action={handleChangeUserStatus}
        data={user}
        type={confirmTypes.disableUser}
      />
      <NewConfirmDialog
        open={isOpenActivate}
        onClose={handleClose}
        action={handleChangeUserStatus}
        data={user}
        type={confirmTypes.enableUser}
      />
      <NewConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleClose}
        data={user}
        action={handleDeleteUser}
        type={confirmTypes.deleteUsers}
      />
      {isActivateOpen && (
        <AssignCompanyToUserDialog
          open={isActivateOpen}
          onClose={handleClose}
          data={user}
          action={handleActivation}
        />
      )}
      {/* <ConfirmDialog
        open={isDeleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        title="Are you sure you want to delete this project?"
        confirmCallback={async (event) => {
          if (event) {
            await handleDeleteProject({ id, userId });
          }
        }}
      /> */}
    </div>
  );
};

export default UserActionsColumn;

const menu = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#D1D2D2",
  },
};

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "60%",
    alignSelf: "center",
    padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
};
