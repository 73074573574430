/* eslint-disable */

import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@mui/material";
import parse from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getFilesAndTerms } from "../../../reducers/files";
import { getFilesTerms } from "../../../services/files";
import types from "../../../actions/types";

function DisplayFilesTermVersions({ files, onClose }) {
  const [stepper, setStepper] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState([]);
  const [elements, setElements] = useState([]);
  const [terms, setTerms] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      const terms = dispatch(getFilesTerms(files));
      terms.then((result) => {
        setTerms(result?.terms);
        const uniqueIds = [...new Set(result?.terms?.map((item) => item.id))];
        setElements(uniqueIds);
      });
    }
  }, []);

  const { filesAndTerms } = useSelector((globalState) => ({
    filesAndTerms: getFilesAndTerms(globalState),
  }));

  const handleNext = () => {
    if (stepper === elements?.length - 1) {
      return;
    }
    setStepper((prev) => prev + 1);
  };

  const handleBack = () => {
    if (stepper <= 0) {
      return;
    }
    setStepper((prev) => prev - 1);
  };

  const handleCheckboxSelection = (event) => {
    const { name, checked, value } = event.target;
    const objectValue = JSON.parse(value);

    if (checked === true) {
      setAcceptedTerms((prevState) => {
        const newData = [...prevState];
        const index = newData?.findIndex((item) => item?.id === objectValue?.id);
        if (index !== -1) {
          // accepted terms already exists, update its checked
          if (newData[index].id === objectValue?.id) {
            newData[index].checked = checked;
          }
        } else {
          // accepted terms doesn't exist, add it to the data array
          newData.push({
            id: objectValue?.id,
            name: name,
            checked: checked,
          });
        }
        return newData;
      });
    } else if (checked === false) {
      setAcceptedTerms((prevState) => {
        const newData = [...prevState];
        const newDataFiltered = newData?.filter((item) => item?.id !== objectValue?.id);
        return newDataFiltered;
      });
    }
  };

  const matchFilesToTerms = (filesList, termsList) => {
    return termsList?.map((term) => ({
      term_id: term.id,
      terms_and_condition_id: term.terms_and_condition_id,
      files: filesList
        ?.filter((file) =>
          file.FilesToTerms?.some(
            (fileToTerm) => fileToTerm.term_id === term.terms_and_condition_id
          )
        )
        ?.map((file) => ({ file_name: file.file_name + "." + file.file_format, file_id: file.id, })),
    }));
  };

  const fileNamesByTerm = matchFilesToTerms(filesAndTerms, terms);

  const getFileIdsByAcceptedTerms = (terms, acceptedTerms) => {
    // Filter terms that are accepted
    const acceptedTermIds = acceptedTerms?.map((term) => term.id);
    // Filter terms that are accepted and map to corresponding file_ids
    const fileIds = terms
      ?.filter((term) => acceptedTermIds.includes(term.term_id))
      .flatMap((term) => term?.files?.map((file) => ({ id: file.file_id, name: file.file_name, termId: term?.term_id })));

    return fileIds;
  };

  const handleDownload = () => {
    const filesToDownload = getFileIdsByAcceptedTerms(fileNamesByTerm, acceptedTerms);
    const uniqueFileToDownload = Array.from(new Set(filesToDownload));
    dispatch({
      type: types.SET_FILES_FOR_DOWNLOAD,
      payload: { files: uniqueFileToDownload, terms: acceptedTerms },
    });
    onClose();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflow: "auto",
      }}
    >
      <div style={{ width: "100%" }}>
        <DialogTitle sx={muiDialogStyles.dialogTitle}>Terms & Conditions</DialogTitle>
        <div style={muiDialogStyles.container}>
          {terms?.length > 1 && (
            <Stepper
              activeStep={stepper}
              alternativeLabel
              sx={{ paddingBottom: "20px", borderBottom: "1px solid #F4F4F4" }}
            >
              {elements?.map((element, index) => {
                const currentAcceptedTerm = acceptedTerms.find((item) => item.id === element);
                return (
                  <Step
                    key={element}
                    completed={currentAcceptedTerm?.checked || false}
                    checked={
                      currentAcceptedTerm?.length > 0
                        ? currentAcceptedTerm?.checked || false
                        : false
                    }
                  >
                    <StepLabel />
                  </Step>
                );
              })}
            </Stepper>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            {terms
              ?.filter((item) => item.id === elements[stepper])
              ?.map((term) => {
                const currentAcceptedTerm = acceptedTerms.find((item) => item.id === term.id);
                return (
                  <div
                    key={term.id}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      minWidth: "640px",
                      margin: "0px 40px",
                      padding: "0px 20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "10px 0px 20px 0px",
                        gap: "2px",
                      }}
                    >
                      <p style={{ margin: "0px" }}>
                        <b>Files:</b>
                      </p>
                      <div
                        style={{
                          display: "flex",
                          maxWidth: "100px",
                          minWidth: "712px",
                          justifyContent: "start",
                          gap: "4px",
                          overflowY: "hidden",
                          overflowX: "auto",
                        }}
                      >
                        {fileNamesByTerm?.map((fileName) => {
                          if (fileName.term_id === term.id) {
                            return fileName?.files?.map((fileItem, index) => (
                              <p
                                key={fileItem.file_id}
                                style={{ padding: "0px 5px", margin: "0px" }}
                              >
                                {`${fileItem.file_name}${fileName?.files?.length === index + 1 ? "" : ","
                                  }`}
                              </p>
                            ));
                          }
                          // If the condition is not met, return null or an empty array
                          return null; // or []
                        })}
                      </div>
                      <hr
                        style={{
                          width: "100%",
                          height: "1px",
                          border: "none",
                          background: "#F4F4F4",
                        }}
                      />
                    </div>
                    <DialogContent sx={muiDialogStyles.content}>
                      <div style={{ textAlign: "left", overflow: "auto" }}>{parse(term?.content)}</div>
                    </DialogContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                        padding: "0px 20px",
                        border: "1px solid  #F4F4F4",
                        borderTop: "none",
                        borderBottom: "none",
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          "& .MuiTypography-root": { fontSize: "14px" },
                          paddingRight: "20px",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          color: "#8D8E8F",
                          height: "68px",
                        }}
                        control={
                          <Checkbox
                            onClick={handleCheckboxSelection}
                            name={term?.version_name}
                            value={JSON.stringify({
                              id: term.id,
                              terms_and_condition_id: term.terms_and_condition_id,
                            })}
                            sx={{ color: "#8D8E8F" }}
                          />
                        }
                        checked={currentAcceptedTerm?.checked || false}
                        label="I have read and accept  the Terms & Conditions"
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #F4F4F4",
          padding: "20px 40px 20px 40px",
        }}
      >
        {stepper === 0 ? (
          <Button onClick={onClose} autoFocus sx={muiDialogStyles.btnDecline}>
            Cancel
          </Button>
        ) : (
          <Button onClick={handleBack} autoFocus sx={muiDialogStyles.btnDecline}>
            Back
          </Button>
        )}
        {stepper === elements?.length - 1 ? (
          <Button
            onClick={handleDownload}
            autoFocus
            sx={muiDialogStyles.btnStart}
            disabled={acceptedTerms.length <= 0}
          >
            Download
          </Button>
        ) : (
          <Button onClick={handleNext} autoFocus sx={muiDialogStyles.btnStart}>
            Next
          </Button>
        )}
      </DialogActions>
    </div>
  );
}

export default DisplayFilesTermVersions;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    alignSelf: "center",
    padding: "0px 0px 30px 0px",
  },

  btnStart: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    // marginRight: "40px",
    margin: "0px",
    "&:hover": {
      background: "#1E9DF2",
    },
    "&.Mui-disabled": {
      background: "#8D8E8F",
    },
  },
  btnDecline: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    margin: "0px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // minWidth: "680px",
    width: "100%",
    // minWidth: "100%",
    // minHeight: "280px",
    maxHeight: "250px",
    height: "334px",
    padding: "0px 20px",
    margin: "0px",
    // color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    // margin: "20px 40px 20px 40px",
    // minWidth: "70%",
    // padding: "0px",
    border: "1px solid  #F4F4F4",
    borderBottom: "none",
    overflow: "auto",
    wordWraop: "break-word",
    "& .MuiDialogContent-root": {
      margin: "0px",
    },
  },
  container: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    textAlign: "center",
  },
};
