/* eslint-disable */

import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, Dialog, FormControl, IconButton, MenuItem, TextField } from "@mui/material";
import { emailRegex } from "../../../../utils/regexList";
import CloseIcon from "@mui/icons-material/Close";
import LoadingScreen from "../../../feedback/LoadingScreen";
import { deleteTermsAndConditions } from "../../../../services/TermsAndConditions";
import { useDispatch } from "react-redux";
import { updateToolsByTermsAndConditionId } from "../../../../services/tools";
import { useQueryClient } from "@tanstack/react-query";

export const actionTypes = {
  change_status: "change status",
  status_draft: "status draft",
  delete_term: "delete term",
};

export const deleteTypes = {
  deleteUsed: "delete used",
  deleteUnused: "delete unused",
}

/**
 * TermVersionActionsDialog is dialog that handles certain action on Terms.
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.open - State of the dialog open/close.
 * @param {any} props.setOpen - Set the state of the dialog open/close.
 * @param {string} props.type - Type represend the type of the Dialog. Check the actionTypes object for references.
 * @param {any} props.data - Additional data, mostly its an object.
 * @param {Array<Object>} props.tools - Array of Tools.
 * @param {Array<Object>} props.termsData - Array of Terms.
 * @param {function} props.confirmStatusChange - Function that will apply the newly selected status of the version.
 * @returns {JSX.Element} The rendered component.
 */

const TermVersionActionsDialog = (props) => {
  const { open, setOpen, type, data, tools, termsData, confirmStatusChange } = props;

  const dispatch = useDispatch();
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [termsInUse, setTermsInUse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTermsData, setFilterTermsData] = useState([]);

  const queryClient = useQueryClient();

  function handleTerms() {
    if (data.is_general === true) {
      const filteredTerms = termsData?.filter((term) =>
        term?.id !== data?.id
        && term?.is_general === data?.is_general
        && term?.termsVersion?.some((version) => version.active)
      );
      setFilterTermsData(filteredTerms);
    }
    if (data.is_general === false) {
      const filteredTerms = termsData?.filter((term) =>
        term?.id !== data?.id
        && term?.is_general === data?.is_general
        && data?.company_id === term?.company_id
        && term?.termsVersion?.some((version) => version.active)
      );
      setFilterTermsData(filteredTerms);
    }
  }

  // function handleTools() {
  //   const filteredTools = tools?.filter((tool) => tool.terms_and_condition_id === data?.id);
  //   setTermsInUse(filteredTools);
  // }

  function handleTermsCheck() {
    if (data?.FilesToTerms.length > 0) {
      setTermsInUse(data?.FilesToTerms);
    }
  }

  useEffect(() => {
    if (tools && data) {
      // handleTools();
      handleTermsCheck();
      handleTerms();
    }
  }, [termsData, data]);

  function handleChangeTerm(event) {
    const termValue = event.target.value;

    const termObject = {
      id: termValue?.id,
      name: termValue?.terms_name,
    };
    setSelectedTerm(termObject);
  }

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    control,
  } = useForm();

  async function onSubmit(data) {
    const emailAddress = data?.notification_email;
    confirmStatusChange(emailAddress);
  }

  const submitForm = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form

      handleSubmit(onSubmit)();
    }
  };

  const closeDialog = () => {
    setOpen(false);
    reset();
    setSelectedTerm({});
  };

  async function handleTermsDelete() {
    await dispatch(deleteTermsAndConditions({ term: data, deleteType: deleteTypes.deleteUnused }));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    setOpen(false);
  }

  async function handleReAssignTerms() {
    // await dispatch(deleteTermsAndConditions(data));
    // const termData = {
    //   old_id: data?.id,
    //   new_id: selectedTerm?.id,
    //   terms: selectedTerm?.name,
    // };

    // await dispatch(updateToolsByTermsAndConditionId(termData));
    await dispatch(deleteTermsAndConditions({ term: data, deleteType: deleteTypes.deleteUsed, new_id: selectedTerm?.id }));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    setOpen(false);
    setSelectedTerm({});
  }

  let content;

  // Close button component
  const CloseComponent = () => {
    return (
      <div
        style={{
          alignSelf: "flex-end",
          marginBottom: "-30px",
          padding: "8px",
        }}
      >
        <IconButton onClick={closeDialog}>
          <CloseIcon color="disabled" />
        </IconButton>
      </div>
    );
  };

  if (actionTypes.change_status === type) {
    content = (
      <div style={muiDialogStyles.contentStyles}>
        <p style={muiDialogStyles.dialogTitle}>
          Are you sure you want to change the status of this terms version?
        </p>
        <p style={muiDialogStyles.infoText}>{data?.version_name}</p>
        <div style={muiDialogStyles.textDescription}>
          <span>
            By changing the status to <b>"Under Review"</b>, your document will be sent to the legal
            department for examination. They'll ensure it complies with all relevant
            regulations and aligns with our company policies.
          </span>
          <p>
            While your document is under review, editing will be disabled to prevent any unintended
            changes. You'll be notified as soon as the review is complete, regardless of the
            outcome.
          </p>
          <p>
            <b>To receive feedback from the legal department directly, please enter your email:</b>
          </p>
        </div>
        <form id="submit_email" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="notification_email"
            control={control}
            rules={{
              required: {
                value: true,
                message: "This field is required!",
              },
              pattern: {
                value: emailRegex,
                message: "This is not a valid email!",
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                fullWidth
                label="Enter email of legal department"
                placeholder="Enter email of legal department"
                variant="outlined"
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
                InputProps={{
                  autoComplete: "new-password",
                }}
                // onBlur={() => trigger()}
                sx={muiDialogStyles.textField}
              />
            )}
          />
        </form>
        <div style={muiDialogStyles.actionsContainer}>
          <Button sx={muiDialogStyles.btnCancel} onClick={closeDialog}>
            Cancel
          </Button>
          <Button
            onClick={submitForm}
            disabled={!isDirty}
            autoFocus
            sx={!isDirty ? muiDialogStyles.btnDisabled : muiDialogStyles.btn}
          >
            Confirm
          </Button>
        </div>
      </div>
    );
  } else if (actionTypes.delete_term === type) {
    let innerContext;

    if (termsInUse?.length > 0) {
      innerContext = (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={muiDialogStyles.textDescription}>
            <p>
              This version of the Terms & Conditions is currently in active use.Deleting it will
              permanently <b>remove all existing versions ({`${data?.termsVersion?.length}`})</b>{" "}
              and impact all users who have already agreed to them.
            </p>
            <b>Before deleting, you must designate a replacement for these terms:</b>
            <FormControl fullWidth>
              <TextField
                fullWidth
                size="small"
                select
                label="Select terms"
                // error={!!fieldState.error}
                // helperText={fieldState.error ? fieldState.error.message : null}
                // {...field}
                // defaultValue={state?.marketSelect}
                sx={muiDialogStyles.textField}
                onChange={handleChangeTerm}
              >
                {filterTermsData?.map((term) => {
                  return (
                    <MenuItem key={term?.id} value={term}>
                      {term?.terms_name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </div>
        </div>
      );
    } else {
      innerContext = (
        <div style={muiDialogStyles.textDescription}>
          <p>
            Deleting this version of the Terms & Conditions will permanently remove{" "}
            <b>all existing versions ({`${data?.termsVersion?.length}`})</b> as well. This action
            cannot be undone.
          </p>
        </div>
      );
    }

    content = (
      <div style={muiDialogStyles.contentStyles}>
        <p style={muiDialogStyles.dialogTitle}>Are you sure you want to delete this Terms</p>
        <p style={muiDialogStyles.infoText}>{data?.terms_name}</p>
        {innerContext}
        <div style={muiDialogStyles.actionsContainer}>
          <Button sx={muiDialogStyles.btnCancel} onClick={closeDialog}>
            Cancel
          </Button>
          {termsInUse && termsInUse?.length > 0 ? (
            <Button
              onClick={handleReAssignTerms}
              disabled={selectedTerm ? false : true}
              autoFocus
              sx={selectedTerm ? muiDialogStyles.btn : muiDialogStyles.btnDisabled}
            >
              Confirm
            </Button>
          ) : (
            <Button
              // onClick={submitForm}
              onClick={handleTermsDelete}
              // disabled={!isDirty}
              autoFocus
              sx={muiDialogStyles.btn}
            >
              Yes, Delete
            </Button>
          )}
        </div>
      </div>
    );
  } else if (actionTypes.status_draft === type) {
    content = (
      <div style={muiDialogStyles.contentStyles}>
        <p style={muiDialogStyles.dialogTitle}>
          Are you sure you want to change the status of this terms version?
        </p>
        <div style={muiDialogStyles.actionsContainer}>
          <Button sx={muiDialogStyles.btnCancel} onClick={closeDialog}>
            Cancel
          </Button>
          <Button onClick={submitForm} autoFocus sx={muiDialogStyles.btn}>
            Confirm
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
        "& .MuiPaper-root": { maxWidth: "700px" },
      }}
    >
      <CloseComponent />
      {isLoading ? (
        <div style={muiDialogStyles.contentStyles}>
          <LoadingScreen />{" "}
        </div>
      ) : (
        content
      )}
    </Dialog>
  );
};

export default TermVersionActionsDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    alignSelf: "center",
    margin: "0px",
    paddingBottom: "32px",
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop:"20px",
  },
  companyTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    margin: "48px 0px 0px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#0F6EAE",
    },
  },
  btnDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    minWidth: "300px",
    // maxHeight: "43px",
    // paddingBottom: "25px",
    // marginTop: "16px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
      border: "1px solid #8D8E8F",
    },
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    margin: "40px",
    minWidth: "500px",
  },
  infoText: {
    backgroundColor: "#EEFAFF",
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    textAlign: "center",
    margin: "0px",
  },
  textDescription: {
    color: "#1B1E1F",
    ontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    padding: "16px 0px 0px 0px",
  },
};
