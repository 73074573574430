import React from "react";

/* eslint-disable-next-line */
function MotCosCard({ type, img, title, subTitle }) {
  return (
    <div style={motcosCardStyles.motcos_card_root}>
      <div style={type === "SimLib" ? motcosCardStyles.lib_header : motcosCardStyles.app_header}>
        <div style={motcosCardStyles.header_inner_wrapper}>
          <p style={motcosCardStyles.motcos_title}>MotCoS</p>
          <p style={{ margin: "0px" }}>{type}</p>
          <div>
            <img
              src={img}
              alt={type}
              style={{
                border: "1px solid transparent",
                borderRadius: "100px",
                background: type === "SimLib" ? "#cae9f5" : "#e1dbf4",
              }}
            />
          </div>
        </div>
      </div>
      <div style={motcosCardStyles.context_container}>
        <p style={motcosCardStyles.context_title}>{title}</p>
        {subTitle ? <p style={motcosCardStyles.context_subTitle}>{subTitle}</p> : null}
      </div>
    </div>
  );
}

export default MotCosCard;

const motcosCardStyles = {
  motcos_card_root: {
    minWidth: "47%",
    maxWidth: "47%",
    fontFamily: "IBM Plex Sans",
    borderRadius: "5.511px",
    background: "#FFF",
    boxShadow: " 0px 22.04347801208496px 44.08695602416992px 0px rgba(195, 206, 217, 0.10)",
  },
  motcos_title: {
    fontSize: "clamp(0.75rem, 0.536rem + 1.071vw, 1.5rem)",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "150%",
    letterSpacing: "-0.48px",
    margin: "0px",
  },
  lib_header: {
    borderRadius: "5.511px 5.511px 0px 0px",
    background:
      "linear-gradient(0deg, #EEFAFF 0%, #EEFAFF 100%), linear-gradient(180deg, #346FA4 0%, #3F7BB1 100%)",
    color: "#43A5CB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  app_header: {
    borderRadius: " 5.511px",
    background:
      "linear-gradient(0deg, #F5F2FF 0%, #F5F2FF 100%), linear-gradient(180deg, #346FA4 0%, #3F7BB1 100%)",
    color: "#7B6FA3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  context_container: {
    padding: "0px 16.5px 22px 16.6px",
  },
  context_title: {
    fontSize: "clamp(1rem, 0.857rem + 0.714vw, 1.5rem)",
    maxWidth: "45%",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "120%",
    letterSpacing: "-0.48px",
  },
  context_subTitle: {
    fontSize: "clamp(0.75rem, 0.679rem + 0.357vw, 1rem)",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    letterSpacing: "-0.32px",
    maxWidth: "252px",
  },
  header_inner_wrapper: {
    padding: "28px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
};
