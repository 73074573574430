import axios from "axios";
import { errorHandler, options } from ".";
import { API_URL } from "../config";
import { toast_notifications } from "../utils/notifications";

export function serverMaintenanceNotification(data) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/server/maintenance", data, "post"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        // await dispatch({
        //   type: types.FETCH_ALL_USERS,
        //   payload: response.data,
        // });
        toast_notifications.info("Notifications for maintanence were successfully send!");
      }
    } catch (error) {
      console.warn("Error:", error);
      await dispatch(errorHandler(error));
    }
  };
}

export function updateMaintanence(data) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/server/status", data, "post"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";

      if (responseOK) {
        toast_notifications.info("Maintanence notification were successfully updated!");
      }
    } catch (error) {
      await dispatch(errorHandler(error));
    }
  };
}

export async function getMaintanenceStatus() {
  const response = await axios.get(API_URL + "/server/status", {
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    },
  });

  const responseOK = response && response.status === 200 && response.statusText === "OK";

  if (responseOK) {
    return response.data;
  }

  return { message: "server down" };
}
