/* eslint-disable */
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Button,
} from "@mui/material";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowBack as ArrowBackIcon,
  BorderColor as BorderColorIcon,
  ChangeCircleOutlined as ChangeCircleOutlinedIcon,
  Update as UpdateIcon,
} from "@mui/icons-material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ExtensionsColorSchemaResolver } from "../../../../components/cards/FilesCard/extensionsColorSchemaResolver";
import { formatFileSize, getDateTime2 } from "../../../../utils/misc";
import FileUpdate from "../../../../components/files/files_actions/FileUpdate";
import { filesService } from "../../../../services/files";
import EditFile from "../EditFile/EditFile";
import NavigationWrapper from "../../../../components/auth/NavigationWrapper";
import PermissionWrapper, { PermissionsSchema } from "../../../../components/auth/PermissionsWrapper";

const FileDetails = () => {
  const { id } = useParams();

  const {
    data: file,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["fileById", id],
    queryFn: () => filesService.getFileById(id),
  });

  const [openUpdate, setOpenUpdate] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [fileState, setFilestate] = useState({ file_name: file?.data.file?.file_name });

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const maturityLevel = file?.data?.file?.maturity_level?.split(" - ");

  function handleFileEdit() {
    setIsEditOpen(!isEditOpen);
  }

  function handleUpdateState(name, value) {
    setFilestate({
      ...fileState,
      [name]: value,
    });
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      {isEditOpen ? (
        <EditFile file={file?.data?.file} handleClose={handleFileEdit} />
      ) : (
        <div style={{ overflow: "auto", flex: 1 }}>
          <div style={{ height: "800px" }}>
            {!isLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  padding: "25px 40px",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button style={styles.arrowButton} onClick={handleGoBack}>
                      <ArrowBackIcon sx={{ fontSize: "15px", marginRight: "2px" }} /> Back
                    </Button>
                  </div>
                  <PermissionWrapper allowedPermission={PermissionsSchema.files.fm_access}>
                    <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                      <Button sx={styles.buttons} onClick={() => setOpenUpdate(true)}>
                        <span style={styles.buttons}>Replace</span>
                        <ChangeCircleOutlinedIcon sx={{ fontSize: "15px", marginLeft: "4px" }} />
                      </Button>
                      <Button
                        sx={styles.buttons}
                        // onClick={() => navigate("/dashboard/file-management")}
                        onClick={handleFileEdit}
                      >
                        Edit <BorderColorIcon sx={{ fontSize: "15px", marginLeft: "4px" }} />
                      </Button>
                    </div>
                  </PermissionWrapper>
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}
                >
                  <p
                    style={{
                      fontWeight: "600",
                      fontSize: "32px",
                      lineHeight: "44.8px",
                      margin: "0px",
                    }}
                  >
                    File Details
                  </p>
                </div>
                <div style={{ display: "flex", margin: "0px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "110px",
                    }}
                  >
                    <Typography sx={styles.tags}>Name:</Typography>
                    <Typography sx={styles.tagsMiddle}>Format:</Typography>
                    <Typography sx={styles.tagsMiddle}>Size:</Typography>
                    <Typography sx={styles.tagsMiddle}>Maturity level:</Typography>
                    <Typography sx={styles.tagsMiddle}>Created:</Typography>
                    <Typography sx={styles.tagsMiddle}>Modified:</Typography>
                    <Typography sx={styles.tagsMiddle}>Added by:</Typography>
                    <Typography sx={styles.tags}>Description:</Typography>
                    <Typography sx={styles.tags}>Serial ID:</Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "20px",
                      width: "420px",
                    }}
                  >
                    <TextField
                      sx={styles.valuesWithText}
                      value={file?.data.file?.file_name}
                      variant="standard"
                      disabled={!isEditOpen}
                      name="file_name"
                      onChange={(e) => handleUpdateState(e.target.name, e.target.value)}
                    >
                      {fileState.file_name}
                    </TextField>
                    <Typography sx={styles.valuesNoBorder}>
                      {ExtensionsColorSchemaResolver(file?.data?.file?.file_format || "")}
                    </Typography>
                    <Typography sx={styles.valuesNoBorder}>
                      {formatFileSize(file?.data.file?.size)}
                    </Typography>
                    <Typography sx={styles.valuesNoBorder}>
                      <span
                        style={{
                          backgroundColor: Number(maturityLevel[0]) > 4 ? "#EEFAFF" : "#EEF3FF",
                          color: Number(maturityLevel[0]) > 4 ? "#43A5CB" : "#7B6FA3",
                          padding: "2px 8px 2px 8px",
                        }}
                      >
                        {file?.data?.file?.maturity_level}
                      </span>
                    </Typography>
                    <Typography sx={styles.valuesNoBorder}>
                      {getDateTime2(file?.data?.file?.createdAt)}
                    </Typography>
                    <Typography sx={styles.valuesNoBorder}>
                      {getDateTime2(file?.data?.file?.updatedAt)}
                    </Typography>
                    <Typography sx={styles.valuesNoBorder}>
                      <Link
                        style={styles.accordionLink}
                        to={`/dashboard/users/${file?.data?.file?.uploader_id}`}
                      >
                        {file?.data?.file?.uploader_name}
                      </Link>
                    </Typography>
                    <TextField
                      sx={styles.valuesWithTextPadded}
                      value={file?.data?.file?.description}
                      variant="standard"
                      disabled
                    >
                      {file?.data?.file?.description}
                    </TextField>
                    <TextField
                      sx={styles.valuesWithTextPadded}
                      value={file?.data?.file?.serial_id}
                      variant="standard"
                      disabled
                    >
                      {file?.data?.file?.serial_id}
                    </TextField>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "10px 0px",
                    overflow: "hidden",
                  }}
                >
                  {/* <Accordion sx={styles.accordion}>
                    <AccordionSummary
                      sx={styles.accordionSummary}
                      expandIcon={<ArrowDropDownIcon />}
                    >
                      <Typography sx={styles.tags}>Related companies</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: "5px 0px",
                        marginLeft: "10px",
                      }}
                    >
                      {file?.data?.file?.FileConnections?.map((connection, index) => (
                        <p
                          key={index}
                          style={styles.accordionLink}
                          onClick={() =>
                            navigate("/dashboard/companies", {
                              state: { id: connection.Company.id },
                            })
                          }
                        >
                          {connection.Company.name}
                        </p>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion sx={styles.accordion}>
                    <AccordionSummary
                      sx={styles.accordionSummary}
                      expandIcon={<ArrowDropDownIcon />}
                    >
                      <Typography sx={styles.tags}>Linked projects</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: "5px 0px",
                        marginLeft: "10px",
                      }}
                    >
                      {file?.data?.file?.FileConnections?.map((connection, index) => (
                        <p key={index} style={styles.accordionLink}>
                          <Link
                            style={styles.accordionLink}
                            to={`/dashboard/projects/${connection?.project?.id}`}
                          >
                            {connection?.project?.name}
                          </Link>
                        </p>
                      ))}
                    </AccordionDetails>
                  </Accordion> */}
                  <Accordion sx={styles.accordion}>
                    <AccordionSummary
                      sx={styles.accordionSummary}
                      expandIcon={<ArrowDropDownIcon />}
                    >
                      <Typography sx={styles.tags}>Terms & Conditions</Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        padding: "5px 0px",
                        marginLeft: "10px",
                        border: "none",
                      }}
                    >
                      {file?.data?.file?.FilesToTerms?.map((term, index) => (
                        <p
                          key={index}
                          style={styles.accordionLink}
                          onClick={() =>
                            navigate("/dashboard/terms", {
                              state: { id: term.terms_and_condition.id },
                            })
                          }
                        >
                          {term.terms_and_condition.terms_name}
                        </p>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
                <FileUpdate
                  open={openUpdate}
                  onClose={() => setOpenUpdate(false)}
                  file={file?.data?.file}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default FileDetails;

const styles = {
  arrowButton: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#1E9DF2",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
    // marginTop:"6px",
    padding: "0px",
  },
  buttons: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#1E9DF2",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
  },
  tags: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
    padding: "10px 0px",
  },
  tagsMiddle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
    padding: "2px 0px",
  },
  valuesWithText: {
    padding: "8px 0px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    margin: "0px !important",
    borderBottom: "1px solid #E8E8E9",
    textDecoration: "none",
    ".MuiInput-underline": {
      textDecoration: "none",
    },
    ".Mui-disabled": {
      color: "black !important",
      WebkitTextFillColor: "black !important",
      textDecoration: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    ".MuiInput-input": {
      color: "black",
      WebkitTextFillColor: "black",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18.2px",
      padding: "0px",
      border: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
  },
  valuesWithTextPadded: {
    padding: "8px 4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    margin: "0px !important",
    borderBottom: "1px solid #E8E8E9",
    textDecoration: "none",
    ".MuiInput-underline": {
      textDecoration: "none",
    },
    ".Mui-disabled": {
      color: "black !important",
      WebkitTextFillColor: "black !important",
      textDecoration: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    ".MuiInput-input": {
      color: "black",
      WebkitTextFillColor: "black",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18.2px",
      padding: "0px",
      border: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
  },
  valuesNoBorder: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    marginLeft: "16px",
    padding: "2px 0px",
    color: "#8D8E8F",
  },
  accordion: {
    display: "grid",
    gridTemplateColumns: "536px",
    minWidth: "536px !important",
    mинHeight: "36px !important",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      padding: "0px 0px",
      maxHeight: "36px !important",
    },
    ".MuiPaper-root": {
      minHeight: "0px !important",
    },
    ".MuiCollapse-root": {
      border: "none",
    },
  },
  accordionSummary: {
    "& .Mui-expanded": {
      margin: "10px 0px",
    },
    ".MuiAccordionSummary-root": {
      maxHeight: "36px !important",
    },
    borderBottom: "1px solid #E8E8E9",
    minHeight: "36px !important",
    padding: "8px 0px",
    ".MuiAccordionSummary-expandIconWrapper": {
      color: "#1E9DF2",
    },
  },
  accordionLink: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18.2px",
    width: "504px",
    // height: "18px",
    margin: "5px 0px 0px 0px",
    color: "#1E9DF2",
    textDecoration: "none",
    cursor: "pointer",
  },
};
