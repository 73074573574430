/* eslint-disable */
import React from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Skeleton
} from '@mui/material';

const SkeletonTable = () => {
    const rows = Array.from(new Array(5)); // Number of skeleton rows
    const columns = Array.from(new Array(3)); // Number of skeleton columns

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((_, index) => (
                            <TableCell key={index}>
                                <Skeleton variant="text" width="100%" />
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                            {columns.map((_, colIndex) => (
                                <TableCell key={colIndex}>
                                    <Skeleton variant="rectangular" width="100%" height={30} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default SkeletonTable;