/* eslint-disable */
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import "./updateProject.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { market, powerClass, type, platform, motorType } from "../../../utils/dropdownValues";
import { createProject, updateProject } from "../../../services/project";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { mainNameRegex } from "../../../utils/regexList";
import { useLocation, useNavigate } from "react-router-dom";
import { companyServices } from "../../../services/company";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import getTheUserState from "../../../utils/userState";


export const actionType = { create: "create", update: "update" };

function UpdateProject({ setIsOpen, data: projectState, action, userId, pickedCompany }) {
  const { control, handleSubmit, formState, setValue, trigger, getValues, reset } = useForm();
  const { isDirty } = formState;

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [teamData, setTeamData] = useState([]);

  const loggedUser = getTheUserState();

  const checkCompaniesPermission = loggedUser?.user?.role?.permissions?.some(
    (permission => {
      return permission.permission_name === PermissionsSchema?.companies?.view_all_companies && permission.status === true;
    })
  );

  const { data: companiesData, isLoading: isLoadingCompanies } = useQuery({
    queryKey: ["companiesAndTeamsByUserId", userId],
    queryFn: checkCompaniesPermission ? () => companyServices.getCompaniesAndTeams() : () => companyServices.getCompaniesAndTeamsByUserId(userId),
    enabled: actionType.update === action,
  });

  useEffect(() => {
    if (!isLoadingCompanies) {
      const filteredTeams = actionType.create === action
        ? projectState?.companies?.filter((company) => company?.id === projectState?.company_id)
        : companiesData?.data?.data?.filter((company) => company?.id === projectState?.company_id);

      setTeamData(actionType.update === action ? filteredTeams[0]?.Teams : []);
      setValue("companyId", projectState?.company_id);
      setValue("teamId", projectState?.team_id);
      // trigger("companyId");
      // trigger("teamId");
    }
    if (pickedCompany && actionType.create === action) {
      setValue("companyId", pickedCompany?.id);
      setTeamData(actionType.update === action ? filteredTeams[0]?.Teams : pickedCompany?.Teams);
      // trigger("companyId");
    }
  }, [projectState, companiesData?.data, isLoadingCompanies]);


  async function handleNavigationAfterCreate() {
    setIsOpen(false);
    reset();
    queryClient.invalidateQueries(["projectsByCompanyIdAndTeamId"]);
    navigate("/dashboard/projects");
  }

  async function onSubmit(data) {
    const companyObject = actionType.create === action
      ? projectState?.companies?.find((companyItem) => companyItem.id === data.companyId)
      : companiesData?.data?.data?.find((companyItem) => companyItem.id === data.companyId)
    const companyName = companyObject ? companyObject.name : "";
    data.companyName = companyName;
    if (actionType.update === action) {
      data.id = projectState?.id;
      await dispatch(updateProject(data));
      await handleNavigationAfterCreate();
    } else {
      const userData = {
        user: {
          id: userId ? userId : null,
        },
      };

      data.userData = userData;
      await dispatch(createProject(data));
      await handleNavigationAfterCreate();
    }
  }

  const submitForm = async () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      await handleSubmit(onSubmit)();
      // setIsOpen(false);
    }
  };

  const handleSelectTeam = (e) => {
    const teamId = e.target.value;
    setValue("teamId", teamId);
    trigger("teamId");
  };

  const handleSelectedCompany = (e) => {
    const companyId = e.target.value;

    const filteredTeams = actionType.create === action
      ? projectState?.companies?.filter((company) => company?.id === companyId)
      : companiesData?.data?.data?.filter((company) => company?.id === companyId);

    setTeamData(filteredTeams[0]?.Teams);

    setValue("companyId", companyId);
    setValue("teamId", "");
    trigger("companyId");
  };

  const handleCancelUpdate = () => {
    if (location.state === null) {
      setIsOpen(false)
    }
    else {
      navigate(`/dashboard/projects/`)
    }
  }

  return (
    <div className="update_project_container">
      <div className="update_project_content">
        {action === actionType.create ? (
          <p className="create_title">Create Project</p>
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p className="id_title">ID</p>
            <p className="id_value">{projectState?.id}</p>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            defaultValue={actionType.update === action ? projectState.name : ""}
            rules={{
              required: "Name is required",
              pattern: {
                value: mainNameRegex,
                message: "Invalid Project name. Use letters and numbers.",
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                variant="standard"
                error={!!fieldState.error}
                helperText={fieldState.error ? fieldState.error.message : null}
                {...field}
                sx={updateProjectMuiStyles.projectName}
                placeholder="Project name"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <BorderColorIcon sx={{ color: "#8D8E8" }} />
                    </InputAdornment>
                  ),
                  autoComplete: "new-password",
                }}
              />
            )}
          />
          <div style={{ display: "flex", flexDirection: "column", padding: "28px 0px" }}>
            <div style={{ display: "flex", paddingBottom: "18px" }}>
              <Controller
                name="marketSelect"
                control={control}
                defaultValue={actionType.update === action ? projectState.marketSelect : ""}
                rules={{
                  required: "Market / Industry is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Market / Industry"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                  >
                    {market?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="typeOfApp"
                control={control}
                defaultValue={actionType.update === action ? projectState.typeOfApp : ""}
                rules={{
                  required: "Type is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Type of app"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                  >
                    {type?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Controller
                name="powerClass"
                control={control}
                defaultValue={actionType.update === action ? projectState.powerClass : ""}
                rules={{
                  required: "Power class is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Power class"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                  >
                    {powerClass?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </div>
            <div style={{ display: "flex" }}>
              <Controller
                name="motorType"
                control={control}
                defaultValue={actionType.update === action ? projectState.motorType : ""}
                rules={{
                  required: "Motor type is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Motor Type"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                  >
                    {motorType?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              <Controller
                name="platform"
                control={control}
                defaultValue={actionType.update === action ? projectState.platform : ""}
                rules={{
                  required: "Platform is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Platform"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                  >
                    {platform?.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />

              <Controller
                name="companyId"
                control={control}
                defaultValue={actionType.update === action ? projectState.company_id : ""}
                rules={{
                  required: "Company is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Company"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSelectedCompany(e);
                    }}
                  >
                    {actionType.create === action ? projectState?.companies?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.name}
                      </MenuItem>
                    ))
                      :
                      companiesData?.data?.data?.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.id}
                          sx={updateProjectMuiStyles.menuItem}
                        >
                          {option.name}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                )}
              />

              <Controller
                name="teamId"
                control={control}
                defaultValue={actionType.update === action ? projectState.team_id : ""}
                rules={{
                  required: "Team is required",
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    size="small"
                    select
                    label="Team"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    sx={updateProjectMuiStyles.dropdown}
                    onChange={(e) => {
                      field.onChange(e);
                      handleSelectTeam(e);
                    }}
                    disabled={!teamData?.length ? true : false}
                    defaultValue={actionType.update === action ? projectState?.team_id : ""}
                  >
                    {teamData?.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={updateProjectMuiStyles.menuItem}
                      >
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )
                }
              />
            </div>
            <div style={{ maxWidth: "300px" }}>
              <p className="header_title">What kind of project is this ?</p>
              <Controller
                name="type"
                control={control}
                defaultValue={actionType.update === action ? projectState.type : "Test"}
                rules={{
                  required: "This is required",
                }}
                render={({ field, fieldState }) => (
                  <FormControl
                    component="fieldset"
                    error={!!fieldState.error}
                  //This throws an error
                  // helperText={fieldState.error ? fieldState.error.message : null}
                  >
                    <RadioGroup
                      aria-label="type"
                      {...field}
                    >
                      <FormControlLabel
                        value="Test"
                        control={<Radio size="small" />}
                        label="Test"
                      />
                      <FormControlLabel
                        value="Pre development"
                        control={<Radio size="small" />}
                        label="Pre development"
                      />
                      <FormControlLabel
                        value="Serial development"
                        control={<Radio size="small" />}
                        label="Serial development"
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              />
            </div>
          </div>
        </form>
        <div>
          <p style={{ marginTop: "0px" }} className="header_title">
            Comment
          </p>
          <Controller
            name="description"
            control={control}
            defaultValue={actionType.update === action ? projectState.description : ""}
            render={({ field, fieldState }) => (
              <TextField
                multiline
                rows={4}
                {...field}
                sx={{ width: "70%" }}
              />
            )}
          />
        </div>
      </div>
      <div className="update_project_footer">
        <div className="update_project_footer_inner_container">
          {action === actionType.create && <Button
            variant="text"
            color="inherit"
            onClick={() => setIsOpen(false)}
            sx={updateProjectMuiStyles.cancelBtn}
          >
            Cancel
          </Button>}
          {action === actionType.update && <Button
            variant="text"
            color="inherit"
            onClick={handleCancelUpdate}
            sx={updateProjectMuiStyles.cancelBtn}
          >
            Cancel
          </Button>}
          {action === actionType.create ? (
            <Button
              variant="contained"
              color="info"
              onClick={() => handleSubmit(onSubmit)()}
              sx={updateProjectMuiStyles.deleteBtn}
              disabled={!isDirty}
            >
              Create
            </Button>
          ) : (
            <Button
              variant="contained"
              color="info"
              onClick={() => handleSubmit(onSubmit)()}
              sx={updateProjectMuiStyles.deleteBtn}
              disabled={!isDirty}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UpdateProject;

const updateProjectMuiStyles = {
  projectName: {
    "& .MuiInputBase-input": {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "44.8px",
      letterSpacing: "-0.64px",
    },
  },
  dropdown: {
    minWidth: "210px",
    paddingRight: "24px",
    // border: "2px solid red",
    // maxWidth: "210px",
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
      maxWidth: "210px",
    },
    // "& .MuiMenu-paper": {
    //   maxWidth: "210px",
    //   border: "2px solid yellow",
    // },
    // "& .MuiPaper-root": {
    //   border: "2px solid red",
    // },
  },
  cancelBtn: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "140px",
    height: "54px",
    marginRigh: "15px",
  },
  deleteBtn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
};
