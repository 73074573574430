// import { store } from "..";

const getTheUserState = () => {
  const data = sessionStorage.getItem("user");
  const user = JSON.parse(data);
  // console.log("store", store);
  // const user = {
  //   user: "user",
  //   token: "token",
  // };
  // const appState = store.getState("user");
  // const user = appState.auth?.user;
  return user;
};

export default getTheUserState;
