/* eslint-disable */

import { MenuItem, TextField, Typography } from "@mui/material";
import NoNews from "./NoNews";
import NewsCard from "./NewsCard";
import PermissionWrapper, { PermissionsSchema } from "../../../auth/PermissionsWrapper";

const dropdownFilterOptions = [
  {
    value: "all",
    label: "All News",
  },
  {
    value: "published",
    label: "Published",
  },
  {
    value: "drafts",
    label: "Drafts",
  },
  {
    value: "pinned",
    label: "Pinned",
  },
];

const NewsFeed = ({ filteredNews, sortItem, setSortItem }) => {

  return (
    <>
      <div
        style={{
          margin: "24px 0px",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
          width: "100%",
          minHeight: "40px",
          margin: "0px",
        }}
      >
        <Typography sx={styles.title}>News Feed</Typography>
        <PermissionWrapper allowedPermission={PermissionsSchema.news.create_news}>
          <TextField
            name="sort"
            select
            label="Show"
            sx={styles.dropdown}
            value={sortItem}
            onChange={(e) => setSortItem(e.target.value)}
            defaultValue={sortItem}
          >
            {dropdownFilterOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value} sx={styles.menuItem}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </PermissionWrapper>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingBottom: "24px",
          width: "100%",
          marginRight: "36px",
        }}
      >
        {filteredNews?.length === 0 ? (
          <NoNews />
        ) : (
          filteredNews?.map((news, index) => (
            <NewsCard key={news.id} news={news} newsLength={filteredNews?.length} index={index} />
          ))
        )}
      </div>
    </>
  );
};

export default NewsFeed;

const styles = {
  title: {
    color: "#8D8E8F",
    textAlign: "start",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
  },
  dropdown: {
    minWidth: "210px",

    "& .MuiInputBase-root": {
      borderRadius: "4px",
      maxWidth: "210px",
      height: "40px",
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      letterSpacing: "0.14px",
    },
    "& .MuiFormLabel-root": {
      top: "-6px",
      color: "#8D8E8F",
      fontFamily: "IBM Plex Sans",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "150%" /* 16.5px */,
      letterSpacing: "0.11px",
    },
  },
  menuItem: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
  },
};
