/* eslint-disable */
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import FilesSelectCompanyComponent from "../files_select_company/FilesSelectCompanyComponent";
import FilesAssignToUsersComponent from "../files_assign_users/FilesAssignToUsersComponent";
import PermissionWrapper, { PermissionsSchema, DisableML } from "../../auth/PermissionsWrapper";

export const fileDropdownType = {
  maturityLevel: "Maturity Level",
  company: "Specific Company",
  terms: "Terms",
};

export const maturityLevel = [
  {
    title: "0 - demo unverified",
    value: 0,
  },
  {
    title: "1 - demo partly verified by creator",
    value: 1,
  },
  {
    title: "2 - demo function verified by creator",
    value: 2,
  },
  {
    title: "3 - demo systematicall verified",
    value: 3,
  },
  {
    title: "4 - demo req. based created/verified",
    value: 4,
  },
  {
    title: "5 - production auxiliary tool",
    value: 5,
  },
  {
    title: "6 - production tool",
    value: 6,
  },
  {
    title: "7 - product",
    value: 7,
  },
];

export const company = [
  {
    title: "Specific User(s) or Team(s)",
    value: 0,
  },
  {
    title: "Project(s) for multiple companies",
    value: 1,
  },
  {
    title: "Private",
    value: 2,
  },
  {
    title: "Set as General Resource",
    value: 3,
  },
];

const resourceStatusTypes = {
  is_private: "is_private",
  is_general: "is_general",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
  },
  list: {
    padding: "0px !important",
  },
}));

export default function FilesSettingsDropdown({
  index,
  type,
  handleSelectOption,
  handleUpdateFileStatus,
  termsData,
  data,
  defaultValue,
  availableForData,
}) {
  const [status, setStatus] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectCompanyType, setSelectCompanyType] = useState(null);
  const [isCompanyDrawerOpen, setIsCompanyDrawerOpen] = useState(false);
  const [isAssignUsersDrawerOpen, setIsAssignUsersDrawerOpen] = useState(false);

  // Settings for Project(s) for multiple companies
  const [addSettings, setAddSettings] = useState([]);

  // Settings for Specific User(s) for Team(s)
  const [dataType, setDataType] = useState([]);

  const [selectedCompanyValue, setSelectedCompanyValue] = useState("");

  useEffect(() => {
    if (availableForData?.companies !== "" && availableForData?.companies?.length) {
      setSelectedCompanyValue(1);
      setDataType([]);
    } else if (
      availableForData?.available?.type !== "" &&
      availableForData?.available?.data?.length
    ) {
      setSelectedCompanyValue(0);
      setAddSettings([]);
    } else if (availableForData?.available_for?.value !== "") {
      setSelectedCompanyValue(availableForData?.available_for?.value);
    }
  }, [selectedCompanyValue]);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    handleSelectOption(index, name, value);
    setStatus(event.target.value);
    setIsDialogOpen(true);
  };

  const handleChangeCompanySelection = (event) => {
    const value = event.value;
    const name = event.name;

    if (value === 1) {
      setSelectCompanyType(value);
      setIsCompanyDrawerOpen(true);
    } else if (value === 0) {
      setSelectCompanyType(value);
      setIsAssignUsersDrawerOpen(true);
    }
  };

  // const handleAssignTeams = (event) => {
  //   const value = event.value;
  //   const name = event.name;
  //   setSelectCompanyType(value);
  //   setIsAssignUsersDrawerOpen(true);
  // };

  const handleChangeResourceStatus = (value, type, object) => {
    console.log("object", object);
    // Here we need to assign the second type
    // If is_general is true the other one should be false and the oposite.
    // Second property by default will be false.
    let property2;

    if (type === resourceStatusTypes.is_general) {
      property2 = resourceStatusTypes.is_private;
      // handleSelectOption(index, "available_for", { value: 4, name: "private" });
    } else {
      property2 = resourceStatusTypes.is_general;
      // handleSelectOption(index, "available_for", { value: 5, name: "general" });
    }

    handleUpdateFileStatus(index, type, true, property2, object);
    setSelectedCompanyValue(value);
  };

  const handleTermsOption = (event) => {
    const value = event.target.value;

    handleSelectOption(index, "terms_id", value);
  };

  let content;

  if (fileDropdownType.maturityLevel === type) {
    content = (
      <div>
        <FormControl variant="standard" fullWidth>
          <Select
            name="maturity_level"
            labelId="maturity_level"
            id="maturity_level_dropdown"
            disableUnderline
            onChange={handleChange}
            sx={styles.selectMenu}
            size="small"
            defaultValue={defaultValue ? defaultValue : 0}
            MenuProps={{
              classes: {
                paper: classes.paper,
                list: classes.list,
              },
            }}
          >
            <MenuItem sx={styles.menuItem} value={-1} disabled>
              <span style={{ fontSize: "11px", padding: "8px 0px" }}>Choose an option...</span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[0].value}
              key={maturityLevel[0].value}
              disabled={DisableML(PermissionsSchema.files.ml0)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEF3FF",
                  color: "#7B6FA3",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[0].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[1].value}
              key={maturityLevel[1].value}
              disabled={DisableML(PermissionsSchema.files.ml1)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEF3FF",
                  color: "#7B6FA3",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[1].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[2].value}
              key={maturityLevel[2].value}
              onClick={handleChange}
              disabled={DisableML(PermissionsSchema.files.ml2)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEF3FF",
                  color: "#7B6FA3",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[2].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[3].value}
              key={maturityLevel[3].value}
              disabled={DisableML(PermissionsSchema.files.ml3)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEF3FF",
                  color: "#7B6FA3",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[3].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[4].value}
              key={maturityLevel[4].value}
              disabled={DisableML(PermissionsSchema.files.ml4)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEF3FF",
                  color: "#7B6FA3",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[4].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[5].value}
              key={maturityLevel[5].value}
              disabled={DisableML(PermissionsSchema.files.ml5)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEFAFF",
                  color: "#43A5CB",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[5].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[6].value}
              key={maturityLevel[6].value}
              disabled={DisableML(PermissionsSchema.files.ml6)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEFAFF",
                  color: "#43A5CB",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[6].title}
              </span>
            </MenuItem>

            <MenuItem
              sx={styles.menuItem}
              value={maturityLevel[7].value}
              key={maturityLevel[7].value}
              disabled={DisableML(PermissionsSchema.files.ml7)}
            >
              <span
                style={{
                  fontSize: "11px",
                  minWidth: "max-content",
                  backgroundColor: "#EEFAFF",
                  color: "#43A5CB",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {maturityLevel[7].title}
              </span>
            </MenuItem>

            {/* {maturityLevel.map((item, index) => {
              return (
                <MenuItem sx={styles.menuItem} value={item.value} key={index}>
                  <span
                    style={{
                      fontSize: "11px",
                      minWidth: "max-content",
                      backgroundColor: index > 4 ? "#EEFAFF" : "#EEF3FF",
                      color: index > 4 ? "#43A5CB" : "#7B6FA3",
                      padding: "2px 6px",
                      margin: "10px 0px",
                    }}
                  >
                    {item.title}
                  </span>
                </MenuItem>
              );
            })} */}
          </Select>
        </FormControl>
      </div>
    );
  } else if (fileDropdownType.company === type) {
    content = (
      <div>
        <FormControl variant="standard" fullWidth>
          <Select
            labelId="company_dropdown"
            id="company_dropdown"
            // onChange={handleChangeCompanySelection}
            sx={styles.selectMenu}
            disableUnderline
            size="small"
            defaultValue={-1}
            value={selectedCompanyValue !== "" ? selectedCompanyValue : 2}
            inputProps={{
              style: {
                border: "none",
              },
            }}
            MenuProps={{
              classes: {
                paper: classes.paper,
                list: classes.list,
              },
            }}
          >
            <MenuItem sx={styles.menuItem} value={-1} disabled>
              <span style={{ fontSize: "11px", padding: "8px 0px" }}>Choose an option...</span>
            </MenuItem>
            <MenuItem
              name="projects"
              sx={styles.menuItem}
              value={company[1].value}
              key={company[1].value}
              onClick={() =>
                handleChangeCompanySelection({ value: company[1].value, name: company[1].title })
              }
            >
              <span
                style={{
                  fontSize: "11px",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {company[1].title}
              </span>
            </MenuItem>
            <MenuItem
              name="users"
              sx={styles.menuItem}
              value={company[0].value}
              key={company[0].value}
              onClick={() =>
                handleChangeCompanySelection({ value: company[0].value, name: company[0].title })
              }
            >
              <span
                style={{
                  fontSize: "11px",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {company[0].title}
              </span>
            </MenuItem>
            <MenuItem
              name="private"
              sx={styles.menuItem}
              value={company[2].value}
              key={company[2].value}
              onClick={() => {
                handleChangeResourceStatus(company[2].value, resourceStatusTypes.is_private, {
                  index,
                  title: "available_for",
                  data: { value: 2, name: "private" },
                });
                // handleSelectOption(index, "available_for", { value: 2, name: "private" });
              }}
            >
              <span
                style={{
                  fontSize: "11px",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {company[2].title}
              </span>
            </MenuItem>
            <MenuItem
              name="general"
              sx={styles.menuItem}
              value={company[3].value}
              key={company[3].value}
              onClick={() => {
                handleChangeResourceStatus(company[3].value, resourceStatusTypes.is_general, {
                  index,
                  title: "available_for",
                  data: { value: 3, name: "general" },
                });
                // handleSelectOption(index, "available_for", { value: 3, name: "general" });
              }}
            >
              <span
                style={{
                  fontSize: "11px",
                  padding: "2px 6px",
                  margin: "10px 0px",
                }}
              >
                {company[3].title}
              </span>
            </MenuItem>
            {/* {company.map((item, index) => {
              return (
                <MenuItem sx={styles.menuItem} value={item.value} key={index}>
                  <span
                    style={{
                      fontSize: "11px",
                      padding: "2px 6px",
                      margin: "10px 0px",
                    }}
                  >
                    {item.title}
                  </span>
                </MenuItem>
              );
            })} */}
          </Select>
        </FormControl>
        <FilesSelectCompanyComponent
          isOpen={isCompanyDrawerOpen}
          setIsOpen={setIsCompanyDrawerOpen}
          handleSelectOption={handleSelectOption}
          index={index}
          fileData={availableForData}
          setSelectedCompanyValue={setSelectedCompanyValue}
          selectedCompanyValue={selectedCompanyValue}
          addSettings={addSettings}
          setAddSettings={setAddSettings}
        />
        <FilesAssignToUsersComponent
          isOpen={isAssignUsersDrawerOpen}
          setIsOpen={setIsAssignUsersDrawerOpen}
          data={data}
          handleSelectOption={handleSelectOption}
          fileIndex={index}
          setSelectedCompanyValue={setSelectedCompanyValue}
          fileData={availableForData}
          dataType={dataType}
          setDataType={setDataType}
        />
      </div>
    );
  } else if (fileDropdownType.terms === type)
    content = (
      <div>
        <FormControl variant="standard" fullWidth>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            onChange={handleTermsOption}
            sx={styles.selectMenu}
            size="small"
            disableUnderline
            defaultValue={-1}
            inputProps={{
              style: {
                border: "none",
              },
            }}
            MenuProps={{
              classes: {
                paper: classes.paper,
                list: classes.list,
              },
            }}
          >
            <MenuItem sx={styles.menuItem} value={-1} disabled>
              <span style={{ fontSize: "11px", padding: "8px 0px" }}>Choose an option...</span>
            </MenuItem>
            {termsData?.map((item, index) => {
              return (
                <MenuItem key={index} sx={styles.menuItem} value={item.id} name={item.terms_name}>
                  <span
                    style={{
                      fontSize: "11px",
                      padding: "2px 6px",
                      margin: "10px 0px",
                    }}
                  >
                    {item.terms_name}
                  </span>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
    );
  else {
    content = <p>Type is not selected!</p>;
  }

  return content;
}

const styles = {
  selectMenu: {
    minWidth: "185px",
    padding: "0px",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "11px",
    borderTop: "1px solid #E8E8E9",
  },
};
