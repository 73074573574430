/* eslint-disable */
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import VerificationtStatusComponent from "../widgets/VeficiationStatusComponent";
import TermVersionActionsDialog, {
  actionTypes,
} from "../dialogs/TermsAndConditions/TermsVersion/TermVersionActionsDialog";
import { useDispatch } from "react-redux";
import { updateTermsVersionStatus } from "../../services/termsVersion";
import { makeStyles } from "@mui/styles";
import { useQueryClient } from "@tanstack/react-query";

const MenuValues = {
  draft: "draft",
  review: "review",
  verified: "verified",
};

const useStyles = makeStyles((theme) => ({
  paper: {
    // Add your custom styles for the Paper component when the menu is open
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
  },
  list: {
    padding: "0px !important",
  },
}));

export default function VerificationStatusDropdown({ data }) {
  const [status, setStatus] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDraftDialogOpen, setIsDraftDialogOpen] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleChange = (event) => {
    const trackEvent = event.target.value;
    if (trackEvent === MenuValues.review) {
      setIsDialogOpen(true);
      setStatus(event.target.value);
    } else {
      setStatus(event.target.value);
      setIsDraftDialogOpen(true);
    }
  };

  async function confirmStatusChange2() {
    const statusObj = {
      id: data?.id,
      status: status,
    };

    await dispatch(updateTermsVersionStatus(statusObj));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
  }

  async function confirmStatusChange(emailAddress) {
    const statusObj = {
      id: data?.id,
      status: status,
      pdf: data.version_name,
      email: emailAddress,
    };

    await dispatch(updateTermsVersionStatus(statusObj));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
  }

  return (
    <div>
      <FormControl variant="standard">
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={data?.status}
          onChange={handleChange}
          sx={styles.selectMenu}
          size="small"
          // MenuProps={{ style: { background: "black" } }}
          MenuProps={{
            classes: {
              paper: classes.paper,
              list: classes.list,
            },
          }}
        >
          <MenuItem sx={styles.menuItem} value="" disabled>
            <span style={{ fontSize: "11px", padding: "8px 0px" }}>Choose verification status</span>
          </MenuItem>
          <MenuItem
            sx={styles.menuItem}
            value="draft"
            disabled={data?.status === "verified" ? true : false}
          >
            {VerificationtStatusComponent("draft")}
          </MenuItem>
          <MenuItem
            sx={styles.menuItem}
            value="review"
            disabled={data?.status === "verified" ? true : false}
          >
            {VerificationtStatusComponent("underReview")}
          </MenuItem>
          <MenuItem
            sx={styles.menuItem}
            value="verified"
            disabled={data?.status === "draft" ? true : false}
          >
            {VerificationtStatusComponent("verified")}
          </MenuItem>
        </Select>
      </FormControl>
      <TermVersionActionsDialog
        open={isDialogOpen}
        type={actionTypes.change_status}
        setOpen={setIsDialogOpen}
        data={data}
        confirmStatusChange={confirmStatusChange}
      />
      <TermVersionActionsDialog
        open={isDraftDialogOpen}
        type={actionTypes.status_draft}
        setOpen={setIsDraftDialogOpen}
        confirmStatusChange={confirmStatusChange2}
      />
    </div>
  );
}

const styles = {
  selectMenu: {
    minWidth: "185px",
    // minWidth: "250px",
    padding: "0px",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "11px",
    borderTop: "1px solid #E8E8E9",
    // borderLeft: "1px solid gray",
    // borderRight: "1px solid gray",
    // border: "1px solid gray",
  },
};
