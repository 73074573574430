/* eslint-disable */
import { useEffect, useState } from "react";
import { Drawer, IconButton, Checkbox, TextField, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { companyServices } from "../../../../services/company";
import getTheUserState from "../../../../utils/userState";
import { useFormContext } from "react-hook-form";
import LoaderSkeletonComponent from "./LoadingSkeletonComponent";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { PermissionsSchema } from "../../../../components/auth/PermissionsWrapper";

import "./fileEditMultipleUsers.css";

const stepperOptions = {
  0: "company",
  1: "team",
  2: "users",
};

const FileEditMultipleUsers = () => {
  const userCompanies = getTheUserState()?.user?.companies;
  const { setValue, watch } = useFormContext();
  const defaultUsers = watch("file_user");

  const [step, setStep] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [defaultUsersValues, setDefaultUsersValues] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [dataToSave, setDataToSave] = useState([]);

  const loggedUser = getTheUserState();

  const checkCompaniesPermission = loggedUser?.user?.role?.permissions?.some(
    (permission => {
      return permission.permission_name === PermissionsSchema?.companies?.view_all_companies && permission.status === true;
    })
  );

  const {
    data: allCompanies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["all-companies-user"],
    queryFn: checkCompaniesPermission
      ? () => companyServices.getAllCompaniesWithUsers()
      : () => companyServices.getAllCompaniesByUser(userCompanies),
    enabled: isOpen,
  });

  useEffect(() => {
    if (isOpen) {
      setDefaultUsersValues(defaultUsers);
    }
  }, [isOpen]);

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  function handleDrawerState() {
    setIsOpen(!isOpen);
  }

  function selectCompany(companyID) {
    const selectedCompany = allCompanies?.data?.companies.filter((c) => c.id === companyID);
    setCompanyData(selectedCompany);
    setStep((prev) => prev + 1);
  }

  function selectTeam(team) {
    setTeamData(team);
    setStep((prev) => prev + 1);
  }

  function handleSave() {
    setValue("file_user", defaultUsersValues, { shouldDirty: true });
    setValue("companies", []);
    setValue("is_private", false);
    setValue("is_general", false);
    setIsOpen(false);
    setStep(0);
  }

  // Components Starts from Here
  const HandleCloseComponent = () => {
    return (
      <div style={assignUsersStyles.handleCloseContainer}>
        <IconButton aria-label="close" onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const ResetAllComponent = (type) => {
    function resetAllData() {
      setDataToSave([]);
      setDefaultUsersValues(defaultUsers);
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap:"4px", marginBottom:"4px"}}>
        <span style={assignUsersStyles.resetTitle}>{type}</span>
        <button style={assignUsersStyles.resetBtn} onClick={resetAllData}>
          Reset all
        </button>
      </div>
    );
  };

  const NoResultFromSearch = () => {
    return <p>There are no results for this search.</p>;
  };

  const NoData = ({ type }) => {
    let content;

    if (type === "company") {
      content = <p>There are not Companies!</p>;
    } else if (type === "team") {
      content = <p>This Company dont have any Teams</p>;
    } else if (type === "users") {
      content = <p>This Team dont have any Users</p>;
    }

    return content;
  };

  const FooterComponent = () => {
    return (
      <div style={{ padding: "12px 32px", borderTop: "1px solid  #E8E8E9" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {step > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setStep((prev) => prev - 1);
                // setDataType({ type: stepperOptions[step - 1], data: [] });
              }}
              sx={assignUsersStyles.cancelButton}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowBackIcon />
                <span style={{ paddingLeft: "5px" }}>Back</span>
              </div>
            </Button>
          ) : (
            <Button variant="text" onClick={handleCloseDrawer} sx={assignUsersStyles.cancelButton}>
              Cancel
            </Button>
          )}

          <Button
            variant="text"
            onClick={handleSave}
          // sx={
          //   dataToSave?.length ? assignUsersStyles.addButton : assignUsersStyles.addButtonDisabled
          // }
          // disabled={dataToSave?.length ? false : true}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const CompanyStepComponent = () => {
    const [searchCompany, setSearchCompany] = useState("");

    const handleSearcCompany = (event) => {
      const searchValue = event.target.value;
      setSearchCompany(searchValue.toLowerCase());
    };

    const companyFilteredData = allCompanies?.data?.companies?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchCompany);
      return matchSearch;
    });

    if (isLoading) {
      return <LoaderSkeletonComponent count={7} />;
    }

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>Choose Specific Customers</p>
        {ResetAllComponent("Choose Company")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchCompany}
          fullWidth
          onChange={handleSearcCompany}
        />
        {/* {dataType?.data?.length ? <p>Selected Teams {dataType?.data?.length}</p> : null} */}
        <div style={assignUsersStyles.contentContainerStyles}>
          {allCompanies?.data?.companies?.length ? (
            companyFilteredData?.length ? (
              companyFilteredData?.map((company) => {
                return (
                  <div key={company?.id} style={assignUsersStyles.rowStyles} className="drawer_row" onClick={() => selectCompany(company?.id)}>
                    <div style={assignUsersStyles.rowStylesInner}>
                      <div
                        style={assignUsersStyles.innerRowsStyles}
                      // onClick={() => selectCompany(company?.id)}
                      >
                        <ApartmentIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                        <p style={{ fontSize: "14px" }}>{company?.name}</p>
                      </div>
                    </div>

                    <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} fontSize="small" />
                  </div>
                );
              })
            ) : (
              <NoResultFromSearch />
            )
          ) : (
            <NoData type="company" />
          )}
        </div>
      </div>
    );
  };

  const TeamStepComponent = () => {
    const [searchTeam, setSearchTeam] = useState("");

    const handleSelectTeamCheckboxes = (team, isChecked) => {
      // Find the index of the team

      if (isChecked) {
        const alterData = team?.users?.map((u) => {
          return { user_id: u?.id };
        });

        let onlyUniques = [...defaultUsersValues, ...alterData].reduce((acc, current) => {
          if (!acc.some((item) => item.user_id === current.user_id)) {
            acc.push(current);
          }
          return acc;
        }, []);

        setDefaultUsersValues(onlyUniques);
      } else {
        // Extract user_ids to remove from objectsToRemove
        let idsToRemove = team?.users?.map((obj) => obj.id);

        // Filter out objects with user_id in idsToRemove
        let filteredArray = defaultUsersValues.filter((obj) => !idsToRemove.includes(obj.user_id));

        setDefaultUsersValues(filteredArray);
      }
    };

    const handleSearchTeam = (event) => {
      const searchValue = event.target.value;
      setSearchTeam(searchValue.toLowerCase());
    };

    const teamFilteredData = companyData[0].Teams?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchTeam);
      return matchSearch;
    });

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>{companyData[0]?.name}</p>
        {ResetAllComponent("Choose Team or click on team to select a specific user(s)")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchTeam}
          fullWidth
          onChange={handleSearchTeam}
        // disabled={companyData[0].Teams?.length < 1 ? true : false}
        />
        <div style={assignUsersStyles.contentContainerStyles}>
          {companyData[0].Teams?.length ? (
            teamFilteredData?.length ? (
              teamFilteredData?.map((team) => {
                let isChecked = defaultUsersValues.some((u) =>
                  team?.users?.some((obj) => obj.id === u?.user_id)
                );
                return (
                  <div key={team?.id} style={assignUsersStyles.rowStyles} className="drawer_row">
                    <div style={assignUsersStyles.rowStylesInner}>
                      <Checkbox
                        defaultChecked={isChecked}
                        onClick={(e) => handleSelectTeamCheckboxes(team, e.target.checked)}
                      // disabled={team?.users?.length > 0 ? false : true}
                      />
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto',
                          alignItems: 'center',
                          gap: '8px',
                          padding: '8px 0',
                          width: '100%',
                        }}
                        onClick={() => selectTeam(team)}>
                        <div
                          style={assignUsersStyles.innerRowsStyles}
                        // onClick={() => selectTeam(team)}
                        >
                          <GroupsIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                          <p style={{ fontSize: "14px" }}>{team?.name}</p>
                        </div>
                      </div>
                    </div>

                    <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} fontSize="small" />
                  </div>
                );
              })
            ) : (
              <NoResultFromSearch />
            )
          ) : (
            <NoData type="team" />
          )}
        </div>
      </div>
    );
  };

  const UserStepComponent = () => {
    const [searchUsers, setSearchUsers] = useState("");

    const handleSearchUsers = (event) => {
      const searchValue = event.target.value;
      setSearchUsers(searchValue.toLowerCase());
    };

    const usersFilteredData = teamData?.users?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchUsers);
      return matchSearch;
    });

    const handleSelectUserCheckboxes = (team, user) => {
      const findIndex = defaultUsersValues.findIndex((u) => u?.user_id === user?.id);

      if (findIndex === -1) {
        let userObject = { user_id: user?.id };
        setDefaultUsersValues((prev) => [...prev, userObject]);
      } else {
        const filterUsers = defaultUsersValues.filter((u) => u?.user_id !== user.id);
        setDefaultUsersValues(filterUsers);
      }
    };

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>{teamData?.name}</p>
        {ResetAllComponent("Choose User(s)")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchUsers}
          fullWidth
          onChange={handleSearchUsers}
        />
        {teamData?.users?.length ? (
          usersFilteredData?.length ? (
            usersFilteredData?.map((user) => {
              const isChecked = defaultUsersValues.filter((u) => u?.user_id === user?.id);
              return (
                <div key={user?.id} style={assignUsersStyles.rowStyles} className="drawer_row">
                  <div style={assignUsersStyles.rowStylesInner}>
                    <Checkbox
                      defaultChecked={isChecked?.length ? true : false}
                      onChange={() => handleSelectUserCheckboxes(teamData, user)}
                    />
                    <div
                      style={assignUsersStyles.innerRowsStyles}
                    // onClick={() => selectTeam(team?.id)}
                    >
                      <PersonIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                      <p style={{ fontSize: "14px" }}>{user?.name}</p>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <NoResultFromSearch />
          )
        ) : (
          <NoData type="users" />
        )}
      </div>
    );
  };

  const stepperValues = {
    0: <CompanyStepComponent />,
    1: <TeamStepComponent />,
    2: <UserStepComponent />,
  };

  let buttonTitle = defaultUsers?.length ? "Assigned users" : "Assign users";
  return (
    <div>
      <Button
        variant="outlined"
        color="info"
        sx={{ textTransform: "capitalize", fontSize: "11px" }}
        onClick={handleDrawerState}
      >
        {buttonTitle}
      </Button>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={assignUsersStyles.drawerRoot}
        onClose={handleCloseDrawer}
      >
        <HandleCloseComponent />
        {stepperValues[step]}
        <FooterComponent />
      </Drawer>
    </div>
  );
};

export default FileEditMultipleUsers;

const assignUsersStyles = {
  dataIcon: {
    paddingRight: "9px",
  },
  iconStyle: {
    color: "#1E9DF2",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px", overflow: "hidden" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  handleCloseContainer: {
    padding: "10px 32px 0px 32px",
    display: "flex",
    justifyContent: "flex-end",
  },
  innerContainer: {
    padding: "0px 32px 0px 32px",
    minWidth: "324px",
    height: "100%",
    // minHeight: "550px",
    // minHeight: "85.2%",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
  },
  rowStylesInner: {
    display: "flex",
    alignItems: "center",
    // borderBottom: "1px solid #E8E8E9",
  },
  rowStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E9",
    cursor: "pointer",
  },
  innerRowsStyles: { display: "flex", alignItems: "center", cursor: "pointer" },
  formLabel: { "& .MuiTypography-root": { fontSize: "11px" } },
  buttons: {
    textTransform: "Capitalize",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
    fontSize: "23px",
    lineHeight: "32.2px",
    letterSpace: "0.20px",
    maxWidth: "219px",
    minWidth: "219px",
    marginTop: "0px",
  },
  accordionLabels: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.8px",
    letterSpace: "0.20px",
    paddingLeft: "15px",
  },
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
  resetBtn: {
    background: "transparent",
    cursor: "pointer",
    border: "none",
    color: "#1E9DF2",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18.2px",
    fontFamily: "IBM Plex Sans",
    padding:"0px",
  },
  resetTitle: {
    background: "transparent",
    cursor: "pointer",
    border: "none",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18.2px",
    fontFamily: "IBM Plex Sans",
  },
  contentContainerStyles: {
    // maxHeight: "430px",
    minHeight: "430px", overflow: "auto",
    flexGrow: "1"
  },
};
