/* eslint-disable */
import React, { useState } from "react";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";

// Icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import STATIC_ROUTES from "../../../route/STATIC_ROUTES";
import { companyServices } from "../../../services/company";
import NewConfirmDialog, { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";
import { toast_notifications } from "../../../utils/notifications";

const CompanyActionsColumn = ({ company }) => {
    //   console.log("This is the company data", company);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
    const open = Boolean(anchorEl);

    const queryClient = useQueryClient();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setIsDeleteDialogOpen(false);
    };

    const deleteCompanyMutation = useMutation({
        mutationFn: companyServices.deleteCompany,
        onSuccess: (data) => {
            queryClient.invalidateQueries(["plainCompanies"]);
            toast_notifications.success(data?.data?.message);
        },
        onError: (data) => {
            toast_notifications.error(data?.data?.message);
        },
    });

    function handleDeleteCompany() {
        const teamIds = company?.Teams?.map((team) => team?.id);
        deleteCompanyMutation.mutate({ companyId: company?.id, teamIds: teamIds });
    }

    return (
        <div>
            <IconButton
                id="project-actions"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="project-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "project-actions",
                }}
                sx={menu.menuStyles}
            >
                <PermissionWrapper allowedPermission={PermissionsSchema.companies.delete_company}>
                    <MenuItem onClick={() => setIsDeleteDialogOpen(true)}>
                        <DeleteForeverIcon sx={menu.iconStyles} />
                        <span style={menu.actions}>Delete</span>
                    </MenuItem>
                </PermissionWrapper>
            </Menu>
            <NewConfirmDialog
                open={isDeleteDialogOpen}
                onClose={handleClose}
                action={handleDeleteCompany}
                data={{ name: company?.name }}
                type={confirmTypes.deleteCompany}
            />
        </div>
    );
};

export default CompanyActionsColumn;

const menu = {
    menuStyles: {
        "& .MuiPaper-root": {
            borderRadius: "8px",
            background: "#FFF",
            boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
        },
    },
    actions: {
        color: "#1B1E1F",
        fontFamily: "IBM Plex Sans",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "0.14px",
        paddingLeft: "4px",
    },
    iconStyles: {
        color: "#D1D2D2",
    },
};

const muiDialogStyles = {
    dialogTitle: {
        color: "#1B1E1F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "44.8px",
        letterSpacing: "-0.64px",
        maxWidth: "60%",
        alignSelf: "center",
        padding: "48px 24px 0px 24px",
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "48px 0px",
    },
    btn: {
        borderRadius: "2px",
        background: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        color: "white",
        "&:hover": {
            background: "#1E9DF2",
        },
    },
    btnCancel: {
        color: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        marginTop: "4px",
    },
};
