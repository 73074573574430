export const ProductsCardContent = [
  {
    id: 0,
    title: "Software: MotCos OS & GUI",
    subTitle:
      "MotCoS OS is a ready-to-use system which includes all basic motor control and communication functions.",
    listItems: [
      "MotCoS OS is modular and can be expanded by customized functions.",
      "MotCoS OS provides a standard interface between the application software and the hardware.",
    ],
  },
  {
    id: 1,
    title: "Functions included in MotCoS OS",
    subTitle: "",
    listItems: [
      "High optimized motor control algorithm (for example sensor-less FOC based control for 3ph BLDC)",
      "Start and initialization",
      "Motor control: start, speed/torque control, stop, ..",
      "Diagnostic and error management: Stall detection and shut-down, temperature, current, voltage, ..",
      "Basic communication functions (LIN stack, ..)",
      "Application interface including task control",
      "Hardware abstraction layer optimized for target controll",
    ],
  },
];
