/* eslint-disable */

import { Button, Dialog } from "@mui/material";
import { ExtensionsColorSchemaResolver } from "../../../components/cards/FilesCard/extensionsColorSchemaResolver";
import { FileStatusBox } from "../../../components/widgets/FileStatus/FileStatusColorSchemaResolver";
import { filesService } from "../../../services/files";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import getTheUserState from "../../../utils/userState";
import { toast_notifications } from "../../../utils/notifications";

const RequestFileDownloadDialog = ({
  isOpen,
  onClose,
  fileName,
  fileFormat,
  fileId,
  fileStatus,
}) => {
  const loggedInUser = getTheUserState();
  const userIdRevalidation = loggedInUser?.user?.id;
  const queryClient = useQueryClient();

  const requestFileMutation = useMutation({
    mutationFn: filesService.requestAccess,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["files-in-catalog", userIdRevalidation],
        exact: true,
      });
      toast_notifications.success(data?.data?.message);
      onClose();
    },
  });

  function handleCancel() {
    onClose();
  }

  function handleFileRequest() {
    const convertedCompanies = JSON.stringify(loggedInUser?.user?.companies);

    requestFileMutation.mutate({
      file_id: fileId,
      user: {
        user_id: loggedInUser?.user?.id,
        fullName: loggedInUser?.user?.firstName + " " + loggedInUser?.user?.lastName,
        email: loggedInUser?.user?.email,
        companies: convertedCompanies,
      },
    });
  }

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
        "& .MuiPaper-root": { minWidth: "645px" },
        borderRadius: "16px",
      }}
    >
      <div style={requestDialogStyles.contentStyles}>
        <p style={requestDialogStyles.title}>Request File Download</p>
        <p style={requestDialogStyles.textInfo}>
          You are attempting to download files that require permission from our site administrators.
          To request access, please click the "Send Request" button. Your request will be reviewed
          by our team, and you will receive an email notification once permission has been granted
          or denied.
        </p>
        <p style={requestDialogStyles.filesInfo}>
          Files to download which you need to send a request:
        </p>
        <div style={{ display: "flex", borderBottom: "1px solid #F4F4F4" }}>
          <p style={{ alignSelf: "center", width: "10px" }}>{FileStatusBox(fileStatus)}</p>
          <p style={{ padding: "0px 16px 0px 8px" }}>
            {fileName?.length >= 70 ? fileName.slice(0, 70).concat("...") : fileName}
          </p>
          <p>{ExtensionsColorSchemaResolver(fileFormat)}</p>
        </div>
      </div>
      <div style={requestDialogStyles.actionsContainer}>
        <Button sx={requestDialogStyles.cancelButton} onClick={handleCancel}>
          Cancel
        </Button>
        <Button sx={requestDialogStyles.createButton} onClick={handleFileRequest}>
          Send Request
        </Button>
      </div>
    </Dialog>
  );
};

export default RequestFileDownloadDialog;

const requestDialogStyles = {
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    margin: "0px",
    textAlign: "center",
  },

  textInfo: {
    color: "#1B1E1F",
    ontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    letterSpacing: "0.14px",
    padding: "24px 0px",
    margin: "0px",
  },
  actionsContainer: {
    display: "flex",
    borderTop: "2px solid #F4F4F4",
    justifyContent: "space-between",
    padding: "16px 40px",
  },
  contentStyles: {
    padding: "40px",
  },
  createButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },

  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
  filesInfo: {
    color: "#1B1E1F",
    ontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "0.14px",
    padding: "0px 0px 8px 0px",
    margin: "0px",
  },
};
