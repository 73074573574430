/* eslint-disable */
import "../../../../components/files/files_select_time/fileSelectionTime.css";
import { useEffect, useState } from "react";
import { Drawer, Checkbox, FormControlLabel, Button } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDateTime2, getDateTime3 } from "../../../../utils/misc";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";
import { useFormContext } from "react-hook-form";

const FileEditSetTimeToUse = () => {
  const { setValue, watch } = useFormContext();
  const defaultTimeToUse = watch("time_to_use");

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUndefined, setIsUndefined] = useState(false);
  const [date, setDate] = useState("");

  useEffect(() => {
    if (defaultTimeToUse !== "undefined") {
      const newDate = dayjs(defaultTimeToUse);
      setDate(newDate);
      setIsUndefined(false);
    } else {
      setDate(defaultTimeToUse);
      setIsUndefined(true);
    }
  }, [defaultTimeToUse]);

  function handleDrawerState() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function handleUndefinedAction() {
    setIsUndefined(!isUndefined);
  }

  function handleCancelAction() {
    setIsDrawerOpen(!isDrawerOpen);

    if (date && date !== "") {
      setIsUndefined(false);
      const newDate = dayjs(defaultTimeToUse);
      setDate(newDate);
    } else {
      setIsUndefined(true);
      setDate("");
    }
  }

  function handelAcceptAction() {
    setIsDrawerOpen(!isDrawerOpen);

    if (isUndefined) {
      setValue("time_to_use", "undefined", { shouldDirty: true });
    } else {
      const newDate = getDateTime3(date);
      setValue("time_to_use", newDate, { shouldDirty: true });
    }
  }

  const formatDate = (date) => {
    if (!date) return "";
    setDate(date);
  };

  function formatDefaultTime(time) {
    if (time !== "undefined") {
      const date = dayjs(defaultTimeToUse);
      const newDate = getDateTime2(date);
      return newDate;
    }

    return time;
  }

  return (
    <>
      <Button
        variant="outlined"
        color="info"
        sx={{ textTransform: "capitalize", fontSize: "11px" }}
        onClick={handleDrawerState}
      >
        <span className="file_selection_title">{formatDefaultTime(defaultTimeToUse)}</span>
        <CalendarMonthIcon fontSize="small" sx={selectTimeStyles.iconStyle} />
      </Button>
      <Drawer anchor="right" open={isDrawerOpen} sx={selectTimeStyles.drawerRoot}>
        <div className="file_drawer_root">
          <div className="file_drawer_header_container">
            <div>
              <span className="file_selection_title" style={{ color: "#49454F" }}>
                Time to use
              </span>
            </div>
            <div className="file_drawer_header_container_data">
              <FormControlLabel
                control={
                  <Checkbox size="small" checked={isUndefined} onClick={handleUndefinedAction} />
                }
                label="undefined"
                sx={selectTimeStyles.formLabel}
              />
            </div>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                disablePast={true}
                disabled={isUndefined}
                value={date}
                onChange={formatDate}
                displayWeekNumber
                showDaysOutsideCurrentMonth
                sx={{ "& .MuiDialogActions-root": { display: "none" } }}
              />
            </LocalizationProvider>
          </div>
          <div className="file_drawer_footer">
            <Button variant="text" sx={selectTimeStyles.buttons} onClick={handleCancelAction}>
              Cancel
            </Button>
            <Button variant="text" sx={selectTimeStyles.buttons} onClick={handelAcceptAction}>
              Ok
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default FileEditSetTimeToUse;

const selectTimeStyles = {
  iconStyle: {
    color: "#1E9DF2",
    paddingLeft: "10px",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  formLabel: { "& .MuiTypography-root": { fontSize: "11px" } },
  buttons: {
    textTransform: "Capitalize",
  },
};
