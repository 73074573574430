/* eslint-disable */

import { Button, Typography } from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  BorderColor as BorderColorIcon,
  DeleteForever as DeleteForeverIcon,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { newsServices } from "../../../services/news";
import parse from "html-react-parser";
import { getDateTime2 } from "../../../utils/misc";
import { toast_notifications } from "../../../utils/notifications";
import { useState } from "react";
import NewsDialogs, {
  newsDialogType,
} from "../../../components/dialogs/NewConfirmDialog/NewsDialogs";
import STATIC_ROUTES from "../../../route/STATIC_ROUTES";
// import "./singleNews.css";
import "./singleNews.css";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";

const SingleNews = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [openDelete, setOpenDelete] = useState(false);

  const {
    data: news,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["newsById", id],
    queryFn: () => newsServices.getNewsById(id),
  });

  // const queryClient = useQueryClient();

  // const addFavouritesMutation = useMutation({
  //     mutationFn: newsServices.addToFavourites,
  //     onSuccess: (data) => {
  //         queryClient.invalidateQueries(["newsById"]);
  //         toast_notifications.success(data?.data?.message);
  //     },
  //     onError: (data) => {
  //         toast_notifications.error(data?.data?.message);
  //     },
  // });

  // const handleAddFavourites = () => {
  //     addFavouritesMutation.mutate({ id: news?.data?.id, is_favourite: !news?.data?.is_favourite });
  // }

  const deleteNewsMutation = useMutation({
    mutationFn: newsServices.deleteNews,
    onSuccess: (data) => {
      navigate(`/dashboard`);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleDeleteNews = () => {
    deleteNewsMutation.mutate({ id: news?.data?.id });
  };

  // const AddToFavoriteButton = () => {
  //     return (
  //         <div style={{ display: "flex" }}>
  //             <Button
  //                 variant="text"
  //                 size="small"
  //                 sx={{
  //                     margin: "0px",
  //                     padding: "0px",
  //                     textTransform: "capitalize",
  //                     maxHeight: "15px",
  //                     "&:hover": {
  //                         background: "transparent",
  //                     },
  //                 }}
  //                 onClick={handleAddFavourites}
  //             >
  //                 {news?.data?.is_favourite ? <StarIcon sx={muiStyles.favouritesIcons} /> : <StarBorderIcon sx={muiStyles.favouritesIcons} />}
  //                 {news?.data?.is_favourite ? (
  //                     <p style={muiStyles.favouritesTitle}>Remove from favorites</p>
  //                 ) : (
  //                     <p style={muiStyles.favouritesTitle}>Add to favorites</p>
  //                 )}
  //             </Button>
  //         </div>
  //     );
  // }

  const handleGoToEditNews = () => {
    navigate(STATIC_ROUTES.news, { state: { type: "edit", id: news?.data?.id } });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <div style={{ overflow: "auto", flex: 1, padding: "24px 40px", marginBottom: "24px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button sx={muiStyles.backBtn} onClick={() => navigate(`/dashboard`)}>
            <ArrowBackIcon />
            Back to Dashboard
          </Button>
          <div>
            <PermissionWrapper allowedPermission={PermissionsSchema.news.update_news}>
              <Button sx={muiStyles.editBtn} onClick={handleGoToEditNews}>
                <span style={{ alignSelf: "center" }}>Edit</span>
                <BorderColorIcon sx={muiStyles.editIcon} />
              </Button>
            </PermissionWrapper>
            <PermissionWrapper allowedPermission={PermissionsSchema.news.delete_news}>
              <Button sx={muiStyles.editBtn} onClick={() => setOpenDelete(true)}>
                <span style={{ alignSelf: "center" }}>Delete</span>
                <DeleteForeverIcon sx={muiStyles.editIcon} />
              </Button>
            </PermissionWrapper>
          </div>
        </div>
        {!isLoading && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "center", margin: "16px 0px" }}>
              <Typography sx={muiStyles.createdAt}>
                <span style={muiStyles.createdAtSpan}>Published:</span>{" "}
                {getDateTime2(news?.data?.createdAt || "--||--")}
              </Typography>
              {/* <span style={muiStyles.dotSeparator}>•</span>
                        <AddToFavoriteButton /> */}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                justifyContent: "space-between",
              }}
            >
              <p style={muiStyles.title}>{news?.data?.title}</p>
              <article className="content_article">{parse(news?.data?.content)}</article>
            </div>
          </div>
        )}
      </div>
      {openDelete && (
        <NewsDialogs
          open={openDelete}
          onClose={() => setOpenDelete(false)}
          type={newsDialogType.delete}
          action={handleDeleteNews}
        />
      )}
    </div>
  );
};

export default SingleNews;

const muiStyles = {
  backBtn: {
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    minWidth: "95px",
    justifyContent: "space-between",
    letterSpacing: "0.11px",
    gap: "6px",
  },
  editBtn: {
    fontSize: "11px",
    color: "#1E9DF2",
    textTransform: "none",
    width: "36px",
    letterSpacing: "0.11px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  editIcon: {
    fontSize: "15px",
  },
  title: {
    width: "900px",
    fontFamily: "IBM Plex Sans",
    // fontSize: "32px",
    fontSize: "clamp(0.875rem, 0.637rem + 1.191vw, 4.688rem)",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%" /* 44.8px */,
    letterSpacing: "-0.64px",
    margin: "12px 0px",
  },
  // content: {
  //     width: "900px",
  //     height: "803px",
  //     // overflowX: "hidden",
  //     // overflowY: "hidden",
  //     "& .img": {
  //         maxWidth: "900px",
  //     }
  // },
  textButtons: {
    minWidth: "140px",
    height: "54px",
    padding: "16px 32px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    color: "#1E9DF2",
  },
  containedButtons: {
    minWidth: "140px",
    height: "54px",
    padding: "16px 32px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    background: "#1E9DF2",
  },
  favouritesTitle: {
    marginBottom: "10px",
    color: "#1E9DF2",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%", //16.5px
    letterSpacing: "-0.22px",
    textAlign: "left",
    marginLeft: "4px",
  },
  favouritesIcons: {
    width: "16px",
    height: "16px",
    color: "#1E9DF2",
  },
  createdAt: {
    marginBottom: "10px",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "-0.22px",
    textAlign: "center",
    margin: "0px",
  },
  createdAtSpan: {
    marginBottom: "10px",
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "-0.22px",
    textAlign: "center",
    margin: "0px",
  },
  dotSeparator: {
    padding: "0px 8px",
    alignSelf: "center",
    color: "#8D8E8F",
  },
};
