/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Paper,
  IconButton,
  LinearProgress,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import { getAllFiles, getUploadType } from "../../../reducers/files";
import axios from "axios";
import { API_URL } from "../../../config";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { ExtensionsColorSchemaResolver } from "../../cards/FilesCard/extensionsColorSchemaResolver";
import { useQueryClient } from "@tanstack/react-query";
import { uploadTypes } from "../../files/files_container/FilesContainer";

const FileUploadIndicator = () => {
  const [expand, setExpand] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [cancelTokens, setCancelTokens] = useState([]);
  const [canceledUploads, setCanceledUploads] = useState([]);
  const [uploadCounter, setUploadCounter] = useState(0);
  const [isIndicatorOpened, setIsIndicatorOpened] = useState(true);

  const queryClient = useQueryClient();

  // Selected files from the store
  const { files, uploadType } = useSelector((globalState) => ({
    files: getAllFiles(globalState),
    uploadType: getUploadType(globalState),
  }));

  const handleCloseIndicator = () => {
    setIsIndicatorOpened(false);
  };
  // In order to track every file upload % we need to upload them separated.
  const handleUploadFiles = () => {
    setIsIndicatorOpened(true);
    setUploadCounter(0);
    files.forEach((file, index) => uploadFile(file, index));
    setCanceledUploads([]);

    const timer = setTimeout(() => {
      if (isIndicatorOpened) {
        handleCloseIndicator();
      }
    }, 30000);

    return () => clearTimeout(timer);
  };

  // Handle upload of the files.
  const uploadFile = async (singleFile, index) => {
    const fd = new FormData();

    const { file, settings } = singleFile;

    // Append the file with a unique key
    fd.append(`file${index} + 1`, file);

    // Append the settings as a JSON string with the same key, we need this to match settings coresponding to the file on the server.
    fd.append(`file${index} + 1`, JSON.stringify(settings));

    // Create a new CancelToken source
    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokens((prevTokens) => ({ ...prevTokens, [index]: cancelTokenSource }));

    try {
      const response = await axios.post(API_URL + "/files/upload", fd, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [index]: percentCompleted,
          }));
        },
        cancelToken: cancelTokenSource.token,
      });
      setUploadCounter((prevCount) => prevCount + 1);

      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        queryClient.invalidateQueries(["files"]);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setCanceledUploads((prevCanceledUploads) => [...prevCanceledUploads, index]);
      } else {
        console.error("Error uploading file", error);
      }
    }
  };

  // Handle upload of the files from a project.
  const uploadFilesFromProject = async (singleFile, index) => {
    const fd = new FormData();

    const { file, settings } = singleFile;

    // Append the file with a unique key
    fd.append(`file${index} + 1`, file, encodeURIComponent(file.name));

    // Append the settings as a JSON string with the same key, we need this to match settings coresponding to the file on the server.
    fd.append(`file${index} + 1`, JSON.stringify(settings));

    // Create a new CancelToken source
    const cancelTokenSource = axios.CancelToken.source();
    setCancelTokens((prevTokens) => ({ ...prevTokens, [index]: cancelTokenSource }));

    try {
      const response = await axios.post(API_URL + "/files/upload/project", fd, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [index]: percentCompleted,
          }));
        },
        cancelToken: cancelTokenSource.token,
      });
      setUploadCounter((prevCount) => prevCount + 1);
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        queryClient.invalidateQueries(["projectById"]);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setCanceledUploads((prevCanceledUploads) => [...prevCanceledUploads, index]);
      } else {
        console.error("Error uploading file", error);
      }
    }
  };

  // Handle upload files from a project
  const handleUploadFilesFromProject = () => {
    setIsIndicatorOpened(true);
    setUploadCounter(0);
    files.forEach((file, index) => uploadFilesFromProject(file, index));
    setCanceledUploads([]);

    const timer = setTimeout(() => {
      if (isIndicatorOpened) {
        handleCloseIndicator();
      }
    }, 30000);

    return () => clearTimeout(timer);
  };

  // Cancel the corresponding upload using the cancel token
  const handleCancel = (index) => {
    if (cancelTokens[index]) {
      cancelTokens[index].cancel("Upload canceled by user");
      setCancelTokens((prevTokens) => {
        const updatedTokens = { ...prevTokens };
        delete updatedTokens[index];
        return updatedTokens;
      });
      setCanceledUploads([]);
    }
  };

  useEffect(() => {
    if (files && files?.length) {
      if (uploadType === uploadTypes.fManager) {
        handleUploadFiles();
      } else {
        handleUploadFilesFromProject();
      }
    }
  }, [files]);

  const filedDownloadIndicatorStyles = {
    paperContainer: {
      position: "fixed",
      right: 25,
      bottom: 50,
      minWidth: "292px",
      minHeight: expand ? "260px" : "77px",
      maxHeight: "405px",
      overflowY: "auto",
      transition: "all 0.5s",
      zIndex: 10000,
    },
    expandButton: {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      transform: expand ? "rotateX(180deg)" : "rotateX(0deg)",
      transition: "ease-in 0.5s",
    },
    title: {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "22.4px",
      letterSpacing: "-0.32px",
      textTransform: "capitalize",
      padding: "0px",
      margin: "0px",
    },
    title2: {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      margin: "7px 0px",
    },
  };

  const progressValue = files?.length === 0 ? 0 : (uploadCounter / files?.length) * 100;

  if (files && files?.length && isIndicatorOpened) {
    return (
      <Paper elevation={3} sx={filedDownloadIndicatorStyles.paperContainer}>
        {expand ? (
          <div style={{ display: "flex", flexDirection: "column", margin: "10px 20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={filedDownloadIndicatorStyles.title}>Uploading</p>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                  onClick={() => setExpand(!expand)}
                  style={filedDownloadIndicatorStyles.expandButton}
                >
                  <KeyboardArrowUpIcon />
                </button>
                <button
                  onClick={handleCloseIndicator}
                  style={filedDownloadIndicatorStyles.expandButton}
                >
                  <CloseIcon color="disabled" />
                </button>
              </div>
            </div>
            <p style={filedDownloadIndicatorStyles.title2}>
              {uploadCounter} out of {files?.length}
            </p>
            {files?.map((file, index) => {
              return (
                <UploadingComponent
                  index={index}
                  key={file?.name}
                  status={`${uploadProgress[index] || 0}%`}
                  value={uploadProgress[index]}
                  file={file}
                  handleCancel={handleCancel}
                  canceledUploads={canceledUploads}
                />
              );
            })}
          </div>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", margin: "10px 20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={filedDownloadIndicatorStyles.title}>Uploading</p>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                  onClick={() => setExpand(!expand)}
                  style={filedDownloadIndicatorStyles.expandButton}
                >
                  <KeyboardArrowUpIcon />
                </button>
                <button
                  onClick={handleCloseIndicator}
                  style={filedDownloadIndicatorStyles.expandButton}
                >
                  <CloseIcon color="disabled" />
                </button>
              </div>
            </div>
            <p style={filedDownloadIndicatorStyles.title2}>
              {uploadCounter} out of {files?.length}
            </p>
            <LinearProgress
              variant="determinate"
              value={progressValue}
              sx={{ background: "#F4F4F4" }}
            />
          </div>
        )}
      </Paper>
    );
  }

  return null;
};

export default FileUploadIndicator;

const UploadingComponent = ({ index, file, value, status, handleCancel, canceledUploads }) => {
  // Since there was an issue we have give a check on the array with the canceled uploads
  function isUploadCanceled(index) {
    if (Array.isArray(canceledUploads) && canceledUploads.includes(index)) {
      return true;
    }

    return false;
  }

  const splitFileName = file?.file?.name.split(".");
  const fileName = splitFileName[0];
  const fileExtension = splitFileName[1];

  return (
    <div
      style={{
        display: "flex",
        borderBottom: "2px solid #F4F4F4",

        justifyContent: "space-between",
        padding: "8px 0px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* <CircularProgress variant="determinate" value={value} color="success" size={20} /> */}
        <Box sx={{ position: "relative", display: "inline-flex" }}>
          <CircularProgress
            variant="determinate"
            color={isUploadCanceled(index) ? "error" : "success"}
            value={value}
            sx={{ width: "30px!important", height: "30px!important" }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {status !== "100%" ? (
                <IconButton onClick={() => handleCancel(index)}>
                  <CloseIcon color="error" fontSize="small" />
                </IconButton>
              ) : (
                <IconButton>
                  <CheckIcon fontSize="small" />
                </IconButton>
              )}
            </Typography>
          </Box>
        </Box>
        <span style={{ paddingLeft: "10px" }}>{fileName}</span>
      </div>
      <div
        style={{
          margin: "0px",
          padding: "0px",
          display: "flex",
          justifyContent: "space-between",
          minWidth: "110px",
          alignItems: "center",
        }}
      >
        {ExtensionsColorSchemaResolver(fileExtension)}
        <p style={{ color: status === "Ready" ? "#81CE7B" : "black" }}>{status}</p>
      </div>
    </div>
  );
};
