import React, { useEffect, useState } from "react";
import { Box, Paper, Typography } from "@mui/material";
import SINGUP_BACKGROUND from "../../assets/img/landing_main_background.png";
import ToolsTable from "../../components/tables/ToolsTable";
import { getToolDataById } from "../../services/tools";

function ShareDownloadLink() {
  const [data, setData] = useState([]);
  const [invalidUrl, setInvalidUrl] = useState("");

  // Decode the file data
  async function decodeData() {
    const urlParams = new URLSearchParams(window.location.search);
    const encodedData = urlParams.get("data");
    try {
      const decodedData = JSON.parse(window.atob(encodedData));
      if (decodedData) {
        const result = getToolDataById(decodedData.id);
        result
          .then((res) => res)
          .then((fileData) => setData(fileData))
          .catch((err) => console.log(err));
      }
    } catch (error) {
      setInvalidUrl("Invalid Download Link");
    }
  }

  useEffect(() => {
    decodeData();
  }, []);

  const fileData = [data];

  return (
    <div style={styles.linkWrapper}>
      <Typography componet="div" sx={styles.innerWrapper}>
        {invalidUrl ? (
          <Box sx={styles.boxContainer}>
            <Paper elevation={10} sx={styles.paperContainer}>
              {invalidUrl}
            </Paper>
          </Box>
        ) : (
          <ToolsTable tools={fileData} />
        )}
      </Typography>
    </div>
  );
}

export default ShareDownloadLink;

const styles = {
  linkWrapper: {
    minHeight: "800px",
    background: "#DDE5EF",
    overflowX: "hidden",
    overflowY: "hidden",
    padding: 20,
    backgroundImage: `url(${SINGUP_BACKGROUND})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    fontWeight: "300",
  },
  innerWrapper: {
    width: "85%",
    margin: "0 auto",

    "& .MuiPaper-root": {
      padding: "0px 24px",
    },
    "& thead tr th div": {
      color: "#1f577b",
      fontSize: "1.1rem",
      fontWeight: "500",
    },
    "& tbody tr td div": {
      color: "#5690b5",
      fontSize: "0.875rem",
    },
  },
  boxContainer: { display: "flex", justifyContent: "center", margin: "2vh 0vh" },
  paperContainer: {
    minWidth: "30%",
    textAlign: "center",
    padding: "2vh !important",
    fontSize: "25px",
    fontWeight: "400",
    color: "red",
  },
};
