/* eslint-disable */
import { Tooltip } from "@mui/material";
import getTheUserState from "../../utils/userState";

const BlockPermissionComponent = ({ children, allowedPermission }) => {
  // We get the user state from the global state
  const user = getTheUserState();

  // List user permissions
  const userPermissions = user?.user?.role.permissions;

  // Check if the allowed permission exists in the user permissions
  const foundPermission = userPermissions.find(
    (permission) => permission.permission_name === allowedPermission && permission.status === true
  );
  // console.log("foundPermission", foundPermission);
  // Check the status of the permission
  if (foundPermission?.status) {
    return children;
  }
  return (
    <Tooltip
      title={
        <span
          style={{
            color: "salmon",
            fontWeight: "bold",
            fontSize: "15px",
            fontFamily: "IBM Plex sans",
          }}
        >
          You dont have permissions for this action!
        </span>
      }
      // arrow
      placement="bottom-start"
      followCursor
    >
      <div style={{ cursor: "not-allowed", backgroundColor: "whitesmoke" }}>
        <button disabled style={{ border: "none" }}>
          {children}
        </button>
      </div>
    </Tooltip>
  );
};

export default BlockPermissionComponent;
