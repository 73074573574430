/* eslint-disable */

import { Close as CloseIcon } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FilesCard, { filesCardType } from "../../cards/FilesCard/FIlesCard";
import { useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import axios from "axios";
import { API_URL } from "../../../config";

import LinearProgress from "@mui/material/LinearProgress";

const FileUpdate = ({ open, onClose, file: oldFile }) => {
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [cancelToken, setCancelToken] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isAborted, setIsAborted] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    setFiles(files);
    setRejectedFiles(rejectedFiles);
    setIsAborted(false);
  }, [files, rejectedFiles]);

  const handleClose = () => {
    setFiles([]);
    setRejectedFiles([]);
    onClose();
    setIsAborted(false);
  };

  // Select Files and put them in the accepted/rejected categories.
  const onDrop = (data, rej) => {
    const accepted = [];
    const rejected = [];

    if (data) {
      data.forEach((file) => {
        let renamedFile;
        const spaceCount = (file?.name.match(/\s/g) || []).length;
        if (spaceCount > 0) {
          const updatedFile = file?.name.replaceAll(" ", "-");
          renamedFile = new File([file], updatedFile, { type: file?.type });
        }
        const fileObj = {
          file: spaceCount > 0 ? renamedFile : file,
          settings: {
            id: oldFile?.id,
            status: oldFile?.status,
            description: oldFile?.description,
            maturity_level: oldFile?.maturity_level,
            companies: oldFile?.FileConnections,
            time_to_use: oldFile?.time_to_use,
            uploader_id: oldFile?.uploader_id,
            is_private: oldFile?.is_private,
            is_general: oldFile?.is_general,
            terms_id: oldFile?.terms_id,
          },
        };
        accepted.push(fileObj);
      });
    }

    if (rej) {
      rej?.forEach((file) => {
        rejected.push({ file });
      });
    }

    setFiles([...accepted]);
    setRejectedFiles([...rejected]);
  };

  // Custom file name length validation
  function nameLengthValidator(file) {
    const maxLength = 40;
    if (file?.name?.length > maxLength) {
      return {
        code: "name-too-large",
        message: `Name is larger than ${maxLength} characters`,
      };
    }
    return null;
  }

  // Custom file name special characters validation
  const forbiddenCharacterValidator = (file) => {
    // const forbiddenCharacters = /^(?!.*\s)[A-Za-z0-9_-]+$/;
    const forbiddenCharacters =
      /^(?!.*\s{2,})(?!.*[^A-Za-z0-9 _()\-ÄÖÜäöüß])(?!^\s)(?!.*\s$)[A-Za-z0-9 _()\-ÄÖÜäöüß]+$/;

    const currentFileName = file?.name.split(".")[0];
    if (!forbiddenCharacters.test(currentFileName)) {
      return {
        code: "invalid-file-name",
        message: "Only allowed special characters: - and _",
      };
    }
    return null;
  };

  function combinedValidator(file) {
    const validators = [nameLengthValidator, forbiddenCharacterValidator]; // Add More Custom Validators here

    for (let validator of validators) {
      const error = validator(file);
      if (error) {
        return error;
      }
    }

    return null;
  }

  // Here we can add more options to the dropzone component
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
    fileRejections,
    open: openUpload,
  } = useDropzone({
    maxFiles: 1,
    onDrop,
    accept: {
      "text/html": [".html", ".htm", ".txt"],
      // "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "image/png": [".png"],
      "image/svg+xml": [".svg"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls", ".docx"],
      "text/csv": [".csv"],
    },
    validator: combinedValidator,
    noClick: true,
    noKeyboard: true,
  });

  // Remove file from the settings table
  function handleRemove(row, index) {
    const updatedAcceptedFiles = [...files];
    updatedAcceptedFiles.splice(index, 1);
    setFiles(updatedAcceptedFiles);
    setIsAborted(false);
  }

  // Handle upload of the files.
  const uploadFile = async (singleFile) => {
    setIsUpdating(true);
    const fd = new FormData();

    const { file, settings } = singleFile;

    // Append the file with a unique key
    fd.append(`file + 1`, file);

    // Append the settings as a JSON string with the same key, we need this to match settings coresponding to the file on the server.
    fd.append(`file + 1`, JSON.stringify({ id: settings.id, uploader_id: settings.uploader_id }));

    // Create a new CancelToken source
    const cancelTokenSource = axios.CancelToken.source();
    setCancelToken(cancelTokenSource);

    try {
      const response = await axios.post(API_URL + "/files/update", fd, {
        responseType: "blob",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percentCompleted = Math.round((loaded * 100) / total);
          setUploadProgress(percentCompleted);
        },
        cancelToken: cancelTokenSource.token,
      });

      const responseOK = response && response.status === 200 && response.statusText === "OK";

      if (responseOK) {
        setIsUpdating(false);
        queryClient.invalidateQueries(["fileById"]);
        toast_notifications.success("File uploaded and updated successfully"); //response?.data?.message
        handleClose();
        setUploadProgress(0);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        setCancelToken({});
        toast_notifications.error("Upload canceled by user"); //response?.data?.message
        console.error("Error uploading file", error);
        setIsUpdating(false);
        setUploadProgress(0);
      } else {
        toast_notifications.error("File with that name already exists!"); //response?.data?.message
        console.error("Error uploading file", error);
        setIsUpdating(false);
        setUploadProgress(0);
      }
    }
  };

  const handleFileUpdate = () => {
    uploadFile(files[0]);
    setIsAborted(false);
  };

  const handleCancel = () => {
    if (cancelToken) {
      cancelToken.cancel("Upload canceled by user");
      setCancelToken({});
      setIsAborted(true);
      setUploadProgress(0);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={updateStyles.dialog}>
      <div
        style={{
          alignSelf: "flex-end",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon color="disabled" />
        </IconButton>
      </div>
      <DialogTitle sx={updateStyles.dialogTitle}>Replacing File</DialogTitle>
      <Typography sx={updateStyles.text}>
        Are you sure you want to replace this file? The previous file version will be deleted.
      </Typography>
      <DialogContent sx={{ padding: "0px", marginTop: "20px" }}>
        <div>
          <div
            style={{
              border: "1px solid #D1D2D2",
              borderStyle: "dashed",
              borderRadius: "4px",
              borderWidth: "2px",
              backgroundColor: "white",
              width: "650px",
              height: "148px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "20px 0px",
              backgroundColor: isDragActive ? "#f0f0f0" : "white",
              "&:hover": {
                backgroundColor: "#f0f0f0",
              },
              overflowX: "hidden",
            }}
          >
            <div
              {...getRootProps()}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <input {...getInputProps()} />
              <p
                style={{
                  textAlign: "center",
                }}
              >
                Drag and drop file or{" "}
                <span style={{ color: "#1E9DF2", cursor: "pointer" }} onClick={openUpload}>
                  click here to upload
                </span>
              </p>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {files?.length !== 0 && (
                  <div>
                    {files?.map((file, index) => {
                      return (
                        <div
                          key={index}
                          style={{ display: "flex", justifyContent: "space-between" }}
                        >
                          <IconButton onClick={handleRemove}>
                            <CloseIcon color="disabled" />
                          </IconButton>
                          <FilesCard key={index} file={file} type={filesCardType.accepted} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              {rejectedFiles.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "487px",
                    height: "72px",
                  }}
                >
                  <p style={updateStyles.textRejectRed}>
                    The file format you are trying to upload is not supported by this site.{" "}
                  </p>
                  <p style={updateStyles.textReject}>
                    Please upload a file in one of the following supported formats:
                  </p>
                  <p style={updateStyles.textReject}>DOCX, PNG, SVG, PDF, TXT, XLS, CSV</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Button variant="text" sx={updateStyles.buttonCancel} onClick={handleClose}>
          Cancel
        </Button>
        <div style={{ position: "relative" }}>
          {!isUpdating ? (
            <Button
              disabled={!(files.length > 0)}
              variant="contained"
              sx={isAborted ? updateStyles.buttonUpdateAborted : updateStyles.buttonUpdate}
              onClick={handleFileUpdate}
            >
              Replace File
            </Button>
          ) : (
            <Button
              disabled={!(files.length > 0)}
              variant="contained"
              sx={updateStyles.buttonUpdate}
              onClick={() => handleCancel()}
            >
              <span style={{ zIndex: "999" }}>{`${uploadProgress}% Abort`} </span>
              <LinearProgress
                variant="determinate"
                value={uploadProgress}
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: "0",
                  zIndex: "0",
                  minWidth: "160px",
                  borderRadius: "2px",
                }}
              />
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default FileUpdate;

const updateStyles = {
  dialog: {
    padding: "48px 64px",
    "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
    // "& .MuiBackdrop-root": { background: "#CCE9F5" },
    "& .MuiPaper-root": {
      width: "778px",
      minWidth: "fit-content",
      height: "434px",
      boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
      padding: "48px 64px",
      borderRadius: "16px",
    },
  },
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-2%",
    alignSelf: "center",
    padding: "0px",
  },
  text: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-1%",
    alignSelf: "center",
    marginTop: "20px",
  },
  buttonUpdate: {
    borderRadius: "2px",
    marginBottom: "20px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-2%",
    textTransform: "none",
    color: "white",
    padding: "16px 32px",
    zIndex: "100",
    minWidth: "160px",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  buttonUpdateAborted: {
    borderRadius: "2px",
    marginBottom: "20px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-2%",
    textTransform: "none",
    color: "white",
    padding: "16px 32px",
    zIndex: "100",
    minWidth: "160px",
    background: "red",
    "&:hover": {
      background: "#8a0303",
    },
  },
  buttonCancel: {
    borderRadius: "2px",
    marginBottom: "20px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-2%",
    textTransform: "none",
    color: "#1E9DF2",
    padding: "16px 32px",
  },
  textRejectRed: {
    color: "red",
    margin: "0px",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-1%",
    alignSelf: "center",
  },
  textReject: {
    margin: "0px",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-1%",
    alignSelf: "center",
  },
};
