/* eslint-disable */
import { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import ProjectCardComponent from "./ProjectCardComponent";
import SearchIcon from "@mui/icons-material/Search";

function UserProjectsComponent({ projects, userID }) {
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    const searchValue = event.target.value;
    setSearchText(searchValue.toLowerCase());
  };

  const filteredData = projects?.filter((item) => {
    const idString = String(item.id);
    const matchSearch =
      idString.includes(searchText) || item.name.toLowerCase().includes(searchText);
    return matchSearch;
  });

  return (
    <div style={userProjectsStyle.container}>
      <p style={userProjectsStyle.title}>User Projects</p>
      <div style={{ padding: "0px 24px" }}>
        <TextField
          variant="standard"
          placeholder="Search..."
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div style={userProjectsStyle.cards_container}>
        {filteredData?.map((project) => {
          return (
            <ProjectCardComponent
              id={project.id}
              name={project.name}
              company={project.companyName}
              userId={userID}
              key={project.id}
            />
          );
        })}
        {!filteredData?.length && (
          <p
            style={{
              padding: "12px 24px",
              textAlign: "center",
              fontFamily: "IBM Plex Sans",
              fontSize: "11px,",
              fontStyle: "normal",
              color: "red",
            }}
          >
            No data for this search
          </p>
        )}
      </div>
    </div>
  );
}

export default UserProjectsComponent;

const userProjectsStyle = {
  container: {
    flex: "1 1 auto",
    overflow: "hidden",
    background: "white",
    display: "flex",
    flexDirection: "column",
  },
  cards_container: {
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    background: "white",
    height: "100%",
  },
  title: {
    margin: "20px 20px",
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
  },
};
