/* eslint-disable */

import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProjectsCard, { cardTypes } from "../cards/ProjectsCard/ProjectsCard";

const UserProjectsModal = ({ open, setOpen, projects }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
        "& .MuiPaper-root": { borderRadius: "16px" },
      }}
    >
      <div
        style={{
          alignSelf: "flex-end",
          // marginBottom: "-30px",
          padding: "10px 10px 0px 10px",
        }}
      >
        <IconButton onClick={handleClose}>
          <CloseIcon color="disabled" />
        </IconButton>
      </div>
      <DialogContent sx={modalStyles.contentStyles}>
        <DialogTitle sx={modalStyles.dialogTitle}>User {projects?.user} projects</DialogTitle>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {projects?.Projects.map((prj) => {
            return (
              <ProjectsCard
                type={cardTypes.projects}
                key={prj?.id}
                id={prj?.id}
                name={prj?.name}
                isArchived={prj?.archived}
                isFavorite={prj?.isFavorite}
                market={prj?.marketSelect}
                created={prj?.created}
              />
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UserProjectsModal;

const modalStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    // maxWidth: "60%",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    margin: "0px 64px 48px 64px",
    padding: "0px",
  },
};
