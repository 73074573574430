/* eslint-disable */

const VerificationtStatusComponent = (status) => {
  const colorSchema = {
    draft: {
      color: "#4D69AC",
      textAlign: "center",
      padding: "0px 4px",
      width: "fit-content",
      textTransform: "capitalize",
      background: "#EEF3FF",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
    verified: {
      color: "#2D9933",
      textAlign: "center",
      padding: "0px 4px",
      width: "fit-content",
      borderRadius: "2px",
      background: "#EAF5EB",
      textTransform: "capitalize",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
    underReview: {
      color: "#D6AC2F",
      textAlign: "center",
      padding: "0px 4px",
      width: "fit-content",
      background: "#FCF8EC",
      textTransform: "capitalize",
      fontSize: "11px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
      fontFamily: "IBM Plex Sans",
    },
  };

  return <p style={colorSchema[status]}>{status === "underReview" ? "Under Review" : status}</p>;
};

export default VerificationtStatusComponent;
