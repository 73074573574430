/* eslint-disable */
import "./productCardComponent.css";

export const types = { software: "img_side_software", functions: "img_side_functions" };

function ProductCardComponent({ title, subTitle, listItems, type }) {
  return (
    <div
      style={{
        background: type === "img_side_functions" ? "#FFFFFF" : "transparent",
        marginBottom: type === "img_side_functions" ? "0px" : "42px",
      }}
    >
      <div className="container">
        <div className="img_wrapper">
          <img className={type} />
        </div>
        <div className="content_side">
          <p className="content_side_title">{title}</p>
          {subTitle ? <p className="content_side_text">{subTitle}</p> : null}
          <ul>
            {listItems?.map((item, index) => {
              return (
                <li key={index} className="content_side_text">
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ProductCardComponent;
