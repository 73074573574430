/* eslint-disable */
import "./terms.css";
import { useState, useEffect } from "react";
import NoContentComponent from "../../../components/dashboard/noContent/NoContent";
import CreateTermsAndConditionsDialog, {
  termsAndConditionsType,
} from "../../../components/dialogs/TermsAndConditions/CreateTermsAndConditions/CreateTermsAndConditions";
import DefaultButton, { buttonTypes } from "../../../components/company/defaultButton";
import TermsTable from "../../../components/tables/TermsTable/TermsTable";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormControl,
  TextField,
  Typography,
  Chip,
  Paper,
  InputLabel,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { termsAndConditionsService } from "../../../services/TermsAndConditions";
import { getToolsWithLessData } from "../../../services/tools";
import { useDispatch } from "react-redux";
import { onlyCompanies } from "../../../services/company";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import { useQuery } from "@tanstack/react-query";
import { makeStyles } from "@mui/styles";
import getTheUserState from "../../../utils/userState";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
    width: "176px",
  },
  list: {
    padding: "0px !important",
    width: "176px",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Terms = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [generalTerms, setGeneralTerms] = useState([]);
  const [privateTerms, setPrivateTerms] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [tools, setTools] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  async function fetchAllTools() {
    const toolsData = getToolsWithLessData();
    toolsData.then((result) => {
      setTools(result);
    });
  }

  const {
    data: termsAndConditions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["termsAndConditions"],
    queryFn: termsAndConditionsService.getAllTerms,
  });

  const loggedUser = getTheUserState();
  const userId = loggedUser?.user?.id;

  const checkPermission = loggedUser?.user?.role?.permissions?.some((permission) => {
    return (
      permission.permission_name === PermissionsSchema?.terms?.show_all_terms &&
      permission.status === true
    );
  });

  useEffect(() => {
    const filterGeneralTerms = termsAndConditions?.data?.data.filter(
      (term) => term.is_general === true
    );
    const filterPrivateTerms = termsAndConditions?.data?.data.filter(
      (term) => term.is_general !== true
    );
    setGeneralTerms(filterGeneralTerms);
    setPrivateTerms(filterPrivateTerms);
  }, [termsAndConditions?.data?.data]);

  const { data: termsInCompaniesData, isLoading: isLoadingTermsInCompanies } = useQuery({
    queryKey: ["termsInCompanies", checkPermission && userId],
    queryFn: checkPermission
      ? termsAndConditionsService.getTermsInCompanies
      : () => termsAndConditionsService.getTermsInCompaniesByUser({ userId: userId, }),
  });

  useEffect(() => {
    let filtered = termsInCompaniesData?.data?.data;
    if (selectedCompanies?.length > 0) {
      setFilteredData(selectedCompanies);
    } else {
      setFilteredData(filtered);
    }
  }, [termsInCompaniesData?.data?.data, selectedCompanies]);

  async function getCompaniesData() {
    await dispatch(onlyCompanies()).then((result) => {
      setCompanies(result.data);
    });
  }

  useEffect(() => {
    getCompaniesData();
    fetchAllTools();
  }, []);

  function handleCreateDialog() {
    setIsOpen(!isOpen);
  }

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedCompanies(event.target.value);
  };

  const handleUnpick = (item) => {
    const filterPick = selectedCompanies.filter((company) => company !== item);
    setSelectedCompanies(filterPick);
  };

  console.log("expanded", expanded)

  return (
    <div className="terms_container">
      <div style={{ overflow: "auto", flex: 1 }}>
        {!generalTerms && (
          <PermissionWrapper allowedPermission={PermissionsSchema.terms.create_term}>
            <NoContentComponent
              contentTitle="Terms and Conditions"
              title="There Are No Terms and Conditions"
              subTitle="Just one step to create the first terms"
              buttonTitle="Create New Terms"
              buttonActionFunction={handleCreateDialog}
            />
          </PermissionWrapper>
        )}
        <PermissionWrapper allowedPermission={PermissionsSchema.terms.create_term}>
          <CreateTermsAndConditionsDialog
            open={isOpen}
            onClose={handleCreateDialog}
            type={termsAndConditionsType.createTerm}
          />
        </PermissionWrapper>
        {generalTerms && (
          <div className="terms_container">
            <div className="terms_inner_container">
              <button className="history_btn">History</button>
              <p className="terms_title">Terms and Conditions</p>
              <p className="table_title">General</p>
              <div
                style={{
                  maxWidth: "100%",
                  margin: "0px 40px 0px 0px",
                }}
              >
                <TermsTable
                  data={generalTerms}
                  toolsData={tools}
                  termsData={termsAndConditions?.data?.data}
                />
              </div>
              <div
                style={{
                  marginTop: "50px",
                  maxWidth: "100%",
                  paddingBottom: "50px",
                  margin: "30px 40px 0px 0px",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                  <p className="table_title">Company / Project specific</p>
                  <FormControl sx={{ width: "260px", margin: "3px 3px 0px 8px" }} size="small">
                    {/* <Autocomplete
                      sx={{
                        ".MuiAutocomplete-inputRoot": {
                          borderRadius: "4px",
                        },
                        ".MuiAutocomplete-popupIndicator": {
                          color: "#1E9DF2",
                        },
                      }}
                      classes={{
                        listbox: classes.list,
                        paper: classes.paper,
                      }}
                      disableCloseOnSelect
                      disabled={isLoadingTermsInCompanies}
                      size="small"
                      multiple
                      options={termsInCompaniesData?.data?.data || []}
                      getOptionLabel={(option) => option.name}
                      defaultValue={selectedCompanies}
                      value={selectedCompanies}
                      onChange={handleAutocompleteChange}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          <span {...getTagProps({ index })}>{option.name + ","}</span>
                        ))
                      }
                      renderOption={(props, option) => {
                        return (
                          <li {...props} style={styles.autocompleteList}>
                            {option.name}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Company"
                          placeholder="Choose a company"
                          sx={{
                            textField: {
                              fontFamily: "IBM Plex Sans",
                              maxHeight: "10px",
                              [`& fieldset`]: {
                                borderRadius: "4px",
                                fontFamily: "IBM Plex Sans",
                              },
                            },
                          }}
                        />
                      )}
                    /> */}
                    <InputLabel id="companies-multiple-checkbox-label">Company</InputLabel>
                    <Select
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px",
                        },
                      }}
                      labelId="companies-multiple-checkbox-label"
                      id="companies-multiple-checkbox"
                      multiple
                      value={selectedCompanies}
                      onChange={handleAutocompleteChange}
                      input={<OutlinedInput label="Company" />}
                      renderValue={(selected) => selected?.map((obj) => obj.name).join(", ")}
                      MenuProps={MenuProps}
                    >
                      {termsInCompaniesData?.data?.data?.map((company) => (
                        <MenuItem key={company.id} value={company}>
                          <Checkbox
                            checked={selectedCompanies.some((item) => item.id === company.id)}
                          />
                          <ListItemText primary={company.name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {selectedCompanies &&
                  selectedCompanies?.map((companySelected) => (
                    <Chip
                      onDelete={() => handleUnpick(companySelected)}
                      key={companySelected.id}
                      label={companySelected.name}
                      sx={styles.chipStyle}
                    />
                  ))}
                {/* <div style={{border:"solid 1px red", height:`calc(66px * ${termsInCompaniesData?.data?.data.length})`}}> */}
                {!isLoadingTermsInCompanies &&
                  filteredData?.map((company, index) => {
                    return (
                      <Accordion
                        key={index}
                        expanded={expanded === company?.name}
                        onChange={handleChange(company?.name)}
                        sx={{
                          borderTop: "1px solid #E8E8E9",
                          borderLeft:
                            expanded && expanded === company?.name
                              ? "4px solid #1E9DF2"
                              : "4px solid #CCE9F5",
                          borderRight: "1px solid #E8E8E9",
                          borderBottom: "1px solid #E8E8E9",
                          boxShadow: "none!important",
                          borderRadius: "0px",
                          margin: "10px 0px",
                        }}
                      >
                        <AccordionSummary
                          aria-controls="panel1d-content"
                          id={company?.name}
                          expandIcon={
                            expanded && expanded === company?.name ? (
                              <RemoveIcon sx={{ color: "#1E9DF2" }} />
                            ) : (
                              <AddIcon sx={{ color: "#1E9DF2" }} />
                            )
                          }
                          sx={{ flexDirection: "row-reverse" }}
                        >
                          <Typography sx={{ paddingLeft: "50px" }}>{company?.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <TermsTable
                            data={company?.terms}
                            toolsData={tools}
                            termsData={termsAndConditions?.data?.data}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                {/* </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="terms_footer">
        <PermissionWrapper allowedPermission={PermissionsSchema.terms.create_term}>
          <DefaultButton
            buttonType={buttonTypes.create}
            onClickEvent={handleCreateDialog}
            title="Create New Terms"
          />
        </PermissionWrapper>
      </div>
    </div>
  );
};

export default Terms;

const styles = {
  autocompleteList: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    letterSpacing: "0.14px",
    fontWeight: "400",
    borderTop: "1px solid #E8E8E9",
  },
  chipStyle: { marginRight: "4px", marginBottom: "4px", borderRadius: "4px" },
};
