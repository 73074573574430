/* eslint-disable */
import React, { useState } from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import STATIC_ROUTES from "../../route/STATIC_ROUTES";
import { deleteUser, adminServices } from "../../services/admin";
import NewConfirmDialog, { confirmTypes } from "../dialogs/NewConfirmDialog/NewConfirmDialog";
import { useDispatch } from "react-redux";
import PermissionWrapper, { PermissionsSchema } from "../auth/PermissionsWrapper";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../utils/notifications";
import AssignCompanyToUserDialog from "../dialogs/AssignCompanyToUser/AssignCompanyToUserDialog";
import { usersService } from "../../services/users";

function UserActionsComponent({ user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isOpenActivate, setIsOpenActivate] = useState(false);
  const [isOpenDeactivate, setIsOpenDeactivate] = useState(false);
  const [isActivateOpen, setIsActivateOpen] = useState(false);

  const activateUserMutation = useMutation({
    mutationFn: adminServices.activateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const deactivateUserMutation = useMutation({
    mutationFn: adminServices.deactivateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const userActivationMutation = useMutation({
    mutationFn: adminServices.fetchUserActivation,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleSimAccess = useMutation({
    mutationFn: usersService.accessToSimApp,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  async function handleGoBack() {
    navigate(STATIC_ROUTES.users);
  }

  const handleClose = () => {
    setIsOpenActivate(false);
    setIsOpenDeactivate(false);
    setIsActivateOpen(false);
  };

  const handleChangeUserStatus = async () => {
    const data = {
      id: user.id,
    };

    if (user?.status === "disabled") {
      activateUserMutation.mutate(data);
    } else {
      deactivateUserMutation.mutate(data);
    }
  };

  const handleActivation = async (pickedCompanyIds, pickedTeamIds, selectedRole) => {
    if (user?.status === "registered") {
      userActivationMutation.mutate({
        id: user.id,
        activated: !user?.activated,
        companyIds: pickedCompanyIds,
        teamIds: pickedTeamIds,
        roleId: selectedRole,
      });
    }
  };
  async function handleDeleteUser() {
    dispatch(deleteUser([user?.id]));
    await handleGoBack();
  }

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const handleSimAppPermissions = (e) => {
    const isChecked = e.target.checked;

    handleSimAccess.mutate({
      user_id: user?.id,
      isChecked: isChecked,
    });
    // console.log("isChecked", isCheked);
  };

  return (
    <div style={actionsStyles.actions_container}>
      <Button sx={actionsStyles.backBtn} variant="text" onClick={handleGoBack}>
        <ArrowBackIcon fontSize="small" /> Back to All Users
      </Button>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <PermissionWrapper allowedPermission={PermissionsSchema.users.deactivate}>
            {user?.status === "active" && (
              <Button
                sx={actionsStyles.btn}
                variant="text"
                onClick={() => setIsOpenDeactivate(true)}
              >
                Deactivate User
              </Button>
            )}
          </PermissionWrapper>
          <PermissionWrapper allowedPermission={PermissionsSchema.users.activate}>
            {user?.status === "disabled" && (
              <Button sx={actionsStyles.btn} variant="text" onClick={() => setIsOpenActivate(true)}>
                Activate User
              </Button>
            )}
          </PermissionWrapper>
          <PermissionWrapper allowedPermission={PermissionsSchema.users.activate}>
            {user?.status === "registered" && (
              <Button sx={actionsStyles.btn} variant="text" onClick={() => setIsActivateOpen(true)}>
                Activate User
              </Button>
            )}
          </PermissionWrapper>
          <PermissionWrapper allowedPermission={PermissionsSchema.users.delete}>
            <Button
              sx={actionsStyles.deleteBtn}
              variant="text"
              onClick={() => setIsDeleteDialogOpen(true)}
            >
              Delete <DeleteForeverOutlinedIcon fontSize="small" />
            </Button>
          </PermissionWrapper>
          <NewConfirmDialog
            open={isDeleteDialogOpen}
            onClose={closeDeleteDialog}
            action={handleDeleteUser}
            data={{ name: user?.name }}
            type={confirmTypes.deleteUser}
          />
          <NewConfirmDialog
            open={isOpenDeactivate}
            onClose={handleClose}
            action={handleChangeUserStatus}
            data={user}
            type={confirmTypes.disableUser}
          />
          <NewConfirmDialog
            open={isOpenActivate}
            onClose={handleClose}
            action={handleChangeUserStatus}
            data={user}
            type={confirmTypes.enableUser}
          />
          {isActivateOpen && (
            <AssignCompanyToUserDialog
              open={isActivateOpen}
              onClose={handleClose}
              data={user}
              action={handleActivation}
            />
          )}
        </div>
        <FormControlLabel
          onChange={handleSimAppPermissions}
          control={
            <Checkbox
              size="small"
              defaultChecked={user?.sim_app_access ? user?.sim_app_access : false}
            />
          }
          label="Give access to SimApp"
          sx={{
            "& .MuiTypography-root": {
              fontSize: "13px",
            },
          }}
        />
      </div>
    </div>
  );
}

export default UserActionsComponent;

const actionsStyles = {
  actions_container: {
    display: "flex",
    justifyContent: "space-between",
    paddingRight: "40px",
  },
  backBtn: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    textTransform: "none",
    marginLeft: "-8px",
    "&:hover": {
      color: "#1E9DF2",
      backgroundColor: "transparent",
    },
  },
  btn: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    textTransform: "none",
    "&:hover": {
      color: "#1E9DF2",
    },
  },
  deleteBtn: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    textTransform: "none",
    "&:hover": {
      color: "#D00101",
    },
  },
};
