/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useSelector } from "react-redux";
import { getUploadType } from "../../../reducers/files";
import { uploadTypes } from "../files_container/FilesContainer";

const termTypes = {
  generalTerms: "General Terms",
  privateTerms: "Private Terms",
};

const SelectTermsComponent = ({
  terms,
  handleSelectedTerms,
  fileIndex,
  companyID,
  defaultValue,
}) => {
  const [collectData, setCollectData] = useState({
    type: "",
    data: [],
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [generalTermsSearchText, setGeneralTermsSearchText] = useState("");
  const [privateTermsSearchText, setPrivateTermsSearchText] = useState("");

  const { uploadType } = useSelector((globalState) => ({
    uploadType: getUploadType(globalState),
  }));

  useEffect(() => {
    if (isDrawerOpen) {
      setCollectData(defaultValue);
    }
  }, [isDrawerOpen]);

  const handleSearchChange = (event, type) => {
    const searchValue = event.target.value;

    if (type === "general") {
      setGeneralTermsSearchText(searchValue.toLowerCase());
    } else {
      setPrivateTermsSearchText(searchValue.toLowerCase());
    }
  };

  const handleChangeTabs = (event, newValue) => {
    if (newValue === "1") {
      setPrivateTermsSearchText("");
    } else {
      setGeneralTermsSearchText("");
    }
    setValue(newValue);
    // setCollectData({
    //   type: "",
    //   data: [],
    // });
  };

  function handleDrawerState() {
    setIsDrawerOpen(!isDrawerOpen);
    setCollectData({ type: "", data: [] });
    setValue("1");
  }

  function handleSelectTerm(e) {
    const value = e.target.value;
    const name = e.target.name;

    setCollectData({ type: name, data: [value] });

    // Index, Property, Value
  }

  function handleSelectTermInCompany(e, companyID) {
    const isChecked = e.target.checked;
    const type = e.target.name;
    const value = e.target.value;

    const currentCompany = collectData.data[0]?.company_id !== companyID ? false : true;
    if (!currentCompany) {
      setCollectData({ type: "", data: [] });
    }

    if (isChecked === true) {
      setCollectData((prevState) => {
        const newData = [...prevState.data];
        const index = newData?.findIndex((item) => item.company_id === companyID);
        if (index !== -1) {
          // Company already exists, update its terms
          if (!newData[index].terms?.includes(value)) {
            newData[index].terms?.push(value);
          }
        } else {
          // Company doesn't exist, add it to the data array
          newData.push({
            company_id: companyID,
            terms: [value],
          });
        }
        return { type: type, data: newData };
      });
    } else if (isChecked === false) {
      setCollectData((prevState) => {
        const newData = [...prevState.data];
        const index = newData?.findIndex((item) => item?.company_id === companyID);
        if (index !== -1) {
          // Remove value from terms of the company
          newData[index].terms = newData[index].terms?.filter((term) => term !== value);
          // Remove company if terms becomes empty
          if (newData[index].terms?.length === 0) {
            newData?.splice(index, 1);
          }
        }
        return { ...prevState, data: newData };
      });
    }
  }

  function handleAcceptState() {
    handleSelectedTerms(fileIndex, "terms", collectData);
    setIsDrawerOpen(false);
  }

  const filteredGeneralData = terms?.generalTerms?.filter((item) => {
    const matchSearch = item.terms_name.toLowerCase().includes(generalTermsSearchText);
    return matchSearch;
  });

  const filteredPrivateData = terms?.privateTerms?.filter((item) => {
    const matchSearch = item.company?.name.trim().toLowerCase().includes(privateTermsSearchText);
    return matchSearch;
  });

  const NoResultComponent = () => {
    return <p>No results found.</p>;
  };

  function returnDefaulTitle(type) {
    let text;
    if (type === "generalTerms") {
      text = "General Terms";
    } else if (type === "privateTerms") {
      text = "Company / Project specific";
    } else {
      text = " Choose an option...";
    }

    return text;
  }

  return (
    <div>
      <div onClick={handleDrawerState} style={{ cursor: "pointer" }}>
        {returnDefaulTitle(defaultValue?.type)}
      </div>
      <Drawer anchor="right" open={isDrawerOpen} sx={styles.drawerRoot}>
        <div
          style={{
            padding: "48px 32px",
            minWidth: "360px",
            // maxHeight: "550px",
            // minHeight: "550px",
            height: "100%",
            overflowY: "auto",
          }}
        >
          <div>
            <TabContext value={value} sx={styles.tabPanel}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChangeTabs} aria-label="term tabs">
                  <Tab label="General Terms" value="1" sx={styles.tab} />
                  <Tab label="Company / Project specific" value="2" sx={styles.tab} />
                </TabList>
              </Box>
              <TabPanel value="1" sx={styles.tabPanel}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" sx={{ color: "#D22B2B", paddingTop: "24px" }}>Check if company / project specific T&C are agreed</Typography>
                  <TextField
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    variant="outlined"
                    size="small"
                    placeholder="Search in General Terms"
                    sx={styles.textField}
                    onChange={(e) => handleSearchChange(e, "general")}
                  />
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={parseInt(defaultValue?.data[0], 10)}
                      name="radio-buttons-group"
                    >
                      {filteredGeneralData?.length ? (
                        filteredGeneralData?.map((term, index) => {
                          return (
                            <div key={index} style={styles.checkboxContainer}>
                              <FormControlLabel
                                name="generalTerms"
                                value={term?.id}
                                control={<Radio size="small" />}
                                label={term?.terms_name}
                                onClick={(e) => handleSelectTerm(e)}
                                sx={{
                                  '.MuiFormControlLabel-label': {
                                    maxWidth: '280px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                  },
                                }
                                }
                              />
                            </div>
                          );
                        })
                      ) : (
                        <NoResultComponent />
                      )}
                    </RadioGroup>
                  </FormControl>
                </div>
              </TabPanel>
              <TabPanel value="2" sx={styles.tabPanel}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <TextField
                    variant="outlined"
                    size="small"
                    placeholder="Search Terms by Company"
                    sx={styles.textField2}
                    onChange={(e) => handleSearchChange(e, "private")}
                  />
                  {uploadType === uploadTypes.fManager ? (
                    filteredPrivateData?.length ? (
                      filteredPrivateData?.map((term, termMainIndex) => {
                        const defaultLength = term?.terms?.length;
                        const termsInCompany = collectData?.data?.filter(
                          (s) => s?.company_id === term?.company?.id
                        );
                        const termsLength = termsInCompany[0]?.terms?.length;
                        return (
                          <Accordion sx={styles.accordionContainer} key={termMainIndex}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon sx={{ color: "#1E9DF2" }} />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              {term?.company?.name.toUpperCase()}
                            </AccordionSummary>

                            {term?.terms?.map((singleTerm, termIndex) => {
                              const filterData = collectData?.data?.filter(
                                (setting) => setting?.company_id === singleTerm?.company_id
                              );
                              let parseId = singleTerm?.id.toString();
                              const isChecked = filterData[0]?.terms?.includes(parseId);
                              return (
                                <AccordionDetails key={termIndex}>
                                  <div style={styles.checkboxContainer}>
                                    <FormControlLabel
                                      name="privateTerms"
                                      value={singleTerm?.id}
                                      sx={styles.checkboxTitles}
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={isChecked ? true : false}
                                          disabled={
                                            !filterData[0]?.terms?.includes(parseId) &&
                                              filterData[0]?.terms?.length > 0
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label={singleTerm.terms_name}
                                      onClick={(e) =>
                                        handleSelectTermInCompany(e, term?.company?.id)
                                      }
                                    />
                                  </div>
                                </AccordionDetails>
                              );
                            })}
                          </Accordion>
                        );
                      })
                    ) : (
                      <NoResultComponent />
                    )
                  ) : filteredPrivateData?.length ? (
                    filteredPrivateData
                      ?.filter((tr) => tr.company?.id === companyID)
                      ?.map((term, termMainIndex) => {
                        const defaultLength = term?.terms?.length;
                        const termsInCompany = collectData?.data?.filter(
                          (s) => s?.company_id === term?.company?.id
                        );
                        const termsLength = termsInCompany[0]?.terms?.length;
                        return (
                          <Accordion sx={styles.accordionContainer} key={termMainIndex}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon sx={{ color: "#1E9DF2" }} />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                              sx={{ maxWidth: "350px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                            >
                              {term?.company?.name.toUpperCase()}
                            </AccordionSummary>

                            {term?.terms?.map((singleTerm, termIndex) => {
                              const filterData = collectData?.data?.filter(
                                (setting) => setting?.company_id === singleTerm?.company_id
                              );
                              let parseId = singleTerm?.id.toString();
                              const isChecked = filterData[0]?.terms?.includes(parseId);

                              return (
                                <AccordionDetails key={termIndex}>
                                  <div style={styles.checkboxContainer}>
                                    <FormControlLabel
                                      name="privateTerms"
                                      value={singleTerm?.id}
                                      sx={styles.checkboxTitles}
                                      control={
                                        <Checkbox
                                          size="small"
                                          checked={isChecked ? true : false}
                                          disabled={
                                            !filterData[0]?.terms?.includes(parseId) &&
                                              filterData[0]?.terms?.length > 0
                                              ? true
                                              : false
                                          }
                                        />
                                      }
                                      label={singleTerm.terms_name}
                                      onClick={(e) =>
                                        handleSelectTermInCompany(e, term?.company?.id)
                                      }
                                    />
                                  </div>
                                </AccordionDetails>
                              );
                            })}
                          </Accordion>
                        );
                      })
                  ) : (
                    <NoResultComponent />
                  )}
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
        <div style={{ padding: "0px 32px", borderTop: "1px solid  #E8E8E9", paddingTop: "12px" }}>
          <div style={{
            display: "flex", justifyContent: "flex-end", paddingBottom: "17px",
            paddingTop: "12px",
          }}>
            <Button variant="text" onClick={handleDrawerState} sx={styles.cancelButton}>
              Cancel
            </Button>
            <Button
              variant="text"
              onClick={handleAcceptState}
              sx={collectData.data?.length > 0 ? styles.addButton : styles.addButtonDisabled}
              disabled={collectData.data?.length > 0 ? false : true}
            >
              Save
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SelectTermsComponent;

const styles = {
  selectMenu: {
    minWidth: "185px",
    padding: "0px",
  },
  menuItem: {
    paddingTop: "0px",
    paddingBottom: "0px",
    fontSize: "11px",
    borderTop: "1px solid #E8E8E9",
  },
  labels: {
    // padding: "8px 12px",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    borderBottom: "1px solid #E8E8E9",
  },
  checkboxContainer: {
    borderBottom: "1px solid #E8E8E9",
    padding: "0px 12px",
  },
  checkboxTitles: {
    "& .MuiTypography-root": {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "11px",
      fontWeight: "500",
      lineHeight: "16.5px",
      letterSpacing: "0.11px",
    },
  },
  accordionContainer: {
    borderRadius: "0px",
    boxShadow: "none",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px", zIndex: "999" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  tabPanel: {
    padding: "0px",
    minWidth: "360px",
  },
  textField: {
    padding: "0px 0px 24px 0px",
    fontFamily: "IBM Plex Sans",
    // minWidth: "300px",
    maxHeight: "43px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  textField2: {
    padding: "24px 0px",
    fontFamily: "IBM Plex Sans",
    // minWidth: "300px",
    maxHeight: "43px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  tab: {
    fontWeight: "500",
    textTransform: "capitalize",
  },
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
