/* eslint-disable */
import { Skeleton } from "@mui/material";

const LoaderSkeletonComponent = ({ count }) => {
  return (
    <div>
      {Array.from({ length: count }).map((_, index) => (
        <div style={{ margin: "10px 0" }} key={index}>
          <Skeleton variant="rect" width={360} height={20} />
        </div>
      ))}
    </div>
  );
};

export default LoaderSkeletonComponent;
