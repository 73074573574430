/* eslint-disable */
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormHelperText,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { companyServices } from "../../../services/company";
import { Controller, useForm } from "react-hook-form";
import {
  addressRegex,
  emailRegex,
  mainNameRegex,
  nameRegex,
  userNameRegex,
  zipCodeRegex,
} from "../../../utils/regexList";
import PhoneInput from "react-phone-input-2";
import CountriesDropdown from "../../dropdown/CountriesDropdown";
import { KeyboardBackspace } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import { usersService } from "../../../services/users";
import { makeStyles } from "@mui/styles";
import getTheUserState from "../../../utils/userState";
import UserRoleDropdownNoForm from "../../dropdown/UserRoleDropdownNoForm";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
    maxWidth: "176px",
    maxHeight: "450px",
  },
  list: {
    padding: "0px !important",
    maxWidth: "176px",
    "& .MuiTypography-root": {
      fontSize: "small",
    },
  },
  select: {
    width: 300,
    height: 46,
    borderRadius: "4px",
    marginTop: "5px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  menuItem: {
    width: 300,
  },
}));

const CreateCompanyDialog = ({ open, onClose, onBack, data: userData }) => {
  const loggedInUserID = getTheUserState()?.user?.id;

  const [selectedName, setSelectedName] = useState(null);
  const [autoCompleteAdmins, setAutocompleteAdmins] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedRoleError, setSelectedRoleError] = useState(false);
  // const [showInfo, setShowInfo] = useState(false);

  const queryClient = useQueryClient();

  const classes = useStyles();

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    control,
    clearErrors,
    setValue,
  } = useForm();

  const {
    data: users,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["users"],
    queryFn: usersService.getAllUsers,
  });

  useEffect(() => {
    if (users?.data) {
      const filteredUsers = users?.data?.filter(
        (user) => user.id !== userData.id && user.status === "active"
      );
      setAutocompleteAdmins(filteredUsers);
    }
  }, [userData, users?.data]);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const createCompanyMutation = useMutation({
    mutationFn: companyServices.createCompany,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleChangeAdmin = (event, newValue) => {
    setSelectedName(newValue);
    setValue("companyAdmin", newValue);
    clearErrors("companyAdmin");
  };

  async function onSubmit(data) {
    const users = [userData.id, loggedInUserID];

    data.userId = userData.id;
    const companyAdminId = selectedName?.id;
    data.companyAdminId = companyAdminId;
    data.userRole = selectedRole;
    const pickedAdmin = users?.some(user => user === companyAdminId);
    if (pickedAdmin) {
      users.pop(loggedInUserID)
    }
    data.users = users;
    // await createCompany(data);
    createCompanyMutation.mutate(data);
    onClose();
  }

  const submitForm = async () => {
    if (selectedRole === null) {
      setSelectedRoleError(true)
      return;
    }
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      await handleSubmit(onSubmit)();
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onBack}
        sx={{
          ".MuiPaper-root": {
            maxWidth: "1200px",
            maxHeight: "765px",
            overflowX: "auto",
          },
          "& .MuiBackdrop-root": { background: "transparent" },
        }}
      >
        <div
          style={{
            alignSelf: "flex-end",
            marginBottom: "-30px",
            padding: "10px",
            zIndex: "1",
          }}
        >
          <IconButton onClick={onBack}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <DialogContent sx={muiDialogStyles.contentStyles}>
          <h3 style={createCompanyMUIstyles.title}>Activate user to new company</h3>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
          <p style={muiDialogStyles.additionalInfoStyle}>
            This new user will automatically be assigned to the newly created company!
          </p>
          {/* <IconButton
              sx={{
                // position: "absolute",
                margin: "2px 0px 6px 6px",
                // left: "610px",
                zIndex: "999",
              }}
              onClick={() => setShowInfo(true)}
            >
              <InfoOutlinedIcon sx={{ color: "#1E9DF2" }} />
            </IconButton>
          </div>
          <Dialog
            open={showInfo || false}
            onClose={() => setShowInfo(false)}
            sx={{
              // "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
              "& .MuiBackdrop-root": { background: "transparent" },
              position: "absolute",
              left: "1000px",
            }}
          >
            <div
              style={{
                alignSelf: "flex-end",
                marginBottom: "-30px",
                padding: "10px",
                zIndex: "1",
              }}
            >
              <IconButton onClick={() => setShowInfo(false)}>
                <CloseIcon color="disabled" />
              </IconButton>
            </div>
            <DialogContent
            // sx={muiDialogStyles.contentStyles}
            >
              <Typography variant="h3" sx={{ marginBottom: "16px" }}>User Information</Typography>
              <Typography variant="body2">Username:</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>{userData?.user}</Typography>
              <Typography variant="body2">Email:</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>{userData?.email}</Typography>
              <Typography variant="body2">First name:</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>{userData?.firstName}</Typography>
              <Typography variant="body2">Last name:</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>{userData?.lastName}</Typography>
              <Typography variant="body2">Company user input:</Typography>
              <Typography variant="body1" sx={{ marginBottom: "10px" }}>{userData?.company_user_input}</Typography>
            </DialogContent>
          </Dialog> */}
          <form id="myForm" onSubmit={handleSubmit(onSubmit)}>
            <div style={createCompanyMUIstyles.formWrapper}>
              <div
                className="form_column_dialog_wrapper"
                style={createCompanyMUIstyles.fieldsWrapper}
              >
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value: mainNameRegex,
                      message: "Invalid Company Name. Use letters and numbers.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Company *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter company name"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
                <Controller
                  name="companyEmail"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value: emailRegex,
                      message: "Invalid Email format.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Email *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter company email"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
                <Controller
                  name="companyAdmin"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This field is required!",
                  }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      // multiple
                      id="search-select"
                      options={autoCompleteAdmins}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      value={selectedName}
                      onChange={handleChangeAdmin}
                      filterOptions={(options, state) => {
                        const inputValue = state?.inputValue?.toLowerCase();
                        return options.filter(
                          (option) =>
                            option?.name?.toLowerCase().includes(inputValue) ||
                            option?.email?.toLowerCase().includes(inputValue)
                        );
                      }}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      renderOption={(props, option, { selected }) => (
                        <li {...props} style={{ fontSize: "12px" }} key={option.id}>
                          <Checkbox checked={selected} color="primary" disableRipple size="small" />
                          {`${option.firstName} ${option.lastName} (${option.email})`}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Company admin *"
                          size="small"
                          placeholder="Enter admin"
                          sx={createCompanyMUIstyles.textField}
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="companyPhone"
                  control={control}
                  defaultValue=""
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: "This field is required!",
                  //   },
                  // }}
                  render={({ field, fieldState }) => (
                    <>
                      <PhoneInput
                        country={"de"}
                        enableSearch={true}
                        {...field}
                        placeholder="Company phone"
                        containerStyle={{ color: fieldState.error ? "#D22B2B" : "" }}
                        inputStyle={{
                          borderRadius: "4px",
                          fontSize: "14px",
                          marginTop: "5px",
                          padding: "13.5px 14px 14.5px 58px",
                          borderColor: fieldState.error ? "#D22B2B" : "",
                        }}
                        searchStyle={{ border: "none", margin: "0", width: "100%", padding: "0" }}
                        disableSearchIcon={false}
                        className="phone-input-form"
                        // inputProps={{ required: true, }}
                        specialLabel="Phone"
                      />
                      {fieldState.error && (
                        <FormHelperText error>This field is required!</FormHelperText>
                      )}
                    </>
                  )}
                />
                <Controller
                  name="companyURL"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Company URL *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter company URL"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textFieldSpaced}
                    />
                  )}
                />
                <Controller
                  name="userRole"
                  control={control}
                  defaultValue=""
                  // rules={{
                  //   required: {
                  //     value: true,
                  //     message: "This field is required!",
                  //   },
                  // }}
                  render={({ field, fieldState }) => (
                    <div style={{ marginTop: "6px" }}>
                      {/* <p className="filter_title_assign">Role</p> */}
                      <UserRoleDropdownNoForm
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        selectLabel="Select role for new user *"
                      />
                      {selectedRoleError && (
                        <FormHelperText sx={{ marginLeft: "8px" }} error>This field is required!</FormHelperText>
                      )}
                    </div>
                  )}
                />
              </div>
              <div
                className="form_column_dialog_wrapper"
                style={createCompanyMUIstyles.fieldsWrapper}
              >
                <Controller
                  name="country"
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: "This field is required!",
                  }}
                  render={({ field, fieldState }) => (
                    <CountriesDropdown
                      field={field}
                      fieldState={fieldState}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
                <Controller
                  name="province"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This field is required!",
                    pattern: {
                      value: nameRegex,
                      message: "Please use only letters and numbers.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="State / Province * "
                      placeholder="Enter state or province"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value: userNameRegex,
                      message: "Please use only letters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="City *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter city"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />

                <Controller
                  name="companyAddress"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value: addressRegex,
                      message: "Please use letters and numbers only.",
                    },
                  }}
                  defaultValue=""
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Company address *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter company address"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
                <Controller
                  name="zipCode"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "This field is required!",
                    minLength: {
                      value: 4,
                      message: "Zip Code should contain at least 4 digets.",
                    },
                    pattern: {
                      value: zipCodeRegex,
                      message: "Invalid Zip Code. Please use only numbers.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="ZIP code *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Enter ZIP code"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={createCompanyMUIstyles.textField}
                    />
                  )}
                />
              </div>
              <div
                className="form_column_dialog_wrapper"
                style={createCompanyMUIstyles.fieldsWrapper}
              >
                <div style={{ maxWidth: "300px", borderLeft: '2px solid #F4F4F4', borderRadius: "8px", padding: '16px' }}>
                  <Typography variant="h3" sx={{ marginBottom: "16px", fontSize: "24px" }}>User Information</Typography>
                  <Typography variant="body2">Username:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.user}</Typography>
                  <Typography variant="body2">Email:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.email}</Typography>
                  <Typography variant="body2">First name:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.firstName}</Typography>
                  <Typography variant="body2">Last name:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.lastName}</Typography>
                  <Typography variant="body2">Company user input:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.company_user_input}</Typography>
                  <Typography variant="body2">Address:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.address}</Typography>
                  <Typography variant="body2">City:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.city}</Typography>
                  <Typography variant="body2">Zip Code:</Typography>
                  <Typography variant="body1" noWrap sx={{ marginBottom: "6px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{userData?.zipCode}</Typography>
                </div>
              </div>
            </div>
          </form>
          <div style={createCompanyMUIstyles.buttonWrapper}>
            <Button
              variant="text"
              color="primary"
              sx={createCompanyMUIstyles.cancelButton}
              onClick={onBack}
            >
              <KeyboardBackspace sx={{ marginRight: "8px" }} /> Back
            </Button>
            <Button
              disabled={!isDirty || selectedRole === null}
              variant="contained"
              color="primary"
              sx={createCompanyMUIstyles.createButton}
              onClick={submitForm}
            >
              Activate user to new company
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateCompanyDialog;

const createCompanyMUIstyles = {
  title: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "0.4em 0px",
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    minWidth: "300px",
    maxWidth: "300px",
    paddingBottom: "24px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  menuItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    letterSpacing: "0.14px",
    fontWeight: "400",
    borderTop: "1px solid #E8E8E9",
    // maxWidth:"300px"
  },
  textFieldSpaced: {
    fontFamily: "IBM Plex Sans",
    minWidth: "300px",
    maxWidth: "300px",
    paddingBottom: "24px",
    marginTop: "34px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  formWrapper: {
    display: "flex",
    justifiyContent: "space-between",
    alignItems: "space-between",
    gap: "40px",
  },
  fieldsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifiyContent: "space-between",
    alignItems: "space-between",
  },
  dropdownsWrapper: {
    display: "flex",
    justifiyContent: "space-between",
    gap: "40px",
    marginTop: "20px",
    border: "5px solid 9e9e9e",
  },
  buttonWrapper: {
    display: "flex",
    padding: "24px 60px",
    width: "100%",
    justifyContent: "space-between",
    borderTop: "2px solid #F4F4F4",
  },
  createButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "294px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "80%",
    alignSelf: "center",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "1200px",
    maxHeight: "745px",
    padding: "0px 60px",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
  },
  additionalInfoStyle: {
    color: "#9e9e9e",
    width: "100%",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    textAlign: "center",
    lineHeight: "150%" /* 24px */,
    letterSpacing: "-0.16px",
  },
};
