/* eslint-disable */

import React, { useEffect, useState } from "react";
import "./dashboardHomePage.css";
import { useDispatch, useSelector } from "react-redux";
import { Button, TablePagination } from "@mui/material";
import { fetchAllUsers } from "../../services/admin";
import UsersStatus from "../../components/cards/UsersStatus/UsersStatus";
import { getUsers } from "../../reducers/admin";
import PipeImg from "../../assets/others/pipe.svg";
import CarImg from "../../assets/img/car.png";
import { getUserData } from "../../reducers/auth";
import getTheUserState from "../../utils/userState";
import ProjectsCard, { cardTypes } from "../../components/cards/ProjectsCard/ProjectsCard";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import STATIC_ROUTES from "../../route/STATIC_ROUTES";
import NewsFeed from "../../components/dashboard/layout/News/NewsFeed";
import PermissionWrapper, { PermissionsSchema } from "../../components/auth/PermissionsWrapper";
import { useQuery } from "@tanstack/react-query";
import { filesService } from "../../services/files";
import { Link } from "react-router-dom";
import { newsServices } from "../../services/news";
import { projectService } from "../../services/project";
import { LightTooltip } from "../../components/tables/TermsTable/TermsTable";

function DashboardHomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { usersCount, user } = useSelector((globalState) => ({
    usersCount: getUsers(globalState),
    user: getUserData(globalState),
  }));

  const userState = getTheUserState();

  const userId = userState?.user.id;

  // const [collectProjectsByUserId, setCollectProjectsByUserId] = useState([]);
  const {
    data: allNews,
    isLoading: isLoadingNews,
    isError: isNewsError,
  } = useQuery({
    queryKey: ["allNews"],
    queryFn: newsServices.getAllNews,
  });

  const {
    data: userProjects,
    isLoading: isLoadingProjects,
    isError: isProjectsError,
  } = useQuery({
    queryKey: ["userProjects", userId],
    queryFn: () => projectService.fetchProjectsByUserId({ userId: userId }),
  });

  // console.log("userProjects", userProjects)
  const [sortItem, setSortItem] = useState("all");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);

  //   setPage(0);
  // };

  const filteredNews = allNews?.data?.filter((news) => {
    if (sortItem === "published") {
      return news.is_draft === false;
    } else if (sortItem === "drafts") {
      return news.is_draft === true;
    }
    else if (sortItem === "pinned") {
      return news.is_pinned === true;
    }
    else {
      return news;
    }
  });

  const handleChangePage = (event, newPage) => {
    const maxPage = Math.ceil(filteredNews.length / rowsPerPage) - 1;
    if (newPage >= 0 && newPage <= maxPage) {
      setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0); // Reset to the first page
  };

  const checkPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.file_requests &&
      permission.status === true
  );

  const checkAllPermission = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.all_file_requests &&
      permission.status === true
  );

  function stringifyIds() {
    const companiesArray = !checkAllPermission && userState?.user?.companies;

    const stringifiedIds = companiesArray?.join(",");

    return stringifiedIds;
  }

  const {
    data: files,
    isLoading,
    isError,
    isPending,
  } = useQuery({
    queryKey: ["requsted-files-count", !checkAllPermission && stringifyIds()],
    queryFn: () =>
      checkAllPermission
        ? filesService.requestFilesCount()
        : filesService.requestFilesByCompanies(stringifyIds()),
    // queryFn: filesService.requestFilesCount,
  });

  // async function fetchProjectsByUserId(userId) {
  //   const data = { userId };
  //   // setIsLoadingUserProjects(true);
  //   const response = await axios(
  //     axiosOptions(API_URL + "/project/getProjectsByUserId", data, "post")
  //   ).then((data) => {
  //     setCollectProjectsByUserId(data.data);
  //     // setIsLoadingUserProjects(false);
  //   });
  // }

  useEffect(() => {
    dispatch(fetchAllUsers());
    // fetchProjectsByUserId(userId);
  }, []);

  const goToCreateNewsPage = () => {
    navigate(STATIC_ROUTES.news, { state: { type: "add" } });
  };

  const activeUsers = usersCount.filter((active) => active?.status === "active");
  const pendingUsers = usersCount.filter((pending) => pending?.status === "pending");
  const registeredUsers = usersCount.filter((registered) => registered?.status === "registered");
  const disabledUser = usersCount.filter((disabled) => disabled?.status === "disabled");
  // const noCompanyUser = usersCount.filter((user) => user?.company?.toLowerCase() === "unknown");
  const favoriteProjects = userProjects?.data?.projects?.filter(
    (project) => project?.isFavorite === true
  );

  function handleRequestsCount() {
    let renderComponent;
    if (checkAllPermission) {
      renderComponent = (
        <PermissionWrapper allowedPermission={PermissionsSchema.files.all_file_requests}>
          <img src={PipeImg} className="pipe_img" />
          <LightTooltip title={"View file requests."} arrow placement="top">
            <span style={{ pointerEvents: files?.data?.count === 0 ? 'none' : 'auto', opacity: files?.data?.count === 0 ? 0.8 : 1 }}>
              <Link to="/dashboard/file-requests" style={{ textDecoration: "none" }}>
                <UsersStatus count={files?.data?.count} title="File Requests" />
              </Link>
            </span>
          </LightTooltip>
        </PermissionWrapper>
      );
    } else if (checkPermission && !checkAllPermission) {
      renderComponent = (
        <PermissionWrapper allowedPermission={PermissionsSchema.files.file_requests}>
          <img src={PipeImg} className="pipe_img" />
          <LightTooltip title={"View file requests."} arrow placement="top">
            <span style={{ pointerEvents: files?.data?.files?.length === 0 ? 'none' : 'auto', opacity: files?.data?.files?.length === 0 ? 0.8 : 1 }}>
              <Link to="/dashboard/file-requests" style={{ textDecoration: "none" }}>
                <UsersStatus count={files?.data?.files?.length} title="File Requests" />
              </Link>
            </span>
          </LightTooltip>
        </PermissionWrapper>
      );
    }

    return renderComponent;
  }

  // TODO: FIX THE CAR!!!
  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
        <div
          style={{
            overflowY: "auto",
            overflowX: "hidden",
            flex: 1,
            position: "relative",
            paddingRight: "63px",
          }}
        >
          <div className="dashboard_home_container">
            <div className="dashboard_home_left_side">
              <h3 className="dashboard_header">Welcome {user ? user?.user?.user : "unknown"}!</h3>
              <PermissionWrapper allowedPermission={PermissionsSchema.users.view_stats}>
                <div className="users_status_wrapper">
                  <LightTooltip title={"View active users."} arrow placement="top">
                    <span style={{ pointerEvents: activeUsers.length === 0 ? 'none' : 'auto', opacity: activeUsers.length === 0 ? 0.8 : 1 }}>
                      <Link to="/dashboard/users?status=active" style={{ textDecoration: "none" }}>
                        <UsersStatus count={activeUsers.length} title="Active Users" />
                      </Link>
                    </span>
                  </LightTooltip>
                  <img src={PipeImg} className="pipe_img" />
                  <LightTooltip title={"View pending users."} arrow placement="top">
                    <span style={{ pointerEvents: pendingUsers.length === 0 ? 'none' : 'auto', opacity: pendingUsers.length === 0 ? 0.8 : 1 }}>
                      <Link to="/dashboard/users?status=pending" style={{ textDecoration: "none" }}>
                        <UsersStatus count={pendingUsers.length} title="Pending Users" />
                      </Link>
                    </span>
                  </LightTooltip>
                  <img src={PipeImg} className="pipe_img" />
                  <LightTooltip title={"View registered users."} arrow placement="top">
                    <span style={{ pointerEvents: registeredUsers.length === 0 ? 'none' : 'auto', opacity: registeredUsers.length === 0 ? 0.8 : 1 }}>
                      <Link to="/dashboard/users?status=registered" style={{ textDecoration: "none" }}>
                        <UsersStatus count={registeredUsers.length} title="Registered Users" />
                      </Link>
                    </span>
                  </LightTooltip>
                  <img src={PipeImg} className="pipe_img" />
                  <LightTooltip title={"View disabled users."} arrow placement="top">
                    <span style={{ pointerEvents: disabledUser.length === 0 ? 'none' : 'auto', opacity: disabledUser.length === 0 ? 0.8 : 1 }}>
                      <Link to="/dashboard/users?status=disabled" style={{ textDecoration: "none" }}>
                        <UsersStatus count={disabledUser.length} title="Disabled Users" />
                      </Link>
                    </span>
                  </LightTooltip>
                  {/* {checkPermission && (
                  <PermissionWrapper allowedPermission={PermissionsSchema.files.file_requests}>
                    <img src={PipeImg} className="pipe_img" />
                    <Link to="/dashboard/file-requests" style={{ textDecoration: "none" }}>
                      <UsersStatus count={files?.data?.files?.length} title="File Requests" />
                    </Link>
                  </PermissionWrapper>
                )} */}
                  {handleRequestsCount()}
                  {/* {checkPermission && (
                  <PermissionWrapper allowedPermission={PermissionsSchema.files.file_requests}>
                    <img src={PipeImg} className="pipe_img" />
                    <Link to="/dashboard/file-requests" style={{ textDecoration: "none" }}>
                      <UsersStatus count={files?.data?.files?.length} title="File Requests" />
                    </Link>
                  </PermissionWrapper>
                )}
                {checkAllPermission && (
                  <PermissionWrapper allowedPermission={PermissionsSchema.files.all_file_requests}>
                    <img src={PipeImg} className="pipe_img" />
                    <Link to="/dashboard/file-requests" style={{ textDecoration: "none" }}>
                      <UsersStatus count={files?.data?.count} title="File Requests" />
                    </Link>
                  </PermissionWrapper>
                )} */}
                </div>
              </PermissionWrapper>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  paddingTop: "24px",
                  zIndex: "200",
                }}
              >
                {/* <button onClick={handleFunctions}>Some content</button> */}
                <NewsFeed
                  filteredNews={filteredNews?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  sortItem={sortItem}
                  setSortItem={setSortItem}
                />
              </div>
            </div>
          </div>
          <img
            src={CarImg}
            style={{
              width: "auto",
              height: "350px",
              bottom: "0px",
              opacity: 0.1,
              right: "-330px",
              zIndex: "1",
              position: "absolute",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderTop: "1px solid #E8E8E9",
            background: "white",
            paddingLeft: "48px",
            flexShrink: 0,
          }}
        >
          {filteredNews && filteredNews?.length > 0 ? (
            <div>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredNews.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  ".MuiTablePagination-toolbar": { padding: "0px", },
                  ".MuiIconButton-root": {
                    color: "#1E9DF2",
                    "&.Mui-disabled": {
                      color: 'rgba(0, 0, 0, 0.26)', // Default disabled color or any other appropriate color
                    },
                  },
                  ".MuiTablePagination-displayedRows": {
                    fontSize: "14px",
                    color: "#1B1E1F",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "500",
                  },
                  ".MuiTablePagination-select": {
                    fontSize: "14px",
                    color: "#1B1E1F",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "500",
                  },
                  ".MuiTablePagination-selectIcon": {
                    color: "#1E9DF2",
                  },
                  ".MuiTablePagination-selectLabel": {
                    fontSize: "14px",
                    color: "#1B1E1F",
                    fontFamily: "IBM Plex Sans",
                    fontWeight: "500",
                  },
                }}
              />
            </div>
          ) : null}
          <PermissionWrapper allowedPermission={PermissionsSchema.news.create_news}>
            <div className="add_news_inner">
              <Button sx={styles.addNewsButton} onClick={goToCreateNewsPage}>
                Add News <AddIcon />
              </Button>
            </div>
          </PermissionWrapper>
        </div>
      </div>
      <div className="dashboard_home_right_side">
        <p className="projects_title">Favorite Projects</p>
        {favoriteProjects?.map((project, index) => {
          return (
            <ProjectsCard
              key={index}
              type={cardTypes.isFavorite}
              id={project.id}
              name={project.name}
            />
          );
        })}
      </div>
    </div>
  );
}

export default DashboardHomePage;

const styles = {
  addNewsButton: {
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%" /* 22.4px */,
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    minWidth: "128px",
    borderRadius: "2px",
    height: "50px",
    gap: "6px",
    color: "#1E9DF2",
  },
};
