/* eslint-disable */
import "react-quill/dist/quill.snow.css";
import "./textEditor.css";
import ReactQuill from "react-quill";

export const editorTypes = {
  view: "read only",
  editable: "editable",
};

const TextEditor = ({ data, onChange, style, type, quillRef }) => {
  const modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      [{ color: [] }],
      ["bold", "italic", "underline", "strike", "blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
      ["link", "image"],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "size",
    "color",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "code-block",
    "clean",
  ];

  return (
    <ReactQuill
      theme="snow"
      modules={editorTypes.view === type ? { toolbar: false } : modules}
      formats={formats}
      value={data}
      onChange={onChange}
      style={style}
      // style={{ maxHeight: "300px", height: "300px" }}
      readOnly={editorTypes.view === type ? true : false}
      ref={quillRef}
    />
  );
};

export default TextEditor;
