/* eslint-disable */
import "./filesContainer.css";
import { useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { useDropzone } from "react-dropzone";
import FilesTable, { filesTableTypes } from "../../tables/CustomFilesTable/FilesTable";
import FilesCard, { filesCardType } from "../../cards/FilesCard/FIlesCard";
import { useDispatch } from "react-redux";
import getTheUserState from "../../../utils/userState";
import types from "../../../actions/types";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { fileName } from "../../../utils/regexList";
import { filesService } from "../../../services/files";

export const uploadTypes = {
  fManager: "file-manager",
  project: "project",
};

const stepTypes = {
  forward: "forward",
  back: "back",
};

const FilesContainer = ({
  handleUploadActivation,
  type,
  setIsUploadActive,
  projectName,
  uploadFrom,
  project,
}) => {
  const dispatch = useDispatch();
  const user = getTheUserState();

  const [isInfoVisible, setIsInfoVisibile] = useState(false);
  const [files, setFiles] = useState([]);
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [step, setStep] = useState(0);
  const [forceRerender, setForceRerender] = useState(0);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [step]);

  useEffect(() => {
    setFiles(files);
    setRejectedFiles(rejectedFiles);
  }, [files, rejectedFiles]);

  // Select Files and put them in the accepted/rejected categories.
  const onDrop = (data, rej) => {
    const accepted = [];
    const rejected = [];

    const currentFileName = data[0]?.name.split(".")[0];
    const checkForExistingFile = files?.filter(
      (f) => f.file?.name?.split(".")[0] === currentFileName
    );
    if (checkForExistingFile?.length) {
      return;
    }

    if (data) {
      data.forEach((file) => {
        // let renamedFile;
        // const spaceCount = (file?.name.match(/\s/g) || []).length;
        // if (spaceCount > 0) {
        //   const updatedFile = file?.name.replaceAll(" ", "-");
        //   renamedFile = new File([file], updatedFile, { type: file?.type });
        // }

        if (uploadFrom === uploadTypes.fManager) {
          const fileObj = {
            // file: spaceCount > 0 ? renamedFile : file,
            file: file,
            settings: {
              size: file?.size ? file?.size : null,
              status: "",
              description: "",
              maturity_level: 0,
              companies: "",
              time_to_use: "undefined",
              uploader_id: user?.user?.id,
              is_private: true,
              is_general: false,
              available_for: {
                value: "",
                name: "",
              },
              terms_id: "",
              terms: {
                type: "",
                data: [],
              },
              available: {
                type: "",
                data: [],
              },
              isValid: false,
            },
          };

          accepted.push(fileObj);
        } else {
          const fileObj = {
            // file: spaceCount > 0 ? renamedFile : file,
            file: file,
            settings: {
              size: file?.size ? file?.size : null,
              status: "",
              description: "",
              maturity_level: 0,
              companies: "",
              time_to_use: "undefined",
              uploader_id: user?.user?.id,
              is_private: false,
              is_general: false,
              available_for: {
                value: "",
                name: "",
              },
              terms_id: "",
              terms: {
                type: "",
                data: [],
              },
              company_id: project?.company_id ? project?.company_id : null,
              project_id: project?.id ? project?.id : null,
              team_id: project?.team_id,
              isValid: false,
            },
          };

          accepted.push(fileObj);
        }
      });
    }

    if (rej) {
      rej?.forEach((file) => {
        let customError = null;
        if (file.errors) {
          file.errors.forEach((error) => {
            if (error.code === "file-invalid-type") {
              customError =
                "The file format you are trying to upload is not supported. Check information.";

              error.message = customError;
            } else if (error.code === "file-too-large") {
              customError = "File is larger than 100 MB";
              error.message = customError;
            } else if (error.code === "invalid-file-name") {
              customError = "Only allowed special characters: - and _ and parentheses: ( )";
              error.message = customError;
            } else if (error.code === "file-name-exists") {
              customError = "File with that name already exists.";
            }
          });
        }
        rejected.push({ file });
      });
    }
    setFiles([...files, ...accepted]);
    setRejectedFiles([...rejectedFiles, ...rejected]);
  };

  // Custom file name length validation
  function nameLengthValidator(file) {
    const maxLength = 40;
    if (file?.name?.length > maxLength) {
      return {
        code: "name-too-large",
        message: `Name is larger than ${maxLength} characters`,
      };
    }
    return null;
  }

  // Custom file name special characters validation
  const forbiddenCharacterValidator = (file) => {
    // const forbiddenCharacters =
    //   /^(?!.*\s{2,})(?!.*[^A-Za-z0-9 _-])(?!^\s)(?!.*\s$)[A-Za-z0-9 _-]+$/;

    const forbiddenCharacters =
      /^(?!.*\s{2,})(?!.*[^A-Za-z0-9 _()\-ÄÖÜäöüß])(?!^\s)(?!.*\s$)[A-Za-z0-9 _()\-ÄÖÜäöüß]+$/;

    const currentFileName = file?.name.split(".")[0];
    if (!forbiddenCharacters.test(currentFileName)) {
      return {
        code: "invalid-file-name",
        message: "Only allowed special characters: - and _ and parentheses: ( )",
      };
    }
    return null;
  };

  const checkIfFileAlreadyExists = async (file) => {
    const currentFileName = file?.name.split(".")[0];

    const result = await filesService.checkIfFileExists({ file_name: currentFileName });
    if (!result?.data?.isValid) {
      return {
        code: "file-name-exists",
        message: "File with that name already exists.",
      };
    }
    return null;
  };

  function combinedValidator(file) {
    const validators = [nameLengthValidator, forbiddenCharacterValidator]; // Add More Custom Validators here

    for (let validator of validators) {
      const error = validator(file);
      if (error) {
        return error;
      }
    }

    return null;
  }

  // Here we can add more options to the dropzone component
  const { getRootProps, getInputProps, isDragActive, acceptedFiles, fileRejections, open } =
    useDropzone({
      onDrop,
      accept: {
        "text/html": [".html", ".htm", ".txt"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
        "image/png": [".png"],
        "image/svg+xml": [".svg"],
        "application/pdf": [".pdf"],
        "application/vnd.ms-excel": [".xls", ".docx"],
        "text/csv": [".csv"],
      },
      // validator: nameLengthValidator,
      validator: combinedValidator,
      maxSize: 104857600, // 100 mb
      noClick: true,
      noKeyboard: true,
    });

  // Cycle between upload/settings components
  function handleStep(type) {
    if (stepTypes.forward === type) {
      setStep((prev) => prev + 1);
    } else {
      setStep(0);
    }
  }

  // Remove file from the settings table
  function handleRemove(row, index) {
    const updatedAcceptedFiles = [...files];
    updatedAcceptedFiles.splice(index, 1);
    setFiles(updatedAcceptedFiles);
    setForceRerender((prev) => prev + 1);
  }

  // From here we update settings for every file.
  const handleUpdateFileSettings = async (index, property, value) => {
    const updatedFiles = [...files];

    if (updatedFiles[index] && updatedFiles[index].file) {
      if (property === "companies") {
        updatedFiles[index] = {
          file: updatedFiles[index].file,
          settings: {
            ...updatedFiles[index].settings,
            available: {
              type: "",
              data: [],
            },
            available_for: {
              value: "",
              name: "",
            },
            is_general: false,
            is_private: false,
            [property]: value,
          },
        };
      } else if (property === "available") {
        updatedFiles[index] = {
          file: updatedFiles[index].file,
          settings: {
            ...updatedFiles[index].settings,
            is_general: false,
            is_private: false,
            companies: "",
            available_for: {
              value: "",
              name: "",
            },
            [property]: value,
          },
        };
      }

      updatedFiles[index] = {
        file: updatedFiles[index].file,
        settings: {
          ...updatedFiles[index].settings,
          [property]: value,
        },
      };

      if (
        !updatedFiles[index]?.settings.time_to_use.includes("NaN") &&
        !updatedFiles[index]?.settings.terms.type !== ""
      ) {
        updatedFiles[index] = {
          file: updatedFiles[index].file,
          settings: {
            ...updatedFiles[index].settings,
            isValid: true,
            // is_general: false,
            // is_private: false,
          },
        };
      }

      setFiles(updatedFiles);
    }
  };

  // From here we update settings for private/general status.
  const handleUpdateFileStatus = (index, property1, value1, property2, object) => {
    const updatedFiles = [...files];

    if (updatedFiles[index] && updatedFiles[index].file) {
      updatedFiles[index] = {
        file: updatedFiles[index].file,
        settings: {
          ...updatedFiles[index].settings,
          [property1]: value1,
          [property2]: !value1,
        },
      };

      if (object.data.value === 2 || object.data.value === 3) {
        updatedFiles[index] = {
          file: updatedFiles[index].file,
          settings: {
            ...updatedFiles[index].settings,
            companies: "",
            available: {
              type: "",
              data: [],
            },
          },
        };
      }

      setFiles(updatedFiles);
    }
  };

  async function handleUploadFiles() {
    dispatch({
      type: types.SET_FILES,
      payload: files,
    });

    setIsUploadActive(false);
  }

  function handleButtonRestriction() {
    const filesLength = files?.length;
    let validFiles = 0;

    files.forEach((file) => {
      if (file?.settings?.terms?.data?.length) {
        validFiles++;
      }
    });

    if (filesLength === validFiles && filesLength > 0) {
      return false;
    }

    return true;
  }

  function checkTermsSettings(index) {
    const file = files[index];

    if (file?.settings?.terms?.type !== "") {
      return true;
    }

    return false;
  }

  let content;

  if (step === 0) {
    content = (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
        <div style={{ overflow: "auto", flex: 1 }}>
          <div className="files_container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="settings_title">Upload Files</p>
              <IconButton
                sx={{ marginTop: "20px", marginLeft: "10px" }}
                onClick={() => setIsInfoVisibile(true)}
              >
                <InfoOutlinedIcon sx={{ color: "#1E9DF2" }} />
              </IconButton>
              {isInfoVisible ? (
                <div
                  style={{
                    borderRadius: "8px",
                    background: "#FFF",
                    boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
                    maxWidth: "300px",
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "0px",
                    zIndex: 9999,
                    position: "absolute",
                    marginLeft: "200px",
                    marginTop: "150px",
                  }}
                >
                  <button
                    type="button"
                    style={{
                      width: "24px",
                      height: "24px",
                      alignSelf: "flex-end",
                      margin: "10px 20px -5px 0px",
                      color: "gray",
                      border: "none",
                      background: "transparent",
                      cursor: "pointer",
                      color: "#D1D2D2",
                    }}
                    onClick={() => setIsInfoVisibile(false)}
                  >
                    <ClearIcon />
                  </button>
                  <p
                    style={{
                      fontSize: "10px",
                      margin: "-15px 40px 15px 15px",
                      fontFamily: "IBM Plex Sans",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "16px",
                      letterSpacing: "0.2px",
                      color: "#1B1E1F",
                      maxWidth: "244px",
                    }}
                  >
                    This page lets you upload documents, images, and spreadsheets in various
                    formats, including DOCX, PNG, SVG, PDF, TXT, XLS, and CSV. The maximum file size
                    allowed is 100MB.
                  </p>
                </div>
              ) : null}
            </div>

            <div
              className="drop_zone"
              style={{ backgroundColor: isDragActive ? "#f0f0f0" : "white" }}
            >
              <div
                {...getRootProps()}
                style={{
                  width: "100%",
                  height: "148px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <input {...getInputProps()} />
                <p
                  style={{
                    textAlign: "center",
                  }}
                >
                  Drag and drop file or{" "}
                  <span style={{ color: "#1E9DF2", cursor: "pointer" }} onClick={open}>
                    click here to upload
                  </span>
                </p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {files?.length !== 0 && (
                <div>
                  <h3 className="files_status_label">Accepted Files</h3>
                  {files?.map((file, index) => {
                    return <FilesCard key={index} file={file} type={filesCardType.accepted} />;
                  })}
                </div>
              )}
              {rejectedFiles?.length !== 0 && (
                <div>
                  <h3 className="files_status_label">Rejected Files</h3>
                  {rejectedFiles.map((file, index) => {
                    return (
                      <FilesCard
                        key={index}
                        file={file?.file}
                        type={filesCardType.rejected}
                        errors={file?.file?.errors}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #E8E8E9",
            height: "80px",
            background: "white",
            flexShrink: 0,
          }}
        >
          <div className="files_footer">
            <div>
              <Button color="info" sx={actionButtons.cancelButton} onClick={handleUploadActivation}>
                Cancel
              </Button>
            </div>
            <div style={{ paddingRight: "31px" }}>
              <Button
                color="info"
                sx={files?.length !== 0 ? actionButtons.addButton : actionButtons.addButtonDisabled}
                disabled={files?.length !== 0 ? false : true}
                onClick={() => handleStep(stepTypes.forward)}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
        <div style={{ overflow: "auto", flex: 1 }}>
          <div className="files_container">
            <p className="settings_title">Upload Settings</p>
            {uploadTypes.project === type && (
              <p className="settings_sub_title">
                <b style={{ paddingRight: "10px" }}>Project:</b>
                {projectName}
              </p>
            )}
            <p className="settings_sub_title">
              <b style={{ paddingRight: "10px" }}>Files:</b>
              {files?.length}
            </p>
            <div style={{ paddingTop: "24px" }}>
              <FilesTable
                type={filesTableTypes.settings}
                uploadFrom={uploadFrom}
                data={files}
                handleUpdateFileSettings={handleUpdateFileSettings}
                handleUpdateFileStatus={handleUpdateFileStatus}
                handleRemove={handleRemove}
                companyID={project?.company_id}
                checkTermsSettings={checkTermsSettings}
                forceRerender={forceRerender}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            border: "1px solid #E8E8E9",
            height: "80px",
            background: "white",
            flexShrink: 0,
          }}
        >
          <div className="files_footer">
            {/* <button onClick={() => console.log("files", files)}>Log</button> */}
            <Button
              sx={actionButtons.cancelButton}
              color="info"
              onClick={() => handleStep(stepTypes.back)}
            >
              Back
            </Button>
            <div style={{ paddingRight: "31px" }}>
              <Button
                sx={
                  handleButtonRestriction()
                    ? actionButtons.addButtonDisabled
                    : actionButtons.addButton
                }
                onClick={() => handleUploadFiles(files)}
                disabled={handleButtonRestriction()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return content;
};

export default FilesContainer;

const actionButtons = {
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
