import axios from "axios";
import { options } from ".";
import { API_URL } from "../config";

export const newsServices = {
    getAllNews: () => axios(options(API_URL + "/news", {}, "get")),
    getNewsById: (data) => axios(options(API_URL + `/news/${data}`, {}, "get")),
    newsMutate: (data) => axios(options(API_URL + "/news", data, "post")),
    addToFavourites: (data) => axios(options(API_URL + "/news/addFavourites", data, "post")),
    pinNews: (data) => axios(options(API_URL + "/news/pin", data, "post")),
    deleteNews: (data) => axios(options(API_URL + "/news", data, "delete")),
};