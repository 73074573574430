import React from "react";
import "./usersStatus.css";
/* eslint-disable */
function UsersStatus({ count, title }) {
  return (
    <div className="users_status_container">
      <h2 className="users_status_count">{count ? count : 0}</h2>
      <p className="users_status_title">{title}</p>
    </div>
  );
}

export default UsersStatus;
