/* eslint-disable */
import { useEffect, useState } from "react";
import { Drawer, IconButton, Checkbox, TextField, Button } from "@mui/material";

// Icons
import CloseIcon from "@mui/icons-material/Close";
import ApartmentIcon from "@mui/icons-material/Apartment";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./filesAssignToUsers.css";

const stepperOptions = {
  0: "company",
  1: "team",
  2: "users",
};

const FilesAssignToUsersComponent = ({
  fileIndex,
  isOpen,
  setIsOpen,
  data,
  handleSelectOption,
  setSelectedCompanyValue,
  fileData,
  dataType,
  setDataType,
}) => {
  const [step, setStep] = useState(0);
  const [companyData, setCompanyData] = useState([]);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    setDataType(
      fileData?.available?.type !== "" ? fileData?.available : { type: "team", data: [] }
    );
  }, [isOpen]);

  const handleCloseDrawer = () => {
    setIsOpen(false);
  };

  function selectCompany(companyID) {
    const selectedCompany = data?.data?.companies.filter((c) => c.id === companyID);
    setCompanyData(selectedCompany);
    setStep((prev) => prev + 1);
  }

  function selectTeam(team) {
    setTeamData(team);
    setStep((prev) => prev + 1);
  }

  function handleSave() {
    handleSelectOption(fileIndex, "available", dataType);
    setIsOpen(false);
    setSelectedCompanyValue(0);
    setStep(0);
    console.log("dataType", dataType);
  }

  // Components Starts from Here

  const HandleCloseComponent = () => {
    return (
      <div style={assignUsersStyles.handleCloseContainer}>
        <IconButton aria-label="close" onClick={handleCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </div>
    );
  };

  const ResetAllComponent = (type) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "4px", marginBottom:"4px" }}>
        <span style={assignUsersStyles.resetTitle}>{type}</span>
        <button
          style={assignUsersStyles.resetBtn}
          onClick={() => setDataType({ type: stepperOptions[step], data: [] })}
        >
          Reset all
        </button>
      </div>
    );
  };

  const NoResultFromSearch = () => {
    return <p>There are no results for this search.</p>;
  };

  const NoData = ({ type }) => {
    let content;

    if (type === "company") {
      content = <p>There are not Companies!</p>;
    } else if (type === "team") {
      content = <p>This Team doesn't have any Users.</p>;
    } else if (type === "users") {
      content = <p>This Team doesn't have any Users.</p>;
    }

    return content;
  };

  const FooterComponent = () => {
    return (
      <div style={{
        display: "flex", alignItems: "center", justifyContent: "flex-end",
        padding: "20.5px 32px",
        borderTop: "1px solid  #E8E8E9",
      }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {step > 0 ? (
            <Button
              variant="text"
              onClick={() => {
                setStep((prev) => prev - 1);
                // setDataType({ type: stepperOptions[step - 1], data: [] });
              }}
              sx={assignUsersStyles.cancelButton}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ArrowBackIcon />
                <span style={{ paddingLeft: "5px" }}>Back</span>
              </div>
            </Button>
          ) : (
            <Button variant="text" onClick={handleCloseDrawer} sx={assignUsersStyles.cancelButton}>
              Cancel
            </Button>
          )}

          <Button
            variant="text"
            onClick={handleSave}
            sx={
              dataType.data?.length
                ? assignUsersStyles.addButton
                : assignUsersStyles.addButtonDisabled
            }
            disabled={dataType.data?.length ? false : true}
          >
            Save
          </Button>
        </div>
      </div>
    );
  };

  const CompanyStepComponent = () => {
    const [searchCompany, setSearchCompany] = useState("");

    const handleSearcCompany = (event) => {
      const searchValue = event.target.value;
      setSearchCompany(searchValue.toLowerCase());
    };

    const companyFilteredData = data?.data?.companies?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchCompany);
      return matchSearch;
    });

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>Choose Specific Customers</p>
        {ResetAllComponent("Choose Company")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchCompany}
          fullWidth
          onChange={handleSearcCompany}
        />
        {dataType?.data?.length ? <p>Selected Teams {dataType?.data?.length}</p> : null}
        <div style={assignUsersStyles.contentContainerStyles}>
          {data?.data?.companies?.length ? (
            companyFilteredData?.length ? (
              companyFilteredData?.map((company) => {
                return (
                  <div key={company?.id} style={assignUsersStyles.rowStyles} onClick={() => selectCompany(company?.id)} className="drawer_row">
                    <div style={assignUsersStyles.rowStylesInner}>
                      {/* <Checkbox /> */}
                      <div
                        style={assignUsersStyles.innerRowsStyles}
                      // onClick={() => selectCompany(company?.id)}
                      >
                        <ApartmentIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                        <p style={{ fontSize: "14px" }}>{company?.name}</p>
                      </div>
                    </div>

                    <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} fontSize="small" />
                  </div>
                );
              })
            ) : (
              <NoResultFromSearch />
            )
          ) : (
            <NoData type="company" />
          )}
        </div>
      </div>
    );
  };

  const TeamStepComponent = () => {
    const [searchTeam, setSearchTeam] = useState("");

    const handleSelectTeamCheckboxes = (team) => {
      // Find the index of the team
      console.log("team", team);
      const teamIndex = dataType.data.findIndex((t) => t.id === team?.id);

      // Check if the team is in the array of data
      if (teamIndex === -1) {
        setDataType((prevData) => ({
          ...prevData,
          data: [...prevData?.data, { id: team?.id, users: team?.users }],
        }));
      } else {
        setDataType((prevDataType) => ({
          ...prevDataType,
          data: prevDataType.data.filter((t) => t.id !== team?.id),
        }));
      }

      // team?.users.forEach((user) => {
      //   const userIndex = dataType.data.findIndex((usr) => usr === user?.id);

      //   if (userIndex === -1) {
      //     setDataType((prevData) => ({
      //       ...prevData,
      //       data: [...prevData?.data, user?.id],
      //     }));
      //   } else {
      //     setDataType((prevDataType) => ({
      //       ...prevDataType,
      //       data: prevDataType.data.filter((usr) => usr !== user?.id),
      //     }));
      //   }
      // });

      // const teamIndex = dataType.data.findIndex((team) => team.id === team?.id);
      // console.log("index", teamIndex);
      // if (teamIndex === -1) {
      //   // Team not found in the array, add it
      //   const selectedTeam = teamFilteredData.find((team) => team.id === team?.id);
      //   if (selectedTeam) {
      //     setDataType((prevDataType) => ({
      //       ...prevDataType,
      //       data: [...prevDataType.data, { id: selectedTeam?.id }],
      //     }));
      //   }
      // } else {
      //   // Team found in the array, remove it
      //   setDataType((prevDataType) => ({
      //     ...prevDataType,
      //     data: prevDataType.data.filter((team) => team.id !== team?.id),
      //   }));
      // }

      console.log("datatype", dataType);
    };

    const handleSearchTeam = (event) => {
      const searchValue = event.target.value;
      setSearchTeam(searchValue.toLowerCase());
    };

    const teamFilteredData = companyData[0].Teams?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchTeam);
      return matchSearch;
    });

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>{companyData[0]?.name}</p>
        {ResetAllComponent("Choose team or click on team to select a specific user(s)")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchTeam}
          fullWidth
          onChange={handleSearchTeam}
          disabled={companyData[0].Teams?.length < 1 ? true : false}
        />
        <div style={assignUsersStyles.contentContainerStyles}>
          {companyData[0].Teams?.length ? (
            teamFilteredData?.length ? (
              teamFilteredData?.map((team) => {
                return (
                  <div key={team?.id} style={assignUsersStyles.rowStyles} className="drawer_row">
                    <div style={assignUsersStyles.rowStylesInnerWidth}>
                      <Checkbox
                        checked={dataType?.data?.some((t) => t.id === team?.id)}
                        onChange={() => handleSelectTeamCheckboxes(team)}
                        disabled={team?.users?.length > 0 ? false : true}
                      />
                      <div
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr auto',
                          alignItems: 'center',
                          gap: '8px',
                          padding: '8px 0',
                          width: '100%',
                        }}
                        onClick={() => selectTeam(team)}>
                        <div
                          style={assignUsersStyles.innerRowsStyles}
                        // onClick={() => selectTeam(team)}
                        >
                          <GroupsIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                          <p style={{ fontSize: "14px" }}>{team?.name}</p>
                        </div>
                        <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} fontSize="small" />
                      </div>
                    </div>

                    {/* <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} fontSize="small" /> */}
                  </div>
                );
              })
            ) : (
              <NoResultFromSearch />
            )
          ) : (
            <NoData type="team" />
          )}
        </div>
      </div>
    );
  };

  const UserStepComponent = () => {
    const [searchUsers, setSearchUsers] = useState("");

    const handleSearchUsers = (event) => {
      const searchValue = event.target.value;
      setSearchUsers(searchValue.toLowerCase());
    };

    const usersFilteredData = teamData?.users?.filter((item) => {
      const matchSearch = item.name.toLowerCase().includes(searchUsers);
      return matchSearch;
    });

    console.log("teamData", teamData);

    const handleSelectUserCheckboxes = (team, user) => {
      const teamIndex = dataType.data.findIndex((item) => item.id === team.id);
      // const newData = dataType;
      // if (teamIndex === -1) {
      //   newData?.data.push({ id: team.id, users: [user] });
      //   setDataType(newData);
      //   // setDataType((prev) => [...prev, { data: [user] }]);
      // } else {
      //   const existingTeam = newData?.data[teamIndex];
      //   const userIndex = existingTeam.users.findIndex((u) => u.id === user.id);
      //   if (userIndex === -1) {
      //     existingTeam.users.push(user);
      //   } else {
      //     existingTeam.users.splice(userIndex, 1);
      //   }
      //   if (existingTeam.users.length === 0) {
      //     newData.splice(teamIndex, 1);
      //   }
      //   setDataType(newData);
      //   console.log("team is in the array");
      // }

      // console.log("datatype", dataType);
      setDataType((prevState) => {
        const newData = [...prevState.data];
        console.log("prevState", prevState);
        console.log("newData", newData);
        if (teamIndex === -1) {
          // Team does not exist, create it and add user
          newData.push({ id: team.id, users: [user] });
        } else {
          // Team exists
          const existingTeam = newData[teamIndex];
          const userIndex = existingTeam.users.findIndex((u) => u.id === user.id);
          if (userIndex === -1) {
            // User not in team, add user
            existingTeam.users.push(user);
          } else {
            // User in team, remove user
            existingTeam.users.splice(userIndex, 1);
            // If no users left, remove team
            if (existingTeam.users.length === 0) {
              newData.splice(teamIndex, 1);
            }
          }
        }
        return { type: "team", data: newData };
      });

      // Find the index of the team in the data array
      // const teamIndex = dataType.data.findIndex((item) => item.id === team.id);
      // if (teamIndex === -1) {
      //   // Team does not exist, create it and add user
      //   dataType.data.push({ id: team.id, users: [user] });
      // } else {
      //   // Team exists
      //   const existingTeam = dataType.data[teamIndex];
      //   const userIndex = existingTeam.users.findIndex((u) => u.id === user.id);
      //   if (userIndex === -1) {
      //     // User not in team, add user
      //     existingTeam.users.push(user);
      //   } else {
      //     // User in team, remove user
      //     existingTeam.users.splice(userIndex, 1);
      //     // If no users left, remove team
      //     if (existingTeam.users.length === 0) {
      //       dataType.data.splice(teamIndex, 1);
      //     }
      //   }
      // }
      // const checkForTeam = dataType.data?.some((t) => t.id === team?.id);
      // console.log("team", team);
      // console.log("check for team", checkForTeam);
    };

    return (
      <div style={assignUsersStyles.innerContainer}>
        <p style={assignUsersStyles.title}>{teamData?.name}</p>
        {ResetAllComponent("Choose User(s)")}
        <TextField
          variant="outlined"
          placeholder="Search..."
          size="small"
          value={searchUsers}
          fullWidth
          onChange={handleSearchUsers}
          disabled={teamData?.users?.length < 1 ? true : false}
        />
        <div style={assignUsersStyles.contentContainerStyles}>
          {teamData?.users?.length ? (
            usersFilteredData?.length ? (
              usersFilteredData?.map((user) => {
                const filterTeam = dataType?.data?.filter((t) => t.id === teamData?.id);
                const isUserChecked = filterTeam[0]?.users?.some((u) => u.id === user?.id);

                return (
                  <div key={user?.id} style={assignUsersStyles.rowStyles} className="drawer_row">
                    <div style={assignUsersStyles.rowStylesInner}>
                      <Checkbox
                        checked={isUserChecked}
                        onChange={() => handleSelectUserCheckboxes(teamData, user)}
                      />
                      <div
                        style={assignUsersStyles.innerRowsStyles}
                      // onClick={() => selectTeam(team?.id)}
                      >
                        <PersonIcon fontSize="small" sx={assignUsersStyles.dataIcon} />
                        <p style={{ fontSize: "14px" }}>{user?.name}</p>
                      </div>
                    </div>
                    {/* <ArrowForwardIosIcon sx={assignUsersStyles.iconStyle} /> */}
                  </div>
                );
              })
            ) : (
              <NoResultFromSearch />
            )
          ) : (
            <NoData type="users" />
          )}
        </div>
      </div>
    );
  };

  const stepperValues = {
    0: <CompanyStepComponent />,
    1: <TeamStepComponent />,
    2: <UserStepComponent />,
  };

  return (
    <div>
      <Drawer
        anchor="right"
        open={isOpen}
        sx={assignUsersStyles.drawerRoot}
        onClose={handleCloseDrawer}
      >
        <HandleCloseComponent />
        {stepperValues[step]}
        <FooterComponent />
      </Drawer>
    </div>
  );
};

export default FilesAssignToUsersComponent;

const assignUsersStyles = {
  dataIcon: {
    paddingRight: "9px",
  },
  iconStyle: {
    color: "#1E9DF2",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px", overflow: "hidden" },
    "& .MuiBackdrop-root": { background: "transparent" },
    "& .MuiDrawer-paper": {
      height: "100vh",
    }
  },
  handleCloseContainer: {
    padding: "10px 32px 0px 32px",
    display: "flex",
    justifyContent: "flex-end",
  },
  innerContainer: {
    padding: "0px 32px 48px 32px",
    minWidth: "324px",
    // maxHeight: "550px",
    // height: "80.2%",
    height: "100%",
    minHeight: "550px",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  },
  rowStylesInner: {
    display: "flex",
    alignItems: "center",
    // minWidth: "100%",
    // borderBottom: "1px solid #E8E8E9",
  },
  rowStylesInnerWidth: {
    display: "flex",
    alignItems: "center",
    minWidth: "100%",
    // borderBottom: "1px solid #E8E8E9",
  },
  rowStyles: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #E8E8E9",
    cursor: "pointer",
  },
  innerRowsStyles: { display: "flex", alignItems: "center", cursor: "pointer", },
  formLabel: { "& .MuiTypography-root": { fontSize: "11px" } },
  buttons: {
    textTransform: "Capitalize",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "600",
    fontSize: "23px",
    lineHeight: "32.2px",
    letterSpace: "0.20px",
    maxWidth: "219px",
    minWidth: "219px",
    marginTop: "0px",
  },
  accordionLabels: {
    fontFamily: "IBM Plex Sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.8px",
    letterSpace: "0.20px",
    paddingLeft: "15px",
  },
  addButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  addButtonDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "120px",
    height: "40px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
  resetBtn: {
    background: "transparent",
    cursor: "pointer",
    border: "none",
    color: "#1E9DF2",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18.2px",
    fontFamily: "IBM Plex Sans",
    padding: "0px",
  },
  resetTitle: {
    background: "transparent",
    cursor: "pointer",
    border: "none",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "18.2px",
    fontFamily: "IBM Plex Sans",
  },
  contentContainerStyles: { minHeight: "430px", overflow: "auto", flexGrow: "1" },
};
