/* eslint-disable */
import "./filesCard.css";

export const filesCardType = {
  accepted: "accepted",
  rejected: "rejected",
};

const extensionsColorSchema = {
  txt: "txt",
  pdf: "pdf",
  svg: "svg",
  png: "png",
  csv: "csv",
  docx: "docx",
  xsl: "xsl",
};

const FilesCard = ({ file, type, errors }) => {
  const fileData = file?.file?.name?.split(".");
  const fileSize = file?.file?.size;
  let fileName = fileData[0]?.length > 40 ? fileData[0].slice(0, 40).concat("...") : fileData[0];
  let fileExtention = fileData[1];

  // Format the file sizes = b, kb and Mb
  function formatFileSize(fileSizeInBytes) {
    let kilobyteThreshold = 1024;
    let megabyteThreshold = kilobyteThreshold * 1024;

    if (fileSizeInBytes < kilobyteThreshold) {
      return fileSizeInBytes + " B";
    } else if (fileSizeInBytes < megabyteThreshold) {
      return (fileSizeInBytes / kilobyteThreshold).toFixed(2) + " KB";
    } else {
      return (fileSizeInBytes / megabyteThreshold).toFixed(2) + " MB";
    }
  }

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div className="files_card_container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className={`files_card_status ${
              filesCardType.accepted === type ? "status_accepted" : "status_rejected"
            }`}
          />
          <p className="files_card_title">{fileName}</p>
          <span className={`files_extension ${extensionsColorSchema[fileExtention]}`}>
            {fileExtention}
          </span>
        </div>
        <p className="files_card_size">{formatFileSize(fileSize)}</p>
      </div>
      {filesCardType.rejected === type ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "24px",
            alignSelf: "center",
          }}
        >
          {errors?.map((e) => (
            <p className="files_card_error" key={e.code}>
              {e.message}
            </p>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default FilesCard;
