/* eslint-disable */
import "./noContent.css";
import DefaultButton, { buttonTypes } from "../../company/defaultButton";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";

const NoContentComponent = ({
  contentTitle,
  title,
  subTitle,
  buttonTitle,
  buttonActionFunction,
}) => {
  return (
      <div className="no_content_container">
        <h3 className="no_content_title">{contentTitle}</h3>
        <div className="content" style={{ flex: 1, overflow: "hidden", position: "relative" }}>
          <div className="no_content_wrapper" style={{ zIndex: "9999" }}>
            <div className="no_content_inner">
              <p className="inner_title">{title}</p>
              <p className="inner_sub_text">{subTitle}</p>
              {buttonTitle === "Create Company" ? (
                <PermissionWrapper allowedPermission={PermissionsSchema.companies.create_company}>
                  <DefaultButton
                    buttonType={buttonTypes.create}
                    title={buttonTitle}
                    onClickEvent={buttonActionFunction}
                  />
                </PermissionWrapper>
              ) : (
                <DefaultButton
                  buttonType={buttonTypes.create}
                  title={buttonTitle}
                  onClickEvent={buttonActionFunction}
                />
              )}
            </div>
          </div>
        </div>
      </div>
  );
};

export default NoContentComponent;
