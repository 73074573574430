/* eslint-disable */

import { Typography } from "@mui/material";

const NoNews = () => {

    return (
        <div style={{ marginTop: "24px", minWidth: "188px", display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
            <Typography sx={styles.title}>No news available</Typography>
            <Typography sx={styles.paragraph}>just one step to create the first news</Typography>
        </div>
    );
}

export default NoNews;

const styles = {
    title: {
        color: "#1B1E1F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "23px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "140%",//32.2px
        letterSpacing: "0.46px",
        textAlign:"left"
    },
    paragraph: {
        color: "#8D8E8F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "150%",//16.5px
        letterSpacing: "0.11px",
        width: "133px",
        textAlign:"left"
    }
}