/* eslint-disable */
import React from "react";
import "./fileStatus.css";

export const fileStatusColorSchemaArray = [
  "Access required",
  "Access provided",
  "Access declined",
  "Requested",
];

export function FileStatusColorSchemaResolver(fileStatus) {
  const getColorByString = (value) => {
    switch (value) {
      case "Access required":
        return "required";
      case "Access provided":
        return "provided";
      case "Access declined":
        return "declined";
      case "Requested":
        return "requested";
      default:
        "Access required";
        break;
    }
  };

  let content;

  if (fileStatus) {
    content = (
      <div className="box_container">
        <span className={`status_box ${getColorByString(fileStatus)}`} />
        <span>{fileStatus}</span>
      </div>
    );
  } else {
    content = (
      <div className="box_container">
        <span className={`status_box ${getColorByString("Access required")}`} />
        <span>Access required</span>
      </div>
    );
  }

  return content;
}

export function FileStatusBox(fileStatus) {
  const getColorByString = (value) => {
    switch (value) {
      case "Access required":
        return "required";
      case "Access provided":
        return "provided";
      case "Access declined":
        return "declined";
      case "Requested":
        return "requested";
      default:
        "Access required";
        break;
    }
  };

  return <span className={`status_box ${getColorByString(fileStatus)}`} />;
}
