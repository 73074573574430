/* eslint-disable */
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmInCatalog = ({ file, open, onClose, action }) => {

    const handleActionExecution = () => {
        action();
        onClose();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={onClose}
                sx={{
                    "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
                }}
            >
                <div style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px" }}>
                    <IconButton onClick={onClose}>
                        <CloseIcon color="disabled" />
                    </IconButton>
                </div>
                <DialogContent sx={muiDialogStyles.contentStyles}>
                    <DialogTitle sx={muiDialogStyles.dialogTitle}>
                        {file?.file_catalog === 0
                            ? `Are you sure you want to add this file to the file catalog? If you do it, it will be visible to all users.`
                            : `Are you sure you want to remove this file from the file catalog?`}
                    </DialogTitle>
                    <p style={muiDialogStyles.companyTitle}>{file?.file_name}</p>
                </DialogContent>
                <div
                    style={muiDialogStyles.fileActionsContainer}
                >
                    <Button onClick={handleActionExecution} autoFocus sx={muiDialogStyles.btn}>
                        {`${file?.file_catalog === 0 ? "Add" : "Remove"}`}
                    </Button>
                    <Button onClick={onClose} sx={muiDialogStyles.btnCancel}>
                        Cancel
                    </Button>
                </div>
            </Dialog>
        </div >
    );
}

export default ConfirmInCatalog;

const muiDialogStyles = {
    dialogTitle: {
        color: "#1B1E1F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "44.8px",
        letterSpacing: "-0.64px",
        maxWidth: "80%",
        alignSelf: "center",
    },
    companyTitle: {
        color: "#1B1E1F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "23px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "32.2px",
        letterSpacing: "-0.46px",
        alignSelf: "center",
    },
    btn: {
        borderRadius: "2px",
        background: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        color: "white",
        "&:hover": {
            background: "#1B8DD9",
        },
    },
    btnCancel: {
        color: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        marginTop: "4px",
    },
    contentStyles: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 40px",
    },
    fileActionsContainer: {
        display: "flex",
        flexDirection: "row-reverse",
        justifyContent: "space-between",
        alignItems: "centar",
        padding: "32px 40px 40px 40px",
    }
};