/* eslint-disable */
import "./userProfilePage.css";
import "react-phone-input-2/lib/material.css";
import { useEffect, useState } from "react";
import { usersService } from "../../../services/users";
import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { getDateTime } from "../../../utils/misc";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import {
  nameRegex,
  userNameRegex,
  emailRegex,
  zipCodeRegex,
  passwordRegex,
  mainNameRegex,
  addressRegex,
} from "../../../utils/regexList";
import UserActionsComponent from "../../../components/UserActions/UserActionsComponent";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UserStatusComponent from "../../../components/widgets/UserStatusComponent";
import UserProjectsComponent from "../../../components/UserActions/UserProjectsComponent";
import PhoneInput from "react-phone-input-2";
import LoadingScreen from "../../../components/feedback/LoadingScreen";
import UserRoleDropdown from "../../../components/dropdown/UserRoleDropdown";
import CountriesDropdown from "../../../components/dropdown/CountriesDropdown";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import PermissionWrapper, { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import { companyServices } from "../../../services/company";
import { selectedType } from "../FileManagement/FileManagement";
import { makeStyles } from "@mui/styles";
import { LightTooltip } from "../../../components/tables/TermsTable/TermsTable";
import getTheUserState from "../../../utils/userState";
import FilesInUser from "../../../components/cards/FilesCard/FilesInUser";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1px solid #E8E8E9 !important",
    borderRadius: "0px !important",
    margin: "0px",
    padding: "0px",
    boxShadow: "none !important",
    width: "300px",
  },
  list: {
    padding: "0px !important",
    width: "300px",
    height: "350px",
  },
  select: {
    width: 300,
    height: 46,
    borderRadius: "4px",
    marginTop: "5px",
  },
  menuItem: {
    width: 300,
  },
}));

const UserProfile = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const userState = getTheUserState();

  const {
    control,
    handleSubmit,
    formState,
    setValue,
    getValues,
    reset,
    watch,
    clearErrors,
    trigger,
  } = useForm();
  const { isDirty, errors } = formState;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // Display the password info
  const [displayPasswordInfo, setDisplayPasswordInfo] = useState(false);
  const [phone, setPhone] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [companiesOptions, setCompaniesOptions] = useState([]);

  const checkPermission = userState?.user?.role?.permissions?.some((permission) => {
    return (
      permission.permission_name === PermissionsSchema?.companies?.view_all_companies &&
      permission.status === true
    );
  });

  const {
    data: allCompanies,
    isLoading: isLoadingCompanies,
    isError,
  } = useQuery({
    queryKey: ["allCompanies", id],
    queryFn: checkPermission
      ? companyServices.getAllCompanies
      : () => companyServices.getCompaniesAndTeamsByUserId(id),
  });

  // Validate password & confirm password matches
  const validatePasswordMatch = (value, values) => {
    if (value === values.pass) {
      return true;
    }
    return "Passwords do not match";
  };

  useEffect(() => {
    if (allCompanies?.data?.companies && !isLoadingCompanies) {
      const filtered = allCompanies?.data?.companies.map((value) => {
        return { id: value.id, name: value.name };
      });
      setCompaniesOptions(filtered);
    }
  }, [allCompanies?.data?.companies]);

  const { data: user, isLoading } = useQuery({
    queryKey: ["userById", id],
    queryFn: () => usersService.getUserById({ id: id }),
  });

  const updateUserMutation = useMutation({
    mutationFn: usersService.updateUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userById"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message || data?.response?.data?.message);
    },
  });

  useEffect(() => {
    if (user?.data && !isLoading) {
      const filteredCompanies = user?.data?.Companies.map((companyFromUser) => {
        return {
          id: companyFromUser?.id,
          name: companyFromUser?.name,
          isTheUserAdmin: companyFromUser?.CompanyUser?.isAdmin,
        };
      });
      setValue("company", filteredCompanies);
    }
  }, [user?.data]);
  const companiesDefault = getValues("company");
  const companiesFromForm = watch("company", companiesDefault);
  const setDefaultValues = () => {
    const filteredCompanies = user?.data?.Companies.map((companyFromUser) => {
      return { id: companyFromUser.id, name: companyFromUser.name };
    });
    setValue("user", user?.data?.user);
    setValue("firstName", user?.data?.firstName);
    setValue("lastName", user?.data?.lastName);
    setValue("email", user?.data?.email);
    setValue("company", filteredCompanies);
    setValue("companyURL", user?.data?.companyURL);
    setValue("company_user_input", user?.data?.company_user_input);
    setValue("businessRole", user?.data?.businessRole);
    setValue("role_id", user?.data?.role_id);
    setValue("country", user?.data?.country);
    setValue("industry", user?.data?.industry);
    setValue("province", user?.data?.province);
    setValue("city", user?.data?.city);
    setValue("zipCode", user?.data?.zipCode);
    setValue("id", user?.data?.id);
    setValue("pass", "");
    setValue("confirmPassword", "");
    setSelectedRole(user?.data?.role_id);
  };

  useEffect(() => {
    if (user?.data) {
      setDefaultValues();
      reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
    }
  }, [user?.data]);

  function handelClear() {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }

  useEffect(() => {
    setSelectedRole(user?.data?.role_id);
  }, [user]);

  const countryValue = watch("country");

  const checkPasswordErrors = () => {
    if (errors?.pass || errors?.confirmPassword) {
      setDisplayPasswordInfo(true);
    }
  };

  function isEmpty(obj) {
    return Object.entries(obj).length === 0;
  }

  useEffect(() => {
    checkPasswordErrors();
  }, [isEmpty(errors)]);

  // Compare the state before change and the state after change, to take only the properties that were changed.
  function getChangedProperties(originalObject, updatedObject) {
    const changedProperties = {};

    for (const key in updatedObject) {
      const originalValue = originalObject[key];
      const updatedValue = updatedObject[key];

      // Check if the value is different and not an empty string
      if (
        originalValue !== updatedValue
        //  && updatedValue !== ""
      ) {
        changedProperties[key] = updatedValue;
      }
    }

    // attach the user id & country name
    changedProperties.id = originalObject?.id;
    changedProperties.country = updatedObject?.country;

    return changedProperties;
  }

  async function onSubmit(data) {
    if (
      (data?.role_id || data?.company?.length > 0) &&
      (user?.data?.status === "registered" || user?.data?.status === "pending")
    ) {
      toast_notifications.error(
        "You can not change the company and role of user who is not active!"
      );
      handelClear();
      return;
    }

    // Check if pass and confirmPassword are empty and remove them from data
    if (!data.pass) {
      delete data.pass;
    }
    if (!data.confirmPassword) {
      delete data.confirmPassword;
    }

    data.phone = phone;
    //Company.name needed when CompaniesDropdown used so it does not break the backend
    const company = getValues("company");
    const uniqueIds = {};
    // Filter the array to keep only objects with unique IDs
    const uniqueCompanyArray = company?.filter((companyObject) => {
      if (!uniqueIds[companyObject.id]) {
        // If the ID is not yet encountered, mark it as seen and keep the object
        uniqueIds[companyObject.id] = true;
        return true;
      }
      return false;
    });
    const companyInfo = uniqueCompanyArray?.map((companyItem) => ({
      name: companyItem.name,
      id: companyItem.id,
    }));

    data.company = companyInfo;
    data.teams = user?.data?.Teams;
    const diff = getChangedProperties(user?.data, data);
    updateUserMutation.mutate(diff);
  }

  const submitForm = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      checkPasswordErrors();
      return;
    } else {
      // Submit The form
      handleSubmit(onSubmit)();
    }
  };

  const handleChange = (event, type, option) => {
    clearErrors("company");
    const isChecked = event.target.checked;
    const value = option;

    const companiesDefault = getValues("company");
    const currentCompanies = [...companiesDefault];
    if (isChecked) {
      currentCompanies.push(value);
      setValue("company", currentCompanies, { shouldDirty: true });
    } else {
      // if()
      const newCompanies = currentCompanies.filter((item) => item.id !== value.id);
      setValue("company", newCompanies, { shouldDirty: true });
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
        <div style={{ overflowY: "auto", overflowX: "hidden", flex: 1, position: "relative" }}>
          <div className="user_profile_main_content">
            {isLoading ? <p>Loading...</p> : <UserActionsComponent user={user?.data || {}} />}
            {isLoading ? (
              <LoadingScreen />
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="user"
                  control={control}
                  defaultValue={user?.data?.user}
                  disabled={user?.data?.status === "registered"}
                  rules={{
                    required: "Name is required",
                    pattern: {
                      value: nameRegex,
                      message: "Invalid Username. Use letters or numbers.",
                    },
                    minLength: {
                      value: 4,
                      message: "The username should contain at least 4 letters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      focused
                      label="Username"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="standard"
                      placeholder="Username"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <BorderColorIcon sx={{ color: "#8D8E8" }} />
                          </InputAdornment>
                        ),
                      }}
                      sx={userFormUi.userName}
                    />
                  )}
                />

                <div style={{ display: "flex", alignItems: "center" }}>
                  {UserStatusComponent(user?.data?.status)}
                  <span className="user_info_separator">•</span>
                  <span className="user_info_text">ID: {user?.data?.id}</span>
                  <span className="user_info_separator">•</span>
                  <p className="user_info_text">
                    Created:
                    <span className="user_info_date">{getDateTime(user?.data?.createdAt)}</span>
                  </p>
                </div>
                <div style={{ paddingBottom: "42px" }}>
                  <span className="user_info_text">
                    Companies:{" "}
                    {user?.data?.Companies?.length > 0
                      ? user?.data?.Companies?.map((company, index) => (
                        <span key={company?.id}>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/dashboard/companies/${company?.id}`}
                          >
                            {company?.name}
                            {/* {user?.data?.Companies?.length + 1 === index ? ", " : ""} */}
                          </Link>
                          {index < user?.data?.Companies?.length - 1 ? ", " : ""}
                        </span>
                      ))
                      : "No Companies!"}
                  </span>
                </div>
                <div className="input_container">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={user?.data?.email}
                    disabled={user?.data?.status === "registered"}
                    rules={{
                      pattern: {
                        value: emailRegex,
                        message: "Invalid email address.",
                      },
                      required: "This field is required!",
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Email *"
                        placeholder="Email"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <div
                    className="double_row"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Controller
                          name="pass"
                          control={control}
                          disabled={user?.data?.status === "registered"}
                          defaultValue=""
                          rules={{
                            pattern: {
                              value: passwordRegex,
                              message: "Invalid Password check the information.",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              label="Password"
                              placeholder="Password"
                              variant="outlined"
                              size="small"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              {...field}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                autoComplete: "new-password",
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ marginRight: "38px" }}>
                                    <IconButton
                                      aria-label="toggle-password-visibility"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={userFormUi.textField}
                            />
                          )}
                        />
                        <IconButton
                          onClick={() => setDisplayPasswordInfo(true)}
                          sx={{
                            position: "absolute",
                            marginTop: "8px",
                            left: "246px",
                            zIndex: "999",
                          }}
                        >
                          <InfoOutlinedIcon sx={{ color: "#1E9DF2" }} />
                        </IconButton>

                        {displayPasswordInfo ? (
                          <div
                            style={{
                              borderRadius: "8px",
                              background: "#FFF",
                              boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
                              width: "350px",
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "100px",
                              zIndex: 999,
                              position: "absolute",
                              top: "50px",
                              left: "-25px",
                              zIndex: "9999",
                            }}
                          >
                            <button
                              type="button"
                              style={{
                                width: "24px",
                                height: "24px",
                                alignSelf: "flex-end",
                                margin: "10px 20px -5px 0px",
                                color: "gray",
                                border: "none",
                                background: "transparent",
                                cursor: "pointer",
                                color: "#D1D2D2",
                              }}
                              onClick={() => setDisplayPasswordInfo(false)}
                            >
                              <ClearIcon />
                            </button>
                            <p
                              style={{
                                fontSize: "11px",
                                margin: "0px 40px 25px 25px",
                                fontFamily: "IBM Plex Sans",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "16px",
                                letterSpacing: "0.2px",
                                color: "#1B1E1F",
                              }}
                            >
                              To create a valid password, it is necessary to use a combination of at
                              least 8 characters, including both uppercase and lowercase letters, a
                              number, and one of the special characters such as @, $, !, %, *, ?, or
                              &. Using any other special characters besides these is not allowed.
                            </p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <Controller
                      name="confirmPassword"
                      control={control}
                      defaultValue=""
                      disabled={user?.data?.status === "registered"}
                      rules={{
                        pattern: {
                          value: passwordRegex,
                          message: "Invalid Password check the information.",
                        },
                        validate: validatePasswordMatch,
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          variant="outlined"
                          size="small"
                          // autoComplete="off"
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          {...field}
                          type={showConfirmPassword ? "text" : "password"}
                          InputProps={{
                            autoComplete: "off",
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle-password-visibility"
                                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={userFormUi.textField}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="role_id"
                    control={control}
                    defaultValue={user?.data?.role_id}
                    disabled={user?.data?.status === "registered"}
                    rules={{
                      required: "This field is required!",
                    }}
                    render={({ field, fieldState }) => (
                      <UserRoleDropdown
                        selectedRole={selectedRole}
                        setSelectedRole={setSelectedRole}
                        field={field}
                        fieldState={fieldState}
                      />
                    )}
                  />
                  <Controller
                    name="company"
                    control={control}
                    disabled={user?.data?.status === "registered"}
                    rules={{
                      required: "This field is required!",
                      pattern: {
                        value: mainNameRegex,
                        message: "Please use only letters and numbers.",
                      },
                    }}
                    render={({ field, fieldState }) => {
                      return (
                        // <CompaniesDropdown field={field} fieldState={fieldState} />
                        <FormControl
                          sx={{ display: "flex", flexDirection: "column", margin: "0px" }}
                        >
                          <InputLabel
                            sx={{
                              color:
                                user?.data?.status === "registered"
                                  ? "#D1D2D2"
                                  : fieldState.error
                                    ? "#D22B2B"
                                    : "",
                              top: "-2px",
                            }}
                            id="demo-select-small-label"
                          >
                            Company *
                          </InputLabel>
                          <Select
                            multiple
                            className={classes.select}
                            sx={{
                              borderRadius: "4px",
                              // margin: "0px",
                              // ".MuiSelect-icon": { color: "#1E9DF2" },
                            }}
                            disabled={user?.data?.status === "registered"}
                            error={!!fieldState.error}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={companiesFromForm || []}
                            // onChange={(e) => handleChange(e, selectedType.companiesType)}
                            input={<OutlinedInput label="Company *" />}
                            MenuProps={{
                              classes: {
                                paper: classes.paper,
                                list: classes.list,
                              },
                            }}
                            renderValue={(selected) => {
                              return selected.map((option) => `${option.name}`).join(", ");
                            }}
                          >
                            <MenuItem sx={userFormUi.menuItem} value={-1} disabled>
                              <span style={{ fontSize: "16px" }}>Choose an option...</span>
                            </MenuItem>
                            {!isLoadingCompanies &&
                              companiesOptions?.map((option) => {
                                const isSelected = companiesFromForm?.some(
                                  (object) => object.id === option.id
                                );
                                const isTheUserAdmin = companiesFromForm?.some(
                                  (object) =>
                                    object.id === option.id && object.isTheUserAdmin === true
                                );
                                const tooltipTitle = `${user?.data?.user} is company admin of ${option.name}.
                               In order to uncheck ${option.name} you need to change company admin first.`;
                                return isTheUserAdmin ? (
                                  <LightTooltip title={tooltipTitle}>
                                    <MenuItem
                                      key={option.id}
                                      value={option}
                                      sx={userFormUi.menuItem}
                                    >
                                      <Checkbox
                                        disabled={isTheUserAdmin}
                                        checked={isSelected}
                                        onChange={(e) =>
                                          handleChange(e, selectedType.companiesType, option)
                                        }
                                      />
                                      <ListItemText
                                        disabled={isTheUserAdmin}
                                        primary={`${option.name}`}
                                      />
                                    </MenuItem>
                                  </LightTooltip>
                                ) : (
                                  <MenuItem
                                    disabled={isTheUserAdmin}
                                    key={option.id}
                                    value={option}
                                    sx={userFormUi.menuItem}
                                  >
                                    <Checkbox
                                      checked={isSelected}
                                      onChange={(e) =>
                                        handleChange(e, selectedType.companiesType, option)
                                      }
                                    />
                                    <ListItemText primary={`${option.name}`} />
                                  </MenuItem>
                                );
                              })}
                          </Select>
                          {fieldState.error && (
                            <FormHelperText error>{fieldState.error.message}</FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  />
                  <div
                    className="double_row"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                    }}
                  >
                    <Controller
                      name="firstName"
                      control={control}
                      defaultValue={user?.data?.firstName}
                      rules={{
                        required: "First Name is required",
                        pattern: {
                          value: userNameRegex,
                          message: "Please use only letters.",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="First name"
                          placeholder="First name"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          {...field}
                          sx={userFormUi.textField}
                        />
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      defaultValue={user?.data?.lastName}
                      rules={{
                        required: "Last Name is required",
                        pattern: {
                          value: userNameRegex,
                          message: "Please use only letters.",
                        },
                      }}
                      render={({ field, fieldState }) => (
                        <TextField
                          label="Last name"
                          placeholder="Last name"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          error={!!fieldState.error}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          {...field}
                          sx={userFormUi.textField}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    name="country"
                    control={control}
                    defaultValue={user?.data?.country}
                    value={countryValue}
                    rules={{
                      required: "This field is required!",
                    }}
                    render={({ field, fieldState }) => (
                      <CountriesDropdown field={field} fieldState={fieldState} />
                    )}
                  />
                  <Controller
                    name="province"
                    control={control}
                    defaultValue={user?.data?.province}
                    rules={{
                      pattern: {
                        value: nameRegex,
                        message: "Please use only letters and numbers.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="State / Province"
                        placeholder="Enter state or province"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    defaultValue={user?.data?.city}
                    rules={{
                      required: "City is required",
                      pattern: {
                        value: userNameRegex,
                        message: "Please use only letters.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="City"
                        placeholder="City"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="address"
                    control={control}
                    defaultValue={user?.data?.address}
                    rules={{
                      pattern: {
                        value: addressRegex,
                        message: "Please use letters and numbers only.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Address"
                        placeholder="Enter address"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="businessRole"
                    control={control}
                    defaultValue={user?.data?.businessRole}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Business Role (Optional)"
                        placeholder="Business Role"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="zipCode"
                    control={control}
                    defaultValue={user?.data?.zipCode}
                    rules={{
                      required: "This field is required!",
                      minLength: {
                        value: 4,
                        message: "Zip Code should contain at least 4 digets.",
                      },
                      pattern: {
                        value: zipCodeRegex,
                        message: "Invalid Zip Code. Please use only numbers.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Zip Code"
                        placeholder="Zip Code"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <PhoneInput
                    country={"de"}
                    onChange={(phone) => setPhone(phone)}
                    enableSearch={true}
                    value={user?.data?.phone}
                    inputStyle={{
                      borderRadius: "4px",
                      fontSize: "14px",
                      marginTop: "5px",
                      marginRight: "13.5px",
                      padding: "13.5px 14px 14.5px 58px",
                    }}
                    searchStyle={{ border: "none", margin: "0", width: "100%", padding: "0" }}
                    disableSearchIcon={false}
                    className="phone-input-form"
                  />
                  <Controller
                    name="companyURL"
                    control={control}
                    defaultValue={user?.data?.companyURL}
                    rules={{
                      maxLength: {
                        value: 30,
                        message: "Maximum allowed 30 characters.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Company URL (optional)"
                        placeholder="Company URL (optional)"
                        variant="outlined"
                        size="small"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        InputProps={{
                          autoComplete: "new-password",
                        }}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="industry"
                    control={control}
                    defaultValue={user?.data?.industry}
                    rules={{
                      maxLength: {
                        value: 20,
                        message: "Maximum allowed 20 characters.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Industry (optional)"
                        placeholder="Industry (optional)"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                  <Controller
                    name="company_user_input"
                    control={control}
                    defaultValue={user?.data?.company_user_input}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Company User Input"
                        placeholder="Company User Input"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={userFormUi.textField}
                      />
                    )}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #E8E8E9",
            // height: "80px",
            background: "white",
            flexShrink: 0,
          }}
        >
          <footer className="user_profile_footer">
            <PermissionWrapper allowedPermission={PermissionsSchema.users.update}>
              <div
                style={{
                  width: "380px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginRight: "24px",
                }}
              >
                <Button
                  sx={userFormUi.cancelBtn}
                  onClick={() => handelClear()}
                  disabled={
                    !isDirty ||
                    user?.data?.status === "registered" ||
                    user?.data?.status === "pending"
                  }
                >
                  Discard Changes
                </Button>
                <Button
                  sx={
                    !isDirty ||
                      user?.data?.status === "registered" ||
                      user?.data?.status === "pending"
                      ? userFormUi.saveBtnDisabled
                      : userFormUi.saveBtn
                  }
                  disabled={
                    !isDirty ||
                    user?.data?.status === "registered" ||
                    user?.data?.status === "pending"
                  }
                  onClick={submitForm}
                >
                  Save Changes
                </Button>
              </div>
            </PermissionWrapper>
          </footer>
        </div>
      </div>
      <div className="user_profile_projects_container">
        <UserProjectsComponent projects={user?.data?.Projects} userID={user?.data?.id} />
        <div style={{ height: "100%", overflow: "hidden" }}>
          <p
            style={{
              margin: "20px 20px",
              color: "#1B1E1F",
              fontFamily: "IBM Plex Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "32.2px",
              letterSpacing: "-0.46px",
            }}
          >
            Associated files
          </p>
          {/* <div style={{ overflowY: "auto", overflowX: "hidden", height: "100%",}}> */}
          <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100% - 76px)" }}>
            {user?.data?.Files?.map((file) => {
              return (
                <FilesInUser
                  id={file.id}
                  name={`${file.file_name}.${file.file_format}`}
                  key={file.id}
                />
              );
            })}
            {!user?.data?.Files?.length && (
              <p
                style={{
                  padding: "12px 24px",
                  textAlign: "center",
                  fontFamily: "IBM Plex Sans",
                  fontSize: "11px,",
                  fontStyle: "normal",
                  color: "red",
                }}
              >
                No files for this user
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;

const userFormUi = {
  userName: {
    maxWidth: "350px",
    "& .MuiInputBase-input": {
      color: "#1B1E1F",
      fontFamily: "IBM Plex Sans",
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "44.8px",
      letterSpacing: "-0.64px",
    },
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    minWidth: "300px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  menuItem: {
    paddingTop: "10px",
    paddingBottom: "10px",
    fontSize: "16px",
    letterSpacing: "0.14px",
    fontWeight: "400",
    borderTop: "1px solid #E8E8E9",
  },
  cancelBtn: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "140px",
    height: "54px",
    marginRigh: "15px",
  },
  saveBtn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  saveBtnDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
};
