/* eslint-disable */
import "./registerForm.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import {
    TextField,
    Grid,
    Typography,
    Paper,
    FormControlLabel,
    Checkbox,
    FormControl,
    IconButton,
    InputAdornment,
} from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff, Clear as ClearIcon } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
    nameRegex,
    userNameRegex,
    zipCodeRegex,
    passwordRegex,
    emailRegex,
    mainNameRegex,
    addressRegex,
} from "../../../utils/regexList";
import CountriesDropdown from "../../../components/dropdown/CountriesDropdown";
import LoadingScreen from "../../../components/feedback/LoadingScreen";
import STATIC_ROUTES from "../../../route/STATIC_ROUTES";
import i18n from "../../../services/i18n";
import { fetchSignUp } from "../../../services/auth";

const RegisterForm = () => {

    const dispatch = useDispatch();

    const {
        handleSubmit,
        formState: { errors },
        control,
        clearErrors
    } = useForm();

    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState();

    // Handle show/hide password & confirm password fields
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    //Handle the checkboxes
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [checkboxTerms, setcheckboxTerms] = useState(false);

    // Display the password info
    const [openPasswordValidation, setOpenPasswordValidation] = useState(false);

    const [phone, setPhone] = useState("");

    const checkPasswordErrors = () => {
        if (errors?.pass || errors?.confirmPassword) {
            setOpenPasswordValidation(!openPasswordValidation);
        }
    }

    useEffect(() => {
        checkPasswordErrors();
    }, [errors]);

    async function onSubmit(data) {
        data.phone = phone;
        const responseData = await dispatch(fetchSignUp(data));
        setResponse(responseData)
    }

    const submitForm = () => {
        if (Object.keys(errors).length > 0) {
            checkPasswordErrors();
            // Do not submit the forum
            return;
        } else {
            // Submit The form
            handleSubmit(onSubmit)();
        }
    };

    const validatePasswordMatch = (value, values) => {
        if (value === values.pass) {
            return true;
        }
        return "Passwords do not match";
    };


    return (<>
        {
            isLoading ? (
                <LoadingScreen />
            ) : (<>
                {!response?.isSignedUp ? (<Paper
                    sx={{
                        marginBottom: (errors?.pass && errors?.confirmPass) ? "0vh" : "4vh",
                        background: "transparent"
                    }}
                    elevation={0}
                >
                    <p className="registration_title">Registration</p>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="user"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "This field is required!",
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "The username should contain at least 4 letters."
                                            },
                                            pattern: {
                                                value: nameRegex,
                                                message: "Please use only letters or numbers.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Username *"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                variant="outlined"
                                                placeholder="Username"
                                                size="small"
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="email"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: emailRegex,
                                                message: "Please use a valid email.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Email *"
                                                placeholder="Email"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="off"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ position: "relative" }} >
                                <FormControl fullWidth={true}>
                                    <div style={{ position: "relative" }}>
                                        <Controller
                                            name="pass"
                                            control={control}
                                            defaultValue=""
                                            rules={{
                                                required: "This field is required!",
                                                pattern: {
                                                    value: passwordRegex,
                                                    message: "Invalid Password check the information.",
                                                },
                                            }}
                                            render={({ field, fieldState }) => (
                                                <TextField
                                                    label="Password *"
                                                    placeholder="Password"
                                                    variant="outlined"
                                                    size="small"
                                                    error={!!fieldState.error}
                                                    helperText={fieldState.error ? fieldState.error.message : null}
                                                    {...field}
                                                    type={showPassword ? "text" : "password"}
                                                    InputProps={{
                                                        autoComplete: "new-password",
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle-password-visibility"
                                                                    onClick={() => setShowPassword(!showPassword)}
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    sx={classes.textFieldAbsolute}
                                                />
                                            )}
                                        />
                                        <IconButton sx={{ position: "absolute", marginTop: "10px" }}
                                            onClick={() => setOpenPasswordValidation(true)}
                                        >
                                            <InfoOutlinedIcon sx={{ color: "#1E9DF2" }} />
                                        </IconButton>
                                    </div>
                                </FormControl>

                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="confirmPassword"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: passwordRegex,
                                                message: "Invalid Password check the information.",
                                            },
                                            validate: validatePasswordMatch,
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Confirm Password *"
                                                placeholder="Confirm Password"
                                                variant="outlined"
                                                size="small"
                                                // autoComplete="off"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                type={showConfirmPassword ? "text" : "password"}
                                                InputProps={{
                                                    autoComplete: "off",
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle-password-visibility"
                                                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                            >
                                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                {
                                    openPasswordValidation
                                    && (
                                        <div
                                            style={{
                                                borderRadius: "8px",
                                                background: "#FFF",
                                                boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
                                                maxWidth: "670px",
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: "-45px",
                                                zIndex: 999,
                                                position: "absolute",
                                                top: errors.pass || errors.confirmPassword ? "230px" : "190px",
                                            }}
                                        >
                                            <button
                                                type="button"
                                                style={{
                                                    width: "24px",
                                                    height: "24px",
                                                    alignSelf: "flex-end",
                                                    margin: "10px 20px -5px 0px",
                                                    color: "gray",
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                    color: "#D1D2D2",
                                                    zIndex: 1,
                                                }}
                                                onClick={() => setOpenPasswordValidation(false)}
                                            >
                                                <ClearIcon />
                                            </button>
                                            <p style={{ fontSize: "14px", margin: "0px 81px 25px 25px" }}>
                                                Your password must contain at least 8 characters, including both uppercase and lowercase letters,
                                                a number, and one of the special characters such as @, $, !, %, *, ?, or &.
                                            </p>
                                        </div>
                                    )
                                }
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} sx={classes.alignSecondContainer}>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: userNameRegex,
                                                message: "Please use only letters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="First name *"
                                                placeholder="First name"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="off"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: userNameRegex,
                                                message: "Please use only letters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Last name *"
                                                placeholder="Last name"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="off"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="company"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: mainNameRegex,
                                                message: "Please use only letters or letters and numbers.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Company *"
                                                placeholder="Company"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="companyURL"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            maxLength: {
                                                value: 30,
                                                message: "Maximum allowed 30 characters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Company URL (optional)"
                                                placeholder="Company URL (optional)"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="businessRole"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            maxLength: {
                                                value: 20,
                                                message: "Maximum allowed 20 characters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Business Role (optional)"
                                                placeholder="Business Role (optional)"
                                                variant="outlined"
                                                size="small"
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <PhoneInput
                                        country={"de"}
                                        onChange={(phone) => setPhone(phone)}
                                        enableSearch={true}
                                        value=""
                                        inputStyle={{
                                            borderRadius: "4px",
                                            fontSize: "14px",
                                            marginTop: "5px",
                                            padding: "13.5px 14px 14.5px 58px",
                                            width: "100%",
                                        }}
                                        searchStyle={{ border: "none", margin: "0", width: "100%", padding: "0" }}
                                        disableSearchIcon={false}
                                        className="phone-input-form"
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="country"
                                        control={control}
                                        defaultValue={null}
                                        rules={{
                                            required: "This field is required!",
                                        }}
                                        render={({ field, fieldState }) => (
                                            <CountriesDropdown field={field} fieldState={fieldState} sx={classes.textField} />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="city"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: userNameRegex,
                                                message: "Please use only letters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="City *"
                                                placeholder="City"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="address"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            pattern: {
                                                value: addressRegex,
                                                message: "Please use only letters or letters and numbers.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Address *"
                                                placeholder="Enter address"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="province"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            // required: "This field is required!",
                                            pattern: {
                                                value: userNameRegex,
                                                message: "Please use only letters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="State / Province (optional)"
                                                placeholder="State / Province (optional)"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>

                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="zipCode"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            required: "This field is required!",
                                            minLength: {
                                                value: 4,
                                                message: "Zip Code should contain at least 4 digets.",
                                            },
                                            pattern: {
                                                value: zipCodeRegex,
                                                message: "Invalid Zip Code. Please use only numbers.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Zip Code *"
                                                placeholder="Zip Code"
                                                variant="outlined"
                                                size="small"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                InputProps={{
                                                    autoComplete: "new-password",
                                                }}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>



                                <FormControl fullWidth={true}>
                                    <Controller
                                        name="industry"
                                        control={control}
                                        defaultValue=""
                                        rules={{
                                            maxLength: {
                                                value: 20,
                                                message: "Maximum allowed 20 characters.",
                                            },
                                        }}
                                        render={({ field, fieldState }) => (
                                            <TextField
                                                label="Industry (optional)"
                                                placeholder="Industry (optional)"
                                                variant="outlined"
                                                size="small"
                                                autoComplete="off"
                                                error={!!fieldState.error}
                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                {...field}
                                                sx={classes.textField}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <div className="sign_up_concent_wrapper">
                                <div className="sign_up_privacy">
                                    <FormControlLabel
                                        sx={classes.privacyPolicy}
                                        control={
                                            <Checkbox
                                                checked={privacyPolicy}
                                                onChange={() => setPrivacyPolicy(!privacyPolicy)}
                                                name="privacyPolicy"
                                            />
                                        }
                                        label={
                                            <div
                                                style={{
                                                    marginTop: "5px",
                                                    color: "1B1E1F",
                                                    fontFamily: "IBM Plex Sans",
                                                    fontSize: "11px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "150%",
                                                    letterSpacing: "0.11px",
                                                }}
                                            >
                                                I have read the{" "}
                                                <a
                                                    href={STATIC_ROUTES.privacyPolicy}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={classes.privacyPolicyLink}
                                                >
                                                    {i18n.t("core:privacyPolicy")}
                                                </a>{" "}
                                                and consent to the collection, storage and processing of my personal data to
                                                the extent described above.
                                            </div>
                                        }
                                    />
                                </div>
                                <div className="sign_up_terms">
                                    <FormControlLabel
                                        sx={classes.termsCheckbox}
                                        className="termsCheckbox"
                                        control={
                                            <Checkbox
                                                checked={checkboxTerms}
                                                onChange={() => setcheckboxTerms(!checkboxTerms)}
                                                name="checkboxTerms"
                                            />
                                        }
                                        label={
                                            <div
                                                style={{
                                                    marginTop: "5px",
                                                    color: "1B1E1F",
                                                    fontFamily: "IBM Plex Sans",
                                                    fontSize: "11px",
                                                    fontStyle: "normal",
                                                    fontWeight: "400",
                                                    lineHeight: "150%",
                                                    letterSpacing: "0.11px",
                                                }}
                                            >
                                                Hereby I confirm that I have read and agree to the following{" "}
                                                <a
                                                    href={STATIC_ROUTES.termsOfUse}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    style={classes.privacyPolicyLink}
                                                >
                                                    {i18n.t("core:setcheckboxTerms")}
                                                </a>{" "}
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                            <Typography component="div" sx={classes.alignSignUpButton_registration}>
                                <LoadingButton
                                    id="signUpButton"
                                    variant="contained"
                                    color="primary"
                                    sx={classes.singUpButton_register}
                                    onClick={submitForm}
                                    loading={isLoading}
                                    disabled={privacyPolicy === false || checkboxTerms === false}
                                >
                                    Register Now
                                </LoadingButton>
                            </Typography>
                        </Grid>
                    </form>
                </Paper>)
                    : <div>
                        <p className="registration_title">Sign Up</p>
                        <p className="text_description" style={{fontWeight:"450",}}>
                            Thank you for your registration.
                            We have sent you a confirmation email containing an activation link.
                            Please confirm your registration.</p>
                        {/* <p className="text_description">Thank you for your registration.</p>
                        <p className="text_description">We have sent you a confirmation email containing an activation link.</p>
                        <p className="text_description">Please confirm your registration.</p> */}
                    </div>
                }
            </>)
        }
    </>);
}

export default RegisterForm;

const classes = {
    root: {
        padding: "22px 15px 15px 15px",
    },
    root_register: {
        padding: "0px 120px 0px 68px",
        background: "transparent",
    },

    alignSecondContainer: {
        // marginTop: "20px",
    },
    textField: {
        fontFamily: "IBM Plex Sans",
        [`& fieldset`]: {
            borderRadius: "4px",
            fontFamily: "IBM Plex Sans",
        },
    },
    textFieldAbsolute: {
        fontFamily: "IBM Plex Sans",
        width: "100%",
        [`& fieldset`]: {
            borderRadius: "4px",
            fontFamily: "IBM Plex Sans",
        },
    },
    alignSignUpButton: {
        display: "flex",
        justifyContent: "right",
    },
    alignSignUpButton_registration: {
        display: "flex",
        justifyContent: "right",
        width: "100%",
    },
    privacyPolicySection: {
        padding: "8px",
    },
    privacyPolicy: {
        marginTop: "5px",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
        paddingLeft: "6px",
    },
    privacyPolicyLink: {
        color: "#1E9DF2",
        fontWeight: "400",
        textDecoration: "none",
    },
    termsCheckbox: {
        marginTop: "5px",
        marginLeft: "clamp(9%, 9.5%, 10%)",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "left",
    },
    requiredDetailsText: {
        marginTop: "27px",
        marginLeft: "10px",
    },
    singUpButton: {
        marginTop: "20px",
        background: "#147aae",
        color: "#fff",
        float: "right",
        borderRadius: "0",

        "&:hover": {
            background: "#0b98cf !important",
            boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            backgroundColor: "#d5d5d5",
        },
    },
    singUpButton_register: {
        margin: "40px 0px 0px 16px",
        background: "#1E9DF2",
        textTransform: "capitalize",
        color: "#fff",
        float: "right",
        borderRadius: "2",
        width: "100%",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "0.56px",
        fontFamily: "IBM Plex Sans",
        padding: "16px 32px",
    },
    cancelBtn: {
        marginTop: "35px",
        marginRight: "50px",
        background: "gray",
        color: "#fff",
        alignSelf: "flex-end",
        borderRadius: "0",
        width: "50px",
        "&:hover": {
            background: "#a9a6a6 !important",
            boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            backgroundColor: "#d5d5d5",
        },
    },
    createBtn: {
        color: "#fff",
        backgroundColor: "#147AAE ",
        marginLeft: "2vh",
        "&:hover": {
            background: "#0b98cf",
        },
    },
    errorMessage: {
        color: "red",
    },
};