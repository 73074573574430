/* eslint-disable */
import { Button, Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DefaultButton, { buttonTypes } from "../../../company/defaultButton";
const NoTermVersionDialog = ({
  open,
  setOpen,
  handleNewVersion,
  handleNewVersionWithContent,
  data,
}) => {
  function onClose() {
    setOpen(false);
  }

  function handleVersionContent() {
    handleNewVersionWithContent(data);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
          "& .MuiPaper-root": { minWidth: "646px" },
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <div style={muiNoVersionStyles.contentStyles}>
          <p style={muiNoVersionStyles.title}>Add New Version</p>
          <div style={muiNoVersionStyles.textDescription}>
            <p>
              <b>
                Do you want to use the text from the previous version of the Terms and Conditions,
                or create a completely new version?
              </b>
            </p>
            <p>
              <b>Use previous version:</b> This will automatically load the previous version of the
              terms and conditions into the editor. You can then make any changes you need before
              saving and publishing the new version.
            </p>
            <p>
              <b>Create new version: </b>This will create a new blank terms and conditions document
              in the editor. You can then start typing your new terms and conditions from scratch.
            </p>
            <b> Which option would you like to choose?</b>
          </div>
        </div>

        <div style={muiNoVersionStyles.actionsContainer}>
          <Button sx={muiNoVersionStyles.cancelButton} onClick={handleNewVersion}>
            Create New Version
          </Button>
          <Button sx={muiNoVersionStyles.createButton} onClick={handleVersionContent}>
            Use Previous Version
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default NoTermVersionDialog;

const muiNoVersionStyles = {
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    marginTop: "0px",
    textAlign: "center",
  },

  textDescription: {
    color: "#1B1E1F",
    ontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    paddingTop: "16px",
  },
  actionsContainer: {
    display: "flex",
    borderTop: "2px solid #F4F4F4",
    justifyContent: "space-between",
    padding: "16px 60px",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: "48px 0px",
  },
  contentStyles: {
    padding: "40px 67px",
  },
  createButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },

  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "#1E9DF2",
    marginRight: "8px",
  },
};
