/* eslint-disable */

import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { filesService } from "../../../services/files";
import { toast_notifications } from "../../../utils/notifications";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useNavigate } from "react-router";
import getTheUserState from "../../../utils/userState";

const accessTypes = {
  approved: "Access provided",
  declined: "Access declined",
};

const FilesRequestActions = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const userState = getTheUserState();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFileAccessMutation = useMutation({
    mutationFn: filesService.handleAccess,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["requsted-files-all"]);
      toast_notifications.success(data?.data?.message);
      // onClose();
    },
    onError: (data) => {
      // toast_notifications.error(data?.data?.message);
      // onClose();
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleaAccess(type) {
    handleFileAccessMutation.mutate({ file: data, action: type, resolvedBy: userState?.user?.email });
    handleClose();
  }

  const handleDisplayFileDetails = () => {
    navigate(`/dashboard/file-management/${data.file_id}`);
  };

  return (
    <div style={{ alignSelf: "center" }}>
      <IconButton
        id="file-requests-actions"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="file-requests-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "file-requests-actions",
        }}
        sx={menu.menuStyles}
      >
        <MenuItem onClick={() => handleaAccess(accessTypes.approved)}>
          <CheckOutlinedIcon color="success" />
          <span style={menu.actions}>Provide Access</span>
        </MenuItem>
        <MenuItem onClick={() => handleaAccess(accessTypes.declined)}>
          <CloseOutlinedIcon color="error" />
          <span style={menu.actions}>Decline Access</span>
        </MenuItem>
        <MenuItem onClick={handleDisplayFileDetails}>
          <InfoOutlinedIcon sx={menu.iconStyles} />
          <span style={menu.actions}>File Details</span>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FilesRequestActions;

const menu = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#D1D2D2",
  },
};
