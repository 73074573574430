import React from "react";
import "./filesCard.css";

export const extensionsColorSchemaArray = ["txt", "pdf", "svg", "png", "csv", "docx", "xsl"];

const extensionsColorSchema = {
  txt: "txt",
  pdf: "pdf",
  svg: "svg",
  png: "png",
  csv: "csv",
  docx: "docx",
  xsl: "xsl",
};

export function ExtensionsColorSchemaResolver(fileExtension) {
  return (
    <span className={`files_extension ${extensionsColorSchema[fileExtension.toLowerCase()]}`}>
      {fileExtension.toUpperCase()}
    </span>
  );
}
