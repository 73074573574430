/* eslint-disable */
import getTheUserState from "../../utils/userState";

const PermissionWrapper = ({ children, allowedPermission }) => {
  // We get the user state from the global state
  const user = getTheUserState();
  // List user permissions
  const userPermissions = user?.user?.role.permissions;

  // Check if the allowed permission exists in the user permissions
  const foundPermission = userPermissions.find(
    (permission) => permission.permission_name === allowedPermission && permission.status === true
  );

  // Check the status of the permission
  if (foundPermission?.status) {
    return children;
  }
  return null;
};

export default PermissionWrapper;

// NOTE: Permissions names must be the same as in the db -> Permissions(table) -> permission_name(column)
// Any Change HERE, must be done at the server side as well in the permissionResolver.js file
export const PermissionsSchema = {
  users: {
    activate: "Activate User",
    deactivate: "Deactivate User",
    view: "View User",
    delete: "Delete User",
    update: "Update User",
    create: "Create User",
    view_stats: "View Stats",
  },
  files: {
    fm_access: "FM Access",
    ml0: "Maturity Level 0 - demo unverified",
    ml1: "Maturity Level 1 - demo partly verified by creator",
    ml2: "Maturity Level 2 - demo function verified by creator",
    ml3: "Maturity Level 3 - demo systematicall verified",
    ml4: "Maturity Level 4 - demo req. based created/verified",
    ml5: "Maturity Level 5 - production auxiliary tool",
    ml6: "Maturity Level 6 - production tool",
    ml7: "Maturity Level 7 - product",
    upload_files: "Upload Files From Project",
    file_requests: "File Requests",
    all_file_requests: "All File Requests",
    view_download_history: "View Download History",
    view_all_files: "View All Files",
    add_to_file_catalog: "Add To File Catalog",
    file_catalog_full_access: "File Catalog Full Access",
  },
  roles: {
    create: "Create Role",
    update: "Update Role",
    copy: "Copy Role",
    delete: "Delete Role",
  },
  terms: {
    create_term: "Create Term",
    update_term: "Update Term",
    delete_term: "Delete Term",
    add_term_version: "Add Version",
    edit_term_version: "Edit Version",
    delete_term_version: "Delete Version",
    download_term_version: "Download Version",
    status_term_version: "Change Version Verification Status",
    state_term_version: "Change Version State",
    show_all_terms: "Show All Private Terms",
  },
  news: {
    create_news: "Create News",
    update_news: "Update News",
    delete_news: "Delete News",
    pin: "Pin / Unpin News",
  },
  companies: {
    create_company: "Create Company",
    edit_company: "Edit Company",
    delete_company: "Delete Company",
    add_team: "Add New Team",
    add_members: "Add Members to Team",
    edit_team_name: "Edit Team Name",
    delete_team: "Delete Team",
    remove_users: "Remove Users from Team",
    view_all_companies: "View All Companies",
  },
  projects: {
    create_project: "Create Project",
    update_project: "Update Project",
    delete_project: "Delete Project",
    // move_to_archive: "Move Project to Archive"
  },
};

export function DisableML(allowedPermission) {
  // We get the user state from the global state
  const user = getTheUserState();

  // List user permissions
  const userPermissions = user?.user?.role.permissions;

  // Check if the allowed permission exists in the user permissions
  const foundPermission = userPermissions.find(
    (permission) => permission.permission_name === allowedPermission && permission.status === true
  );

  // Check the status of the permission
  if (foundPermission?.status) {
    return false;
  }
  return true;
}
