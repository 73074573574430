import React from "react";
import { useNavigate } from "react-router-dom";
import "./heroContainer.css";
import { Button } from "@mui/material";
import i18n from "../../../services/i18n";
import STATIC_ROUTES from "../../../route/STATIC_ROUTES";

export default function HeroContainer() {
  const navigate = useNavigate();

  function handleClick() {
    navigate(STATIC_ROUTES.signup);
  }

  return (
    <div className="hero_wrapper">
      <div className="hero_content">
        <h1 className="motcos_title">MotCoS</h1>
        <p className="motcos_text">The electric drive development revolution starts here</p>
        <div className="button_container">
          <Button
            id="landingRegisterButton"
            sx={{
              maxWidth: "fit-content",
              padding: "16px 32px",
              backgroundColor: "#1E9DF2",
              fontFamily: "IBM Plex Sans",
              fontSize: "clamp(1rem, 1.036rem + -0.179vw, 0.875rem)",
              minWidth: "100%",
            }}
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {i18n.t("core:registerNow")}
          </Button>
        </div>
      </div>
    </div>
  );
}
