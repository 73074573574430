/* eslint-disable */

import { useEffect } from "react";
import { Dialog, IconButton, Button, DialogContent, DialogActions, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { noSpecialCharactersRegex } from "../../utils/regexList";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { teamServices } from "../../services/team";
import { toast_notifications } from "../../utils/notifications";

export function UpdateTeamNameModal({
  open,
  onClose,
  action,
  teamData,
}) {
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors, isDirty },
    control,
    setValue,
  } = useForm();

  const queryClient = useQueryClient();

  const updateTeamNameMutation = useMutation({
    mutationFn: teamServices.updateTeamName,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["plainCompanies", "companyById"]);
      onClose();
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  function setDefaultValues() {
    setValue("teamName", teamData?.team_name);
  }

  useEffect(() => {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }, []);

  function handelClear() {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }

  async function onSubmit(data) {
    const mutateObject = {
      name: data?.teamName,
      id: teamData?.team_id,
    };

    updateTeamNameMutation.mutate(mutateObject);
  }

  const submitForm = async () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      await handleSubmit(onSubmit)();
    }
  };

  return (
    <div>
      <Dialog open={open} sx={membersToTeamStyles.dialog}>
        <div
          style={{
            alignSelf: "flex-end",
            padding: "10px 10px 0px 10px",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <div style={{ margin: "0px 64px 0px 64px" }}>
          <p style={membersToTeamStyles.title}>Update Team Name</p>
          <DialogContent sx={membersToTeamStyles.content}>
            <form id="update_team_name" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="teamName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  pattern: {
                    value: noSpecialCharactersRegex,
                    message: "Invalid Team Name. Use letters and numbers.",
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    label="Team Name"
                    error={!!fieldState.error}
                    helperText={fieldState.error ? fieldState.error.message : null}
                    {...field}
                    variant="outlined"
                    placeholder="Enter company name"
                    size="small"
                    fullWidth
                    InputProps={{
                      autoComplete: "new-password",
                    }}
                    sx={membersToTeamStyles.textField}
                  />
                )}
              />
            </form>
          </DialogContent>
        </div>
        <DialogActions sx={membersToTeamStyles.actions}>
          <Button sx={membersToTeamStyles.cancelBtn} disabled={!isDirty} onClick={handelClear}>
            Discard Changes
          </Button>
          <Button
            style={
              !isDirty ? membersToTeamStyles.saveBtnDisabled : membersToTeamStyles.saveBtnActive
            }
            disabled={!isDirty}
            onClick={submitForm}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const membersToTeamStyles = {
  dialog: {
    "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
    "& .MuiPaper-root": { borderRadius: "16px", width: "100%", minWidth: "fit-content" },
  },
  content: {
    margin: "0px",
    padding: "0px",
  },
  title: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    margin: "0px",
    paddingBottom: "24px",
  },
  actions: {
    borderTop: "1px solid #F4F4F4",
    padding: "16px 64px",
    justifyContent: "space-between",
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    paddingBottom: "24px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  cancelBtn: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#FFF",
    color: "#1E9DF2",
    textTransform: "capitalize",
  },
  saveBtnActive: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#1E9DF2",
    color: "#FFF",
    textTransform: "capitalize",
  },
  saveBtnDisabled: {
    minWidth: "160px",
    height: "54px",
    padding: "16px 0px",
    borderRadius: "2px",
    background: "#D1D2D2",
    color: "#FFF",
    textTransform: "capitalize",
  },
};
