/* eslint-disable */

import { Button, Chip, Typography } from "@mui/material";
import {
  Star as StarIcon,
  StarBorder as StarBorderIcon,
  PushPin as PushPinIcon,
} from "@mui/icons-material";
import { getDateTime2 } from "../../../../utils/misc";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { newsServices } from "../../../../services/news";
import { toast_notifications } from "../../../../utils/notifications";
import NewsActions from "./NewsActions";
import { useNavigate } from "react-router";
import parse from "html-react-parser";
import "./newsCard.css";
import NavigationWrapper from "../../../auth/NavigationWrapper";
import PermissionWrapper, { PermissionsSchema } from "../../../auth/PermissionsWrapper";

const NewsCard = ({ news, index, newsLength }) => {
  const navigate = useNavigate();

  // const queryClient = useQueryClient();

  // const addFavouritesMutation = useMutation({
  //     mutationFn: newsServices.addToFavourites,
  //     onSuccess: (data) => {
  //         queryClient.invalidateQueries(["news"]);
  //         toast_notifications.success(data?.data?.message);
  //     },
  //     onError: (data) => {
  //         toast_notifications.error(data?.data?.message);
  //     },
  // });

  // const handleAddFavourites = () => {
  //     addFavouritesMutation.mutate({ id: news?.id, is_favourite: !news?.is_favourite });
  // }

  // const AddToFavoriteButton = () => {
  //     return (
  //         <div style={{ display: "flex" }}>
  //             <Button
  //                 variant="text"
  //                 size="small"
  //                 sx={{
  //                     margin: "0px",
  //                     padding: "0px",
  //                     textTransform: "capitalize",
  //                     maxHeight: "15px",
  //                     "&:hover": {
  //                         background: "transparent",
  //                     },
  //                 }}
  //                 onClick={handleAddFavourites}
  //             >
  //                 {news?.is_favourite ? <StarIcon sx={styles.favouritesIcons} /> : <StarBorderIcon sx={styles.favouritesIcons} />}
  //                 {news?.is_favourite ? (
  //                     <p style={styles.favouritesTitle}>Remove from favorites</p>
  //                 ) : (
  //                     <p style={styles.favouritesTitle}>Add to favorites</p>
  //                 )}
  //             </Button>
  //         </div>
  //     );
  // }

  // const newsContent = parse(news?.content.replace(/\s+/g, ' ').trim())

    return (
        <div
            className="card_container"
            style={{
                display: "flex",
                width: "100%",
                borderBottom: index !== newsLength - 1 ? "1px solid #E8E8E9" : "none",
                paddingBottom: "20px",
                marginTop: "24px",
                justifyContent:"space-between",
            }}>
            <div
                style={{
                    cursor: "pointer",
                    width: "715px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                }}
                onClick={() => navigate(`/dashboard/news/${news?.id}`)}
            >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                    <div style={{ display: "flex", gap: "4px" }}>
                        {news?.is_pinned && <PushPinIcon sx={styles.iconStyles} />}
                        <Typography sx={styles.title}>{news?.title}</Typography>
                    </div>
                </div>
                <div style={{ width: "715px" }}>
                    <article className="news_article">
                        {parse(news?.content)}
                    </article>
                </div>
                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "4px", paddingTop:"8px" }}>
                    {news?.is_draft && <>
                        <p style={styles.draftSign}>Draft</p>
                        <span style={styles.dotSeparator}>•</span>
                    </>}
                    <Typography sx={styles.createdAt}>
                        {getDateTime2(news?.createdAt || "--||--")}
                    </Typography>
                    {/* <span style={styles.dotSeparator}>•</span>
                <AddToFavoriteButton /> */}
        </div>
      </div>
      <NavigationWrapper
        allowedPermissions={[
          PermissionsSchema.news.delete_news,
          PermissionsSchema.news.pin,
          PermissionsSchema.news.update_news,
        ]}
      >
        <NewsActions news={news} />
      </NavigationWrapper>
    </div>
  );
};

export default NewsCard;

const styles = {
  title: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "140%", //32.2px
    letterSpacing: "0.46px",
    textAlign: "left",
  },
  favouritesTitle: {
    marginBottom: "10px",
    color: "#1E9DF2",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "140%", //16.5px
    letterSpacing: "-0.22px",
    textAlign: "left",
    marginLeft: "4px",
  },
  favouritesIcons: {
    width: "16px",
    height: "16px",
    color: "#1E9DF2",
  },
  createdAt: {
    marginBottom: "10px",
    color: "#8D8E8F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "500",
    letterSpacing: "-0.22px",
    margin: "0px",
  },
  draftSign: {
    color: "#8D8E8F",
    textAlign: "center",
    margin: "0px",
    padding: "2px 8px 1px 8px",
    width: "fit-content",
    textTransform: "capitalize",
    background: "#F4F4F4",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    fontFamily: "IBM Plex Sans",
    borderRadius: "2px",
  },
  dotSeparator: {
    padding: "0px 4px",
    alignSelf: "center",
    color: "#8D8E8F",
  },
  iconStyles: {
    color: "#D1D2D2",
    marginTop: "4px",
  },
};
