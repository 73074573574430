import React from "react";
import { Outlet } from "react-router-dom";
import DashboardSidebar from "../sidebar/dashboardSidebar";
import Header from "../../../containers/template/Header/Header";
import "./dashboardLayout.css";
import FileUploadIndicator from "../../dialogs/FileIndicators/FileUploadIndicator";
import FileDownloadIndicator from "../../dialogs/FileIndicators/FileDownloadIndicator";

/* eslint-disable */
function DashboardLayout() {
  return (
    <div className="dashboard_layout_container">
      <Header />
      <section className="layout_page">
        <DashboardSidebar />
        <Outlet />
        <FileUploadIndicator />
        <FileDownloadIndicator />
      </section>
    </div>
  );
}

export default DashboardLayout;
