/* eslint-disable */
import "./fileSelectionTime.css";
import { useEffect, useState } from "react";
import { Drawer, Checkbox, FormControlLabel, Button } from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getDateTime3 } from "../../../utils/misc";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import dayjs from "dayjs";

const FilesSelectTime = ({ index, handleUpdateTimeToUse, defaultValue }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isUndefined, setIsUndefined] = useState(defaultValue === "undefined" ? true : false);
  const [date, setDate] = useState("");

  const name = "time_to_use";
  const timeIsUndefined = "undefined";

  useEffect(() => {
    if (defaultValue !== "undefined") {
      const newDate = dayjs(defaultValue);
      setDate(newDate);
      setIsUndefined(false);
    }
  }, [defaultValue]);

  function handleDrawerState() {
    setIsDrawerOpen(!isDrawerOpen);
  }

  function handleUndefinedAction() {
    setIsUndefined(!isUndefined);
  }

  function handleCancelAction() {
    setIsDrawerOpen(!isDrawerOpen);

    if (date && date !== "") {
      setIsUndefined(false);
      const newDate = dayjs(defaultValue);
      setDate(newDate);
    } else {
      setIsUndefined(true);
      setDate("");
    }
  }

  function handelAcceptAction() {
    setIsDrawerOpen(!isDrawerOpen);

    if (isUndefined) {
      handleUpdateTimeToUse(index, name, timeIsUndefined);
      setDate("");
    } else {
      const newDate = getDateTime3(date);
      handleUpdateTimeToUse(index, name, newDate);
    }
  }

  const formatDate = (date) => {
    if (!date) return "";
    setDate(date);
  };

  return (
    <>
      <div className="file_selection_root" onClick={handleDrawerState}>
        <span className="file_selection_title">{defaultValue}</span>
        <CalendarMonthIcon fontSize="small" sx={selectTimeStyles.iconStyle} />
      </div>
      <Drawer anchor="right" open={isDrawerOpen} sx={selectTimeStyles.drawerRoot}>
        <div className="file_drawer_root">
          <div className="file_drawer_header_container">
            <div>
              <span className="file_selection_title" style={{ color: "#49454F" }}>
                Time to use
              </span>
            </div>
            <div className="file_drawer_header_container_data">
              <FormControlLabel
                control={
                  <Checkbox size="small" checked={isUndefined} onClick={handleUndefinedAction} />
                }
                label="undefined"
                sx={selectTimeStyles.formLabel}
              />
            </div>
          </div>
          <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                disablePast={true}
                disabled={isUndefined}
                value={date}
                onChange={formatDate}
                displayWeekNumber
                showDaysOutsideCurrentMonth
                sx={{ "& .MuiDialogActions-root": { display: "none" } }}
              />
            </LocalizationProvider>
          </div>
          <div className="file_drawer_footer">
            <Button variant="text" sx={selectTimeStyles.buttons} onClick={handleCancelAction}>
              Cancel
            </Button>
            <Button variant="text" sx={selectTimeStyles.buttons} onClick={handelAcceptAction}>
              Ok
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default FilesSelectTime;

const selectTimeStyles = {
  iconStyle: {
    color: "#1E9DF2",
  },
  drawerRoot: {
    "& .MuiPaper-root": { minWidth: "360px" },
    "& .MuiBackdrop-root": { background: "transparent" },
  },
  formLabel: { "& .MuiTypography-root": { fontSize: "11px" } },
  buttons: {
    textTransform: "Capitalize",
  },
};
