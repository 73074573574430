/* eslint-disable */
import React, { useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";

// Icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { filesService } from "../../../services/files";
import { toast_notifications } from "../../../utils/notifications";
import ConfirmInCatalog from "../../dialogs/ConfirmDeletingFiles/ConfirmInCatalog";
import { filesTableTypes } from "../../tables/CustomFilesTable/FilesTable";
import DeleteFilesDialog from "../../dialogs/ConfirmDeletingFiles/DeleteFilesDialog";
import DownloadFilesDialog from "../../dialogs/NewConfirmDialog/DownloadFileDialog";
import types from "../../../actions/types";
import { useDispatch } from "react-redux";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";

const FilesActions = ({ file, type, setSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (wholeData && type === filesTableTypes.projectsPrivate) {
  //     const filteredFilesWithTerms = wholeData?.filter(item => item.id === file.id);
  //     console.log("filteredFilesWithTerms", filteredFilesWithTerms)
  //     dispatch({
  //       type: types.SET_FILES_TERMS,
  //       payload: filteredFilesWithTerms,
  //     });
  //   }
  // }, [wholeData, type]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelected([]);
  };

  const handleOpenDownload = () => {
    if (file && (type === filesTableTypes.projectsPrivate || type === filesTableTypes.projectsGeneral)) {
      dispatch({
        type: types.SET_FILES_TERMS,
        payload: [file],
      });
    }
    setOpenDownload(true);
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
    setSelected([]);
  };

  const handleDisplayFileDetails = () => {
    navigate(`/dashboard/file-management/${file.id}`);
  };

  const handleUpdateInCatalogMutation = useMutation({
    mutationFn: filesService.updateInCatalog,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["files"]);
      toast_notifications.success(data?.data?.message);
      handleClose();
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleToggleInCatalog = () => {
    handleUpdateInCatalogMutation.mutate({
      id: file?.id,
      file_catalog: file?.file_catalog === 0 ? 1 : 0,
    });
  };

  const renderIsFileInCatalog = (file) => {
    if (file === 1) {
      return (
        <PermissionWrapper allowedPermission={PermissionsSchema.files.add_to_file_catalog}>
          <MenuItem onClick={() => setOpenConfirmation(true)}>
            <RemoveOutlinedIcon sx={menu.iconStyles} />
            <span style={menu.actions}>Remove from File Catalog</span>
          </MenuItem>
        </PermissionWrapper>
      );
    }

    return (
      <PermissionWrapper allowedPermission={PermissionsSchema.files.add_to_file_catalog}>
        <MenuItem onClick={() => setOpenConfirmation(true)}>
          <AddOutlinedIcon sx={menu.iconStyles} />
          <span style={menu.actions}>Add to File Catalog</span>
        </MenuItem>
      </PermissionWrapper>
    );
  };

  const handleDeleteDialog = () => {
    setOpenDelete(true);
    setAnchorEl(null);
  };
  return (
    <div style={{ alignSelf: "center" }}>
      <IconButton
        id="file-actions"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="file-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "file-actions",
        }}
        sx={menu.menuStyles}
      >
        <MenuItem onClick={handleDisplayFileDetails}>
          <InfoOutlinedIcon sx={menu.iconStyles} />
          <span style={menu.actions}>File Details</span>
        </MenuItem>
        {type !== filesTableTypes.projectsGeneral &&
          type !== filesTableTypes.projectsPrivate &&
          renderIsFileInCatalog(file?.file_catalog)}
        <MenuItem onClick={handleOpenDownload}>
          <DownloadIcon sx={menu.iconStyles} />
          <span style={menu.actions}>Download</span>
        </MenuItem>
        {filesTableTypes.projectsPrivate || type === filesTableTypes.projectsGeneral ?
          <PermissionWrapper allowedPermission={PermissionsSchema.files.fm_access}>
            <MenuItem onClick={handleDeleteDialog}>
              <DeleteForeverIcon sx={menu.iconStyles} />
              <span style={menu.actions}>Delete</span>
            </MenuItem>
          </PermissionWrapper> :
          <MenuItem onClick={handleDeleteDialog}>
            <DeleteForeverIcon sx={menu.iconStyles} />
            <span style={menu.actions}>Delete</span>
          </MenuItem>
        }
        {/* {type !== filesTableTypes.projectsGeneral && type !== filesTableTypes.projectsPrivate && (
          <MenuItem onClick={handleDisplayFileDetails}>
            <InventoryIcon sx={menu.iconStyles} />
            <span style={menu.actions}>Move to Archive</span>
          </MenuItem>
        )} */}
      </Menu>

      {openConfirmation && (
        <ConfirmInCatalog
          open={openConfirmation}
          onClose={() => setOpenConfirmation(false)}
          action={handleToggleInCatalog}
          file={file}
        />
      )}
      {openDelete && (
        <DeleteFilesDialog open={openDelete} onClose={handleCloseDelete} files={[file?.id] || []} />
      )}
      {openDownload && (
        <DownloadFilesDialog
          open={openDownload}
          onClose={handleCloseDownload}
          files={[file?.id] || []}
        />
      )}
    </div>
  );
};

export default FilesActions;

const menu = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#D1D2D2",
  },
};
