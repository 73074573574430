import React from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import GavelIcon from "@mui/icons-material/Gavel";
// import UploadFileIcon from "@mui/icons-material/UploadFile";
// import DownloadingIcon from "@mui/icons-material/Downloading";
// import ConstructionIcon from "@mui/icons-material/Construction";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
// import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import InputIcon from "@mui/icons-material/Input";
import InventoryIcon from "@mui/icons-material/Inventory";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
// import CompaniesIcon from "../../../assets/logos/companies.svg";
import ApartmentIcon from "@mui/icons-material/Apartment";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import HistoryIcon from '@mui/icons-material/History';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import STATIC_ROUTES from "../../../route/STATIC_ROUTES";

export const SidebarData = [
  {
    id: 0,
    name: "Dashboard",
    url: STATIC_ROUTES.dashboardHome,
    icon: <GridViewIcon />,
  },
  {
    id: 1,
    name: "Projects",
    url: STATIC_ROUTES.projects,
    icon: <FolderOutlinedIcon />,
  },
  {
    id: 2,
    name: "Companies",
    url: STATIC_ROUTES.companies,
    icon: <ApartmentIcon />,
  },
  {
    id: 3,
    name: "Users",
    url: STATIC_ROUTES.users,
    icon: <PeopleOutlineOutlinedIcon />,
  },
  {
    id: 4,
    name: "Terms",
    url: STATIC_ROUTES.terms,
    icon: <GavelIcon />,
  },

  {
    id: 6,
    name: "File Management",
    url: STATIC_ROUTES.fileManagement,
    icon: <InventoryIcon />,
  },
  {
    id: 12,
    name: "Role Management",
    url: STATIC_ROUTES.roleManagement,
    icon: <SupervisedUserCircleIcon />,
  },
  {
    id: 13,
    name: "File Catalog",
    url: STATIC_ROUTES.fileCatalog,
    icon: <LibraryBooksIcon />,
  },
  {
    id: 14,
    name: "Files Requests",
    url: STATIC_ROUTES.fileRequests,
    icon: <ContentPasteIcon />,
  },
  {
    id: 13,
    name: "My Files",
    url: STATIC_ROUTES.myFiles,
    icon: <FolderSpecialIcon />,
  },
  {
    id: 15,
    name: "Download History",
    url: STATIC_ROUTES.downloadHistory,
    icon: <HistoryIcon />,
  },
  {
    id: 9,
    name: "Account",
    url: STATIC_ROUTES.accountSettings,
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    id: 11,
    name: "Log out",
    url: STATIC_ROUTES.logout,
    icon: <InputIcon />,
  },
  // {
  //   id: 7,
  //   name: "Download history",
  //   url: STATIC_ROUTES.downloadHistory,
  //   icon: <DownloadingIcon />,
  // },
  // {
  //   id: 8,
  //   name: "Maintenance",
  //   url: STATIC_ROUTES.maintenance,
  //   icon: <ConstructionIcon />,
  // },

  // {
  //   id: 10,
  //   name: "Support",
  //   url: STATIC_ROUTES.support,
  //   icon: <ContactSupportOutlinedIcon />,
  // },

  // {
  //   id: 12,
  //   name: "Test",
  //   url: STATIC_ROUTES.testPage,
  //   // icon: <InputIcon />,
  // },
];

export const SidebarPrivate = [];
