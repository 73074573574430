/* eslint-disable */
import { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
// Icons
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import types from "../../../actions/types";
import DownloadFilesDialog from "../../../components/dialogs/NewConfirmDialog/DownloadFileDialog";
import { filesTableTypes } from "../../../components/tables/CustomFilesTable/FilesTable";
import RequestFileDownloadDialog from "./RequestFileDownloadDialog";
import getTheUserState from "../../../utils/userState";
import { PermissionsSchema } from "../../../components/auth/PermissionsWrapper";
import { LightTooltip } from "../../../components/tables/TermsTable/TermsTable";

const filesStatus = {
  required: "Access required",
  prvided: "Access provided",
  declined: "Access declined",
  pending: "Pending",
};

const FilesInCatalogActions = ({ fileStatus, fileId, fileName, fileFormat, type, setSelected, file, secondaryType }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [openDownload, setOpenDownload] = useState(false);
  const [isRequestDialogOpen, setIsRequestDialogOpen] = useState(false);

  const userState = getTheUserState();

  const checkFMAccess = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.fm_access &&
      permission.status === true
  );

  const checkFullCatalogAccess = userState?.user?.role?.permissions?.some(
    (permission) =>
      permission.permission_name === PermissionsSchema.files.file_catalog_full_access &&
      permission.status === true
  );

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // Functions from the Action menu starts from here

  const handleNavigateToFileDetails = () => {
    navigate(`/dashboard/file-management/${fileId}`);
    // handleClose();
  };

  const handleOpenDownload = () => {
    if (fileId && type === filesTableTypes.file_catalog || secondaryType === filesTableTypes.my_files) {
      dispatch({
        type: types.SET_FILES_TERMS,
        payload: [file],
      });
    }
    setOpenDownload(true);
    // handleClose();
  };

  const handleCloseDownload = () => {
    setOpenDownload(false);
    setSelected([]);
    // handleClose();
  };

  const handleOpenDownloadDialog = () => {
    setIsRequestDialogOpen(true);
  };

  const handleCloseDownloadDialog = () => {
    setIsRequestDialogOpen(false);
    // setAnchorEl(null);
  };

  return (
    <div style={{ alignSelf: "center", display: "flex", justifyContent: "center" }}>
      {/* <IconButton
        id="file-actions"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="file-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "file-actions",
        }}
        sx={menu.menuStyles}
      > */}
      {checkFMAccess &&
        // <MenuItem onClick={handleNavigateToFileDetails}>
        (<LightTooltip title={"File Details"} arrow placement="top">
          <InfoOutlinedIcon sx={menu.iconStyles} onClick={handleNavigateToFileDetails} />
        </LightTooltip>)
        // <span style={menu.actions}>File Details</span>
        // </MenuItem>
      }
      {(filesStatus.prvided === fileStatus || secondaryType === filesTableTypes.my_files || checkFullCatalogAccess) && (
        // <MenuItem onClick={handleOpenDownload}>
        <LightTooltip title={"Download"} arrow placement="top">
          <DownloadIcon sx={menu.iconStyles} onClick={handleOpenDownload} />
        </LightTooltip>
        // {/* <span style={menu.actions}>Download</span> */}
        // {/* </MenuItem> */}
      )}
      {(filesStatus.required === fileStatus && !checkFullCatalogAccess) && (
        // <MenuItem onClick={handleOpenDownloadDialog}>
        <LightTooltip title={"Request file download"} arrow placement="top">
          <LockOutlinedIcon sx={menu.iconStyles} onClick={handleOpenDownloadDialog} />
        </LightTooltip>
        // {/* <span style={menu.actions}>Request file download</span> */}
        // </MenuItem>
      )}
      {(filesStatus.declined === fileStatus && !checkFullCatalogAccess) && (
        // <MenuItem onClick={handleOpenDownloadDialog}>
        <LightTooltip title={"Send another request"} arrow placement="top">
          <LockOutlinedIcon sx={menu.iconStyles} onClick={handleOpenDownloadDialog} />
        </LightTooltip>
        // <span style={menu.actions}>Send another request</span>
        // </MenuItem>
      )}
      {openDownload && (
        <DownloadFilesDialog
          open={openDownload}
          onClose={handleCloseDownload}
          files={[fileId] || []}
        />
      )}
      {isRequestDialogOpen && (
        <RequestFileDownloadDialog
          isOpen={isRequestDialogOpen}
          onClose={handleCloseDownloadDialog}
          fileName={fileName}
          fileFormat={fileFormat}
          fileId={fileId}
          fileStatus={fileStatus}
        />
      )}
      {/* </Menu> */}
    </div>
  );
};

export default FilesInCatalogActions;

const menu = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "#FFF",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#1E9DF2",
    cursor: "pointer",
  },
};
