/* eslint-disable */

import React, { useState } from "react";
import { DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

function DisplayTermsAndConditions({ elements, tools }) {
  const [stepper, setStepper] = useState(0);
  const [acceptedTerms, setAcceptedTerms] = useState([]);

  const handleAcceptTerms = (type, item) => {
    if (type === "accept") {
      if (acceptedTerms.includes(item)) {
        return;
      }
      setAcceptedTerms((prev) => [...prev, item]);
      if (stepper + 1 >= elements?.length) {
        if (stepper + 1 === elements?.length) {
          console.log("accepted terms", acceptedTerms);
        }
        return;
      }
      setStepper((prev) => prev + 1);
    } else if (type === "decline") {
      if (stepper + 1 >= elements?.length) {
        return;
      }
      setStepper((prev) => prev + 1);
    }
  };

  return (
    <div>
      <DialogTitle sx={muiDialogStyles.dialogTitle}>Terms & Conditions</DialogTitle>
      <div style={muiDialogStyles.indexTitle}>
        {stepper + 1} out of {elements?.length}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p>
            <b>Files:</b>
          </p>
          {tools
            ?.filter((item) => item.terms_and_condition_id === elements[stepper])
            .map((tool) => {
              return <p style={{ padding: "0px 5px" }}>{tool.tool_name}, </p>;
            })}
        </div>
      </div>
      <DialogContent sx={muiDialogStyles.content}>{elements[stepper]}</DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          onClick={() => handleAcceptTerms("decline", elements[stepper])}
          autoFocus
          sx={muiDialogStyles.btnDecline}
        >
          Decline
        </Button>
        <Button
          onClick={() => handleAcceptTerms("accept", elements[stepper])}
          autoFocus
          sx={muiDialogStyles.btnStart}
        >
          Accept
        </Button>
        {/* <Button
          onClick={() => console.log("accepted", acceptedTerms)}
          autoFocus
          sx={muiDialogStyles.btnStart}
        >
          Display
        </Button> */}
      </DialogActions>
    </div>
  );
}

export default DisplayTermsAndConditions;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    // maxWidth: "60%",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    padding: "0px",
  },

  btnStart: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    // marginRight: "40px",
    margin: "3px 40px 23px 0px",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnDecline: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    margin: "3px 40px 23px 40px",
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    margin: "20px 40px 20px 40px",
    // minWidth: "70%",
    // padding: "0px",
    border: "1px solid green",
  },
  indexTitle: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.16px",
    textAlign: "center",
  },
};
