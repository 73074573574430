/* eslint-disable */
import { useEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  FormControl,
  Checkbox,
} from "@mui/material";
import { ExtensionsColorSchemaResolver } from "../../../../components/cards/FilesCard/extensionsColorSchemaResolver";
import MaturityLevelDropdown from "./MaturityLevelDropdown";
import { getDateTime2 } from "../../../../utils/misc";
import { fileName } from "../../../../utils/regexList";
import { CheckFileName } from "./CheckFileName";
import EditSelectedTerms from "./EditSelectedTerms";
import FileEditMultipleCompanies from "./FileEditMultipleCompanies";
import FileEditSetTimeToUse from "./FileEditSetTimeToUse";
import { filesService } from "../../../../services/files";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// Icons
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileEditMultipleUsers from "./FileEditMultipleUsers";
import LoadingScreen from "../../../../components/feedback/LoadingScreen";
import { toast_notifications } from "../../../../utils/notifications";
import { formatFileSize } from "../../../../utils/misc";

const EditFile = ({ file, handleClose }) => {
  const queryClient = useQueryClient();

  const {
    file_name,
    file_format,
    createdAt,
    updatedAt,
    description,
    maturity_level,
    serial_id,
    is_general,
    is_private,
  } = file;

  const methods = useForm();
  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
  } = methods;
  // filesService.editFile

  //File Edit Mutation
  const fileEditMutation = useMutation({
    mutationFn: filesService.editFile,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["fileById", file?.file_id]);
      toast_notifications.success(data?.data?.message);
      handleClose();
    },
    onError: (data) => {
      toast_notifications.error(data?.response.data?.message);
    },
  });

  const setDefaultValues = () => {
    setValue("file_name", file_name);
    setValue("description", description);
    setValue("serial_id", serial_id);
    setValue("ml", maturity_level);
    setValue("terms_and_conditions", file?.FilesToTerms[0]);
    setValue("is_general", file?.is_general);
    setValue("is_private", file?.is_private);
    setValue("time_to_use", file?.time_to_use);

    if (file?.FileConnections.length) {
      // Use reduce to group the projects by company_id
      const groupedCompanies = file?.FileConnections?.reduce((acc, item) => {
        const { company_id, project } = item;
        if (!acc[company_id]) {
          acc[company_id] = { company_id, projects: [] };
        }
        acc[company_id].projects.push(project);
        return acc;
      }, {});

      // Adjust data format
      const companies = Object.keys(groupedCompanies)?.map((company_id) => {
        return {
          company_id: parseInt(company_id, 10),
          projects: groupedCompanies[company_id].projects.map((project) => project.id), // We need it in the format => projects: ["1", "2", etc..]
        };
      });
      setValue("companies", companies);
    } else {
      setValue("companies", file?.FileConnections);
    }

    if (file?.FileToUsers?.length) {
      setValue("file_user", file?.FileToUsers || []);
    } else {
      setValue("file_user", []);
    }
  };

  const termsValues = getValues("terms_and_conditions");
  const getIsGeneralValue = watch("is_general");
  const getIsPrivateValue = watch("is_private");

  useEffect(() => {
    if (getIsGeneralValue === true || getIsPrivateValue === true) {
      setValue("companies", []);
      setValue("file_user", []);
    }
  }, [getIsGeneralValue, getIsPrivateValue]);

  useEffect(() => {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }, []);

  function handelClear() {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }

  async function onSubmit(data) {
    data.file_id = file?.id;
    // data.maturity_level = maturityLevel;
    // data.phone = phone;
    // const diff = getChangedProperties(userData?.data, data);
    // updateUserMutation.mutate(diff);
    // console.log("maturity", data);
    fileEditMutation.mutate({
      data,
    });
  }

  const submitForm = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      // checkPasswordErrors();
      return;
    } else {
      // Submit The form
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div style={{ overflow: "auto", flex: 1 }}>
      {fileEditMutation.isPending ? (
        <LoadingScreen />
      ) : (
        <div>
          {
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
                padding: "25px 40px",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Button style={styles.arrowButton} onClick={handleClose}>
                    <ArrowBackIcon sx={{ fontSize: "15px", marginRight: "2px" }} /> Cancel Edit
                  </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                  <Button sx={styles.clearBtn} onClick={handelClear}>
                    <span style={styles.buttons}>Discard Changes</span>

                    <CloseIcon sx={{ fontSize: "15px", marginLeft: "4px" }} />
                  </Button>
                  <Button sx={styles.saveBtn} onClick={submitForm} disabled={!isDirty}>
                    <span style={!isDirty ? { color: "gray" } : styles.buttons}>Save</span>
                    <SaveIcon sx={{ fontSize: "15px", marginLeft: "4px" }} />
                  </Button>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "32px",
                    lineHeight: "44.8px",
                    margin: "0px",
                  }}
                >
                  File Details
                </p>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <form id="edit-file" onSubmit={handleSubmit(onSubmit)} style={{ display: "flex" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                      <Typography sx={styles.tags}>Name:</Typography>
                      <FormControl
                        error={!!errors.file_name}
                        variant="outlined"
                        sx={{ marginBottom: "-10px", minWidth: "420px" }}
                      >
                        <Controller
                          name="file_name"
                          control={control}
                          defaultValue={file_name}
                          rules={{
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            pattern: {
                              value: fileName,
                              message: "Invalid File Name",
                            },
                            minLength: {
                              value: 3,
                              message: "The File Name should contain at least 3 letters.",
                            },
                            maxLength: {
                              value: 45,
                              message:
                                "The File Name is too long. Maximum allowed characters is 45.",
                            },
                          }}
                          render={({ field, fieldState }) => (
                            <TextField
                              sx={styles.valuesWithText}
                              value={file_name}
                              variant="standard"
                              error={!!fieldState.error}
                              // helperText={fieldState.error ? fieldState.error.message : null}
                              name="file_name"
                              {...field}
                              InputProps={{
                                autoComplete: "new-password",
                              }}
                            >
                              {file_name}
                            </TextField>
                          )}
                        />
                        <FormHelperText sx={{ marginLeft: "0px" }}>
                          {errors.file_name ? errors.file_name.message : " "}
                        </FormHelperText>
                      </FormControl>
                      {/* <CheckFileName
                    exepcted_name={fileName}
                    disabled={errors.file_name ? true : false}
                  /> */}
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Format:</Typography>
                      {ExtensionsColorSchemaResolver(file_format || "")}
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Size:</Typography>
                      <span style={styles.valuesNoBorder}>{formatFileSize(file?.size)}</span>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Maturity level:</Typography>
                      <MaturityLevelDropdown
                        defaultValue={maturity_level}
                        control={control}
                        name="ml"
                      />
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Created:</Typography>
                      <span style={styles.valuesNoBorder}>{getDateTime2(createdAt)}</span>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Modified:</Typography>
                      <span style={styles.valuesNoBorder}>{getDateTime2(updatedAt)}</span>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Description:</Typography>
                      <FormControl sx={{ minWidth: "420px" }}>
                        <Controller
                          name="description"
                          control={control}
                          defaultValue={description}
                          render={({ field, fieldState }) => (
                            <TextField
                              sx={styles.valuesWithText}
                              value={description}
                              variant="standard"
                              name="description"
                              {...field}
                            >
                              {description}
                            </TextField>
                          )}
                        />
                      </FormControl>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Serial ID:</Typography>
                      <FormControl
                        // error={!!errors.serialID}
                        variant="outlined"
                        sx={{ marginBottom: "-10px", minWidth: "420px" }}
                      >
                        <Controller
                          name="serial_id"
                          control={control}
                          value={serial_id}
                          // rules={{
                          //   required: {
                          //     value: true,
                          //     message: "This field is required!",
                          //   },
                          //   pattern: {
                          //     value: fileName,
                          //     message: "Invalid File Name",
                          //   },
                          //   minLength: {
                          //     value: 3,
                          //     message: "The File Name should contain at least 3 letters.",
                          //   },
                          //   maxLength: {
                          //     value: 45,
                          //     message: "The File Name is too long. Maximum allowed characters is 45.",
                          //   },
                          // }}
                          render={({ field, fieldState }) => (
                            <TextField
                              sx={styles.valuesWithText}
                              value={serial_id}
                              variant="standard"
                              name="serialID"
                              {...field}
                              InputProps={{
                                autoComplete: "new-password",
                              }}
                            >
                              {serial_id}
                            </TextField>
                          )}
                        />
                        <FormHelperText sx={{ marginLeft: "0px" }}>
                          {errors.serialID ? errors.serialID.message : " "}
                        </FormHelperText>
                      </FormControl>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.tags}>Terms:</Typography>
                      <FormProvider {...methods}>
                        <EditSelectedTerms file={file} terms={termsValues} />
                      </FormProvider>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "50px",
                      paddingLeft: "50px",
                      borderLeft: "1px solid #E8E8E9",
                    }}
                  >
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        marginTop: "0px",
                        fontFamily: "IBM Plex Sans, sans-serif",
                      }}
                    >
                      Available For:
                    </p>
                    <div style={styles.row}>
                      <Typography sx={styles.longTags}>Set as General Resource:</Typography>
                      <Controller
                        name="is_general"
                        control={control}
                        defaultValue={is_general}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            checked={field.value || false}
                            disabled={getIsPrivateValue}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.longTags}>Set as Private Resource:</Typography>
                      <Controller
                        name="is_private"
                        control={control}
                        defaultValue={is_private}
                        render={({ field, fieldState }) => (
                          <Checkbox
                            checked={field.value || false}
                            disabled={getIsGeneralValue}
                            {...field}
                          />
                        )}
                      />
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.longTags}>
                        Project(s) for multiple companies:
                      </Typography>
                      <FormProvider {...methods}>
                        <FileEditMultipleCompanies />
                      </FormProvider>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.longTags}>Specific User(s) or Team(s):</Typography>
                      <FormProvider {...methods}>
                        <FileEditMultipleUsers />
                      </FormProvider>
                    </div>
                    <div style={styles.row}>
                      <Typography sx={styles.longTags}>Time To Use:</Typography>
                      <FormProvider {...methods}>
                        <FileEditSetTimeToUse />
                      </FormProvider>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default EditFile;

const styles = {
  arrowButton: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#1E9DF2",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
    // marginTop:"6px",
    padding: "0px",
  },
  buttons: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#1E9DF2",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
  },
  saveBtn: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#42b07c",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
  },
  clearBtn: {
    fontFamily: "IBM Plex Sans, sans-serif",
    color: "#c25745",
    textTransform: "none",
    fontWeight: "400",
    fontSize: "11px",
    lineHeight: "16.5px",
    textAlign: "center",
    borderRadius: "2px",
  },
  tags: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
    padding: "10px 0px",
    width: "90px",
  },
  longTags: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
    padding: "10px 0px",
    width: "250px",
  },
  tagsMiddle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18.2px",
    padding: "2px 0px",
  },
  valuesWithText: {
    padding: "0px 0px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    margin: "0px !important",
    // borderBottom: "1px solid #E8E8E9",
    textDecoration: "none",
    ".MuiInput-underline": {
      textDecoration: "none",
    },
    ".Mui-disabled": {
      color: "black !important",
      WebkitTextFillColor: "black !important",
      textDecoration: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    ".MuiInput-input": {
      color: "black",
      WebkitTextFillColor: "black",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18.2px",
      padding: "0px",
      border: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
  },
  valuesWithTextPadded: {
    padding: "8px 4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    margin: "0px !important",
    borderBottom: "1px solid #E8E8E9",
    textDecoration: "none",
    ".MuiInput-underline": {
      textDecoration: "none",
    },
    ".Mui-disabled": {
      color: "black !important",
      WebkitTextFillColor: "black !important",
      textDecoration: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
    ".MuiInput-input": {
      color: "black",
      WebkitTextFillColor: "black",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "18.2px",
      padding: "0px",
      border: "none",
      "&::before": {
        border: "none !important",
      },
      "&::after": {
        border: "none !important",
      },
    },
  },
  valuesNoBorder: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18.2px",
    // marginLeft: "16px",
    // padding: "2px 0px",
    color: "#8D8E8F",
  },
  accordion: {
    display: "grid",
    gridTemplateColumns: "536px",
    minWidth: "536px !important",
    mинHeight: "36px !important",
    boxShadow: "none",
    "&::before": {
      display: "none",
    },
    "& .MuiButtonBase-root": {
      padding: "0px 0px",
      maxHeight: "36px !important",
    },
    ".MuiPaper-root": {
      minHeight: "0px !important",
    },
    ".MuiCollapse-root": {
      border: "none",
    },
  },
  accordionSummary: {
    "& .Mui-expanded": {
      margin: "10px 0px",
    },
    ".MuiAccordionSummary-root": {
      maxHeight: "36px !important",
    },
    borderBottom: "1px solid #E8E8E9",
    minHeight: "36px !important",
    padding: "8px 0px",
    ".MuiAccordionSummary-expandIconWrapper": {
      color: "#1E9DF2",
    },
  },
  accordionLink: {
    fontFamily: "IBM Plex Sans, sans-serif",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18.2px",
    width: "104px",
    height: "18px",
    margin: "5px 0px 0px 0px",
    color: "#1E9DF2",
    textDecoration: "none",
    cursor: "pointer",
  },
  row: { display: "flex", alignItems: "center", gap: "20px", paddingTop: "10px" },
};
