const nameRegex = /^[A-Za-z0-9äöüÄÖÜß]*$/;
const userNameRegex = /^[a-zA-Z\s]*$/g;
const mainNameRegex = /^(?![- ])(?!.*[- ]$)[A-Za-z0-9äöüÄÖÜß -]*(?<![- ])/;
// const mainNameRegex = /^[A-Za-z0-9äöüÄÖÜß -]*$/;
const addressRegex = /^[a-zA-Z0-9]+(?:\s[a-zA-Z0-9]+)*$/;
const zipCodeRegex = /^[0-9\s]*$/g;
// const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={};':"\\|,.<>/?])?[A-Za-z\d!@#$%^&*()_+\-={};':"\\|,.<>/?]{8,}$/;
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const noSpecialCharactersRegex = /^[a-zA-Z0-9](?:[a-zA-Z0-9\s.]*[a-zA-Z0-9])?$/;
const noSpecialOnlyUnderscoreAndHyphen = /^[a-zA-Z0-9_-](?:[a-zA-Z0-9\s._-]*[a-zA-Z0-9])?$/;
// const fileName = /^[a-zA-Z0-9]+[a-zA-Z0-9-_]*[a-zA-Z0-9]+$/;
const fileName =
  /^(?!.*\s{2,})(?!.*[^A-Za-z0-9 _()\-ÄÖÜäöüß])(?!^\s)(?!.*\s$)[A-Za-z0-9 _()\-ÄÖÜäöüß]+$/;

export {
  nameRegex,
  userNameRegex,
  zipCodeRegex,
  passwordRegex,
  emailRegex,
  noSpecialCharactersRegex,
  noSpecialOnlyUnderscoreAndHyphen,
  mainNameRegex,
  addressRegex,
  fileName,
};
