/* eslint-disable */
import CarImage from "../../../assets/img/car.png";

const NoFilesInCatalogComponent = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <div
        style={{
          overflow: "auto",
          flex: 1,
          paddingLeft: "40px",
          paddingTop: "32px",
        }}
      >
        <p style={noFilesStyles.title}>File Catalog</p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "80%",
            width: "auto",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <p style={noFilesStyles.title2}>No files available for download</p>
          <p style={noFilesStyles.subTitle}>Please check back later for new additions</p>
          <div style={{ position: "relative", display: "flex" }}>
            <img
              src={CarImage}
              style={{
                width: "auto",
                height: "480px",
                bottom: "-310px",
                opacity: 0.1,
                right: "-540px",
                zIndex: 1,
                position: "absolute",
                transform: "rotate(-3.178deg)",
                zIndex: "499",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoFilesInCatalogComponent;

const noFilesStyles = {
  root: {
    border: "1px solid red",
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "100%",
    paddingLeft: "40px",
    paddingTop: "32px",
  },
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    color: "#1B1E1F",
    margin: "0px 0px 24px 0px",
  },
  title2: {
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    color: "#1B1E1F",
    margin: "0px",
    zIndex: 500,
  },
  subTitle: {
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11",
    color: "#8D8E8F",
    margin: "0px",
    width: "130px",
    zIndex: 500,
    textAlign: "center",
    // alignSelf: "center",
  },
};
