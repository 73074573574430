import React from "react";
import "./videoContainer.css";

function VideoContainer() {
  return (
    <section className="video_section">
      <div className="video_container">
        {/* eslint-disable-next-line */}
        <video controls kind="captions">
          <source src="https://www.dropbox.com/s/53onnec5tbtuvuy/Elmos_MotCoS_D04_1920px_Mid_Res.mp4?raw=1" />
        </video>
      </div>
    </section>
  );
}

export default VideoContainer;
