/* eslint-disable */
import "./accountSettings.css";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  nameRegex,
  userNameRegex,
  zipCodeRegex,
  passwordRegex,
  emailRegex,
  addressRegex,
} from "../../../utils/regexList";
import CountriesDropdown from "../../../components/dropdown/CountriesDropdown";
import { usersService } from "../../../services/users";
import LoadingScreen from "../../../components/feedback/LoadingScreen";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import getTheUserState from "../../../utils/userState";
import { useDispatch } from "react-redux";
import types from "../../../actions/types";

const AccountSettings = () => {
  const userId = getTheUserState()?.user.id;
  const dispatch = useDispatch();

  const {
    handleSubmit,
    formState: { errors, isDirty, },
    control,
    reset,
    watch,
    setValue,
    getValues,
    trigger,
  } = useForm();

  const queryClient = useQueryClient();

  // Handle show/hide password & confirm password fields
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Display the password info
  const [displayPasswordInfo, setDisplayPasswordInfo] = useState(false);

  const [phone, setPhone] = useState("");

  const { data: userData, isLoading } = useQuery({
    queryKey: ["userByIdInfo", userId],
    queryFn: () => usersService.getUserInfoById(userId),
  });

  const updateUserMutation = useMutation({
    mutationFn: usersService.updateUserAccount,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["userByIdInfo"]);
      toast_notifications.success(data?.data?.message);
      // Update sessionStorage with the new user data
      const userFromStorage = JSON.parse(sessionStorage.getItem('user'));
      userFromStorage.user.user = data?.data?.user;
      sessionStorage.setItem('user', JSON.stringify(userFromStorage));
      dispatch({ type: types.AUTH_USER, user: userFromStorage });
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const setDefaultValues = () => {
    setValue("user", userData?.data?.user);
    setValue("firstName", userData?.data?.firstName);
    setValue("lastName", userData?.data?.lastName);
    setValue("email", userData?.data?.email);
    setValue("companyURL", userData?.data?.companyURL);
    setValue("businessRole", userData?.data?.businessRole);
    setValue("role_id", userData?.data?.role_id);
    setValue("country", userData?.data?.country);
    setValue("industry", userData?.data?.industry);
    setValue("province", userData?.data?.province);
    setValue("city", userData?.data?.city);
    setValue("address", userData?.data?.address);
    setValue("zipCode", userData?.data?.zipCode);
    setValue("id", userData?.data?.id);
    setValue("pass", "");
    setValue("confirmPassword", "");
  };

  useEffect(() => {
    if (userData?.data) {
      setDefaultValues();
      reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
    }
  }, [userData?.data]);

  function handelClear() {
    setDefaultValues();
    reset(watch(), { keepValues: false, keepDirty: false, keepDefaultValues: false });
  }

  const countryValue = watch("country");

  const checkPasswordErrors = () => {
    if (errors?.pass || errors?.confirmPassword) {
      setDisplayPasswordInfo(true);
    }
  };

  function isEmpty(obj) {
    return Object.entries(obj).length === 0;
  }

  useEffect(() => {
    checkPasswordErrors();
  }, [isEmpty(errors)]);

  // Compare the state before change and the state after change, to take only the properties that were changed.
  function getChangedProperties(originalObject, updatedObject) {
    const changedProperties = {};

    for (const key in updatedObject) {
      const originalValue = originalObject[key];
      const updatedValue = updatedObject[key];

      // Check if the value is different and not an empty string
      if (originalValue !== updatedValue && updatedValue !== "") {
        changedProperties[key] = updatedValue;
      }
    }

    // attach the user id & country name
    changedProperties.id = originalObject?.id;
    changedProperties.country = updatedObject?.country;

    return changedProperties;
  }

  async function onSubmit(data) {
    data.phone = phone;
    const diff = getChangedProperties(userData?.data, data);
    updateUserMutation.mutate(diff);
  }

  const submitForm = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      checkPasswordErrors();
      return;
    } else {
      // Submit The form
      handleSubmit(onSubmit)();
    }
  };

  const validatePasswordMatch = (value, values) => {
    if (value === values.pass) {
      return true;
    }
    return "Passwords do not match";
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100%", width: "100%" }}>
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          flex: 1,
          position: "relative",
          padding: "0px 40px",
        }}
      >
        <div className="users_main_content_account">
          <h3 className="user_title_account">Account</h3>
          {isLoading ? (
            <LoadingScreen />
          ) : (
            <form id="myForm" onSubmit={handleSubmit(onSubmit)} style={{ zIndex: "999" }}>
              <div className="form_container_users_account">
                <Controller
                  name="user"
                  control={control}
                  defaultValue={userData?.data?.user}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    pattern: {
                      value: nameRegex,
                      message: "Invalid Username. Use letters or numbers.",
                    },
                    minLength: {
                      value: 4,
                      message: "The username should contain at least 4 letters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Username *"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      variant="outlined"
                      placeholder="Username"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                      disabled
                    />
                  )}
                />
                <div
                  className="double_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        alignItems: "center",
                      }}
                    >
                      <Controller
                        name="pass"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: passwordRegex,
                            message: "Invalid Password check the information.",
                          },
                        }}
                        render={({ field, fieldState }) => {
                          // fieldState.error && setDisplayPasswordInfo(true);
                          return (
                            <TextField
                              label="Change Password"
                              placeholder="Password"
                              variant="outlined"
                              size="small"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : null}
                              {...field}
                              type={showPassword ? "text" : "password"}
                              InputProps={{
                                autoComplete: "new-password",
                                endAdornment: (
                                  <InputAdornment position="end" sx={{ marginRight: "38px" }}>
                                    <IconButton
                                      aria-label="toggle-password-visibility"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={muiAccountSettingsStyles.textField}
                            />
                          );
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          marginTop: "8px",
                          left: "246px",
                          zIndex: "999",
                        }}
                        onClick={() => setDisplayPasswordInfo(true)}
                      >
                        <InfoOutlinedIcon sx={{ color: "#1E9DF2" }} />
                      </IconButton>
                    </div>
                    {displayPasswordInfo ? (
                      <div
                        style={{
                          borderRadius: "8px",
                          background: "#FFF",
                          boxShadow: "0px 24px 44px 0px rgba(90, 100, 105, 0.40)",
                          width: "300px",
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "0px",
                          zIndex: 9999,
                          position: "absolute",
                          top: "150px",
                          zIndex: "9999",
                        }}
                      >
                        <button
                          type="button"
                          style={{
                            width: "24px",
                            height: "24px",
                            alignSelf: "flex-end",
                            margin: "10px 20px -5px 0px",
                            color: "gray",
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            color: "#D1D2D2",
                          }}
                          onClick={() => setDisplayPasswordInfo(false)}
                        >
                          <ClearIcon />
                        </button>
                        <p
                          style={{
                            fontSize: "11px",
                            margin: "0px 40px 25px 25px",
                            fontFamily: "IBM Plex Sans",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "16px",
                            letterSpacing: "0.2px",
                            color: "#1B1E1F",
                          }}
                        >
                          To create a valid password, it is necessary to use a combination of at
                          least 8 characters, including both uppercase and lowercase letters, a
                          number, and one of the special characters such as @, $, !, %, *, ?, or &.
                          Using any other special characters besides these is not allowed.
                        </p>
                      </div>
                    ) : null}
                  </div>
                  <Controller
                    name="confirmPassword"
                    control={control}
                    defaultValue=""
                    rules={{
                      pattern: {
                        value: passwordRegex,
                        message: "Invalid Password check the information.",
                      },
                      validate: validatePasswordMatch,
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Confirm New Password"
                        placeholder="Confirm Password"
                        variant="outlined"
                        size="small"
                        // autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          autoComplete: "off",
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle-password-visibility"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={muiAccountSettingsStyles.textField}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="email"
                  control={control}
                  defaultValue={userData?.data?.email}
                  rules={{
                    required: "This field is required!",
                    pattern: {
                      value: emailRegex,
                      message: "Invalid Email format.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Email *"
                      placeholder="Email"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <div
                  className="double_row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <Controller
                    name="firstName"
                    control={control}
                    defaultValue={userData?.data?.firstName}
                    rules={{
                      required: "This field is required!",
                      pattern: {
                        value: userNameRegex,
                        message: "Please use only letters.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="First name *"
                        placeholder="First name"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={muiAccountSettingsStyles.textField}
                      />
                    )}
                  />
                  <Controller
                    name="lastName"
                    control={control}
                    defaultValue={userData?.data?.lastName}
                    rules={{
                      required: "This field is required!",
                      pattern: {
                        value: userNameRegex,
                        message: "Please use only letters.",
                      },
                    }}
                    render={({ field, fieldState }) => (
                      <TextField
                        label="Last name *"
                        placeholder="Last name"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        error={!!fieldState.error}
                        helperText={fieldState.error ? fieldState.error.message : null}
                        {...field}
                        sx={muiAccountSettingsStyles.textField}
                      />
                    )}
                  />
                </div>
                <Controller
                  name="country"
                  control={control}
                  defaultValue={userData?.data?.country}
                  value={countryValue}
                  rules={{
                    required: "This field is required!",
                  }}
                  render={({ field, fieldState }) => (
                    <CountriesDropdown field={field} fieldState={fieldState} />
                  )}
                />
                <Controller
                  name="province"
                  control={control}
                  defaultValue={userData?.data?.province}
                  rules={{
                    // required: "This field is required!",
                    pattern: {
                      value: nameRegex,
                      message: "Please use only letters and numbers.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="State / Province (optional)"
                      placeholder="State / Province (optional)"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="city"
                  control={control}
                  defaultValue={userData?.data?.city}
                  rules={{
                    required: "This field is required!",
                    pattern: {
                      value: userNameRegex,
                      message: "Please use only letters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="City *"
                      placeholder="City"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  defaultValue={userData?.data?.address}
                  rules={{
                    required: "This field is required!",
                    pattern: {
                      value: addressRegex,
                      message: "Please use letters and numbers only.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Address *"
                      placeholder="Enter address"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="businessRole"
                  control={control}
                  defaultValue={userData?.data?.businessRole}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "Maximum allowed 20 characters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Business Role (optional)"
                      placeholder="Business Role (optional)"
                      variant="outlined"
                      size="small"
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="zipCode"
                  control={control}
                  defaultValue={userData?.data?.zipCode}
                  rules={{
                    required: "This field is required!",
                    minLength: {
                      value: 4,
                      message: "Zip Code should contain at least 4 digets.",
                    },
                    pattern: {
                      value: zipCodeRegex,
                      message: "Invalid Zip Code. Please use only numbers.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Zip Code *"
                      placeholder="Zip Code"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <PhoneInput
                  country={"de"}
                  onChange={(phone) => setPhone(phone)}
                  enableSearch={true}
                  value={userData?.data?.phone}
                  inputStyle={{
                    borderRadius: "4px",
                    fontSize: "14px",
                    marginTop: "5px",
                    padding: "13.5px 14px 14.5px 58px",
                  }}
                  searchStyle={{ border: "none", margin: "0", width: "100%", padding: "0" }}
                  disableSearchIcon={false}
                  className="phone-input-form"
                />
                <Controller
                  name="companyURL"
                  control={control}
                  defaultValue={userData?.data?.companyURL}
                  rules={{
                    maxLength: {
                      value: 30,
                      message: "Maximum allowed 30 characters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Company URL (optional)"
                      placeholder="Company URL (optional)"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="industry"
                  control={control}
                  defaultValue={userData?.data?.industry}
                  rules={{
                    maxLength: {
                      value: 20,
                      message: "Maximum allowed 20 characters.",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      label="Industry (optional)"
                      placeholder="Industry (optional)"
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      sx={muiAccountSettingsStyles.textField}
                    />
                  )}
                />
              </div>
            </form>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          borderTop: "1px solid #E8E8E9",
          height: "80px",
          background: "white",
          flexShrink: 0,
        }}
      >
        <footer className="account_settings_footer_container">
          <Button
            sx={muiAccountSettingsStyles.cancelButton}
            onClick={() => handelClear()}
            disabled={!isDirty}
          >
            Discard Changes
          </Button>
          <LoadingButton
            sx={
              !isDirty
                ? muiAccountSettingsStyles.createBtnDisabled
                : muiAccountSettingsStyles.createButton
            }
            onClick={submitForm}
            type="button"
            disabled={!isDirty}
            loading={isLoading}
          >
            Save Changes
          </LoadingButton>
        </footer>
      </div>
    </div>
  );
};

export default AccountSettings;

const muiAccountSettingsStyles = {
  createButton: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  createBtnDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "236px",
    height: "54px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  cancelButton: {
    borderRadius: "2px",
    background: "#FFF",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "140px",
    height: "54px",
    color: "#1E9DF2",
    marginRight: "8px",
    // "&:hover": {
    //   background: "#1E9DF2",
    // },
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    zIndex: "99",
    minWidth: "300px",
    maxHeight: "43px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
};
