/* eslint-disable */
import { useState, useEffect } from "react";
import { Button, Dialog, IconButton, MenuItem, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, useForm } from "react-hook-form";
import { noSpecialCharactersRegex, noSpecialOnlyUnderscoreAndHyphen } from "../../../../utils/regexList";
import { onlyCompanies, getCompaniesByUser } from "../../../../services/company";
import { getProjectsByCompanyId } from "../../../../services/project";
import { useDispatch } from "react-redux";
import {
  createTermsAndConditions,
  termsAndConditionsService,
} from "../../../../services/TermsAndConditions";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../../utils/notifications";
import getTheUserState from "../../../../utils/userState";
import { PermissionsSchema } from "../../../auth/PermissionsWrapper";

const termsType = {
  label: "Terms type",
  data: [
    { label: "General", value: 1 },
    { label: "Company/Project specific", value: 0 },
  ],
};

export const termsAndConditionsType = {
  editTerm: "edit term",
  createTerm: "create term",
};

const CreateTermsAndConditionsDialog = ({ open, action, onClose, type, term }) => {
  const loggedInUser = getTheUserState()?.user;

  const {
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    control,
    setValue,
    watch,
  } = useForm();

  const dispatch = useDispatch();

  const [companies, setCompanies] = useState([]);

  // const [trackCompanyID, setTrackCompanyID] = useState(null);

  const [isProjectsLoading, setIsProjectsLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  async function fetchProjectsByCompanyID(companyID) {
    setIsProjectsLoading(true);
    const projectsData = dispatch(getProjectsByCompanyId(companyID));
    projectsData.then((result) => {
      setProjects(result?.data);
      setIsProjectsLoading(false);
    });
  }

  const fetchData = async () => {
    const userPermissions = loggedInUser?.role.permissions;

    const foundPermission = userPermissions.find(
      (permission) =>
        permission.permission_name === PermissionsSchema.terms.show_all_terms &&
        permission.status === true
    );

    if (!foundPermission) {
      const companiesData = dispatch(getCompaniesByUser(loggedInUser?.companies));
      companiesData.then((result) => {
        setCompanies(result?.data);
      });
    } else {
      const companiesData = dispatch(onlyCompanies());
      companiesData.then((result) => {
        setCompanies(result?.data);
      });
    }
  };

  const queryClient = useQueryClient();

  const createTermMutation = useMutation({
    mutationFn: termsAndConditionsService.createTermsAndConditions,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const updateTermMutation = useMutation({
    mutationFn: termsAndConditionsService.updateTerm,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const watchIsGeneral = watch("is_general");
  const watchCompany = watch("company_id");

  useEffect(() => {
    if (open) {
      fetchData();
      if (type === termsAndConditionsType.editTerm) {
        fetchProjectsByCompanyID(term.company_id);
      }
    }
  }, [open]);

  useEffect(() => {
    if (type === termsAndConditionsType.editTerm) {
      setValue("terms_name", term.terms_name);
      setValue("description", term.description);
      setValue("is_general", term.is_general ? 1 : 0);
      setValue("company_id", term.company_id);
      // setTrackCompanyID(term.company_id);
      setValue("project_id", term.project_id);
    }
  }, [term]);

  useEffect(() => {
    if (
      watchIsGeneral === 1 ||
      (watchIsGeneral === undefined && type === termsAndConditionsType.createTerm)
    ) {
      setValue("company_id", 0);
      setValue("project_id", 0);
    }
    if (watchIsGeneral === 1 && type === termsAndConditionsType.editTerm) {
      setValue("company_id", 0);
      setValue("project_id", 0);
    }
  }, [watchIsGeneral]);

  const handleActionExecution = () => {
    action();
    onClose();
  };

  const closeDialog = () => {
    onClose();
    reset();
  };

  const handleChangeCompany = async (e) => {
    const companyId = e.target.value;
    setValue("company_id", companyId);
    // setTrackCompanyID(companyId);
    await fetchProjectsByCompanyID(companyId);
  };

  async function onSubmit(data) {
    createTermMutation.mutate(data);
    // await dispatch(createTermsAndConditions(data));
    // queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    closeDialog();
  }

  function onSubmitMutation(data) {
    const dataWithId = { ...data, id: term.id };
    updateTermMutation.mutate(dataWithId);
    closeDialog();
  }

  const submitForm = () => {
    if (Object.keys(errors).length > 0) {
      // Do not submit the forum
      return;
    } else {
      // Submit The form
      if (type === termsAndConditionsType.editTerm) {
        handleSubmit(onSubmitMutation)();
      } else {
        handleSubmit(onSubmit)();
      }
    }
  };

  // Had to do that. I need the error state of the form, but dont want to flood screen with the other styles.
  const dropdown = {
    minWidth: "210px",
    // paddingRight: "24px",
    // border: "2px solid red",
    // maxWidth: "210px",
    marginBottom: "25px",
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
          "& .MuiPaper-root": { maxWidth: "650px" },
          zIndex: "999",
        }}
      >
        <div
          style={{
            alignSelf: "flex-end",
            marginBottom: "-30px",
            padding: "8px",
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0px 60px 40px 60px",
            minWidth: "500px",
          }}
        >
          <div>
            {
              <p style={muiDialogStyles.dialogTitle}>
                {type === termsAndConditionsType.editTerm ? "Edit Terms" : "Terms & Conditions"}
              </p>
            }
            <div>
              <form id="create_terms_form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="terms_name"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    maxLength: {
                      value: 40,
                      message: "Maximum allowed 40 characters.",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum allowed 3 characters.",
                    },
                    pattern: {
                      value: noSpecialOnlyUnderscoreAndHyphen,
                      message:
                        "Empty spaces at the begining/end & special characters are not allowed!",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      label="Terms name"
                      placeholder="Enter terms name"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiDialogStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Description is required!",
                    },
                    maxLength: {
                      value: 40,
                      message: "Maximum allowed 40 characters.",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum allowed 3 characters.",
                    },
                    pattern: {
                      value: noSpecialOnlyUnderscoreAndHyphen,
                      message:
                        "Empty spaces at the begining/end & special characters are not allowed!",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      label="Description"
                      placeholder="Enter terms description"
                      variant="outlined"
                      size="small"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      InputProps={{
                        autoComplete: "new-password",
                      }}
                      sx={muiDialogStyles.textField}
                    />
                  )}
                />
                <Controller
                  name="is_general"
                  control={control}
                  // defaultValue={actionType.update === action ? state.name : ""}
                  rules={{
                    required: { message: "Term type is required", value: true },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      size="small"
                      select
                      label="Terms Type"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      // defaultValue={state?.marketSelect}
                      sx={dropdown}
                      // onChange={handleInputChange}
                    >
                      {termsType.data?.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          // sx={updateProjectMuiStyles.menuItem}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name="company_id"
                  control={control}
                  // defaultValue={actionType.update === action ? state.name : ""}
                  // rules={{
                  //   required: watchIsGeneral === 1 ? undefined : "Company is required",
                  // }}
                  rules={{
                    required: { message: "Company is required", value: true },
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      size="small"
                      select
                      label="Company"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      // defaultValue={state?.marketSelect}
                      sx={dropdown}
                      onChange={handleChangeCompany}
                      disabled={watchIsGeneral !== 0 || !termsAndConditionsType.editTerm}
                    >
                      {companies?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                <Controller
                  name="project_id"
                  control={control}
                  // defaultValue={actionType.update === action ? state.name : ""}
                  // rules={{
                  //   required: "Project is required",
                  // }}
                  render={({ field, fieldState }) => (
                    <TextField
                      fullWidth
                      size="small"
                      select
                      label="Project"
                      error={!!fieldState.error}
                      helperText={fieldState.error ? fieldState.error.message : null}
                      {...field}
                      // defaultValue={state?.marketSelect}
                      sx={dropdown}
                      // onChange={handleChangeCompany}
                      disabled={!watchCompany || !termsAndConditionsType.editTerm ? true : false}
                    >
                      {projects?.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </form>
            </div>
          </div>
        </div>
        <div style={muiDialogStyles.actionsContainer}>
          <Button sx={muiDialogStyles.btnCancel} onClick={closeDialog}>
            Cancel
          </Button>
          {type === termsAndConditionsType.editTerm ? (
            <Button
              onClick={submitForm}
              disabled={!isDirty}
              autoFocus
              sx={
                !isDirty || (watchCompany === 0 && watchIsGeneral === 0)
                  ? muiDialogStyles.btnDisabled
                  : muiDialogStyles.btn
              }
            >
              Update
            </Button>
          ) : (
            <Button
              onClick={submitForm}
              disabled={!isDirty}
              autoFocus
              sx={
                !isDirty || (watchCompany === 0 && watchIsGeneral === 0)
                  ? muiDialogStyles.btnDisabled
                  : muiDialogStyles.btn
              }
            >
              Create
            </Button>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default CreateTermsAndConditionsDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    // maxWidth: "60%",
    alignSelf: "center",
    margin: "0px",
    paddingBottom: "40px",
    // padding: "48px 24px 0px 24px",
  },
  actionsContainer: {
    display: "flex",
    borderTop: "2px solid #F4F4F4",
    justifyContent: "space-between",
    padding: "16px 60px",
    // flexDirection: "column",
    // alignItems: "center",
    // padding: "48px 0px",
  },
  companyTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    margin: "48px 0px 0px 0px",
  },
  btn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#0F6EAE",
    },
  },
  btnDisabled: {
    borderRadius: "2px",
    background: "#D1D2D2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "194px",
    height: "54px",
    color: "white!important",
    "&:hover": {
      background: "#D1D2D2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  },
  textField: {
    fontFamily: "IBM Plex Sans",
    minWidth: "300px",
    maxHeight: "43px",
    paddingBottom: "25px",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
      border: "1px solid #8D8E8F",
    },
  },
};
