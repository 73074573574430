/* eslint-disable */

import { useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllRoles } from "../../services/admin";

const UserRoleDropdown = ({ selectedRole, setSelectedRole, field, fieldState }) => {
  const dispatch = useDispatch();

  const [roles, setRoles] = useState([]);
  const [selected, setSelected] = useState("");

  // This is workaround for the input lable but, where the lable is not placed correctly in the dropdown and after.
  const [value, setValue] = useState("");
  useEffect(() => {
    const rolesData = dispatch(getAllRoles());
    rolesData.then((data) => {
      setRoles(data);
    });
  }, []);

  useEffect(() => {
    setSelected(selectedRole);
  }, [selectedRole])

  const handleChange = (event) => {
    setSelectedRole(event.target.value);
    setValue(event.target.value);
    // thise line is needed to set the picked value from the dropdown
    field.onChange(event.target.value);
  };

  return (
    <Box>
      <FormControl fullWidth error={!!fieldState.error}>
        <InputLabel
          id="user-role-selection"
          sx={{
            top: value ? "0px" : "-7px",
            "&.Mui-focused": {
              top: "0px",
            },
          }}
        >
          Role*
        </InputLabel>
        <Select
          {...field}
          labelId="user-role-selection"
          value={selected}
          label="Role"
          onChange={handleChange}
          size="small"
          sx={{
            fontFamily: "IBM Plex Sans",
            minWidth: "300px",
            maxHeight: "43px",
            [`& fieldset`]: {
              borderRadius: "4px",
              fontFamily: "IBM Plex Sans",
            },
          }}
        >
          {roles.map((role) => {
            return (
              <MenuItem sx={{zIndex:"990"}} key={role?.role_id} value={role?.role_id}>
                {role?.role_name}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{fieldState.error ? fieldState.error.message : null}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default UserRoleDropdown;
