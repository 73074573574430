/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Button, Dialog, IconButton, Popover, TextField } from "@mui/material";
import TextEditor, { editorTypes } from "../../../textEditor/textEditor";
import DefaultButton, { buttonTypes } from "../../../company/defaultButton";
import CloseIcon from "@mui/icons-material/Close";
import { createTermsVersion, updateTermsVersion } from "../../../../services/termsVersion";
import { useDispatch } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useQueryClient } from "@tanstack/react-query";

export const createTermsType = {
  create: "create",
  update: "update",
  view: "view",
};

const CreateTermsVersion = ({ open, setOpen, type, data, defaultContent }) => {
  const quillRef = useRef();
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const defaultState = {
    id: "",
    terms_and_condition_id: data?.id,
    version_name: createVersionName(data?.version_name, data?.count),
    version_description: "",
    status: "draft",
    freeze: 0,
    active: 0,
    content: "",
    raw_data: "",
  };

  const [state, setState] = useState(defaultState);
  const [text, setText] = useState("");

  const [popOverAnchorEl, setPopOverAnchorEl] = useState(null);

  const handleClick = (event) => {
    setPopOverAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setPopOverAnchorEl(null);
  };

  const openPopOver = Boolean(popOverAnchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  function createVersionName(name, count) {
    let originalName = name;

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    const modifiedName = `${originalName}_${year}_${month < 10 ? "0" + month : month}_${count + 1}`;

    return modifiedName;
  }

  useEffect(() => {
    if (type === createTermsType.create && defaultContent !== null) {
      setText(defaultContent);
    } else {
      setText("");
    }
  }, [defaultContent]);

  useEffect(() => {
    if (type === createTermsType.update && data) {
      setState({
        id: data?.id,
        terms_and_condition_id: data?.terms_and_condition_id,
        version_name: data?.version_name,
        version_description: data?.version_description,
        status: "draft",
        freeze: 0,
        active: 0,
        content: data?.content,
        raw_data: "",
      });
      setText(data?.content);
    }
  }, [type, data]);

  useEffect(() => {
    if (open && data && type === createTermsType.view) {
      setText(data?.content);
    }
  }, [open]);

  const handleChanges = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setState({
      ...state,
      [name]: value,
    });
  };

  function cancelChanges() {
    if (type === createTermsType.update) {
      setState({
        id: data?.id,
        terms_and_condition_id: data?.terms_and_condition_id,
        version_name: data?.version_name,
        version_description: data?.version_description,
        status: 0,
        freeze: 0,
        active: 0,
        content: data?.content,
        raw_data: "",
      });
      setText(data?.content);
    } else {
      setState(defaultState);
      if (defaultContent) {
        setText(defaultContent);
      } else {
        setText("");
      }
    }
  }

  function onClose() {
    setOpen(false);
    cancelChanges();
  }

  function onCloseView() {
    setOpen(false);
    setTimeout(() => {
      setText("");
    }, 500);
  }

  const handleCreateVersion = async () => {
    state.content = text;
    await dispatch(createTermsVersion(state));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    setOpen(false);
  };

  const handleUpdateVersion = async () => {
    state.content = text;
    await dispatch(updateTermsVersion(state));
    queryClient.invalidateQueries(["termsAndConditions", "termsInCompanies"]);
    setOpen(false);
  };

  async function copyToClipBoard() {
    const editor = quillRef.current.getEditor();
    const content = editor.getText();
    navigator.clipboard.writeText(content);
  }

  let content;

  if (createTermsType.view === type) {
    content = (
      <>
        <Dialog
          open={open}
          onClose={onCloseView}
          sx={{
            "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
            "& .MuiPaper-root": { minWidth: "1016px", padding: "35px 64px" },
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
          >
            <IconButton onClick={onCloseView}>
              <CloseIcon color="disabled" />
            </IconButton>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p style={muiVersionStyles.title}>{data?.version_name}</p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={muiVersionStyles.termDesc}>{data?.version_description}</p>

              <button
                onClick={(e) => {
                  copyToClipBoard();
                  handleClick(e);
                }}
                style={{
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "flex-end",
                  minWidth: "100px",
                  justifyContent: "space-between",
                  color: "#1E9DF2",
                }}
              >
                Copy Text
                <ContentCopyIcon fontSize="small" />
              </button>
              <Popover
                id={id}
                open={openPopOver}
                anchorEl={popOverAnchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px",
                    backgroundColor: "#2f7d32",
                  }}
                >
                  <CheckCircleOutlineIcon
                    sx={{ color: "#fff", paddingRight: "10px" }}
                    fontSize="small"
                  />
                  <p style={{ color: "#fff", fontWeight: "500" }}>Text Copied!</p>
                </div>
              </Popover>
            </div>
          </div>
          <div style={{ paddingTop: "24px", paddingBottom: "24px" }}>
            <TextEditor
              data={text}
              style={{ minHeight: "450px", height: "450px" }}
              type={editorTypes.view}
              quillRef={quillRef}
            />
          </div>
        </Dialog>
      </>
    );
  } else {
    content = (
      <>
        <Dialog
          open={open}
          onClose={onClose}
          sx={{
            "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
            "& .MuiPaper-root": { minWidth: "1016px", padding: "35px 64px" },
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "20px",
              right: "20px",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon color="disabled" />
            </IconButton>
          </div>
          <p style={muiVersionStyles.title}>Create New Version</p>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {createTermsType.create === type ? (
              <TextField
                label="Version Name (generated automatically)"
                // placeholder=
                name="version_name"
                variant="outlined"
                size="small"
                onChange={handleChanges}
                value={state.version_name}
                disabled
                InputProps={{
                  autoComplete: "new-password",
                }}
                sx={muiVersionStyles.textField}
              />
            ) : (
              <TextField
                label="Version Name"
                placeholder="Enter version name"
                name="version_name"
                variant="outlined"
                size="small"
                disabled={createTermsType.update === type}
                onChange={handleChanges}
                value={state.version_name}
                InputProps={{
                  autoComplete: "new-password",
                }}
                sx={muiVersionStyles.textField}
              />
            )}

            <TextField
              label="Description (optional)"
              placeholder="Enter version description"
              variant="outlined"
              size="small"
              name="version_description"
              value={state.version_description}
              onChange={handleChanges}
              InputProps={{
                autoComplete: "new-password",
              }}
              sx={muiVersionStyles.textField}
            />
          </div>
          <div style={{ paddingTop: "24px", paddingBottom: "74px" }}>
            <TextEditor
              data={text}
              onChange={setText}
              style={{ maxHeight: "300px", height: "300px" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DefaultButton buttonType={buttonTypes.cancel} title="Cancel" onClickEvent={onClose} />
            {createTermsType.create === type ? (
              <Button
                variant="contained"
                sx={muiVersionStyles.createBtn}
                onClick={handleCreateVersion}
              >
                Add Version
              </Button>
            ) : (
              <Button
                variant="contained"
                sx={muiVersionStyles.createBtn}
                onClick={handleUpdateVersion}
              >
                Update Version
              </Button>
            )}
          </div>
        </Dialog>
      </>
    );
  }

  return content;
};

export default CreateTermsVersion;

const muiVersionStyles = {
  title: {
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    marginTop: "0px",
  },
  createBtn: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  textField: {
    width: "500px",
    fontFamily: "IBM Plex Sans",
    [`& fieldset`]: {
      borderRadius: "4px",
      fontFamily: "IBM Plex Sans",
    },
  },
  termDesc: {
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14",
    textTransform: "capitalize",
    color: "#8D8E8F",
    margin: "0px",
  },
};
