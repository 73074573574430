/* eslint-disable */

import { useEffect, useState } from "react";
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { getAllRoles } from "../../services/admin";

const UserRoleDropdownNoForm = ({ selectedRole, setSelectedRole, selectLabel }) => {
    const dispatch = useDispatch();

    const [roles, setRoles] = useState([]);
    const [selected, setSelected] = useState("");

    // This is workaround for the input lable but, where the lable is not placed correctly in the dropdown and after.
    const [value, setValue] = useState("");
    useEffect(() => {
        const rolesData = dispatch(getAllRoles());
        rolesData.then((data) => {
            setRoles(data);
        });
    }, []);

    useEffect(() => {
        setSelected(selectedRole);
    }, [selectedRole])

    const handleChange = (event) => {
        setSelectedRole(event.target.value);
        setValue(event.target.value);
    };

    return (
        <Box>
            <FormControl sx={{ margin: "0px 0px", width: "100%" }}>
                <InputLabel label="user-role-selection" sx={{ top: "-6px" }}>{selectLabel || "Select Role"}</InputLabel>
                <Select
                    labelId="user-role-selection"
                    value={selected}
                    // label="Select Role"
                    onChange={handleChange}
                    placeholder={selectLabel || "Select Role"}
                    size="small"
                    id="demo-select-small"
                    input={<OutlinedInput label={selectLabel || "Select Role"} />}
                    sx={{
                        fontFamily: "IBM Plex Sans",
                        marginBottom: "24px",
                        [`& fieldset`]: {
                            borderRadius: "4px",
                            fontFamily: "IBM Plex Sans",
                        },
                    }}
                >
                    {roles.map((role) => {
                        return (
                            <MenuItem sx={{ zIndex: "990" }} key={role?.role_id} value={role?.role_id}>
                                {role?.role_name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </Box>
    );
};

export default UserRoleDropdownNoForm;
