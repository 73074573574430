/* eslint-disable */
import { useState } from "react";
import "./availableCompany.css";
import { IconButton, Menu, MenuItem } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EnhancedTable, { tableType } from "../../tables/CustomMuiTable/EnchancedMuiTable";
import AddMembersToTeamModal from "../../modal/AddMembersToTeamModal";
import NewConfirmDialog, { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";
import DeleteMembersModal from "../../modal/DeleteMembersModal";
import { teamServices } from "../../../services/team";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast_notifications } from "../../../utils/notifications";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";
import NavigationWrapper from "../../auth/NavigationWrapper";
import BlockPermissionComponent from "../../auth/BlockPermissionComponent";
import { UpdateTeamNameModal } from "../../modal/UpdateTeamNameModal";

const RenderTeamTableComponent = ({
  team,
  pickedCompany,
  handleDeleteTeam,
}) => {
  const [selectedTeamUsers, setSelectedTeamUsers] = useState([]);
  const [isDeleteTeamOpen, setIsDeleteTeamOpen] = useState(false);
  const [isUpdateTeamNameOpen, setIsUpdateTeamNameOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  function closeDeleteTeamDialog() {
    setIsDeleteTeamOpen(false);
  }

  const queryClient = useQueryClient();

  const deleteUsersToTeamMutation = useMutation({
    mutationFn: teamServices.deleteTeamUser,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["plainCompanies", "companyById"]);
      setAnchorEl(null);
      setSelectedTeamUsers([]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  function handleDeleteTeamUsers() {
    deleteUsersToTeamMutation.mutate({ selectedUserIds: selectedTeamUsers, teamId: team.id });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleUpdateTeamModalState() {
    setIsUpdateTeamNameOpen(!isUpdateTeamNameOpen);
  }

  return (
    <div key={team?.id || 0} style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p className="team_title">{team?.name}</p>
        {selectedTeamUsers.length > 0 ? (
          <DeleteMembersModal
            data={{ teamData: team, selected: selectedTeamUsers }}
            handleDelete={handleDeleteTeamUsers}
            type={confirmTypes.deleteTeamUser}
          />
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {team?.projects_count && <p style={teamTableComponentMUIStyles.noCompaniesSubText}>
              Number of projects in this team - {team?.projects_count || 0}
            </p>}
            <PermissionWrapper allowedPermission={PermissionsSchema.companies.add_members}>
              <AddMembersToTeamModal team={team} company={pickedCompany} />
            </PermissionWrapper>

            <NavigationWrapper
              allowedPermissions={[
                PermissionsSchema.companies.edit_team_name,
                PermissionsSchema.companies.delete_team,
              ]}
            >
              <IconButton
                sx={{
                  // padding: "8px",
                  // alignSelf: "center",
                  // display: "flex",
                  minWidth: "40px",
                  color: "#D1D2D2",
                  // textTransform: "capitalize",
                  // fontSize: "16px",
                }}
                // variant="text"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </IconButton>
            </NavigationWrapper>
            <Menu
              id="project-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "project-actions",
              }}
              sx={teamTableComponentMUIStyles.menuStyles}
            >
              <PermissionWrapper allowedPermission={PermissionsSchema.companies.edit_team_name}>
                <MenuItem
                  onClick={() => {
                    handleUpdateTeamModalState();
                    setAnchorEl(null);
                  }}
                >
                  <BorderColorIcon sx={{ color: "#D1D2D2" }} />
                  <span style={teamTableComponentMUIStyles.actions}>Edit team name</span>
                </MenuItem>
              </PermissionWrapper>
              <PermissionWrapper allowedPermission={PermissionsSchema.companies.delete_team}>
                <MenuItem
                  onClick={() => {
                    setIsDeleteTeamOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  <DeleteOutlineIcon sx={{ color: "#D1D2D2" }} />
                  <span style={teamTableComponentMUIStyles.actions}> Delete</span>
                </MenuItem>
              </PermissionWrapper>
            </Menu>
            <NewConfirmDialog
              open={isDeleteTeamOpen}
              onClose={closeDeleteTeamDialog}
              action={handleDeleteTeam}
              data={{ name: team?.name }}
              type={confirmTypes.deleteTeam}
            />
            <UpdateTeamNameModal
              open={isUpdateTeamNameOpen}
              onClose={handleUpdateTeamModalState}
              teamData={{ team_name: team?.name, team_id: team?.id }}
            />
          </div>
        )}
      </div>
      <EnhancedTable
        selected={selectedTeamUsers}
        setSelected={setSelectedTeamUsers}
        rows={team?.users}
        type={tableType.teamUsers}
      />
    </div>
  );
};

export default RenderTeamTableComponent;

const teamTableComponentMUIStyles = {
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "var(--main-grey-white, #FFF)",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
  actions: {
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  noCompaniesSubText: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    // lineHeight: "16.5px",
    letterSpacing: "0.11px",
    margin: " 0px",
    // maxWidth: "130px",
    // paddingTop: "8px",
    // paddingBottom: "24px",
  },
};
