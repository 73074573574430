import React from "react";
import { Outlet } from "react-router-dom";

function ProjectsLayout() {
  return (
    // <div style={projectsLayout.projectsContainer}>
    <section style={projectsLayout.page}>
      <Outlet />
    </section>
    // </div>
  );
}

export default ProjectsLayout;

const projectsLayout = {
  projectsContainer: {
    display: "flex",
  },
  page: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
};
