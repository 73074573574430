import React from "react";
import "./sectionComponent.css";
import PROTECTED_IMG from "../../../assets/img/protected.png";

/* eslint-disable-next-line */
function SectionComponent({ title, subTitle, img, type }) {
  let styleClass;

  if (type === "aspice") {
    styleClass = "aspice";
  } else if (type === "behavioural") {
    styleClass = "behavioural";
  } else {
    styleClass = "all";
  }

  return (
    <section
      // style={{
      //   background: type === "aspice" ? "#F4FCFF" : "transparent",
      //   paddingBottom: type === "behavioural" ? "100px" : "0px",
      // }}
      className={styleClass}
    >
      <div className="section_container">
        <div className="section_wrapper">
          <h1 className="section_title">{title}</h1>
          <h3 className="section_subTitle" style={{ display: subTitle ? "block" : "none" }}>
            {subTitle}
          </h3>
          <img
            src={img}
            alt={title}
            style={{ display: img ? "block" : "none" }}
            className="section_container_img"
          />
          {type === "behavioural" ? (
            <div style={{ alignSelf: "baseline", paddingTop: "26px" }}>
              <p className="behavioural_title">Enables fast parametrizattion</p>
              <p className="behavioural_title">Enables full system level simulation of HW + SW</p>
              <div className="behavioural_wrapper">
                <img src={PROTECTED_IMG} alt="protected shield" className="protected_img" />
                <p className="behavioural_text">
                  This symbol indicates intellectual property protection of the marked content
                </p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
}

export default SectionComponent;
