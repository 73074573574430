import axios from "axios";
import { errorHandler, options } from "./index";
import { API_URL } from "../config";
import { toast_notifications } from "../utils/notifications";

export function createNewTeam(body) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/team", body, "post"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";

      if (responseOK) {
        toast_notifications.success("Team successfully created!");
      }
    } catch (error) {
      console.warn("Error:", error);
      dispatch(errorHandler(error));
    }
  };
}

export function assignUsersToTeam(body) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/team/users", body, "post"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";

      if (responseOK) {
        toast_notifications.success("Users successfully assigned to team!");
      }
    } catch (error) {
      console.warn("Error:", error);
      dispatch(errorHandler(error));
    }
  };
}

export function deleteTeamUser(body) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/team/deleteTeamUser", body, "delete"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        toast_notifications.success(response.data.message);
      }
    } catch (error) {
      console.warn("Error:", error);
      dispatch(errorHandler(error));
    }
  };
}

export function deleteTeam(body) {
  return async (dispatch) => {
    try {
      const response = await axios(options(API_URL + "/team/deleteTeam", body, "delete"));
      const responseOK = response && response.status === 200 && response.statusText === "OK";
      if (responseOK) {
        toast_notifications.success(response.data.message);
      }
    } catch (error) {
      console.warn("Error:", error);
      dispatch(errorHandler(error));
    }
  };
}

export const teamServices = {
  getAllTeams: () => axios(options(API_URL + "/teams", {}, "get")),
  createNewTeam: (body) => axios(options(API_URL + "/team", body, "post")),
  assignUsersToTeam: (body) => axios(options(API_URL + "/team/users", body, "post")),
  deleteTeamUser: (body) => axios(options(API_URL + "/team/deleteTeamUser", body, "delete")),
  deleteTeam: (body) => axios(options(API_URL + "/team/deleteTeam", body, "delete")),
  getTeamsByUserId: (data) => axios(options(API_URL + `/teams/${data}`, {}, "get")),
  updateTeamName: (data) => axios(options(API_URL + "/teams/update", data, "post")),
};
