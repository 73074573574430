/* eslint-disable */
import { IconButton } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

function ProjectCardComponent({ name, id, company, userId }) {
  const navigate = useNavigate();

  const handleNavigateToProject = (projectID) => {
    sessionStorage.setItem("i", 1);
    navigate(`/dashboard/projects/${projectID}`, { userId });
  };

  return (
    <div style={cardStyles.cardWrapper}>
      <div style={cardStyles.cardContent}>
        <div style={cardStyles.content}>
          <p style={cardStyles.title}>
            {name?.length > 29 ? name?.slice(0, 29).concat("...") : name}
          </p>
          <div style={cardStyles.info}>
            <span style={cardStyles.separator}>•</span>
            <p style={cardStyles.infoLabels}>{company ? company : "unkown"}</p>
          </div>
        </div>
        <div style={cardStyles.navigation}>
          <IconButton onClick={() => handleNavigateToProject(id)}>
            <ArrowForwardIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default ProjectCardComponent;

const cardStyles = {
  cardWrapper: {
    width: "274px",
    zIndex: "999",
    backgroundColor: "white",
    // height: "44px",
    // padding: "12px 24px",
  },
  cardContent: {
    display: "flex",
    width: "100%",
    // margin: "12px 24px",
    padding: "12px 24px",
    borderBottom: "1px solid #D1D2D2",
  },
  title: {
    margin: 0,
    color: "#1B1E1F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px,",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingBottom: "2px",
  },
  info: {
    margin: 0,
    display: "flex",
    alignItems: "center",
  },
  infoLabels: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px,",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    margin: 0,
  },
  separator: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px,",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    padding: "0px 4px",
  },
  content: {
    width: "204px",
  },
  navigation: {},
};
