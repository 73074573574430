/* eslint-disable */
import "./projectCard.css";
import { useState } from "react";
import { useHistory } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { deleteProject, projectService, updateArchivedStatus } from "../../../services/project";
import StarIcon from "@mui/icons-material/Star";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch } from "react-redux";
import ConfirmDialog from "../../dialogs/ConfirmDialog";
import { getDateTime2 } from "../../../utils/misc";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import PermissionWrapper, { PermissionsSchema } from "../../auth/PermissionsWrapper";
import NavigationWrapper from "../../auth/NavigationWrapper";
import { toast_notifications } from "../../../utils/notifications";
import NewConfirmDialog, { confirmTypes } from "../../dialogs/NewConfirmDialog/NewConfirmDialog";
import { LightTooltip } from "../../tables/TermsTable/TermsTable";

export const cardTypes = {
  isFavorite: "favorite",
  regular: "regular",
  projects: "projects",
};

const ProjectsCard = ({
  id,
  userId,
  name,
  market,
  companyName,
  created,
  updated,
  setEditForm,
  setProjectForEdit,
  isFavorite,
  isArchived,
  type,
  projectFiles,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  const queryClient = useQueryClient();

  const archiveMutation = useMutation({
    mutationFn: projectService.updateProjectArchived,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projectsByCompanyIdAndTeamId"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const addToFavouriteMutation = useMutation({
    mutationFn: projectService.updateProjectFavoriteStatus,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projectsByCompanyIdAndTeamId"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const deleteMutation = useMutation({
    mutationFn: projectService.deleteProject,
    onSuccess: (data) => {
      queryClient.invalidateQueries(["projectsByCompanyIdAndTeamId"]);
      toast_notifications.success(data?.data?.message);
    },
    onError: (data) => {
      toast_notifications.error(data?.data?.message);
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // setEditForm(true);
    setProjectForEdit(id);
    setAnchorEl(null);
  };

  const handleNavigateToProject = () => {
    sessionStorage.setItem("i", 1);
    navigate(`/dashboard/projects/${id}`);
    // history.push(`/dashboard/projects/${id}`);
    // history.replace(`/dashboard/projects/${id}`);
  };

  const handleIsFavoriteStatus = async () => {
    const data = { projectId: id, status: isFavorite, userId: userId };
    // await dispatch(updateProjectFavoriteStatus(data));
    addToFavouriteMutation.mutate(data);
  };

  const handleArchiveStatus = async (id, userId, archived) => {
    const projectData = {
      projectId: id,
      userId: userId,
      archived: archived,
    };

    setAnchorEl(null);
    // await dispatch(updateArchivedStatus(projectData));
    archiveMutation.mutate(projectData);
    queryClient.invalidateQueries(["projectsByCompanyIdAndTeamId"]);
  };
  const handleDeleteProject = async (data) => {
    setAnchorEl(null);
    deleteMutation.mutate(data);
  };

  function handleCloseDeleteDialog() {
    setIsDeleteDialogOpen(false);
  }

  let content;

  if (type === cardTypes.regular) {
    content = (
      <div className="project_card_container">

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            {isArchived ? <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px", }}>
              <span style={projectsCardStyles.demoTag}>Archived</span>
            </Typography>
              : <Typography sx={{ fontWeight: "400", fontSize: "11px", lineHeight: "16.5px", }}>
                <span style={projectsCardStyles.productiveTag}>Active</span>
              </Typography>}
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>{companyName}</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>{market}</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>ID:{id}</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            {isFavorite ? (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleIsFavoriteStatus}>
                <LightTooltip title={`Remove favorite`} arrow placement="top">
                  <IconButton sx={{ margin: "0px", padding: "0px" }}>
                    <StarIcon sx={{ color: "#1E9DF2" }} fontSize="small" />
                  </IconButton>
                </LightTooltip>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer" }} onClick={handleIsFavoriteStatus}>
                <LightTooltip title={`Make favorite`} arrow placement="top">
                  <IconButton sx={{ margin: "0px", padding: "0px" }}>
                    <StarBorderIcon color="disabled" fontSize="small" />
                  </IconButton>
                </LightTooltip>
              </div>
            )}
          </div>
          <Link
            className="link_styles"
            to={{ pathname: `/dashboard/projects/${id}`, state: { project_id: id } }}
          >
            <div>
              <p style={projectsCardStyles.description}>{name}</p>
            </div>
            <div
              style={{
                display: "flex",
              }}
            >
              <p style={projectsCardStyles.timeStamps}>Last edit: {getDateTime2(updated)} </p>
              <p style={projectsCardStyles.timeStamps2}>Created: {getDateTime2(created)} </p>
            </div>
          </Link>
        </div>

        <NavigationWrapper
          allowedPermissions={[
            PermissionsSchema.projects.update_project,
            PermissionsSchema.projects.delete_project,
          ]}
        >
          <div style={{ alignSelf: "center" }}>
            <IconButton
              id="project-actions"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="project-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "project-actions",
              }}
              sx={projectsCardStyles.menuStyles}
            >
              <PermissionWrapper allowedPermission={PermissionsSchema.projects.update_project}>
                <Link
                  to={{
                    pathname: `/dashboard/projects/${id}`,
                  }}
                  state={{ project_id: id, isOpen: true, userId: userId }}
                  style={{ textDecoration: "none" }}
                >
                  <MenuItem>
                    <BorderColorIcon sx={projectsCardStyles.iconStyles} />
                    <span style={projectsCardStyles.actions}>Edit</span>
                  </MenuItem>
                </Link>
              </PermissionWrapper>
              <PermissionWrapper allowedPermission={PermissionsSchema.projects.delete_project}>
                <MenuItem
                  onClick={() => {
                    setIsDeleteDialogOpen(true);
                    setAnchorEl(null);
                  }}
                >
                  <DeleteOutlineIcon sx={projectsCardStyles.iconStyles} />{" "}
                  <span style={projectsCardStyles.actions}> Delete</span>
                </MenuItem>
              </PermissionWrapper>
              {isArchived ? (
                <MenuItem
                  onClick={() => {
                    handleArchiveStatus(id, userId, isArchived);
                  }}
                >
                  <DriveFileMoveIcon sx={projectsCardStyles.iconStyles} />{" "}
                  <span style={projectsCardStyles.actions}>Remove from Archive</span>
                </MenuItem>
              ) : (
                <MenuItem onClick={() => handleArchiveStatus(id, userId, isArchived)}>
                  <DriveFileMoveIcon sx={projectsCardStyles.iconStyles} />{" "}
                  <span style={projectsCardStyles.actions}>Move to Archive</span>
                </MenuItem>
              )}
            </Menu>
            {/* <ConfirmDialog
              open={isDeleteDialogOpen}
              onClose={handleCloseDeleteDialog}
              title="Are you sure you want to delete this project?"
              confirmCallback={async (event) => {
                if (event) {
                  await handleDeleteProject({ id, userId });
                }
              }}
            /> */}
            <NewConfirmDialog
              open={isDeleteDialogOpen}
              onClose={handleCloseDeleteDialog}
              action={() => handleDeleteProject({ id, userId, projectFiles })}
              data={{ name: name }}
              type={confirmTypes.deleteProject}
            />
          </div>
        </NavigationWrapper>
      </div>
    );
  } else if (type === cardTypes.isFavorite) {
    content = (
      <div style={projectsCardStyles.projectsCardsContainer2}>
        <div
          style={{
            display: "flex",
            margin: "6px 24px",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <StarIcon sx={{ color: "#1E9DF2" }} fontSize="small" />
              <span style={projectsCardStyles.dotSeparator}>•</span>
              <p style={projectsCardStyles.separatorText2}>{name}</p>
            </div>

            <p style={projectsCardStyles.separatorText2}>ID:{id}</p>
          </div>
          <div style={{ alignSelf: "center" }}>
            <IconButton onClick={handleNavigateToProject}>
              <ArrowForwardIcon />
            </IconButton>
          </div>
        </div>
      </div>
    );
  } else if (cardTypes.projects) {
    content = (
      <div className="project_card_container">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", alignItems: "flex-end" }}>
            <p className={`archived ${isArchived === false ? "active" : "disabled"}`}>
              {isArchived === false ? "active" : "archived"}
            </p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>Mazda</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>{market}</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            <p style={projectsCardStyles.separatorText}>ID: {id}</p>
            <span style={projectsCardStyles.dotSeparator}>•</span>
            {isFavorite ? (
              <StarIcon sx={{ color: "#1E9DF2", alignSelf: "center" }} fontSize="small" />
            ) : (
              <StarBorderIcon sx={{ alignSelf: "center" }} color="disabled" fontSize="small" />
            )}
          </div>
          <div>
            <p style={projectsCardStyles.description}>{name}</p>
          </div>
          <p style={projectsCardStyles.timeStamps}>Created: {getDateTime2(created)} </p>
        </div>

        <div style={{ alignSelf: "center" }}>
          <IconButton
            id="project-actions"
            // aria-controls={open ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? "true" : undefined}
            onClick={handleNavigateToProject}
          >
            <ArrowForwardIcon />
          </IconButton>
          {/* <Menu
            id="project-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "project-actions",
            }}
            sx={projectsCardStyles.menuStyles}
          >
            <Link
              to={{
                pathname: `/dashboard/projects/${id}`,
                state: { project_id: id, isOpen: true },
              }}
              style={{ textDecoration: "none" }}
            >
              <MenuItem>
                <BorderColorIcon sx={projectsCardStyles.iconStyles} />
                <span style={projectsCardStyles.actions}>Edit</span>
              </MenuItem>
            </Link>

            <MenuItem
              onClick={() => {
                setIsDeleteDialogOpen(true);
                setAnchorEl(null);
              }}
            >
              <DeleteOutlineIcon sx={projectsCardStyles.iconStyles} />{" "}
              <span style={projectsCardStyles.actions}> Delete</span>
            </MenuItem>
            {isArchived ? (
              <MenuItem onClick={() => handleArchiveStatus(id, userId, false)}>
                <DriveFileMoveIcon sx={projectsCardStyles.iconStyles} />{" "}
                <span style={projectsCardStyles.actions}>Remove from Archive</span>
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleArchiveStatus(id, userId, true)}>
                <DriveFileMoveIcon sx={projectsCardStyles.iconStyles} />{" "}
                <span style={projectsCardStyles.actions}>Move to Archive</span>
              </MenuItem>
            )}
          </Menu> */}
          {/* <ConfirmDialog
            open={isDeleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            title="Are you sure you want to delete this project?"
            confirmCallback={async (event) => {
              if (event) {
                await handleDeleteProject({ id, userId });
              }
            }}
          /> */}
          <NewConfirmDialog
            open={isDeleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            action={() => handleDeleteProject({ id, userId, projectFiles })}
            data={{ name: name }}
            type={confirmTypes.deleteProject}
          />
        </div>
      </div>
    );
  }

  return content;
};

export default ProjectsCard;

const projectsCardStyles = {
  projectsCardsContainer2: {
    borderTop: "1px solid #E8E8E9",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    // flexDirection: "column",
  },
  dotSeparator: {
    padding: "0px 8px",
    alignSelf: "center",
  },
  separatorText: {
    color: "var(--main-grey-grey-90, #1B1E1F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    margin: "0px",
    placeSelf: "center",
  },
  separatorText2: {
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    margin: "0px",
    padding: "0px 5px",
  },
  demoTag: {
    fontSize: "14px",
    minWidth: "max-content",
    backgroundColor: "#EEF3FF",
    color: "#7B6FA3",
    padding: "0px 6px",
    margin: "0px 4px 0px 0px",
    // padding: "0px 4px 0px 4px",
    padding: "1px 8px 2px 8px",
    gap: "4px",
    borderRadius: "1px",
    width: "20px",
    height: "20px",
  },
  productiveTag: {
    backgroundColor: "#EEFAFF",
    color: "#43A5CB",
    fontSize: "14px",
    minWidth: "max-content",
    padding: "0px 6px",
    margin: "0px 4px 0px 0px",
    // padding: "0px 4px 0px 4px",
    padding: "1px 8px 2px 8px",
    gap: "4px",
    borderRadius: "1px",
    width: "20px",
    height: "20px",
  },
  description: {
    color: " var(--main-primary-normal, #1E9DF2)",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    marginTop: "4px",
    marginBottom: "4px",
  },
  timeStamps: {
    color: "var(--main-grey-grey-50, #8D8E8F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    marginTop: "0px",
    margin: "0px",
  },
  timeStamps2: {
    color: "var(--main-grey-grey-50, #8D8E8F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16.5px",
    letterSpacing: "0.11px",
    marginTop: "0px",
    marginLeft: "10px",
  },
  actions: {
    color: "var(--main-grey-grey-90, #1B1E1F)",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    paddingLeft: "4px",
  },
  iconStyles: {
    color: "#D1D2D2",
  },
  menuStyles: {
    "& .MuiPaper-root": {
      borderRadius: "8px",
      background: "var(--main-grey-white, #FFF)",
      boxShadow: "0px 28px 64px 0px rgba(209, 210, 210, 0.40)",
    },
  },
};
