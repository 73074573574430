/* eslint-disable */
import getTheUserState from "../../utils/userState";

const NavigationWrapper = ({ children, allowedPermissions }) => {
  // We get the user state from the global state
  const user = getTheUserState();

  // List user permissions
  const userPermissions = user?.user?.role.permissions;

  const hasPermission = (userPermissions, allowedPermissions) => {
    return allowedPermissions?.some((permission) => {
      return userPermissions?.some(
        (userPermission) =>
          userPermission.permission_name === permission && userPermission.status === true
      );
    });
  };
  // Check if the user have any of the allowed permissions
  const userHasPermission = hasPermission(userPermissions, allowedPermissions);

  // Check the status of the permission
  if (userHasPermission) {
    return children;
  }
  return null;
};

export default NavigationWrapper;
