/* eslint-disable */
import {
  Autocomplete,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateCompanyDialog from "./CreateCompanyDialog";
import UserRoleDropdownNoForm from "../../dropdown/UserRoleDropdownNoForm";
import { companyServices } from "../../../services/company";
import { useQuery } from "@tanstack/react-query";
import "./assignCompanyToUser.css";
import getTheUserState from "../../../utils/userState";
import { PermissionsSchema } from "../../auth/PermissionsWrapper";
import { LightTooltip } from "../../tables/TermsTable/TermsTable";

const AssignCompanyToUserDialog = ({ open, onClose, action, data }) => {
  const [pickedCompany, setPickedCompany] = useState([]);
  const [pickedTeam, setPickedTeam] = useState([]);
  const [teamOptions, setTeamOptions] = useState([]);
  const [openRegisterCompany, setOpenRegisterCompany] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const loggedUser = getTheUserState();

  const checkPermissionCreateCompany = loggedUser?.user?.role?.permissions?.some((permission) => {
    return (
      permission.permission_name === PermissionsSchema?.companies?.create_company &&
      permission.status === true
    );
  });

  const {
    data: allCompanies,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["allCompanies"],
    queryFn: companyServices.getAllCompanies,
  });

  useEffect(() => {
    if (pickedCompany) {
      const allTeams = pickedCompany.flatMap(company => company.Teams);
      setTeamOptions(allTeams);
    }
    if (pickedCompany.length === 0) {
      setPickedTeam([]);
    }
  }, [pickedCompany]);

  useEffect(() => {
    if (pickedCompany?.length === 0 || pickedTeam?.length === 0 || selectedRole === null) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [pickedCompany, pickedTeam, selectedRole]);

  const closeAfterCreate = () => {
    setOpenRegisterCompany(false);
    onClose();
  };

  const handleCompanyChange = (event, value) => {
    setPickedCompany(value);
    const remainingTeams = pickedTeam.filter(team =>
      value.some(company =>
        company.Teams.some(companyTeam => companyTeam.id === team.id)
      )
    );
    setPickedTeam(remainingTeams);
  }

  const handleActionExecution = () => {
    const companyIds = pickedCompany.map(pickedCompanyItem => pickedCompanyItem.id)
    const teamIds = pickedTeam.map(pickedTeamItem => pickedTeamItem.id)
    action(companyIds, teamIds, selectedRole);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={open || false}
        onClose={onClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
        }}
      >
        <div style={{ alignSelf: "flex-end", marginBottom: "-60px", padding: "10px" }}>
          <IconButton onClick={onClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        <DialogContent sx={muiDialogStyles.contentStyles}>
          <DialogTitle sx={muiDialogStyles.dialogTitle}>Activate User</DialogTitle>
          <p style={muiDialogStyles.additionalInfoStyle}>
            The new user, "
            <Link
              style={{ textDecoration: "none", color: "blue" }}
              to={`/dashboard/users/${data.id}`}
            >
              {data?.user}
            </Link>
            ", requires company and team assignment before activation. Please select or create company and team to
            which the user will have access.
          </p>
          {/* <p className="filter_title_assign">Show only companies with team(s)</p> */}
          <Autocomplete
            id="searchable-dropdown"
            multiple
            options={allCompanies?.data?.companies?.filter(company => company?.Teams?.length > 0) || []}
            getOptionLabel={(option) => option?.name}
            onChange={handleCompanyChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select company"
                size="small"
                sx={{
                  fontFamily: "IBM Plex Sans",
                  minWidth: "220px",
                  [`& fieldset`]: {
                    borderRadius: "4px",
                    fontFamily: "IBM Plex Sans",
                  },
                }}
              />
            )}
          />
          {/* <p className="filter_title_assign">Team</p> */}
          <Autocomplete
            id="searchable-dropdown"
            multiple
            options={teamOptions || []}
            value={pickedTeam || []}
            getOptionLabel={(option) => option?.name}
            onChange={(event, value) => setPickedTeam(value)}
            disabled={pickedCompany.length === 0 || teamOptions.length === 0}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select team"
                size="small"
                sx={{
                  fontFamily: "IBM Plex Sans",
                  minWidth: "220px",
                  [`& fieldset`]: {
                    borderRadius: "4px",
                    fontFamily: "IBM Plex Sans",
                  },
                }}
              />
            )}
          />
          <div style={{ marginTop: "6px" }}>
            {/* <p className="filter_title_assign">Role</p> */}
            <UserRoleDropdownNoForm
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
            />
          </div>
          <Button
            id="assignCompany"
            variant="contained"
            color="primary"
            sx={muiDialogStyles.btn}
            onClick={handleActionExecution}
            disabled={isDisabled}
          >
            Activate User
          </Button>
          <h2 style={muiDialogStyles.orLine}>
            <span style={{ background: "#fff", padding: "0 10px" }}>or</span>
          </h2>
          {checkPermissionCreateCompany ? <Button
            id="createCompany"
            variant="contained"
            color="primary"
            sx={muiDialogStyles.btn}
            onClick={() => setOpenRegisterCompany(true)}
          >
            Create New Company
          </Button> :
            <LightTooltip title={`You don't have permission to create company`} arrow placement="bottom">
              <div style={{ display: 'inline-block' }}>
                <Button
                  id="createCompany"
                  variant="contained"
                  color="primary"
                  sx={muiDialogStyles.btn}
                  onClick={() => setOpenRegisterCompany(true)}
                  disabled={!checkPermissionCreateCompany}
                >
                  Create New Company
                </Button>
              </div>
            </LightTooltip>}
        </DialogContent>
      </Dialog>
      <CreateCompanyDialog
        open={openRegisterCompany}
        onClose={closeAfterCreate}
        onBack={() => setOpenRegisterCompany(false)}
        data={data}
      />
    </div>
  );
};

export default AssignCompanyToUserDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    maxWidth: "80%",
    alignSelf: "center",
  },
  btn: {
    borderRadius: "2px",
    marginBottom: "20px",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "100%",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  orLine: {
    width: "100%",
    color: "#9e9e9e",
    textAlign: "center",
    borderBottom: "1px solid #000",
    lineHeight: "0.1em",
    fontFamily: "IBM Plex Sans",
    fontWeight: "400",
    fontSize: "18px",
    marginBottom: "20px",
    marginTop: "0px",
  },
  contentStyles: {
    display: "flex",
    flexDirection: "column",
  },
  additionalInfoStyle: {
    color: "#9e9e9e",
    width: "100%",
    fontFamily: "IBM Plex Sans",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: "400",
    textAlign: "left",
    lineHeight: "150%",
    letterSpacing: "-0.16px",
  },
  newTitle: {
    width: "100%",
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "23px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32.2px",
    letterSpacing: "-0.46px",
    alignSelf: "center",
    margin: "24px 0px 0px 0px",
    backgroundColor: "#EEFAFF",
  },
};
