/* eslint-disable */
import { Button, Dialog, DialogTitle, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const newsDialogType = {
    delete: "delete news",
    publish: "publish news",
    stopEdit: "stop edit",
    edit: "edit",
    draft: "draft",
}

const NewsDialogs = ({ open, onClose, type, action }) => {

    const handleClose = () => {
        onClose();
    };

    const handleAction = () => {
        action();
        onClose();
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                sx={{
                    "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
                }}
            >
                <div style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px" }}>
                    <IconButton onClick={handleClose}>
                        <CloseIcon color="disabled" />
                    </IconButton>
                </div>
                {(type !== newsDialogType.draft && type !== newsDialogType.edit) && <DialogTitle sx={muiDialogStyles.dialogTitle}>
                    {(type !== newsDialogType.stopEdit) ? `Are you sure you want to ${type === newsDialogType.delete ? "delete" : "publish"}  this news?`
                        : `Are you sure you want to cancel editing ? Changes you made so far will not  be saved`}
                </DialogTitle>}
                {(type === newsDialogType.draft || type === newsDialogType.edit) && <DialogTitle sx={muiDialogStyles.dialogTitle}>
                    Are you sure you want to save this news?
                </DialogTitle>}
                <div style={muiDialogStyles.actionsContainer}>
                    {type === newsDialogType.publish && <Button onClick={handleAction} autoFocus sx={muiDialogStyles.btn}>
                        Yes, Publish
                    </Button>}
                    {type === newsDialogType.draft && <Button onClick={handleAction} autoFocus sx={muiDialogStyles.btn}>
                        Yes, Save
                    </Button>}
                    {type === newsDialogType.stopEdit && <Button onClick={handleAction} autoFocus sx={muiDialogStyles.btn}>
                        Yes, Cancel
                    </Button>}
                    {type === newsDialogType.delete && <Button onClick={handleAction} autoFocus sx={muiDialogStyles.btn}>
                        Yes, Delete
                    </Button>}
                    {type === newsDialogType.edit && <Button onClick={handleAction} autoFocus sx={muiDialogStyles.btn}>
                        Yes, Save
                    </Button>}
                    <Button onClick={handleClose} sx={muiDialogStyles.btnCancel}>
                        {type === newsDialogType.delete ? `Cancel` : `Keep Editing`}
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default NewsDialogs;

const muiDialogStyles = {
    dialogTitle: {
        color: "#1B1E1F",
        textAlign: "center",
        fontFamily: "IBM Plex Sans",
        fontSize: "32px",
        fontStyle: "normal",
        fontWeight: "600",
        lineHeight: "44.8px",
        letterSpacing: "-0.64px",
        // maxWidth: "60%",
        width: "423px",
        alignSelf: "center",
        padding: "48px 64px 48px 64px",
    },
    actionsContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 0px 48px 0px",
    },
    btn: {
        borderRadius: "2px",
        background: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        color: "white",
        "&:hover": {
            background: "#1E9DF2",
        },
    },
    btnCancel: {
        color: "#1E9DF2",
        fontFamily: "IBM Plex Sans",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "22.4px",
        letterSpacing: "-0.32px",
        textTransform: "capitalize",
        width: "160px",
        height: "54px",
        marginTop: "4px",
    },
};
