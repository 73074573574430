/* eslint-disable */
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DisplayTermsAndConditions from "./DisplayTermsAndConditions";

function TermsAndConditionsDialog({ filteredTools }) {
  const [open, setOpen] = useState(false);
  const [stepper, setStepper] = useState(0);
  const [elements, setElements] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
    const uniqueIds = [...new Set(filteredTools?.map((item) => item.terms_and_condition_id))];
    setElements(uniqueIds);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => {
      setStepper(0);
    }, 300);
  };

  const handleStepperActions = () => {
    setStepper((prev) => prev + 1);
  };

  return (
    <div>
      <Button
        variant="contained"
        color="info"
        onClick={handleClickOpen}
        sx={muiDialogStyles.btnDownload}
      >
        Download
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiBackdrop-root": { background: "#EEFAFFCC" },
          "& .MuiPaper-root": { width: "800px" },
        }}
      >
        <div style={{ alignSelf: "flex-end", marginBottom: "-30px", padding: "10px" }}>
          <IconButton onClick={handleClose}>
            <CloseIcon color="disabled" />
          </IconButton>
        </div>
        {stepper === 0 ? (
          <div>
            <DialogTitle sx={muiDialogStyles.dialogTitle}>Terms & Conditions</DialogTitle>
            <DialogContent sx={muiDialogStyles.content}>
              Before downloading certain files, you should read and accept the terms and conditions
              for each file. These terms and conditions may vary from file to file, so it is
              important to read them carefully. In general, the terms and conditions will explain
              the rights and obligations of both the file owner and the downloader. By downloading
              the file, you agree to be bound by these terms and conditions.
            </DialogContent>
            <DialogActions>
              <Button onClick={handleStepperActions} autoFocus sx={muiDialogStyles.btnStart}>
                Start
              </Button>
            </DialogActions>
          </div>
        ) : null}
        {stepper > 0 ? (
          <DisplayTermsAndConditions elements={elements} tools={filteredTools} />
        ) : null}
      </Dialog>
    </div>
  );
}

export default TermsAndConditionsDialog;

const muiDialogStyles = {
  dialogTitle: {
    color: "#1B1E1F",
    textAlign: "center",
    fontFamily: "IBM Plex Sans",
    fontSize: "32px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "44.8px",
    letterSpacing: "-0.64px",
    // maxWidth: "60%",
    alignSelf: "center",
    // padding: "48px 24px 0px 24px",
    marginBottom: "30px",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "48px 0px",
  },
  btnDownload: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnStart: {
    borderRadius: "2px",
    background: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    color: "white",
    // marginRight: "40px",
    margin: "3px 40px 23px 0px",
    "&:hover": {
      background: "#1E9DF2",
    },
  },
  btnCancel: {
    color: "#1E9DF2",
    fontFamily: "IBM Plex Sans",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "22.4px",
    letterSpacing: "-0.32px",
    textTransform: "capitalize",
    width: "160px",
    height: "54px",
    marginTop: "4px",
  },
  content: {
    // display: "flex",
    // flexDirection: "column",
    color: "#8D8E8F",
    fontFamily: "IBM Plex Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "normal",
    letterSpacing: "0.14px",
    margin: "20px 40px 20px 40px",
    minWidth: "70%",
    padding: "0px",
  },
};
